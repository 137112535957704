import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import rest from "../../../rest";
import { MySnackbar } from '../../mySnackbar/MySnackbar';
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: theme.palette.background.paper,
//         display: 'flex',
//         minHeight: window.innerHeight - 300,
//     },
// }));




const EmailConfig = () => {
    // const classes = useStyles();
    const [snack, setSnack] = useState({});
    const [loading, setLoading] = useState(false);
    const [mailConfig, setMailConfig] = useState({
        host: "",
        defaultMail: "",
        loginMail: "",
        pswMail: "",
        replyMail: "",
        signatureMail: "",
        testMail: "",
        activeTestMail:false,
    });
    const [errorConfigMail, setErrorConfigMail] = useState(false);
    const [errorTestMail, setErrorTestMail] = useState(false);

    useEffect(() => {
        setLoading(true)
        rest.get('/parametresV2/getEmailConfig.json').then(function (response) {
            setMailConfig(response.data)
            setLoading(false)
        })
    }, [])

    const saveMailConfig = () => {
        let error = false;
        let errorTestMail = false;
        if ((mailConfig.host === "") || (mailConfig.defaultMail === "") || (mailConfig.loginMail === "")) {
            error = true;
        }

        if ((mailConfig.activeTestMail) && (mailConfig.testMail==="")) {
            errorTestMail = true;
        }
        setErrorTestMail(errorTestMail)
        setErrorConfigMail(error)
        if ((!error) && (!errorTestMail)) {
            setLoading(true)
            rest.post('/parametresV2/saveEmailConfig.json', mailConfig).then(function (response) {
                if (response.data.error) {
                    setSnack({ message: response.data.message, show: true, severity: "error" })
                } else {
                    setSnack({ message: response.data.message, show: true, severity: "success" })
                }
                setLoading(false)
            })
        }
    }
 

    return (
        <>
            <LoadingIndicator open={loading} />
            <MySnackbar params={snack} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Configuration des emails</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="host"
                        name="host"
                        label="Serveur email"
                        type="text"
                        required
                        fullWidth
                        value={mailConfig.host}
                        onChange={(e) => {
                            setMailConfig(prevState => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                            }));
                        }}
                        error={errorConfigMail}
                        helperText={errorConfigMail ? "Champ obligatoire" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="defaultMail"
                        name="defaultMail"
                        label="Default Email"
                        type="text"
                        required
                        fullWidth
                        value={mailConfig.defaultMail}
                        onChange={(e) => {
                            setMailConfig(prevState => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                            }));
                        }}
                        error={errorConfigMail}
                        helperText={errorConfigMail ? "Champ obligatoire" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="loginMail"
                        name="loginMail"
                        label="Login"
                        type="text"
                        required
                        fullWidth
                        value={mailConfig.loginMail}
                        onChange={(e) => {
                            setMailConfig(prevState => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                            }));
                        }}
                        error={errorConfigMail}
                        helperText={errorConfigMail ? "Champ obligatoire" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="pswMail"
                        name="pswMail"
                        label="Password"
                        type="password"
                        fullWidth
                        value={mailConfig.pswMail}
                        onChange={(e) => {
                            setMailConfig(prevState => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="replyMail"
                        name="replyMail"
                        label="Email réponse"
                        type="text"
                        fullWidth
                        value={mailConfig.replyMail}
                        onChange={(e) => {
                            setMailConfig(prevState => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="signatureMail"
                        name="signatureMail"
                        label="Email signature"
                        type="text"
                        fullWidth
                        value={mailConfig.signatureMail}
                        onChange={(e) => {
                            setMailConfig(prevState => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                            }));
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{marginTop:"40px"}}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Mode Test</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={mailConfig.activeTestMail}
                                name="activeTestMail"
                                onChange={(e) => {
                                    setMailConfig(prevState => ({
                                        ...prevState,
                                        [e.target.name]: e.target.checked
                                    }));
                                }}
                            />
                        }
                        label="Activer le mode test"
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="testMail"
                        name="testMail"
                        label="Email test"
                        type="text"
                        required={mailConfig.activeTestMail}
                        fullWidth
                        value={mailConfig.testMail}
                        onChange={(e) => {
                            setMailConfig(prevState => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                            }));
                        }}
                    error={errorTestMail}
                    helperText={errorTestMail ? "Champ obligatoire" : ""}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: "40px" }}>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                    <Button color="primary" variant="contained" onClick={saveMailConfig}>Enregistrer</Button>
                </Grid>
            </Grid>
        </>
    );
};

export default EmailConfig;
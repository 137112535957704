import React, { useState} from 'react'
import { Paper, Button, Grid, Popover, Dialog, DialogTitle, DialogContent, DialogContentText ,DialogActions} from '@material-ui/core';
import FormulaireInscription from '../../components/formulaire/FormulaireInscription';
import { useStyleProfil } from "./Profil.css";
import { useStylesGlobal } from "../../pages/GlobalStyle.css";
import { useSelector } from "react-redux";
import PhotoProfil from '../../components/PhotoProfil/PhotoProfil';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import rest from '../../rest';
import { useHistory } from 'react-router-dom';

export const Profil = () => {
    const classesProfil = useStyleProfil();
    const classesGlobal = useStylesGlobal();
    const currentUser = useSelector(state => state.user);
    const [open, setOpen] = useState(false);
    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
    const [snack, setSnack] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const openConfirmation = Boolean(anchorEl);
    const history = useHistory();

    const handlePhotoDialog = (event) => {
        setOpen(true)
    }

    const handleDeleteMyProfil = () => {
        setAnchorEl(null);
        setOpenDialogConfirmDelete(true)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseDialogConfirmDelete = () => {
        setOpenDialogConfirmDelete(false)
    }

    const handleDialogConfirmDelete = () => {
        rest.post('/profil_info/supprimer.json', { vAction: "suppressionPropreProfil" }).then((response) => {
            history.push("/connexion.html")
        })
    }

    return (
        <main>
            <div />
            <Paper elevation={3} className={classesProfil.paperClass}>
                <MySnackbar params={snack} />
                <PhotoProfil open={open} setOpen={setOpen} idBenevole={currentUser.User.User_Person_ID} />
                <FormulaireInscription idBenevole={currentUser.User.User_Person_ID} fromProfil={true} setSnack={setSnack} />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Button fullWidth variant="contained" color="secondary" title="Aidez-nous à vous reconnaitre sur le terrain" onClick={handlePhotoDialog}> Ajouter ou modifier une photo de profil</Button>
                    </Grid>
                    <Grid item xs={12}>
                        Au moins l'un des champs marqués de deux étoiles ** est obligatoire.
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button onClick={handleClick} className={classesGlobal.buttonFermer}>
                            Supprimer mon profil
                        </Button>
                        <Popover
                            open={openConfirmation}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            className="popover_class"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Button variant="contained" className={classesGlobal.buttonFermer} onClick={handleDeleteMyProfil}>Confirmer Suppression profil</Button>
                        </Popover>
                    </Grid>
                </Grid>
                {/* confirmation suppression */}
                <Dialog open={openDialogConfirmDelete} onClose={handleCloseDialogConfirmDelete} aria-labelledby="deleteProfil-dialog-title">
                    <DialogTitle id="deleteProfil-dialog-title">Information sur la suppression de votre profil</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Supprimer son profil implique:<br />
                            <ul>
                                <li>Ne plus pouvoir se connecter avec ce compte sur la plateforme</li>
                                <li>Ne plus pouvoir être contacté</li>
                                <li>Perdre son historique des activités précédemment faites lors de l'évènement</li>
                                <li>Ne plus avoir la possibilité d'avoir une attestation bénévole</li>
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialogConfirmDelete} color="default">
                            Fermer
                        </Button>
                        <Button onClick={handleDialogConfirmDelete} className={classesGlobal.buttonFermer} >
                            Supprimer définitivement mon profil
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </main>
    )
}

export default Profil;
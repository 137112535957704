
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const CustomTheme4 = makeStyles((theme) => createStyles({
    modeTestAppBar: {
        backgroundColor: "#37A8AF!important",
        color: "#fff!important",
        minHeight: "15px!important",
        padding: "5px",
    },
    cardHeader: {
        background: "#37A8AF",
        color: "#fff",
    },
bandeauMenu :{
    backgroundColor: "#fff!important",
    color: "#37A8AF!important",
},
toggleMenu :{
    backgroundColor: "#37A8AF!important",
    color: "#fff!important",
    borderRadius: "4px!important",
    marginRight: "15px!important",
    padding: "9px 5px!important",
},
dividerMenu :{
    backgroundColor: "#37A8AF!important",
},
titleMenu :{
    fontWeight: "bold!important",
    /* width: 8%; */
    textAlign: "center",
    height: "55px",
    paddingTop: "7px",
    paddingLeft: "1%",
    paddingRight: "1%",
    lineHeight: "3rem",
    "&:hover": {
        backgroundColor: "#37A8AF!important",
        color: "#fff!important",
        cursor: "pointer",
    },
},
divLogoMenu :{
    marginLeft: "1%",
    marginRight: "1%",
},
logoMenu :{
    maxHeight: "55px",
    float: "left",
},
manifMenu :{
    marginLeft: "1%",
},
/*Sidebar*/
leftmenu :{
    background: "#37A8AF!important",
    color: "#fff!important",
    width: "220px",
    paddingTop: "1%",
},
avatarProfil :{
    width: "75px!important",
    height: "75px!important",
    border: "5px solid #fff",
},
bonjourProfil:{
    color: "#fff!important",
    fontWeight: "600!important",
    marginTop: "10px!important",
    fontSize: "13px",
},
iconsProfil :{
    width: "35px!important",
    height: "35px!important",
    marginTop: "10px",
    marginRight: "10px",
    background: "rgba(0, 0, 0, 0.3)!important",
    color: "#fff",
    "&:hover": {
        background: "#fff!important",
        color: "#0e6166!important",
        cursor: "pointer",
    },
},
listMenu:{
    fontSize: "10px!important",
    fontWeight: "bold",
    "&:hover": {
        background: "rgba(0, 0, 0, 0.3)!important",
        cursor: "pointer",
    },
},
iconsMenu :{
    minWidth: "30px!important",
    color: "#fff!important",
},
textMenu :{
    color: "#fff!important",
    fontSize: "14px",
},
choixManif:{
    width: "25% !important",
},
itemChoixManif:{
    width: "100% !important",
    minHeight: "60px",
    maxHeight: "80px",
    paddingRight: "30px",
},
logoChoixManif:{
    marginRight: "15%",
    width: "20%",
},
manifChoixManif:{
    textAlign: "right!important",
    width: "80% !important",
    fontSize: "13px",
},
/* Connexion Panel */
connexionPanel:{
    position: "absolute",
    bottom: "10%",
    paddingLeft: "15px",
    paddingRight: "15px",
},
connexionCreate :{
    fontSize: "14px",
    color: "#fff",
},
connexionTitle :{
    color: "#fff!important",
    fontSize: "18px",
},
vConnexionEmailSideBar: {
    color: "#fff!important",
    borderColor: "#fff!important",
},
vConnexionPasswordSideBar: {
    color: "#fff!important",
    borderColor: "#fff!important",
},
connexionForgetPwd :{
    textAlign: "center!important",
    textDecoration: "none",
    color: "#fff",
    fontSize: "13px",
    },
    footerBar: {
        top: 'auto',
        bottom: 0,
        zIndex: 10000,
        backgroundColor: "#37A8AF!important",
    },
}))

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleForbidenAccess = makeStyles((theme) => createStyles({
    paperClass: {
        padding: theme.spacing(4),
        height: "70vh",
        color:"#e03f57",
    },
    customIcon: {
        fontSize: "15rem",
    },
}));
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, List, ListItem, ListItemText, Button, FormHelperText, Popover } from '@material-ui/core';
import { connect } from 'react-redux';
import rest from "../../rest";
import { MySnackbar } from '../mySnackbar/MySnackbar';
import { useStylesGlobal } from '../../pages/GlobalStyle.css';
import AppContext from '../appContext';
const subtitle = {
    fontWeight: "bold"
};


const OngletProfil = ({ idBenevole, handleCloseFiche }) => {
    const myContext = useContext(AppContext);
    const classesGlobal = useStylesGlobal();
    const [infoPersonne, setInfosPersonne] = useState({})
    const [fieldConfig, setFieldConfig] = useState(
        {
            champAddress: false,
            champAddress2: false,
            champCountry: false,
            champJob: false,
            champLangue: false,
            champLocality: false,
            champPhone: false,
            champPhotoObligatoire: false,
            champZip: false,
        }
    )
    const [paramsConfig, setParamsConfig] = useState({})
    const [snack, setSnack] = useState({});
    // POPOVER 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    useEffect(() => {
        rest.get("/parametres/recupConfigManif2.json").then((response) => {
            setFieldConfig(response.data.InfosProfil.ChampDemande[0])
            setParamsConfig(response.data.Params)
        })
    }, [])

    useEffect(() => {
        rest.post(`/benevols/GetProfil.json`, { vIdPers: idBenevole }).then((response) => {
            setInfosPersonne(response.data)
        })
    }, [idBenevole])

    const handleConnectAs = () => {
        rest.post(`/benevols/connectAs.json`, { vIdPers: idBenevole }).then((response) => {
            window.location.href ="/index.html";
        })
    }

    const handleDeleteUser = () => {
        rest.post('/profil_info/supprimer.json', { vIdPers: idBenevole }).then((response) => {
            
            if (response.data.Msg === "ok") {
                setSnack({ message: "profil supprimé avec succès.", show: true, severity: "success" })
                if (myContext.setRefreshBen) {
                    myContext.setRefreshBen((prev) => !prev)
                }
                handleCloseFiche()
            } else if (response.data.Msg === "responsableNotOk") {
                setSnack({ message: "Vous ne pouvez pas supprimer ce profil car il est responsable (ou co-responsable) d'au moins une tranche horaire d'une' manifestation.<br> Veuillez contacter les administrateurs pour toutes questions.", show: true, severity: "success" })
            } else if (response.data.Msg === "inscritNotOk") {
                setSnack({ message: "Vous ne pouvez pas supprimer ce profil car il est inscrit à des activités. Vous devez d'abord désincrire la personne de ces activités pour supprimer son profil.", show: true, severity: "success" })
            } else if (response.data.Msg === "Desinscrire") {
                setSnack({ message: "profil supprimé avec succès.", show: true, severity: "success" })
                if (myContext.setRefreshBen) {
                    myContext.setRefreshBen((prev) => !prev)
                }
                handleCloseFiche()
            }
            

        })
    }
    

    return (
        <Grid container spacing={2}>
            <MySnackbar params={snack} />
            {infoPersonne.isLysoftAdminConsult && <Grid item xs={12}>
                <Button color="primary" variant="outlined" onClick={handleConnectAs}>Se connecter en tant que</Button>
            </Grid>}
            {infoPersonne.isAdminConsult &&
                <Grid item xs={12}>
                    <span>
                        <Button disabled={!infoPersonne.isDeletable} className={classesGlobal.buttonFermer} variant="contained" onClick={(e) => handleClick(e)} >Supprimer le profil</Button>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            className="popover_class"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Button variant="contained" className={classesGlobal.buttonFermer} onClick={handleDeleteUser}>Confirmer suppression</Button>
                        </Popover>
                    </span>
                    {!infoPersonne.isDeletable &&
                        <FormHelperText style={{ color: "#d32f2f" }}>{infoPersonne.isDeletableReason}</FormHelperText>
                    }
                </Grid>
            }
            <Grid item xs={12} md={6}>
                <List component="nav">
                    <ListItem>
                        <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Nom & Prénom"} secondary={
                            <Typography>{infoPersonne.Person_Name} {infoPersonne.Person_Firstname}</Typography>
                        } />
                    </ListItem>
                    <ListItem>
                        <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Adresse"} secondary={
                            <Typography>
                                {fieldConfig.champAddress && infoPersonne.Person_Address}<br />
                                {fieldConfig.champZip && infoPersonne.Person_Zip} {fieldConfig.champLocality && infoPersonne.Person_Locality}<br />
                                {fieldConfig.champCountry && infoPersonne.Person_Country}
                            </Typography>
                        } />
                    </ListItem>
                    <ListItem>
                        <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Email"} secondary={
                            <Typography>{infoPersonne.Person_Email}</Typography>
                        } />
                    </ListItem>
                    {fieldConfig.champPhone &&
                        <>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Mobile"} secondary={
                                    <Typography>{infoPersonne.Person_Phone_Mobile}</Typography>
                                } />
                            </ListItem>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Tél privé"} secondary={
                                    <Typography>{infoPersonne.Person_Phone_Pers}</Typography>
                                } />
                            </ListItem>
                            <ListItem>
                                <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Tél prof."} secondary={
                                    <Typography>{infoPersonne.Person_Phone_Pro}</Typography>
                                } />
                            </ListItem>
                        </>
                    }
                    {fieldConfig.champLangue &&
                        <ListItem>
                            <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Langues"} secondary={
                                infoPersonne.Person_Langue &&
                                <Typography>
                                    {infoPersonne.Person_Langue.join(", ")}
                                </Typography>
                            } />
                        </ListItem>
                    }
                </List>
            </Grid>
            <Grid item xs={12} md={6}>
                <List component="nav">
                    <ListItem>
                        <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Date de naissance"} secondary={
                            <Typography>{infoPersonne.Person_Birth}</Typography>
                        } />
                    </ListItem>
                    {fieldConfig.champJob &&
                        <ListItem>
                            <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Profession"} secondary={
                                <Typography>{infoPersonne.Person_Job}</Typography>
                            } />
                        </ListItem>
                    }
                    {paramsConfig.Tshirt &&
                        <ListItem>
                            <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Tshirt"} secondary={
                                <Typography>{infoPersonne.Person_Tshirt}</Typography>
                            } />
                        </ListItem>
                    }
                    <ListItem>
                        <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Accepte la newsletter"} secondary={
                            <Typography>{infoPersonne.Person_AcceptNewsletter ? 'Oui' : 'Non'}</Typography>
                        } />
                    </ListItem>
                    <ListItem>
                        <ListItemText primaryTypographyProps={{ style: subtitle }} primary={"Attribut"} secondary={
                            infoPersonne.Person_Attributs &&
                            <Typography>
                                {infoPersonne.Person_Attributs.map(item => item.name).join(", ")}
                            </Typography>
                        } />
                    </ListItem>
                </List>
            </Grid>
            {/* Infos complémentaire */}
            {infoPersonne.Person_InfosComples &&
                <Grid container >
                    {infoPersonne.Person_InfosComples.InfosComplementaires.length > 0 && <Grid item xs={12}><hr /></Grid>}
                    <Grid item xs={12} >
                        <div style={{ paddingLeft: 10 }}>
                            <List component="nav">
                                {infoPersonne.Person_InfosComples.InfosComplementaires.map((value, index) =>
                                    <ListItem key={value.nom}>
                                        <ListItemText primaryTypographyProps={{ style: subtitle }} primary={value.textQuestion} secondary={
                                            <Typography>{value.valeur}</Typography>
                                        } />
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    </Grid>
                </Grid>}
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        manif: state.manif,
    }
}

export default connect(mapStateToProps)(OngletProfil)



import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import rest from '../../rest';

const GestionTextes = () => {
    const [texte, setTexte] = useState('');
    const [urlParams] = useState(new URLSearchParams(window.location.search));
    
    useEffect(() => {
        const titre = urlParams.get('titre');
        const params = {
            titre,
        };
        
        rest.post('/gestionTextes/detail.json', params)
        .then((response) => {
            setTexte(DOMPurify.sanitize(response.data.contenu));
        });
    }, [urlParams]);

    return (
        <div dangerouslySetInnerHTML={{ __html: texte }} />
    );
}

export default GestionTextes;
import React, { useState, useEffect, useContext } from 'react';
import { useStyleFormInscript } from './FormulaireInscription.css';
import rest from '../../rest';
import { connect, useSelector } from 'react-redux';
/* import SearchableSelect from 'SearchableSelect' */
import AppContext from '../appContext';
import { Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { InputAdornment, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import WorkIcon from '@material-ui/icons/Work';
import WcIcon from '@material-ui/icons/Wc';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTshirt } from '@fortawesome/free-solid-svg-icons'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import LanguagesSelect from '../langues/languagesList.json'

const countryList = [
    "Suisse",
    "France",
    "Afghanistan",
    "Afrique Centrale",
    "Afrique du Sud",
    "Albanie",
    "Algerie",
    "Allemagne",
    "Andorre",
    "Angola",
    "Anguilla",
    "Arabie Saoudite",
    "Argentine",
    "Armenie",
    "Australie",
    "Autriche",
    "Azerbaidjan",
    "Bahamas",
    "Bangladesh",
    "Barbade",
    "Bahrein",
    "Belgique",
    "Belize",
    "Benin",
    "Bermudes",
    "Bielorussie",
    "Bolivie",
    "Botswana",
    "Bhoutan",
    "Boznie Herzegovine",
    "Bresil",
    "Brunei",
    "Bulgarie",
    "Burkina Faso",
    "Burundi",
    "Caiman",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Canaries",
    "Cap Vert",
    "Chili",
    "Chine",
    "Chypre",
    "Colombie",
    "Colombie",
    "Congo",
    "Congo democratique",
    "Cook",
    "Coree du Nord",
    "Coree du Sud",
    "Costa Rica",
    "Côte d Ivoire",
    "Croatie",
    "Cuba",
    "Danemark",
    "Djibouti",
    "Dominique",
    "Egypte",
    "Emirats Arabes Unis",
    "Equateur",
    "Erythree",
    "Espagne",
    "Estonie",
    "Etats Unis",
    "Ethiopie",
    "Falkland",
    "Feroe",
    "Fidji",
    "Finlande",
    "France",
    "Gabon",
    "Gambie",
    "Georgie",
    "Ghana",
    "Gibraltar",
    "Grece",
    "Grenade",
    "Groenland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernesey",
    "Guinee",
    "Guinee Bissau",
    "Guinee Equatoriale",
    "Guyana",
    "Guyane Francaise",
    "Haiti",
    "Hawaii",
    "Honduras",
    "Hong Kong",
    "Hongrie",
    "Inde",
    "Indonesie",
    "Iran",
    "Iraq",
    "Irlande",
    "Islande",
    "Israel",
    "italie",
    "Jamaique",
    "Jan Mayen",
    "Japon",
    "Jersey",
    "Jordanie",
    "Kazakhstan",
    "Kenya",
    "Kirghizistan",
    "Kiribati",
    "Koweit",
    "Laos",
    "Lesotho",
    "Lettonie",
    "Liban",
    "Liberia",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg",
    "Lybie",
    "Macao",
    "Macedoine",
    "Madagascar",
    "Madère",
    "Malaisie",
    "Malawi",
    "Maldives",
    "Mali",
    "Malte",
    "Man",
    "Mariannes du Nord",
    "Maroc",
    "Marshall",
    "Martinique",
    "Maurice",
    "Mauritanie",
    "Mayotte",
    "Mexique",
    "Micronesie",
    "Midway",
    "Moldavie",
    "Monaco",
    "Mongolie",
    "Montserrat",
    "Mozambique",
    "Namibie",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk",
    "Norvege",
    "Nouvelle Caledonie",
    "Nouvelle Zelande",
    "Oman",
    "Ouganda",
    "Ouzbekistan",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papouasie Nouvelle Guinee",
    "Paraguay",
    "Pays Bas",
    "Perou",
    "Philippines",
    "Pologne",
    "Polynesie",
    "Porto Rico",
    "Portugal",
    "Qatar",
    "Republique Dominicaine",
    "Republique Tcheque",
    "Reunion",
    "Roumanie",
    "Royaume Uni",
    "Russie",
    "Rwanda",
    "Sahara Occidental",
    "Sainte Lucie",
    "Saint Marin",
    "Salomon",
    "Salvador",
    "Samoa Occidentales",
    "Samoa Americaine",
    "Sao Tome et Principe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovenie",
    "Somalie",
    "Soudan",
    "Sri Lanka",
    "Suede",
    "Surinam",
    "Swaziland",
    "Syrie",
    "Tadjikistan",
    "Taiwan",
    "Tonga",
    "Tanzanie",
    "Tchad",
    "Thailande",
    "Tibet",
    "Timor Oriental",
    "Togo",
    "Trinite et Tobago",
    "Tristan de cuncha",
    "Tunisie",
    "Turmenistan",
    "Turquie",
    "Ukraine",
    "Uruguay",
    "Vanuatu",
    "Vatican",
    "Venezuela",
    "Vierges Americaines",
    "Vierges Britanniques",
    "Vietnam",
    "Wake",
    "Wallis et Futuma",
    "Yemen",
    "Yougoslavie",
    "Zambie",
    "Zimbabwe",
]

const validationFixedField = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
]

// Debut fonction 
const FormulaireBenevole = ({ idBenevole, setShowForm, setSnack, fromProfil }) => {
    const classes = useStyleFormInscript();
    const myContext = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [openUpdatePsw, setOpenUpdatePsw] = useState(false)
    const [person, setPerson] = useState({
        Person_ID: 0,
        Person_Name: "",
        Person_Firstname: "",
        Person_Birth: "",
        Person_Address: "",
        Person_Address_2: "",
        Person_Zip: "",
        Person_Locality: "",
        Person_Country: "",
        Person_Gender: "",
        Person_Email: "",
        Person_Phone_Mobile: "",
        Person_Phone_Pers: "",
        Person_Phone_Pro: "",
        Person_Job: "",
        Person_Tshirt: "",
        Person_Commentaire: "",
        Person_Attributs: [],
        Person_Langue: [],
    });
    const [listAttributs, setListAttributs] = useState(null)
    const [attributeLoaded, setAttributeLoaded] = useState(false)
    const manifParams = useSelector(state => state.manif);
    const currentUser = useSelector(state => state.user);
    const [fieldComplementaireConfig, setFieldComplementaireConfig] = useState([])
    const [paramsConfig, setParamsConfig] = useState({})
    const [fieldComplementaire, setFieldComplementaire] = useState([])
    const [fieldValidation, setFieldValidation] = useState(validationFixedField)
    //update psw form
    const [psw, setPsw] = useState("")
    const [pswConfirm, setPswConfirm] = useState("")
    const [pswError, setPswError] = useState(false);
    const [pswConfirmError, setPswConfirmError] = useState(false);
    const [pswErrorMsg, setPswErrorMsg] = useState("");
    const [pswConfirmErrorMsg, setPswConfirmErrorMsg] = useState("");

    useEffect(() => {
        if (listAttributs !== null) {
            setAttributeLoaded(true)
        }
    }, [listAttributs])

    useEffect(() => {
        let tmp = []
        let tmpError = JSON.parse(JSON.stringify(validationFixedField))
        for (let index = 0; index < manifParams.manifInfosProfil.InfosComplementaires.length; index++) {
            tmp.push(React.createRef())
            if (manifParams.manifInfosProfil.InfosComplementaires[index].champsObligatoireInfoComple) {
                tmpError.push(false)
            }

        }
        setFieldValidation(tmpError)
        setFieldComplementaire(tmp)
        setFieldComplementaireConfig(manifParams.manifInfosProfil.InfosComplementaires)
        setParamsConfig(manifParams.manifParams)

    }, [manifParams])

    useEffect(() => {
        rest.get('/attribut/liste.json')
            .then((response) => {
                setListAttributs(response.data)
            })
    }, []);
    useEffect(() => {
        if (attributeLoaded) {
            rest.post(`/benevols/GetProfil.json`, { vIdPers: idBenevole }).then((response) => {
                if (response.data.Person_Langue.length > 0) {
                    var newArray = LanguagesSelect.filter(function (el) {
                        return response.data.Person_Langue.indexOf(el.value) >= 0
                    });
                    response.data.Person_Langue = newArray
                }

                if (response.data.Person_Attributs.length > 0) {
                    var newArray2 = listAttributs.filter(function (el) {
                        return response.data.Person_Attributs.map(item => item.id).indexOf(el.id) > -1
                    });
                    response.data.Person_Attributs = newArray2
                }

                setPerson(response.data)
                setLoading(false)
            })
        }
    }, [idBenevole, attributeLoaded, listAttributs])


    const handleFormChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const idInput = target.id;
        if (idInput === undefined || idInput === "") {
            setPerson({ ...person, [target.name]: value });
        } else {
            setPerson({ ...person, [idInput]: value });
        }
    };

    const handleCloseForm = () => {
        setShowForm(false)
    }


    const handleAttrChange = (event) => {
        setPerson({ ...person, "Person_Attributs": event.target.value });
    }

    const handleLangueChange = (event) => {
        setPerson({ ...person, "Person_Langue": event.target.value });
    }

    const saveInfosBen = (event) => {
        let tmpError = JSON.parse(JSON.stringify(fieldValidation))
        tmpError[0] = (person.Person_Name === "")
        tmpError[1] = (person.Person_Firstname === "")
        tmpError[2] = ((person.Person_Birth === "") && (!currentUser.User.User_Administrator))
        if (manifParams.manifInfosProfil.ChampDemande[0].champAddress) {
            tmpError[3] = ((person.Person_Address === "") && (!currentUser.User.User_Administrator))
        }
        // if (manifParams.manifInfosProfil.ChampDemande[0].champAddress2) {
        //     tmpError[4] = (person.Person_Address_2 === "")
        // }
        if (manifParams.manifInfosProfil.ChampDemande[0].champZip) {
            tmpError[5] = ((person.Person_Zip === "") && (!currentUser.User.User_Administrator))
        }
        if (manifParams.manifInfosProfil.ChampDemande[0].champLocality) {
            tmpError[6] = ((person.Person_Locality === "") && (!currentUser.User.User_Administrator))
        }
        if (manifParams.manifInfosProfil.ChampDemande[0].champCountry) {
            tmpError[7] = ((person.Person_Country === "") && (!currentUser.User.User_Administrator))
        }
        if (manifParams.manifInfosProfil.ChampDemande[0].champPhone) {
            tmpError[9] = (person.Person_Phone_Mobile === "") && (person.Person_Phone_Pers === "") && (person.Person_Phone_Pro === "") && (!currentUser.User.User_Administrator)
        }
        if (manifParams.manifInfosProfil.ChampDemande[0].champJob) {
            tmpError[10] = ((person.Person_Job === "") && (!currentUser.User.User_Administrator))
        }
        if (paramsConfig.Tshirt) {
            tmpError[11] = ((person.Person_Tshirt === "") && (!currentUser.User.User_Administrator))
        }
        if (manifParams.manifInfosProfil.ChampDemande[0].champLangue) {
            tmpError[12] = ((person.Person_Langue.length === 0) && (!currentUser.User.User_Administrator))
        }

        tmpError[8] = ((person.Person_Email === "") && (!currentUser.User.User_Administrator))
        tmpError[13] = (person.Person_Gender === "")

        for (let index = 0; index < fieldComplementaireConfig.length; index++) {
            if ((fieldComplementaireConfig[index].champsObligatoireInfoComple) && (!currentUser.User.User_Administrator)) {
                tmpError[14 + index] = (fieldComplementaire[index].current.value === "")
            }
        }

        setFieldValidation(tmpError)
        if (!tmpError.includes(true)) {
            setLoading(true)
            var paramsData = {
                'vIdPerson': person.Person_ID,
                'vProfileName': person.Person_Name,
                'vProfileFirstname': person.Person_Firstname,
                'vProfileSexe': person.Person_Gender,
                'vProfileBirth': person.Person_Birth,
                'vProfileEmail': person.Person_Email,
                'vProfileAddress': person.Person_Address,
                'vProfileAddress2': person.Person_Address_2,
                'vProfileLocality': person.Person_Locality,
                'vProfileZIP': person.Person_Zip,
                'vProfileCountry': person.Person_Country,
                'vProfilePhoneMobile': person.Person_Phone_Mobile,
                'vProfilePhonePers': person.Person_Phone_Pers,
                'vProfilePhonePro': person.Person_Phone_Pro,
                'vProfileJob': person.Person_Job,
                'vTailleTShirt': person.Person_Tshirt,
                'vProfileComment': person.Person_Commentaire,
                'vPersonAttribut': JSON.stringify(person.Person_Attributs),
                'vProfilLangue': JSON.stringify(person.Person_Langue),
            }
            //infos complémentaires
            paramsData.InfosComplementaires = [];
            for (let index = 0; index < fieldComplementaireConfig.length; index++) {
                paramsData.InfosComplementaires.push({ "nom": fieldComplementaireConfig[index].idInfoComple, "valeur": fieldComplementaire[index].current.value })
            }
            paramsData.InfosComplementaires = JSON.stringify(paramsData.InfosComplementaires)

            rest.post('/benevols/profilSave.json', encodeURIComponent(JSON.stringify(paramsData))).then(function (response) {
                if (!response.data.error) {
                    setSnack({ message: response.data.message, show: true, severity: "success" })
                    if (setShowForm) {
                        setShowForm(false)
                    }
                    if (myContext.setRefreshBen) {
                        myContext.setRefreshBen((prev) => !prev)
                    }
                } else {
                    setSnack({ message: response.data.message, show: true, severity: "error" })
                }
                setLoading(false)
            })
        }
    };

    const handleModifyPass = (event) => {
        setOpenUpdatePsw(true)
    }

    const handleCloseUpdatePsw = () => {
        setOpenUpdatePsw(false)
    }

    const handleUpdatePswConfirm = () => {
        let error = false;
        let errorPsw = false;


        if (psw === "") {
            error = true
            setPswError(true)
            setPswErrorMsg("Champ obligatoire")
        } else {
            if (psw.length < 6) {
                error = true
                errorPsw = true
                setPswError(true)
                setPswErrorMsg("Le mot de passe doit contenir minimum 6 caractères.")
            } else {
                setPswError(false)
                setPswErrorMsg("")
            }
        }
        if (pswConfirm === "") {
            error = true
            setPswConfirmError(true)
            setPswConfirmErrorMsg("Champ obligatoire")
        } else {
            if (pswConfirm.length < 6) {
                error = true
                errorPsw = true
                setPswConfirmError(true)
                setPswConfirmErrorMsg("Le mot de passe doit contenir minimum 6 caractères.")
            } else {
                setPswConfirmError(false)
                setPswConfirmErrorMsg("")
            }
        }
        if (((psw !== "") && (pswConfirm !== "")) && (!errorPsw)) {
            if (psw !== pswConfirm) {
                error = true
                setPswError(true)
                setPswConfirmError(true)
                setPswErrorMsg("Les mots de passe doivent concorder.")
                setPswConfirmErrorMsg("Les mots de passe doivent concorder.")
            } else {
                setPswError(false)
                setPswConfirmError(false)
                setPswErrorMsg("")
                setPswConfirmErrorMsg("")
            }
        }

        if (!error) {
            setLoading(true)
            rest.post('/user/updatePassword.json', { vNewMdp: psw }).then(function (response) {
                if (response.data.Message !== "ok") {
                    setSnack({ message: response.data.Message, show: true, severity: "error" })
                } else {
                    setSnack({ message: "Mot de passe modifié avec succès.", show: true, severity: "success" })
                    setOpenUpdatePsw(false)
                }
                setLoading(false)
            })
        }


    }

    const handleChangePsw = (event) => {
        setPsw(event.target.value?.trim())
    }
    const handleChangePswConfirm = (event) => {
        setPswConfirm(event.target.value?.trim())
    }

    return (
        <>
            <LoadingIndicator open={loading} />
            <Grid container spacing={1} className={classes.root}>
                <Grid container>
                    <Grid item xs={12} md={6} >
                        <TextField
                            id="Person_Name"
                            name="Person_Name"
                            dataname="Person_Name"
                            label="Nom"
                            type="text"
                            variant="outlined"
                            fullWidth
                            required={true}
                            value={person.Person_Name}
                            onChange={handleFormChange}
                            className={classes.inputsForm}
                            error={fieldValidation[0]}
                        /><br />
                        <TextField
                            id="Person_Firstname"
                            name="Person_Firstname"
                            label="Prénom"
                            type="text"
                            variant="outlined"
                            fullWidth
                            required={true}
                            value={person.Person_Firstname}
                            onChange={handleFormChange}
                            className={classes.inputsForm}
                            error={fieldValidation[1]}
                        /><br />
                        <InputMask
                            mask={'99.99.9999'}
                            maskPlaceholder="dd.mm.yyyy"
                            maskChar='_'
                            value={person.Person_Birth}
                            onChange={handleFormChange}
                            className={classes.inputsForm}
                            id="Person_Birth"
                            name="Person_Birth"
                        >
                            {() =>
                                <TextField
                                    id="Person_Birth"
                                    name="Person_Birth"
                                    label="Date de naissance (jj.mm.aaaa)"
                                    type="text"
                                    placeholder="jj.mm.aaaa"
                                    variant="outlined"
                                    fullWidth
                                    required={currentUser.User.User_Administrator ? false : true}
                                    value={person.Person_Birth}
                                    onChange={handleFormChange}
                                    className={classes.inputsForm}
                                    error={fieldValidation[2]}
                                />
                            }
                        </InputMask>
                        <br />
                        {manifParams.manifInfosProfil.ChampDemande[0].champAddress &&
                            <span><TextField
                                id="Person_Address"
                                name="Person_Address"
                                label="Adresse"
                                type="text"
                                variant="outlined"
                                fullWidth
                                required={currentUser.User.User_Administrator ? false : true}
                                error={fieldValidation[3]}
                                value={person.Person_Address}
                                onChange={handleFormChange}
                                className={classes.inputsForm}
                            /><br /></span>
                        }
                        {manifParams.manifInfosProfil.ChampDemande[0].champAddress2 &&
                            <span><TextField
                                id="Person_Address_2"
                                name="Person_Address_2"
                                label="Complément d'adresse"
                                type="text"
                                variant="outlined"
                                fullWidth
                                required={false}
                                error={fieldValidation[4]}
                                value={person.Person_Address_2}
                                onChange={handleFormChange}
                                className={classes.inputsForm}
                            /><br /></span>
                        }
                        {manifParams.manifInfosProfil.ChampDemande[0].champZip &&
                            <span><TextField
                                id="Person_Zip"
                                name="Person_Zip"
                                label="Code postal"
                                type="text"
                                variant="outlined"
                                fullWidth
                                required={currentUser.User.User_Administrator ? false : true}
                                error={fieldValidation[5]}
                                value={person.Person_Zip}
                                onChange={handleFormChange}
                                className={classes.inputsForm}
                            /><br /> </span>
                        }
                        {manifParams.manifInfosProfil.ChampDemande[0].champLocality &&
                            <span><TextField
                                id="Person_Locality"
                                name="Person_Locality"
                                label="Ville"
                                type="text"
                                variant="outlined"
                                fullWidth
                                required={currentUser.User.User_Administrator ? false : true}
                                error={fieldValidation[6]}
                                value={person.Person_Locality}
                                onChange={handleFormChange}
                                className={classes.inputsForm}
                            /><br /> </span>
                        }
                        {manifParams.manifInfosProfil.ChampDemande[0].champCountry &&
                            <span>
                                <FormControl variant="outlined" style={{ width: "100%", marginLeft: '1.5%', marginTop:'2%' }} error={fieldValidation[7]} required={currentUser.User.User_Administrator ? false : true}>
                                    <InputLabel>Pays</InputLabel>
                                    <Select
                                        id="Person_Country"
                                        name="Person_Country"
                                        label="Pays"
                                        required={currentUser.User.User_Administrator ? false : true}
                                        value={person.Person_Country}
                                        onChange={handleFormChange}
                                    >
                                        <MenuItem value={''} key={''}></MenuItem>
                                        {countryList.map((item) => (
                                            <MenuItem value={item} key={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <br />
                            </span>
                        }
                    </Grid>
                    <Grid item xs={12} md={5} style={{ marginLeft: '5%' }}>
                        <ButtonGroup caria-label="outlined primary button group" style={{ border: fieldValidation[13] ? '1px solid red' : '1px solid grey', width: '100%' }} className={classes.inputsForm}>
                            <Button style={{ borderRight: fieldValidation[13] ? '1px solid red' : '1px solid grey', color: 'black' }}><WcIcon /></Button>
                            <RadioGroup row aria-label="Sexe" id="Person_Gender" name="Person_Gender" value={person.Person_Gender} onChange={handleFormChange} style={{ margin: 8 }}>
                                <FormControlLabel value="F" control={<Radio color="primary" />} label="Femme" />
                                <FormControlLabel value="H" control={<Radio color="primary" />} label="Homme" />
                            </RadioGroup>
                        </ButtonGroup>
                        <TextField
                            id="Person_Email"
                            name="Person_Email"
                            label="Email"
                            type="email"
                            variant="outlined"
                            placeholder="exemple@domaine.ch"
                            fullWidth
                            required={currentUser.User.User_Administrator ? false : true}
                            error={fieldValidation[8]}
                            value={person.Person_Email}
                            onChange={handleFormChange}
                            className={classes.inputsForm}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><AlternateEmailIcon /></InputAdornment>
                                ),
                            }}
                        /><br />
                        {manifParams.manifInfosProfil.ChampDemande[0].champPhone &&
                            <span>
                                <InputMask
                                    mask={'+99 99 999 99 99'}
                                    maskChar='_'
                                    value={person.Person_Phone_Mobile}
                                    onChange={handleFormChange}
                                    className={classes.inputsForm}
                                    id="Person_Phone_Mobile"
                                    name="Person_Phone_Mobile"
                                >
                                    {() =>
                                        <TextField
                                            id="Person_Phone_Mobile"
                                            name="Person_Phone_Mobile*"
                                            value={person.Person_Phone_Mobile}
                                            label="Téléphone mobile"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            required={currentUser.User.User_Administrator ? false : true}
                                            error={fieldValidation[9]}
                                            className={classes.inputsForm}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start"><PhoneIphoneIcon /></InputAdornment>
                                                ),
                                            }}
                                        />
                                    }
                                </InputMask>
                                <InputMask
                                    mask={'+99 99 999 99 99'}
                                    maskChar='_'
                                    value={person.Person_Phone_Pers}
                                    onChange={handleFormChange}
                                    className={classes.inputsForm}
                                    id="Person_Phone_Pers"
                                    name="Person_Phone_Pers"
                                >
                                    {() =>
                                        <TextField
                                            id="Person_Phone_Pers"
                                            name="Person_Phone_Pers"
                                            value={person.Person_Phone_Pers}
                                            label="Téléphone personnel*"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            required={currentUser.User.User_Administrator ? false : true}
                                            error={fieldValidation[9]}
                                            className={classes.inputsForm}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start"><PhoneIcon /></InputAdornment>
                                                ),
                                            }}
                                        />
                                    }
                                </InputMask>
                                <InputMask
                                    mask={'+99 99 999 99 99'}
                                    maskChar='_'
                                    value={person.Person_Phone_Pro}
                                    onChange={handleFormChange}
                                    className={classes.inputsForm}
                                    id="Person_Phone_Pro"
                                    name="Person_Phone_Pro"
                                >
                                    {() =>
                                        <TextField
                                            id="Person_Phone_Pro"
                                            name="Person_Phone_Pro"
                                            value={person.Person_Phone_Pro}
                                            label="Téléphone professionnel*"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            required={currentUser.User.User_Administrator ? false : true}
                                            error={fieldValidation[9]}
                                            className={classes.inputsForm}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start"><PhoneIcon /></InputAdornment>
                                                ),
                                            }}
                                        />
                                    }
                                </InputMask>
                            </span>
                        }
                        {manifParams.manifInfosProfil.ChampDemande[0].champJob &&
                            <span>
                                <TextField
                                    id="Person_Job"
                                    name="Person_Job"
                                    label="Profession"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    required={currentUser.User.User_Administrator ? false : true}
                                    error={fieldValidation[10]}
                                    value={person.Person_Job}
                                    onChange={handleFormChange}
                                    className={classes.inputsForm}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start"><WorkIcon /></InputAdornment>
                                        ),
                                    }}
                                /><br />
                            </span>
                        }
                        {paramsConfig.Tshirt &&
                            <span>
                                <ButtonGroup caria-label="outlined primary button group" className={classes.inputsForm} style={{ width: "100%" }}>
                                    <Button disabled style={{ borderRight: '1px solid grey' }}><FontAwesomeIcon icon={faTshirt} size="lg" /></Button>
                                    <FormControl variant="outlined" style={{ width: "100%" }} error={fieldValidation[11]} required>
                                        <InputLabel id="demo-simple-select-outlined-label">Tshirt</InputLabel>
                                        <Select
                                            id="Person_Tshirt"
                                            name="Person_Tshirt"
                                            label="Tshir"
                                            required={currentUser.User.User_Administrator ? false : true}
                                            value={person.Person_Tshirt}
                                            onChange={handleFormChange}
                                        >
                                            <MenuItem value={''} key={''}></MenuItem>
                                            <MenuItem value={'XS'} key={'XS'}>XS</MenuItem>
                                            <MenuItem value={'S'} key={'S'}>S</MenuItem>
                                            <MenuItem value={'M'} key={'M'}>M</MenuItem>
                                            <MenuItem value={'L'} key={'L'}>L</MenuItem>
                                            <MenuItem value={'XL'} key={'XL'}>XL</MenuItem>
                                            <MenuItem value={'XXL'} key={'XXL'}>XXL</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ButtonGroup>
                            </span>
                        }
                        {!fromProfil && <ButtonGroup caria-label="outlined primary button group" style={{ marginLeft: '-80%', margin: 8, width: '100%' }}>
                            <Button style={{ borderRight: '1px solid grey' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 3.3c1.49 0 2.7 1.21 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7-1.49 0-2.7-1.21-2.7-2.7 0-1.49 1.21-2.7 2.7-2.7zM18 16H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" /></svg>
                            </Button>
                            <FormControl variant="outlined" style={{ width: "100%" }} >
                                <InputLabel >Attributs</InputLabel>
                                <Select
                                    id="Person_Attributs"
                                    name="Person_Attributs"
                                    multiple
                                    label="Attribut"
                                    value={person.Person_Attributs}
                                    onChange={handleAttrChange}
                                    renderValue={selected => {
                                        return selected.map(item => item.name).join(",");
                                    }}
                                >
                                    {listAttributs && listAttributs.map((attr, i) => (
                                        <MenuItem value={attr} key={attr.id}>
                                            <Checkbox color='primary' checked={person.Person_Attributs.map(item => item.id).indexOf(attr.id) > -1} />
                                            <ListItemText primary={attr.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ButtonGroup>}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            id="Person_Commentaire"
                            name="Person_Commentaire"
                            label="Commentaire"
                            type="text"
                            variant="outlined"
                            fullWidth
                            required={false}
                            value={person.Person_Commentaire}
                            onChange={handleFormChange}
                            className={classes.inputsForm}
                            style={{ width: '96.7%' }}
                        />
                    </Grid>
                </Grid>
                {manifParams.manifInfosProfil.ChampDemande[0].champLangue &&
                    <Grid container style={{ overflow: 'visible' }}>
                        <Grid item xs={12} >
                            <ButtonGroup style={{ width: '100%' }} caria-label="outlined primary button group" className={classes.inputsForm}>
                                <Button disabled style={{ borderRight: '1px solid grey', }}><LanguageIcon style={{ color: "black" }} /></Button>
                                <FormControl variant="outlined" style={{ width: "94.2%" }} error={fieldValidation[12]} required>
                                    <InputLabel >Langues</InputLabel>
                                    <Select
                                        id="Person_Langue"
                                        name="Person_Langue"
                                        multiple
                                        label="Langues"
                                        value={person.Person_Langue}
                                        onChange={handleLangueChange}
                                        renderValue={selected => {
                                            return selected.map(item => item.name).join(",");
                                        }}
                                    >
                                        {LanguagesSelect.map((attr, i) => (
                                            <MenuItem value={attr} key={attr.value}>
                                                <Checkbox color='primary' key={attr.value} checked={person.Person_Langue.map(item => item.value).indexOf(attr.value) > -1} />
                                                <ListItemText primary={attr.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
                {/* informations complémentaires */}
                <Grid container spacing={1} style={{ overflow: 'visible' }}>
                    {person.Person_InfosComples && fieldComplementaireConfig.map((value, index) =>
                        <Grid key={value.idInfoComple} item xs={12} md={6}>
                            <TextField
                                id={value.idInfoComple}
                                name={value.idInfoComple}
                                label={value.nomInfoComple}
                                type="text"
                                variant="outlined"
                                fullWidth
                                error={value.champsObligatoireInfoComple ? fieldValidation[14 + index] : false}
                                required={value.champsObligatoireInfoComple}
                                inputRef={fieldComplementaire[index]}
                                defaultValue={person.Person_InfosComples.InfosComplementaires ? person.Person_InfosComples.InfosComplementaires[index].valeur : ""}
                                className={classes.inputsForm}
                                helperText={value.remarqueChamp !== "" && value.remarqueChamp}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container justifyContent={fromProfil && "flex-end"}>
                    {fromProfil && <Grid item xs={12} md={6}>
                        <Button variant="contained" color="default" style={{ margin: 8, width: '97%' }} onClick={handleModifyPass}>Modifier mot de passe</Button>
                    </Grid>}
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" style={{ margin: 8, width: '97%' }} onClick={saveInfosBen}>Enregistrer</Button>
                    </Grid>
                    {!fromProfil && <Grid item xs={12} md={6}>
                        <Button variant="contained" /* color="default" */ style={{ margin: 8, width: '97%', backgroundColor: "#d32f2f", color: "white" }} onClick={handleCloseForm}>Fermer</Button>
                    </Grid>}
                </Grid>
            </Grid>
            {/* formulaire update psw */}
            <Dialog open={openUpdatePsw} onClose={handleCloseUpdatePsw} aria-labelledby="updatePsw-dialog-title">
                <DialogTitle id="updatePsw-dialog-title">Modifier le mot de passe</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="psw"
                        label="Modifier le mot de passe"
                        type="password"
                        required={true}
                        fullWidth
                        value={psw}
                        onChange={handleChangePsw}
                        error={pswError}
                        helperText={pswError && pswErrorMsg}
                    />
                    <br />
                    <br />
                    <TextField
                        autoFocus
                        id="pswConfirm"
                        label="Confirmez le mot de passe"
                        type="password"
                        required={true}
                        fullWidth
                        value={pswConfirm}
                        onChange={handleChangePswConfirm}
                        error={pswConfirmError}
                        helperText={pswConfirmError && pswConfirmErrorMsg}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdatePsw} color="default" variant="contained">
                        Fermer
                    </Button>
                    <Button onClick={handleUpdatePswConfirm} color="primary" variant="contained">
                        Modifier mon mot de passe
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        manif: state.manif,
    }
}


export default connect(mapStateToProps)(FormulaireBenevole)

import { Grid } from '@material-ui/core';
import React from 'react';
import ParametreTabs from '../../components/Parametre/ParametreTabs';

const Parametre = () => {

    return (
        <Grid container style={{width:"80%"}}>
            <Grid item xs={12}>
                <ParametreTabs/>
            </Grid>
        </Grid>
    );
};

export default Parametre;
import React from 'react';
import AppBar from './appbar'
import SideBar from './sidebar'
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

const Menu = (props) => {
    const location = useLocation()

    return (
        location.pathname !== "/portail.html" && <>
            <AppBar />
            <SideBar />
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        manif: state.manif,
    }
}

export default connect(mapStateToProps)(Menu)

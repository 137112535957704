 /* eslint-disable no-use-before-define */
import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../components/appContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import rest from '../../rest';
import ListesActivitesBenevoles from '../../components/listes/listesActivitesBenevoles'
import { Button, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import 'moment/locale/fr';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useCookies } from 'react-cookie';




const Presences = (props) => {
    const myContext = useContext(AppContext);
    const openSidebar = myContext.openSidebar;
    const [isDatesLoaded, setIsDatesLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [datesRecuperees, setDatesRecuperees] = useState({})
    const [listesActJour, setListesActJour] = useState([])
    const todayInit = JSON.parse(localStorage.getItem('todayInit'))
    /*  var todayInit= {
       dateToString: moment(today).format("ddd")+", "+moment(today).format("ll"), 
       value: moment(today).format("DD.MM.yyyy")
     } */
    const todayDate = JSON.parse(localStorage.getItem('dateSelected'))
    const [datesSelected, setDatesSelected] = useState([todayDate])
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['thSelected'])
    const [cookiesAct, setCookiesAct] = useCookies(['actSelected'])


    const getActivitesListe = (objDates, id) => {
        var vDates = [];
        if (objDates !== "") {
            if (objDates !== "\"\"") {
                vDates.push(JSON.parse(objDates));
            }
        } else {
            vDates.push(JSON.parse(localStorage.getItem('dateSelected')));
        }

        var paramsData = {
            'vDates': JSON.stringify(vDates),
            'vIDAct': id,
        }
        rest.post('/presences/listeActivites.json', encodeURIComponent(JSON.stringify(paramsData)),)
            .then((response) => {
                //TODO
            })
    }


    useEffect(() => {
        myContext.setOpenSidebar(false)
        loadDates();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isDatesLoaded) {
            var paramsData = {
                'vDates': JSON.stringify(datesSelected),
                'vIDAct': cookiesAct.actSelected,
            }
            rest.post('/presences/listeActivitesDuJour.json', encodeURIComponent(JSON.stringify(paramsData)))
                .then((response) => {
                    setListesActJour(response.data)
                    setIsLoaded(true)
                })
        }
    }, [datesSelected, isDatesLoaded, cookiesAct])

    const loadDates = () => {
        rest.get('/presences/listeDates.json')
            .then((response) => {
                setDatesRecuperees(response.data)
                setIsDatesLoaded(true)
            })
    }


    const nowDate = () => {
        getActivitesListe(JSON.stringify(todayInit), 0);
        setCookiesAct('actSelected', JSON.stringify(0), { path: '/' });
        setCookie('thSelected', JSON.stringify(0), { path: '/' });
        localStorage.setItem('dateSelected', JSON.stringify(localStorage.getItem(todayInit)))
        setDatesSelected(JSON.parse(localStorage.getItem('dateSelected')))
        var arrayDate = [];
        setDatesSelected(arrayDate);
        arrayDate.push(todayInit)
        setDatesSelected(arrayDate);
        setIsLoaded(false)
    }

    const handleChangeDate = (event, newValue) => {
        setCookiesAct('actSelected', JSON.stringify(0), { path: '/' });
        setCookie('thSelected', JSON.stringify(0), { path: '/' });
        var arrayDate = [];
        var arrayActJour = [];
        setListesActJour(arrayActJour)
        setDatesSelected(arrayDate);
        getActivitesListe(JSON.stringify(newValue), 0);
        localStorage.setItem('dateSelected', JSON.stringify(newValue))
        arrayDate.push(newValue)
        setDatesSelected(arrayDate);
    }

    /* const exporterDonnees = () => {
      var paramsData= {
        'vListe': JSON.stringify(listesActBen),
        'vDates': JSON.stringify(datesSelected),
      }
      rest.post('/presences/export.json', encodeURIComponent(JSON.stringify(paramsData)))
      .then((response) => {
        FileDownload(response.data, 'Presences.csv');
        //setListesActBen(response.data)
      })
    }
    */

    return (
        <>
            <CssBaseline />
            {isLoaded ?
                <>
                    <Grid container>
                        <Grid item xs={openSidebar ? 4 : 5} >
                            {/* <DatesRecuperees datesRecuperees={datesRecuperees}  datesSelected={datesSelected} setDatesSelected={setDatesSelected} />   */}
                            <Autocomplete
                                id="dates-recuperees"
                                options={datesRecuperees}
                                defaultValue={datesSelected[0]}
                                getOptionLabel={(option) => option.dateToString}
                                onChange={(event, newValue) => {
                                    handleChangeDate(event, newValue)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Dates"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {/* <Grid item xs={5}>
                    <br/>
                    <Button variant="contained" color="primary" fullWidth onClick={exporterDonnees}>Exporter</Button>
                  </Grid> */}
                        <Grid item xs={openSidebar ? 4 : 5}>
                            <br />
                            <Button variant="contained" color="secondary" fullWidth onClick={nowDate}>Maintenant</Button>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Grid container>
                        <Grid item xs={openSidebar ? 10 : 12}>
                            <ListesActivitesBenevoles listesActJour={listesActJour} datesSelected={datesSelected} />
                        </Grid>
                    </Grid>
                </>
                :
                <LoadingIndicator open={!isLoaded} />
            }
        </>
    );
}




export default Presences




import React, { useContext, useEffect, useState } from 'react';
import FileUpload from '../../components/imageUpload/uploadForm';
import'./pageImageUpload.css';
import GetRandomKey from '../../components/general/genereRandomKey';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AppContext from '../../components/appContext';

function chooseImage(imgSrc) {
  function getUrlParam(paramName) {
    var reParam = new RegExp('(?:[\\?&]|&)' + paramName + '=([^&]+)', 'i');
    var match = window.location.search.match(reParam);

    return (match && match.length > 1) ? match[1] : null;
  }

  var funcNum = getUrlParam('CKEditorFuncNum');
  var fileUrl = imgSrc;
  window.opener.CKEDITOR.tools.callFunction(funcNum, fileUrl);
  window.close();
}


const ImageList = () => {
  const [images, setImages] = useState([]);
  const [uploadPorcess, setUploadPorcesss] = useState(0);
  
  const myContext = useContext(AppContext);

  useEffect(() => {
    myContext.setOpenSidebar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  useEffect(() => {
    // Effectuer une requête AJAX pour récupérer les liens des images depuis le serveur
    fetch('/pageImageUpload/listeImage.json')
      .then(response => response.json())
      .then(data => setImages(data))
      .catch(error => console.error('Erreur lors de la récupération des images:', error));
      console.log('uploadPorcess ===>>>', uploadPorcess);
  }, [uploadPorcess]);

  
  const removeFile = async  (fullname) => {

    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?');
    if (!confirmDelete) {
      return;
    }

    try {
      await fetch(`/pageImageUpload/deleteImage.json?name=${fullname}`, {
        method: 'DELETE',
      });
      setImages((prevFiles) => prevFiles.filter((file) => file.fullname !== fullname));
      setUploadPorcesss((p) => p - 1);
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'image:', error);
    }
  };


  return (
    <div>
      <FileUpload setUploadPorcesss={setUploadPorcesss}/>
      <h1>Liste d'images</h1>
      <div className="imageListUpload">
        {images.map((image, index) => (
          <div key={GetRandomKey(17)} className='boxImageUpload'>
            <img 
              key={GetRandomKey(18)} 
              src={image.url} 
              alt={`Profile ${image.name}`} 
              onClick={() => chooseImage(image.url)} 
            />
            <Button variant="contained" color="secondary" onClick={() => removeFile(image.fullName)}>
              <DeleteIcon />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageList;
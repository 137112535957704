import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const TrancheCheckbox = ({ row }) => {
    const [isChecked, setIsChecked] = useState(row.isChecked);

    const handleChange = (event) => {
        setIsChecked(event.target.checked);
        row.isChecked = event.target.checked
    };

    return (
        <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleChange} />}
            label={row.date + " : " + row.periode}
        />
    );
}


export default TrancheCheckbox

import React, { useState } from 'react';
import { Box, TextField, Button, Grid } from '@material-ui/core';

function ModalConfirmText({ text, handleConfirm, handleAnnul }) {
  const [textareaValue, setTextareaValue] = useState('');

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const onConfirm = async () => {
    await handleConfirm?.(textareaValue);
    handleAnnul?.();
  }

  return (
    <Box>
      <p>{text}</p>
        <TextField
          id="CommentaireSupp"
          label="Commentaire"
          multiline
          minRows={4}
          variant="outlined"
          value={textareaValue}
          onChange={handleTextareaChange}
          style={{ width: '60vw' }}/>
      
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button onClick={onConfirm} color="primary">
            Confirmer
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleAnnul} color="secondary" autoFocus>
            Annuler
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ModalConfirmText;

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleActivity = makeStyles((theme) => createStyles({
    root: {
        backgroundColor:  "#309196",
        height:"100%",
        "&:hover $iconCustom": {
          transform: "scale(1.5)",
          left: "35px",
          bottom: "32px",
        }
  },
    rootSousActivite: {
        backgroundColor:  "#3E739D",
        height:"100%",
        "&:hover $iconCustom": {
          transform: "scale(1.5)",
          left: "35px",
          bottom: "32px",
        }
  },
  presentationText: {
    color:"#FFF"
  }, 
  bouton:{
    fontSize: 11,
    backgroundColor: "#3378af",
    color:"#FFF"
  }, 

  filtre:{
    width:"100%" , 
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  groupeBouton:{
    marginBlock: "0.5vw",
    width:"100%",
  }, 
  titreCard : {
    textAlign: "right",
  },
  iconCustom: {
    position: "absolute",
    top: "auto",
    bottom: "15px",
    zIndex: 0,
    fontSize: "90px",
    color: "rgba(0, 0, 0, 0.15)",
    left: "20px",
    transition: "all .7s ease-in-out",

  },
  cardHead: {
    background: "#015384",
    color:"#FFF"
  },
  paperClass: {
    padding: theme.spacing(4),
  },
}));
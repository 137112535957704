import React, { useEffect, useRef, useState } from 'react';
import { Button, Fab, Tooltip } from '@material-ui/core';
import { MySnackbar } from '../mySnackbar/MySnackbar'
import AttachFileIcon from '@material-ui/icons/AttachFile';

const SimpleUploader = ({ fileResource, setFileResource, light, title, allowedType }) => {
    const hiddenFileInput = useRef(null);
    const [listFile, setListFile] = useState({});
    const [snack, setSnack] = useState({});

    useEffect(() => {
        if (!fileResource) {
            setListFile([])
        }
        // eslint-disable-next-line
    }, [fileResource]);

    // useEffect(() => {
    //     console.log("listFile", listFile)
    //     // eslint-disable-next-line
    // }, [listFile]);

    useEffect(() => {
        if (fileResource) {
            setListFile([fileResource])
        }
         // eslint-disable-next-line
    }, []);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        let errorUpload = false;
        if (fileUploaded.size > 10000000) {//10 mo max
            errorUpload = true
        }
        // console.log("fileUploaded.type", fileUploaded.type)
        if (allowedType) {
            if (allowedType.indexOf(fileUploaded.type)<0) {
                errorUpload = true
            }
        } else {
            switch (fileUploaded.type) {
                case "application/pdf":
                case "image/jpeg":
                case "image/jpg":
                case "image/png":
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    //ok
                    break;
                default:
                    errorUpload = true
                    break;
            }
        }
        

        if (!errorUpload) {
            return new Promise(resolve => {
                let baseURL = "";
                // Make new FileReader
                let reader = new FileReader();

                // Convert the file to base64 text
                reader.readAsDataURL(fileUploaded);

                // on reader load somthing...
                reader.onload = () => {
                    baseURL = reader.result;
                    let tmpFile = { name: fileUploaded.name, base64File: baseURL }
                    setFileResource(tmpFile)
                    // fileResource.value = { name: fileUploaded.name, base64File: baseURL };
                    setListFile([{ name: fileUploaded.name, base64File: baseURL }])
                    resolve(baseURL);
                };
               
            });
        } else {
            if (allowedType) {
                setSnack({ message: "La taille du fichier ne doit pas excéder 10Mo et doit être au format " + allowedType.join(", "), show: true, severity: "error" })
            } else {
                setSnack({ message: "La taille du fichier ne doit pas excéder 10Mo et doit être au format pdf, xlsx, docx, png, jpg.", show: true, severity: "error" })
            }
        }
       
    };

    // const previewFile = (file64) => {
    //     var win = window.open();
    //     win.document.write('<iframe src="' + file64 + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    // }
    
    return (
        <>
            <MySnackbar params={snack} />
            {light ?
            <Tooltip
                    title={<h3> {listFile.length > 0 ? "remplacer le fichier" : "Charger un fichier"}</h3>}
                    aria-label={listFile.length > 0 ? "remplacer le fichier" : "Charger un fichier"}
                    placement="left"
            >
                    <Fab color={listFile.length > 0 ? "secondary" : "primary"} size="small" onClick={handleClick} >
                        <AttachFileIcon fontSize="small" />
                    </Fab>
            </Tooltip>
            :
            <Button startIcon={<AttachFileIcon />} size="small" variant="contained" color={listFile.length > 0 ? "secondary" : "primary"} onClick={handleClick}>
                    {title && (listFile.length>0 ? title[1] : title[0])}
            </Button>}
            <input type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
            {/* {listFile.map((loadedFile, index) => (
                <Link key={index} href="#" onClick={_ => previewFile(loadedFile.base64File)} variant="body2">{loadedFile.name} </Link>
            ))} */}
        </>
    );
};
export default SimpleUploader;
import React from 'react';

const OngletInfoSupplAct = () => {
    return (
        <div>
            onglet 7
        </div>
    );
};

export default OngletInfoSupplAct;
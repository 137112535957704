/**
 * Ajoute deux horaires au format "hh:mm" et renvoie le total également au format "hh:mm".
 * @param {string} time1 - Premier horaire au format "hh:mm".
 * @param {string} time2 - Deuxième horaire au format "hh:mm".
 * @return {string} - Total des horaires au format "hh:mm".
 */
const addHoursStringFormat = (time1, time2) => {
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);

    let totalHours = hours1 + hours2;
    let totalMinutes = minutes1 + minutes2;

    // Gérer le cas où le total des minutes dépasse 60
    if (totalMinutes >= 60) {
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;
    }

    // Formater les heures et les minutes pour avoir toujours deux chiffres
    const formattedHours = String(totalHours).padStart(2, "0");
    const formattedMinutes = String(totalMinutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
};

/**
 * Renvoie la couleur complémentaire pour une couleur donnée au format hexadécimal.
 * @param {string} color - Couleur au format hexadécimal (ex. "#RRGGBB").
 * @return {string} - Couleur complémentaire au format hexadécimal.
 */
const getComplementaryColor = (color) => {
    var c = color.slice(1),
        i = parseInt(c, 16),
        v = ((1 << 4 * c.length) - 8 - i).toString(16);

    while (v.length < c.length) {
        v = '0' + v;
    }
    return '#' + v;
};

/**
 * Génère des couleurs aléatoires au format hexadécimal.
 * @param {number} count - Nombre de couleurs à générer.
 * @return {Array} - Tableau de couleurs générées.
 */
const getRandomColor = (count) => {
    // const colors = [];
    // for (let i = 0; i < count; i++) {
    //     colors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
    // }
    // return colors;
    count = count || 0;
    const color = ["#3148f1", "#30f74d", "#f2a929", "#f6401c", "#cae38e", "#65538f", "#c4e0e1", "#669588", "#94e660", "#ab495c", "#b4506e", "#3ce4d6", "#5ceaee", "#cbfdb5", "#af7d44", "#edb9b1", "#e24fc6", "#3d2671", "#7b6fcb", "#324a82", "#5f69d3", "#ea3450", "#5bd0b2", "#cd18c8", "#95282c", "#db420c", "#263dac", "#e747b4", "#9a26e4", "#309365", "#dff1ed", "#ae3e68", "#df4e11", "#e56ba6", "#130db7", "#9cbb11", "#1bd014", "#7f5ecc", "#47b76b", "#f0c2e6", "#cf3cb3", "#5c2f4e", "#1d9abb", "#33f516", "#e53783", "#1bbe2b", "#c2f37e", "#8657aa", "#5c54f3", "#787c63", "#f93776", "#765203", "#1231cb", "#daa8da", "#4c6e4c", "#b5a966", "#78c192", "#256aa4", "#543c46", "#74ec92", "#ae6960", "#e906cf", "#f4ad99", "#10e204", "#81ad7", "#e6810a", "#8036ec", "#dbdb25", "#1fa92a", "#92f000", "#c0c4dd", "#bbcf52", "#62d92d", "#51b9f1", "#fd6d4c", "#fffa8f", "#471fea", "#d78623", "#5cf977", "#acebee", "#2263fc", "#eaf97b", "#8d4b50", "#a141f1", "#2ef724", "#19570", "#7c974b", "#43e143", "#ce7df3", "#cb33a1", "#21dde6", "#e3f1b9", "#db8ecf", "#cc0949", "#2d8d9f", "#51b56c", "#ed9252", "#5ea721", "#7a8c3c", "#7d33"
    ];
    return color.slice(40, count + 40)
};

/**
 * Calcule le nombre total d'heures pour une activité.
 * Utilisé pour les manifestations où une activité peut contenir plusieurs sous-activités.
 * @param {Array} activity - Les données d'activité qui contiennent des sous-activités.
 * @return {Array} - Tableau [totalHours, remainingMinutes] pour les heures totales et les minutes restantes.
 */
const calculateTotalHours = (activity) => {
    let totalMinutes = 0;

    // Parcours des sous-activités dans l'activité donnée
    activity.forEach((subActivity) => {
        const [hours, minutes] = subActivity.duree.split(":"); // Séparation des heures et minutes
        totalMinutes += parseInt(hours) * 60 + parseInt(minutes); // Conversion en minutes et addition au total
    });

    const totalHours = Math.floor(totalMinutes / 60); // Calcul des heures totales
    const remainingMinutes = totalMinutes % 60; // Calcul des minutes restantes

    return [totalHours, remainingMinutes]; // Renvoi des heures totales et minutes restantes
};

/**
 * Calcule le nombre total d'heures pour une activité.
 * Utilisé pour les activités individuelles où la durée est directement fournie en paramètre.
 * @param {string} duree - Durée de l'activité au format "hh:mm".
 * @return {Array} - Tableau [totalHours, remainingMinutes] pour les heures totales et les minutes restantes.
 */
const calculateTotalHoursActivity = (duree) => {
    let totalMinutes = 0;
    const [hours, minutes] = duree.split(":"); // Séparation des heures et minutes
    totalMinutes = parseInt(hours) * 60 + parseInt(minutes); // Conversion en minutes

    const totalHours = Math.floor(totalMinutes / 60); // Calcul des heures totales
    const remainingMinutes = totalMinutes % 60; // Calcul des minutes restantes

    return [totalHours, remainingMinutes]; // Renvoi des heures totales et minutes restantes
};

// =============== Personne

const getGender = (data) => {
    const genderCount = {};
    data.forEach((person) => {
        let gender = person.Gender || 'not';
        if (genderCount[gender]) {
            genderCount[gender] += 1;
        } else {
            genderCount[gender] = 1;
        }
    });
    return genderCount;
};

const getTShirtSize = (data) => {
    const tShirtCount = {};
    data.forEach((person) => {
        let tShirt = person.Taille_Tshirt || 'not';
        if (tShirtCount[tShirt]) {
            tShirtCount[tShirt] += 1;
        } else {
            tShirtCount[tShirt] = 1;
        }
    });
    return tShirtCount;
};

/**
 * Analyse les données d'inscription pour obtenir le nombre d'inscriptions en fonction des intervalles de jours.
 * @param {Array} data - Les données brutes d'inscription.
 * @return {Object} - Les données analysées, groupées par édition et intervalle de jours.
 */
const getIntervalDaysInscription = (data, resEdition) => {
    const editionIntervalCountMap = {}; // Dictionnaire pour stocker les données analysées
    data.forEach(person => { // Parcours des données des personnes
        person.Activite.forEach(manif => { // Parcours des activités de chaque personne
            const edition = formatManifName(manif.nomManif, manif.edition); // Formatage du nom de l'événement et de l'édition
            if (!editionIntervalCountMap[edition]) {
                editionIntervalCountMap[edition] = {};
            }
            const currentEdition = resEdition.find(item => item.Edition === manif.edition);
            if (currentEdition) {
                const eventDate = currentEdition ? new Date(currentEdition.Date.split('.').reverse().join('-')) : new Date(); // Date de l'événement
                manif.Activite.forEach(activite => { // Parcours des activités de chaque événement
                    activite.Tranches.forEach(tranche => { // Parcours des tranches horaires de chaque activité
                        const timestamp = tranche.Inscription;
                        const inscriptionDate = new Date(timestamp * 1000); // Date d'inscription

                        // const eventDate = new Date(tranche.Date.split('.').reverse().join('-')); // Date de l'événement

                        if (!isNaN(inscriptionDate) && !isNaN(eventDate)) { // Vérification de validité des dates
                            const intervalInDays = Math.floor((eventDate - inscriptionDate) / (1000 * 60 * 60 * 24)); // Calcul de l'intervalle en jours

                            if (!isNaN(intervalInDays) && intervalInDays >= 0) { // Vérification de validité de l'intervalle
                                editionIntervalCountMap[edition][intervalInDays] = (editionIntervalCountMap[edition][intervalInDays] || 0) + 1; // Mise à jour du dictionnaire
                            }
                        }
                    });
                });
            }
        });
    });

    for (const edition in editionIntervalCountMap) { // Nettoyage des éditions vides
        if (Object.keys(editionIntervalCountMap[edition]).length === 0) {
            delete editionIntervalCountMap[edition];
        }
    }

    return editionIntervalCountMap; // Renvoi du dictionnaire avec les données analysées
};

/**
 * Transforme les données pour créer un graphique en barres.
 * @param {Object} data - Les données originales sous forme de dictionnaire.
 * @return {Object} - Les données transformées pour le graphique en barres.
 */
const transformDataForBarChart = (data, sortOrder = 'asc') => {
    const transformedData = {};

    // Parcours des données originales et transformation
    for (const edition in data) {
        for (const date in data[edition]) {
            if (!transformedData[date]) {
                transformedData[date] = {};
            }
            transformedData[date][edition] = data[edition][date];
        }
    }

    // Ordonner les dates par nombre total décroissant
    const sortedDates = Object.keys(transformedData).sort((a, b) => {
        const totalA = Object.values(transformedData[a]).reduce((sum, value) => sum + value, 0);
        const totalB = Object.values(transformedData[b]).reduce((sum, value) => sum + value, 0);
        return totalB - totalA;
    });

    // Sélectionner les 30 premières dates (les plus remplies)
    const selectedDates = sortedDates.slice(0, 30);


    // Tri final des 30 dates sélectionnées (croissant ou décroissant)
    selectedDates.sort((a, b) => {
        const aValue = Number(a);
        const bValue = Number(b);

        if (!isNaN(aValue) && !isNaN(bValue)) {
            if (sortOrder === 'asc') {
                return aValue - bValue;
            } else {
                return bValue - aValue;
            }
        } else {
            if (sortOrder === 'asc') {
                return a.localeCompare(b);
            } else {
                return b.localeCompare(a);
            }
        }
    });



    // Création des données pour le graphique en barres
    return {
        labels: selectedDates,
        data: Object.keys(data).map(edition => ({
            label: edition,
            values: selectedDates.map(date => transformedData[date][edition] || 0),
        })),
    };
};

// =============== Manifestation
const sortedManifestations = (data) => {
    return Object.values(data).sort((a, b) => {
        const aTotalParticipants = a.Activite.reduce((total, activite) => total + activite.Tranches.length, 0);
        const bTotalParticipants = b.Activite.reduce((total, activite) => total + activite.Tranches.length, 0);
        return bTotalParticipants - aTotalParticipants;
    }
    )
};

const getManifName = (data) => {
    let Name = new Set();
    data.forEach((person) => {
        person.Activite.forEach((activity) => {
            Name.add(formatManifName(activity.nomManif, activity.edition)); // Nom de la manifestation
        })
    })
    return [...Name].sort();
}

const formatManifName = (nomManif, edition) => {
    const lowerCasedEdition = edition.toLowerCase();
    const lowerCasedNomManif = nomManif.toLowerCase();

    if (lowerCasedEdition.includes(lowerCasedNomManif)) {
        return edition;
    } else {
        return `${nomManif} ${edition}`;
    }
}

/**
 * Calcule les données totales par manifestation en fonction des activités des participants.
 * @param {Array} data - Les données brutes des participants et de leurs activités.
 * @return {Object} - Les données totales par manifestation pour le graphique.
 */
const calculateTotalDataPerManifestation = (data) => {
    const totalDataPerManifestation = {}; // Initialisation d'un objet pour les données totales

    data.forEach((person) => {
        const numberOfReinscriptions = calculateYearOfReinscriptions(person.Activite); // Calcul du nombre de réinscriptions
        person.Activite.forEach((activity) => {
            let currentYear = null;
            if (activity.Activite && activity.Activite.length > 0) {
                currentYear = parseInt(activity.Activite[0].Tranches[0]?.Date.split(".")[2]); // Année de l'activité
            } else {
                console.error("Aucune activité trouvée pour la manifestation === calculateTotalDataPerManifestation.", activity);
            }
            const nomManif = formatManifName(activity.nomManif, activity.edition); // Nom de la manifestation
            const [totalHours] = calculateTotalHours(activity.Activite); // Total des heures pour l'activité

            // Ajouter ou mettre à jour les données pour la manifestation
            if (totalDataPerManifestation[nomManif]) {
                totalDataPerManifestation[nomManif].totalHours += totalHours;
                totalDataPerManifestation[nomManif].totalVolunteers += 1;

            } else {
                totalDataPerManifestation[nomManif] = {
                    totalHours,
                    totalVolunteers: 1,
                    totalReinscriptions: 0,
                };
            }
            if (numberOfReinscriptions.find((year) => year === currentYear - 1))
                totalDataPerManifestation[nomManif].totalReinscriptions += 1;
        });
    });

    // Créer un tableau d'objets pour les données de chaque manifestation
    const manifestDataArray = Object.keys(totalDataPerManifestation).map((nomManif) => ({
        nomManif,
        totalHours: totalDataPerManifestation[nomManif].totalHours,
        totalVolunteers: totalDataPerManifestation[nomManif].totalVolunteers,
        totalReinscriptions: totalDataPerManifestation[nomManif].totalReinscriptions,
    }));

    // Trier le tableau en fonction du total des heures (en ordre décroissant)
    manifestDataArray.sort((a, b) => b.totalHours - a.totalHours);
    return manifestDataArray;
};

const convertToManifChart = (manifs) => {
    manifs = manifs.slice(0, 6);
    const manifsOrder = sortActivityStatsByValues(manifs, 'nomManif', 'asc')
    // Conversion des données en format compatible avec le graphique
    const labels = manifsOrder.map((data) => data.nomManif);
    const totalHoursValues = manifsOrder.map((data) => data.totalHours);
    const totalVolunteersValues = manifsOrder.map((data) => data.totalVolunteers);
    const totalReinscriptionsValues = manifsOrder.map((data) => data.totalReinscriptions);
    return { labels, totalHoursValues, totalVolunteersValues, totalReinscriptionsValues }
};

/**
 * Calcule les années de réinscription à partir des données des activités de manifestation.
 * @param {Array} manif - Les données d'activités de manifestation.
 * @return {Array} - Les années de réinscription distinctes.
 */
const calculateYearOfReinscriptions = (manif) => {
    const distinctYearsSet = new Set(); // Ensemble pour stocker les années distinctes

    manif.forEach((manifestation) => {
        let currentYear = null;
        if (manifestation.Activite && manifestation.Activite.length > 0) {
            currentYear = parseInt(manifestation.Activite[0]?.Tranches[0]?.Date.split(".")[2]); // Année de l'activité
        } else {
            console.error("Aucune activité trouvée pour la manifestation.");
        }

        distinctYearsSet.add(currentYear); // Ajout de l'année à l'ensemble
    });

    const numberOfReinscriptions = Array.from(distinctYearsSet); // Conversion de l'ensemble en tableau
    return numberOfReinscriptions;
};


// =============== activite
const getActivityName = (data) => {
    let Name = new Set();
    data.forEach((person) => {
        person.Activite.forEach((activity) => {
            activity.Activite.forEach((subActivity) => {
                Name.add(subActivity.nom);
            })
        })
    })
    return [...Name].sort();
};
/**
 * Calcule les statistiques d'activité.
 * @param {Array} data - Les données des personnes et de leurs activités.
 * @return {Object} - Les statistiques d'activité calculées.
 */
const calculateActivityStats = (data) => {
    const activityStats = {}; // Initialisation de l'objet pour les statistiques d'activité

    data.forEach((person) => {
        person.Activite.forEach((activity) => {
            activity.Activite.forEach((subActivity) => {
                let currentYear = null;
                if (subActivity.Tranches && subActivity.Tranches.length > 0) {
                    currentYear = parseInt(subActivity.Tranches[0].Date.split(".")[2]);
                }
                if (!activityStats[subActivity.nom]) {
                    // Initialisation des statistiques pour cette sous-activité
                    activityStats[subActivity.nom] = {
                        activityName: subActivity.nom,
                        totalVolunteers: 0,
                        totalHours: 0,
                        reinscription: 0,
                    };
                }
                // Vérification de la réinscription pour l'année précédente
                const reinscription = getNbActivityReinscription(data, (currentYear - 1), subActivity.nom) ? 1 : 0;

                // Calcul des heures totales pour cette sous-activité
                const [totalHours] = calculateTotalHoursActivity(subActivity.duree);

                // Mise à jour des statistiques
                activityStats[subActivity.nom].totalVolunteers += subActivity.Tranches?.length;
                activityStats[subActivity.nom].totalHours += totalHours;
                activityStats[subActivity.nom].reinscription += reinscription;
            });
        });
    });

    return activityStats; // Retourne les statistiques d'activité calculées
};

/**
 * Obtient le nombre de réinscriptions pour une sous-activité donnée au cours d'une année spécifique.
 * @param {Array} data - Les données des personnes et de leurs activités.
 * @param {number} year - L'année pour laquelle vérifier les réinscriptions.
 * @param {string} name - Le nom de la sous-activité à vérifier.
 * @return {boolean} - Indique si des réinscriptions ont eu lieu pour la sous-activité donnée et l'année spécifique.
 */
const getNbActivityReinscription = (data, year, name) => {
    for (const person of data) {
        for (const activity of person.Activite) {
            for (const subActivity of activity.Activite) {
                if (subActivity.nom === name) {
                    if (subActivity.Tranches && subActivity.Tranches.length > 0) {
                        const currentYear = parseInt(subActivity.Tranches[0].Date.split(".")[2]) || null;
                        if (currentYear && currentYear === year) {
                            return true; // Réinscription trouvée pour la sous-activité donnée et l'année spécifique
                        }
                    }
                }
            }
        }
    }
    return false; // Aucune réinscription trouvée pour la sous-activité donnée et l'année spécifique
};


const convertToChartValue = (activityStats) => {
    activityStats = activityStats.slice(0, 7);
    // Convertir les données en format compatible avec le graphique
    const activityChartLabels = Object.values(activityStats).map((activity) => activity.activityName);
    const totalActivityHoursValues = Object.values(activityStats).map((activity) => activity.totalHours);
    const totalActivityVolunteersValues = Object.values(activityStats).map((activity) => activity.totalVolunteers);
    const totalActivityReinscriptionValues = Object.values(activityStats).map((activity) => activity.reinscription);

    return { labels: activityChartLabels, totalActivityHoursValues, totalActivityVolunteersValues, totalActivityReinscriptionValues };
};

// ================== Table 
const getDataForTableActivity = (activityData) => {
    const tableHead = ['N°', 'Activité', 'Heures', 'Bénévoles', 'Réinscriptions'];

    const tableData = Object.keys(activityData).map((name, index) => {
        return [
            (index + 1).toString(),
            activityData[name].activityName || '',
            activityData[name].totalHours?.toString() || '0', // Afficher le total des heures avec 2 décimales
            activityData[name].totalVolunteers?.toString() || '',
            activityData[name].reinscription?.toString() || '',
        ]
    });
    return { tableHead, tableData };
}

// ================== Fonction de filtrage
const filterDataByNomManif = (data, nomManifs) => {
    const filteredData = [];
    for (const person of data) {
        const filteredPerson = JSON.parse(JSON.stringify(person));
        filteredPerson.Activite = [];
        for (const manifActivity of person.Activite) {
            if (nomManifs.includes(formatManifName(manifActivity.nomManif, manifActivity.edition))) {
                filteredPerson.Activite.push(JSON.parse(JSON.stringify(manifActivity)));
            }
        }
        if (filteredPerson.Activite.length > 0) filteredData.push(filteredPerson);
    }
    return filteredData;
};

const filterDataByNomActivite = (data, nomActivites) => {
    const filteredData = [];

    for (const person of data) {
        const filteredPerson = JSON.parse(JSON.stringify(person));
        filteredPerson.Activite = [];

        for (const manifActivity of person.Activite) {
            const filteredManifActivity = JSON.parse(JSON.stringify(manifActivity));
            filteredManifActivity.Activite = [];

            for (const activity of manifActivity.Activite) {
                if (nomActivites.includes(activity.nom)) {
                    filteredManifActivity.Activite.push(JSON.parse(JSON.stringify(activity)));
                }
            }

            if (filteredManifActivity.Activite.length > 0) {
                filteredPerson.Activite.push(filteredManifActivity);
            }
        }

        if (filteredPerson.Activite.length > 0) {
            filteredData.push(filteredPerson);
        }
    }

    return filteredData;
};

const filterDataByGenre = (data, genres) => {
    const filteredData = [];
    for (const person of data) {
        if (genres.includes(person.Gender)) {
            let filteredPerson = JSON.parse(JSON.stringify(person));
            filteredData.push(filteredPerson);
        }
    }
    return filteredData;
};

const sortActivityStatsByValues = (activityStats, sortActivityColumn, sortOrder) => {
    const sortedStats = Object.values(activityStats);
    sortOrder = sortOrder || 'desc';
    sortedStats.sort((a, b) => {
        const valueA = a[sortActivityColumn];
        const valueB = b[sortActivityColumn];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return (sortOrder === 'asc' ? valueA - valueB : valueB - valueA); // Tri numérique
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
            return (sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)); // Tri de chaînes de caractères
        }

        // Types différents, ne peut pas comparer
        return 0;
    });

    return sortedStats;
};

export {
    getRandomColor, getComplementaryColor, addHoursStringFormat, sortedManifestations, calculateTotalDataPerManifestation, convertToManifChart,
    calculateActivityStats, sortActivityStatsByValues, convertToChartValue,
    getDataForTableActivity, filterDataByNomManif, filterDataByNomActivite, filterDataByGenre, getManifName, getActivityName,
    getGender, getTShirtSize, getIntervalDaysInscription, transformDataForBarChart
}
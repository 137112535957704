import { InputLabel, TextField, MenuItem, FormControl, Select, Input, Button, Chip, FormHelperText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import rest from "../../rest";
import { useStylesOnglet } from "./Onglets.css";


const OngletAccreditation = ({ readOnly, idBenevole }) => {
  const classesOnglet = useStylesOnglet();
  const [accreditationBen, setAccreditationBen] = useState([]);
  const [accreditationActBen, setAccreditationActBen] = useState([]);
  const [mentionBadge, setMentionBadge] = useState([]);
  const [listeTypeMat, setListeTypeMat] = useState([]);


  useEffect(() => {
    // Requete pour accreditation & mention deja la 
    var paramsData = {
      'vId': idBenevole
    }
    rest.post('/benevols/accreditationBenevol.json', paramsData).then(function (response) {
      setAccreditationActBen(response.data.accredActivites)
      setAccreditationBen(response.data.Accred)
      if (response.data.Accred.length===0) {
        setAccreditationBen([""])
      }
      setMentionBadge(response.data.Mention)

    })
    //Requete  pour les types de materiel
    var paramsData2 = {
      'vAction': "detailNom",
      'vNomMat': "Accr"
    }
    rest.post('/materiel/typeMateriel.json', paramsData2).then(function (response) {
      if (response.data.Message !=="Erreur") {
        setListeTypeMat(response.data.Couleur)
      }
    })
  }, [idBenevole])


  // Requête pour les sauvegarde
  const saveAccred = () => {
    var paramsData = {
      'vId': idBenevole,
      'vAccredSupp': JSON.stringify(accreditationBen)
    }
    rest.post('/benevols/saveAccredSupp.json', paramsData).then(function (response) {
      if (response.data.reponse === "ok") {
        console.log("ok")
      }
      else {
        console.log("erreur", response.data.error)
      }
    })
  }

  const saveMention = () => {
    var paramsData = {
      'vId': idBenevole,
      'vMention': mentionBadge
    }
    rest.post('/benevols/saveMentionBadge.json', paramsData).then(function (response) {
      if (response.data.reponse === "ok") {
        console.log("ok")
      }
      else {
        console.log("erreur", response.data.error)
      }
    })
  }

  const saveElement = () => {
    saveAccred()
    saveMention()
  }
  // Autre 
  const handleChangeAccreditation = (event) => {
    setAccreditationBen(event.target.value)
  }
  const handleChangeMention = (event) => {
    setMentionBadge(event.target.value)
  }

  return (
    <>
      <FormControl fullWidth className={classesOnglet.formControl} >
        <InputLabel id="select-multiple" style={{ fontSize: 19 }}>Ajouter des accréditations</InputLabel>
        <Select
          labelId="select-multiple"
          id="select-multiple"
          multiple
          value={accreditationBen}
          fullWidth
          disabled={readOnly}
          onChange={handleChangeAccreditation}
          input={<Input id="select-multiple" />}
          renderValue={(accreditationBen) => (
            <div className={classesOnglet.chips}>
              {accreditationActBen.map((value) => (
                  <Chip key={value} label={value} disabled className={classesOnglet.chip} color="secondary" />
              ))}
              {accreditationBen.map((value) => (
                value !=="" && <Chip key={value} label={value} className={classesOnglet.chip} color="primary" />
              ))}
            </div>
          )}
        >
          {listeTypeMat.map((type) => (
            <MenuItem key={type} value={type} >
              {type}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Verte : accréditations provenant des activités auxquelles le bénévole participe.</FormHelperText>
      </FormControl>
      <FormControl fullWidth className={classesOnglet.formControl} style={{ marginBlock: 30, fontSize: 19 }}>
        <TextField id="filled-basic" label="Mention Badge" disabled={readOnly} value={mentionBadge} onChange={handleChangeMention} />
      </FormControl>
      
      <Button disabled={readOnly}  style={{ marginBlock: "1vw", paddingInline: 80 }} color="primary" fullWidth variant="contained" onClick={() => saveElement()}>Enregistrer</Button>
      
    </>
  );
};

export default OngletAccreditation;
import React, { useState, useEffect } from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Button, Fab, Grid, Popover, Slider, Tooltip, Typography, withStyles, Badge, TextField } from '@material-ui/core';
import rest from "../../rest";
import AddIcon from "@material-ui/icons/Add";
import MUIDataTable from "mui-datatables";
import MuiDatatableToolBar from '../muiDatatableToolBar/MuiDatatableToolBar';
import { useStylesGlobal } from '../../pages/GlobalStyle.css';
import FormulaireInscription from '../formulaire/FormulaireInscription';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import { MySnackbar } from '../mySnackbar/MySnackbar';
import { LazyLoadImage } from "react-lazy-load-image-component";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
const PrettoSlider = withStyles({
    track: {
        height: 8,
        borderRadius: 4,
        color: "#37A8AF"


    },
    rail: {
        height: 8,
        borderRadius: 4,
        color: "#F56954",
        opacity: "0.9",

    },
    thumb: {
        display: "none",

    },
})(Slider);


const AccordionActivity = ({ restrict, idSousActivites }) => {
    const classesGlobal = useStylesGlobal();
    const [inscrit, setInscrit] = useState([]);

    // benevole
    const [personnes, setPersonnes] = useState(0);
    // Tranche Horaire
    const [trancheHoraire, setTrancheHoraire] = useState([]);
    const [trancheId, setTrancheId] = useState(0);
    const [horaire, setHoraire] = useState("");
    const [dateActivites, setDateActivites] = useState("");
    // barre de recherche
    const [searchText, setSearchText] = useState("");
    const [rechercheResult, setRechercheResult] = useState([]);
    const [reloadSearch, setReloadSearch] = useState([])
    // Message 
    const [snack, setSnack] = useState({});
    // autre
    const [showForm, setShowForm] = useState(false);
    const [ajoutBen, setAjoutBen] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setSearchText("")
        setRechercheResult([])
        setShowForm(false)
    };
    // POPOVER 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event, value) => {
        setAnchorEl(event.currentTarget);
        setPersonnes(value)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    // USEEFFECT
    useEffect(() => {
        listTranchesHoraire();
        // eslint-disable-next-line
    }, [restrict, ajoutBen, reloadSearch])

    useEffect(() => {
        if (searchText.length>0) {
            let params = {
                vId: trancheId,
                vRech: searchText,
            }
            rest.post('/benevols/recherche.json', params)
                .then(function (response) {
                    setRechercheResult(response.data.Benevols)
                })
        }
        // eslint-disable-next-line
    }, [searchText,reloadSearch])

    useEffect(() => {
        if (!showForm) {
            benevoleInscrit(trancheId, dateActivites, horaire)
        }
        // eslint-disable-next-line
    }, [showForm, personnes])

    // Requete Pour avoir le details des tranches horaire
    const listTranchesHoraire = () => {
        let params = {
            vID: idSousActivites,
            'vRestrict': restrict ? restrict : false,
        }
        rest.post('/tranche/detailactivite.json', params)
            .then((response) => {
                setTrancheHoraire(response.data)
            })
    }
  
    //Requete pour la liste des inscrit pour une tranches horaire
    const benevoleInscrit = (id, date, horaire) => {
        if (id>0) {
            setTrancheId(id)
            setDateActivites(date)
            setHoraire(horaire)
            let params = {
                vID: id,
                vNumSousAct: idSousActivites,
            }
            rest.post('/benevols/listinscrit.json', params)
                .then((response) => {
                    setInscrit(response.data.Benevols)
                })
        } 
    }

    // REQUETE Inscrire/Désinscrire
    const InscripActiviteBenevole = (value) => {
        var paramsData = {
            'vId': trancheId,
            'vIdPersonnes': value ? value : personnes
        }
        rest.post(`/benevols/activiteInscription.json`, paramsData).then((response) => {
            console.log('response accordion ==================>>>>> ', response.data);
            setSnack({ message: response.data.textMsg, show: true, severity: "success" })
            setReloadSearch(!reloadSearch)
        }, (error) => {
            console.log(error);
        });
        setAnchorEl(null);
        // setShowForm(prev => !prev)
    };


    const handleRechBenChange = (event) => {
        setSearchText(event.target.value)   
    }

    const columns = [
        {
            label: "ID",
            name: "Person_ID",
            options: {
                download: false,
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Photo",
            name: "null",
            options: {
                // display: 'excluded',
                sort: false,
                download: false,
                // viewColumns: false,
                filter: false,
                sortThirdClickReset: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                            tableMeta.rowData[12] > 0 ?
                            <Tooltip title={<h3>Nb participation à cette activité / Nb participation à l'évènement</h3>} >
                                <Badge badgeContent={tableMeta.rowData[12] + "/" + tableMeta.rowData[13]} color="primary"> <Avatar variant="rounded" src={"/4DACTION/IMAGE_PROFILE/?person=" + tableMeta.rowData[0]} >
                                </Avatar></Badge>
                            </Tooltip>
                                :
                            <Avatar variant="rounded" >
                                <LazyLoadImage src={`/4DACTION/IMAGE_PROFILE/?person=` + tableMeta.rowData[0]}
                                    // width={600} height={400}
                                    // className={classes.imgAvatar}
                                    alt={`Pix ` + tableMeta.rowData[0]}
                                />
                                </Avatar>
                            
                    )
                }
            }
        },
        {
            label: "Nom",
            name: "Person_Name",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Prenom",
            name: "Person_Firstname",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Naissance",
            name: "Person_Birth",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Groupe",
            name: "Person_Groupe",
            options: {
                filter: true,
                sortThirdClickReset: true,
                display: 'excluded',
            }
        },
        {
            label: "Groupe",
            name: "Person_GroupeInfo",
            options: {
                filter: true,
                sortThirdClickReset: true,
                display: 'excluded',
            }
        },
        {
            label: " ",
            name: "Person_responsableInfos",
            options: {
                filter: true,
                sortThirdClickReset: true,
                display: showForm ? false : true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {!showForm && (tableMeta.rowData[5]) &&
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography color="inherit">Groupe :  {tableMeta.rowData[6]}</Typography>
                                            <Typography color="inherit">Responsable : {value.Name} {value.Firstname}</Typography>
                                            <Typography color="inherit">Numéro de tel : {value.Phone_Mobile}</Typography>
                                            <Typography color="inherit">Email: {value.Email}</Typography>
                                        </React.Fragment>
                                    }
                                >
                                    <GroupIcon />
                                </HtmlTooltip>
                              
                            }
                        </>
                    )

                }

            }
        },

        {
            label: "Email",
            name: "Person_Email",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Mobile",
            name: "Person_Phone_Mobile",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },

        {
            label: "Inscrit",
            name: "Person_Inscrite",
            options: {
                filter: true,
                sortThirdClickReset: true,
                display: "excluded",

            }
        },

        {
            label: " ",
            name: "Person_ID",
            options: {
                filter: true,
                sortThirdClickReset: true,
                display: showForm ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        // La colonne est Person_Inscrite
                        !(tableMeta.rowData[10]) ?
                            <Button onClick={_ => InscripActiviteBenevole(value)} color="primary" variant="contained"> Inscrire</Button>
                            :
                            <span>
                                <Button onClick={(e) => handleClick(e, value)} className={classesGlobal.buttonFermer}> Désincrire</Button>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    className="popover_class"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Button variant="contained" className={classesGlobal.buttonFermer} onClick={_ => InscripActiviteBenevole()}>Confirmer désinscription</Button>
                                </Popover>
                            </span>
                    )

                }
            }
        },
        {
            label: "NbParticipationAct",
            name: "NbParticipationAct",
            options: {
                download: false,
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "NbParticipationTotal",
            name: "NbParticipationTotal",
            options: {
                download: false,
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },

    ];

    const options = {
        search: false,
        download:false,
        filter: false,
        print: false,
        rowsPerPage:20,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
        downloadOptions: { filename: "donneeBenevole.csv", separator: ';' },
        onDownload: function (buildHead, buildBody, columns, data) { return "\uFEFF" + buildHead(columns) + buildBody(data); },
        searchOpen: false,
        textLabels: {
            "body": {
                "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
                "toolTip": "Trier"
            },
            "pagination": {
                "next": "Page suivante",
                "previous": "Page précédente",
                "rowsPerPage": "Ligne par page:",
                "displayRows": "de",
                "jumpToPage": "Aller à la page:"
            },
            "toolbar": {
                "search": "Rechercher",
                "downloadCsv": "Télécharger CSV",
                "viewColumns": "Afficher les colonnes",
                "filterTable": "Tableau des filtres"
            },
            "viewColumns": {
                "title": "Afficher les colonnes",
                "titleAria": "Afficher/Masquer les colonnes du tableau"
            },
            "selectedRows": {
                "text": "ligne(s) sélectionnée(s)",
                "delete": "Effacer",
                "deleteAria": "Supprimer les lignes sélectionnées"
            }
        },
        customToolbar: function () {
            const newRecord = () => {
                setSearchText("")
                setRechercheResult([])
                setShowForm(prev => !prev)
            }
            const add = <Tooltip title={"Ajout bénévole"}>
                <Fab size="small" color="secondary" aria-label="add" onClick={newRecord}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            return (
                <>
                    {!showForm && <MuiDatatableToolBar extraBtn={add} hideFilter={true} />}
                </>
            );
        }
    };


    return (
        <>
            <MySnackbar params={snack} />
            {
                trancheHoraire.map((tranche) => (
                    <Accordion expanded={expanded === tranche.ID} onChange={handleChange(tranche.ID)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            onClick={_ => benevoleInscrit(tranche.ID, tranche.DateActivite, tranche.Horaire)}>
                            <Grid container spacing={1}>
                                <Grid xs={5} >
                                    <Typography >{tranche.DateActivite} de {tranche.Horaire}</Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <span >Occupation : {tranche.NombreInscrit}/{tranche.NombreIdeal}  </span>
                                    <PrettoSlider max={tranche.NombreIdeal} value={tranche.NombreInscrit} />
                                </Grid>
                            </Grid>

                        </AccordionSummary>
                        <AccordionDetails style={{ justifyContent: "center", width: "100%" }}>
                            {!ajoutBen ?
                                (tranche.ID === expanded) ? 
                               !showForm ?
                                    <MUIDataTable
                                        data={inscrit}
                                        columns={columns}
                                        options={options}
                                    />
                                    :
                                        <Grid container spacing={1} style={{width:"90%"}}>
                                            <Grid xs={6}>
                                                <TextField
                                                    label="Rechercher un bénévole..."
                                                    type="text"
                                                    variant="outlined"
                                                    
                                                    value={searchText}
                                                    onChange={handleRechBenChange}
                                                    // className={classes.inputsForm}
                                                    helperText="minimum 2 caractères pour lancer la recherche"
                                                />
                                            </Grid>
                                            <Grid xs={6} style={{textAlign:"right"}}>
                                                <Button variant="contained" onClick={_ => setShowForm(prev => !prev)}>
                                                    Retour
                                                </Button>
                                            </Grid>
                                            <Grid xs={12}>
                                               { rechercheResult.length>0 &&
                                                <MUIDataTable
                                                    data={rechercheResult}
                                                    columns={columns}
                                                    options={options}
                                                />}
                                            </Grid>
                                        </Grid>
                                        
                                :null
                                
                                :
                                (tranche.ID === expanded) ? <FormulaireInscription idBenevole={0} setShowForm={setAjoutBen} setSnack={setShowForm} /> : null}
                        </AccordionDetails>
                        <AccordionActions >
                            {showForm && !ajoutBen &&
                                <>
                                    {/* <Button variant="contained" color="secondary" onClick={_ => setAjoutBen(true)}>
                                        Nouveau benevoles
                                    </Button> */}

                                    <Button variant="contained" onClick={_ => setShowForm(prev => !prev)}>
                                        Retour
                                    </Button>
                                </>}
                        </AccordionActions>
                    </Accordion>
                ))
            }
        </>
    )
};

export default AccordionActivity;
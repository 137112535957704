
import { Box, Button } from '@material-ui/core';
import SplitText from './modalSplitText';

const MultiModal = ({ text, options }) => {

  return (
    <Box>
      <SplitText text={text} />
      {options.map((item, index) =>
        <Button
          onClick={item.handle}
          color={item.color}
          variant={item.variant ? item.variant : 'text'}
          disabled={item.disabled ? true : false}
          style={{marginRight:'10px'}}
          key={index}
        >
          {<SplitText text={item.text} />}
        </Button>
      )}
    </Box>
  )
}

export default MultiModal;
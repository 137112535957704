import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Popover, TextField, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import rest from "../../rest";
import MuiDatatableToolBar from '../muiDatatableToolBar/MuiDatatableToolBar';
import React, { useState, useEffect } from 'react';
import { useStylesGlobal } from '../../pages/GlobalStyle.css';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';


const OngletCaracteristique = () => {
    const classesGlobal = useStylesGlobal();
    const [listCaracteristique, setListCaracteristique] = useState([]);
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [caracteristiques, setCaracteristiques] = useState("");
    const [idCaracteristique, setIdCaracteristique] = useState(0);
    const [showAjout, setShowAjout] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // La liste des caracteristiques
    useEffect(() => {
        rest.get('/caracteristique/liste.json').then(function (response) {
            setListCaracteristique(response.data)
        })
    }, [open, reload])

    const effetCaracteristique = (params) => {
        rest.post('/caracteristique/ajouterModifier.json', params).then(function (response) {
            setOpen(false)
        })

    }

    //  Confirmation de la modification
    const modificationCarac = () => {
        let params = {
            vId: idCaracteristique,
            vCaract: caracteristiques,
            vAction: "modifier"
        }
        effetCaracteristique(params)
        setCaracteristiques("")
    }
    //  Confirmation de l'ajout
    const ajouterCarac = () => {
        let params = {
            vId: null,
            vCaract: caracteristiques,
            vAction: "ajouter"
        }
        effetCaracteristique(params)

    }
    // Au  moment de la selection de la ligne
    const handleLineSelection = (rowData, rowMeta) => {
        let caracteristique = listCaracteristique[rowMeta.dataIndex]
        setIdCaracteristique(caracteristique.id)
        setCaracteristiques(caracteristique.name)
        setOpen(prev => !prev)
        setShowAjout(false)
    };
    //  changement de donnée
    const handleCaract = (e) => {
        setCaracteristiques(e.target.value)
    }
    //  Autre 
    const handleClose = () => {
        setCaracteristiques("")
        setOpen(false);
    };
    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };
    function handleClick(event, id) {
        setOpen(false)
        setShowDelete(true)
        setIdCaracteristique(id)
        setAnchorEl(event.currentTarget);

    };
    const handleDelete = () => {
        var params = {
            'vIdCarac': idCaracteristique,
        }
        rest.post('/caracteristique/deleteCaracteristique.json', params).then(function (response) {
            setReload(!reload)
        })
    };


    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Action",
            name: "nbActLiee",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        (value === 0)&&
                        <span>    
                                <Button onClick={(e) => { handleClick(e, tableMeta.rowData[0]) }}><DeleteIcon color="error" /></Button>
                                 <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseAnchor}
                                    className="popover_class"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Button variant="contained" className={classesGlobal.buttonFermer} onClick={_=>{handleDelete()}}>Confirmer désinscription</Button>
                                </Popover> 
                        </span>
                    );
                },
            }
        },
    ]

    const options = {
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
        onRowClick: handleLineSelection,
        customToolbar: () => {
            const handleOpenModalAddAct = () => {
                setOpen(true)
                setShowAjout(true)
            };

            const element = <Tooltip title={"Ajouter une caractéristique"}>
                <Fab size="small" color="secondary" aria-label="add" onClick={handleOpenModalAddAct}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            return (
                <MuiDatatableToolBar extraBtn={element} hideFilter={true} />
            );
        },
        textLabels: {
            "body": {
                "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
                "toolTip": "Trier"
            },
            "pagination": {
                "next": "Page suivante",
                "previous": "Page précédente",
                "rowsPerPage": "Ligne par page:",
                "displayRows": "de",
                "jumpToPage": "Aller à la page:"
            },
            "toolbar": {
                "search": "Rechercher",
                "downloadCsv": "Télécharger CSV",
                "viewColumns": "Afficher les colonnes",
                "filterTable": "Tableau des filtres"
            },
            "viewColumns": {
                "title": "Afficher les colonnes",
                "titleAria": "Afficher/Masquer les colonnes du tableau"
            },
            "selectedRows": {
                "text": "ligne(s) sélectionnée(s)",
                "delete": "Effacer",
                "deleteAria": "Supprimer les lignes sélectionnées"
            }
        },
    };


    return (
        <>
            <MUIDataTable
                data={listCaracteristique}
                columns={columns}
                options={options} />

           { !showDelete && <Dialog open={open} onClose={handleClose}   fullWidth>
                {!showAjout ? <DialogTitle id="form-dialog-title">Modification caractéristique</DialogTitle>
                    :
                    <DialogTitle id="form-dialog-title">Nouvelle caractéristique</DialogTitle>}
                <DialogContent >
                    <TextField
                        autoFocus
                        margin="dense"
                        value={caracteristiques}
                        onChange={handleCaract}
                        label="Caractéristique"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classesGlobal.buttonFermer}>
                        Femer
                    </Button>
                    {!showAjout ?
                        <Button onClick={_ => { modificationCarac() }} color="primary" variant='contained'>
                            Modifer
                        </Button>
                        :
                        <Button onClick={_ => { ajouterCarac() }} color="secondary" variant='contained'>
                            Ajouter
                        </Button>
                    }
                </DialogActions>
            </Dialog>
}
        </>
    );
};

export default OngletCaracteristique;
import { MenuItem, Button, Checkbox, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, Paper, Select, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import rest from '../../rest';
import { useStyleGererGroupe } from './GererGroupe.css';
import { MySnackbar} from '../../components/mySnackbar/MySnackbar'

// TODO voir et faire en sorte enleve les groupes inscrits en dur!!

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
const GererGroupe = ({ idTrancheHoraire, dateTranche, horaireTranche }) => {
  const classesGererGroupe = useStyleGererGroupe();
  // Requete
  const [listGrp, setListGrp] = useState([]);
  const [selectedGrp, setSelectedGrp] = useState([]);
  const [listInscrit, setListInscrit] = useState([]);
  const [listMembre, setListMembre] = useState([]);
  // Id benevole
  const [idBen, setIdBen] = useState([]);
  // Check 
  const [checked, setChecked] = useState([]);
  const leftChecked = intersection(checked, listInscrit);
  const rightChecked = intersection(checked, listMembre);
  // Message 
  const [snack, setSnack] = useState({});
  const [refresh, setRefresh] = useState(false);
  const handleChange = (event) => {
    setSelectedGrp(event.target.value);
  }

  // Requete liste des groupes
  useEffect(() => {
    rest.get(`/benevols/listeGroupes.json`).then((response) => {
      setListGrp(response.data)
    }, (error) => {
      console.log(error);
    });
  }, [])
  useEffect(() => {
    listMembreGroupe();
    listMembreTranche();
    setIdBen([])
    // eslint-disable-next-line
  }, [selectedGrp, refresh])


  // Liste des membres du groupe selectionné
  const listMembreGroupe = () => {
    let params = {
      vId: selectedGrp > 0 ? selectedGrp : "",
      vIdTH: idTrancheHoraire,
      newUI: true,
    }

    rest.post(`/groupe/listinscrit.json`, params).then((response) => {
      setListInscrit(response.data.Groupe)
    }, (error) => {
      console.log(error);
    });

  }

  // List des membres inscrits à la tranche horaire selectionné
  const listMembreTranche = () => {
    let params = {
      vIdGr: selectedGrp > 0 ? selectedGrp : "",
      vIdTH: idTrancheHoraire
    }
    rest.post(`/groupe/lstMembresTranche.json`, params).then((response) => {
      setListMembre(response.data)
    }, (error) => {
      console.log(error);
    });
  }
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let currentId = 0
    currentId = value.Person_ID > 0 ? value.Person_ID : value.id
    


    if (currentIndex === -1) {
      setIdBen((preArray) => {
        let newArray = [];
        for (let index = 0; index < preArray.length; index++) {
          const element = preArray[index];
            newArray.push(element)
        }
        newArray.push(currentId)
        return newArray
      });

      newChecked.push(value);
    } else {
      setIdBen(idBen.filter(item => item !== currentId))
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Inscription groupe tranche horaire
  const handleAllRight = () => {
    let params = {
      vIdGr: selectedGrp > 0 ? selectedGrp : "",
      vIdTH: idTrancheHoraire
    }
    rest.post(`/activites/addGroupToTH.json`, params).then((response) => {
      // response.data.forEach((addGroupe) => {
        if (response.data.response.length === 0) {
          setListMembre(listMembre.concat(listInscrit));
          setListInscrit([]);
          setRefresh(prev => !prev)
          setSnack({ message: "Membres sont inscrits avec success", show: true, severity: "success" })
        } else {
          let contentError = "";
          for (let index = 0; index < response.data.response.length; index++) {
            const element = response.data.response[index];
            switch (element.type) {
              case "conflit":
                contentError += 'Ce bénévole est en conflit : ' + element.name + "<br/>"
                break;
              case "dejaInscrit":
                contentError += 'Ce bénévole est déjà inscrit : ' + element.name + "<br/>"
                break;
              case "notOk":
                contentError += 'Une erreur est survenue : ' + element.name + "<br/>"
                break;
              default:
                break;
            }
          }

          setSnack({ message: contentError, show: true, severity: "error", autoHideDuration: 20000 })
          // setIdBen([])
          setRefresh(prev => !prev)
        }
     })
      
    // }, (error) => {
    //   console.log(error);
    // });

  };

  // Inscription membre tranche horaire
  const handleCheckedRight = () => {
    let params = {
      vIdGr: selectedGrp > 0 ? selectedGrp : "",
      vIdBen: JSON.stringify(idBen),
      vIdTH: idTrancheHoraire
    }
    rest.post(`/activites/addMembreToTH.json`, params).then((response) => {
      if (response.data.response.length === 0) {
        setListMembre(listMembre.concat(leftChecked));
        setListInscrit(not(listInscrit, leftChecked));
        setChecked(not(checked, leftChecked));
        // setIdBen([])
        setSnack({ message: "Membre est inscrit avec success", show: true, severity: "success" })
        setRefresh(prev => !prev)
      } else {
        let contentError = "";
        for (let index = 0; index < response.data.response.length; index++) {
          const element = response.data.response[index];
          switch (element.type) {
            case "conflit":
              contentError += 'Ce bénévole est en conflit : ' + element.name+"<br/>"
              break;
            case "dejaInscrit":
              contentError += 'Ce bénévole est déjà inscrit : ' + element.name + "<br/>"
              break;
            case "notOk":
              contentError += 'Une erreur est survenue : ' + element.name + "<br/>"
              break;
            default:
              break;
          }
        }

        setSnack({ message: contentError, show: true, severity: "error", autoHideDuration: 20000 })
        // setIdBen([])
        setRefresh(prev => !prev)
      }
    }, (error) => {
      console.log(error);
    });
  };

  // Desincription membre tranche horaire
  const handleCheckedLeft = () => {
    let params = {
      vIdGr: selectedGrp > 0 ? selectedGrp : "",
      vIdBen: JSON.stringify(idBen),
      vIdTH: idTrancheHoraire
    }
    rest.post(`/activites/removeMembreFromTH.json`, params).then((response) => {
      if (response.data.response.length === 0) {
        setListInscrit(listInscrit.concat(rightChecked));
        setListMembre(not(listMembre, rightChecked));
        setChecked(not(checked, rightChecked));
        // setIdBen([])
        setRefresh(prev => !prev)
        setSnack({ message: "Membre est désinscrit avec success", show: true, severity: "success" })

      } else {
        let contentError = "";
        for (let index = 0; index < response.data.response.length; index++) {
          const element = response.data.response[index];
          switch (element.type) {
            case "notOk":
              contentError += 'Une erreur est survenue : ' + element.name + "<br/>"
              break;
            default:
              break;
          }
        }

        setSnack({ message: contentError, show: true, severity: "error", autoHideDuration: 20000 })
        // setIdBen([])
        setRefresh(prev => !prev)
      }
    }, (error) => {
      console.log(error);
    });

  };

  // Desincription groupe tranche horaire
  const handleAllLeft = () => {
    let params = {
      vIdGr: selectedGrp > 0 ? selectedGrp : "",
      vIdTH: idTrancheHoraire
    }
    rest.post(`/activites/removeGroupFromTH.json`, params).then((response) => {
      response.data.forEach((inscrit) => {
        if (inscrit.reponse === "ok") {
          setListInscrit(listInscrit.concat(listMembre));
          setListMembre([]);
          setSnack({ message: "Membres sont désinscrits avec success", show: true, severity: "success" })

        }
        else {
        
        }

      })
      setRefresh(prev => !prev)
    }, (error) => {
      console.log(error);
    });

  };

  const customList = (items) => (
    <Paper className={classesGererGroupe.paper}>
      <List dense component="div" role="list">
        {(items === listMembre) ? <h3 style={{ textAlign: "center" }}>  Membres inscrits </h3>
          :
          <h3 style={{ textAlign: "center" }}> Membres du groupe </h3>}
        <Divider />
        {items && items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;
          return (
            <ListItem key={value.Person_ID} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>

              <ListItemText id={labelId} primary={value.Person_Name ? value.Person_Name + " " + value.Person_Firstname : value.nom + " " + value.prenom} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
    <MySnackbar params={snack} />
      <Grid container spacing={4} >
        <Grid item>
          <h4> Le {dateTranche}  du {horaireTranche}</h4>
        </Grid>
        <Grid item >
          <FormControl className={classesGererGroupe.formControl}>
            <InputLabel >Selectionner un groupe</InputLabel>
            <Select
              value={selectedGrp}
              onChange={handleChange}
              className={classesGererGroupe.select}
            >
              {listGrp.map((grp) => (
                <MenuItem key={grp.idGr} value={grp.idGr}>
                  {grp.nomGr}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <br />
      {selectedGrp > 0 && <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classesGererGroupe.root}
      >
        <Grid item>{customList(listInscrit)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classesGererGroupe.button}
              onClick={handleAllRight}
              disabled={listInscrit.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classesGererGroupe.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classesGererGroupe.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classesGererGroupe.button}
              onClick={handleAllLeft}
              disabled={listMembre.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(listMembre)}</Grid>
      </Grid>
      }
      <br />
    </>
  );

};

export default GererGroupe;
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleEmailList = makeStyles((theme) => createStyles({
    bodyContainer: {
        backgroundColor: "#E5E9EC",
        height: '100vh'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    title: {
        color: "#37A8AF",
        fontWeight: 'bold',
        padding: 0,
        '&:hover': {
            color: "#fff",
            background: "#37A8AF",
        },
    },
   
}));
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Typography, Button, Box, Grow, Paper, ClickAwayListener, Popper, Divider } from '@material-ui/core';
import { useStyles } from '../infosBenevole/InfosBenevole.css';
import rest from "../../rest";
import { useSelector } from 'react-redux';
import { useGlobalContext } from '../general/globalContexte';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import CakeIcon from '@material-ui/icons/Cake';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GetRandomKey from '../general/genereRandomKey';
import InfosBenevole from '../infosBenevole/InfosBenevole';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';


const PopperTipsBenevol = ({ idBenevole, person, text, componentKey, position ='top', showEdit=true }) => {
    const classes = useStyles();
    const [avatarPix, setAvatarPix] = useState(`/4DACTION/IMAGE_PROFILE/?person=${idBenevole}`);
    const [benevol, setBenevol] = useState({});
    const [open, setOpen] = useState(false);
    const [openFicheUser, setOpenFicheUser] = useState(false);
    const anchorRef = useRef(null);
    const timerRef = useRef(null);
    const { openStatesPopper, setOpenStatesPopper } = useGlobalContext();
    const currentUser = useSelector(state => state.user);

    const globalIsOpen = useMemo(() => {
        return openStatesPopper[componentKey] || false;
    }, [openStatesPopper,componentKey])

    useEffect(() => {
        if (person) {
            setBenevol(person);
        } else {
            let params = {
                vID: idBenevole,
            };
            rest.post('/benevols/info.json', params)
                .then((response) => {
                    setBenevol(response.data.benevole);
                });
        }
        let url = `/4DACTION/IMAGE_PROFILE/?person=${idBenevole}&alea=` + Math.floor(Math.random() * 1000000);
        setAvatarPix(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idBenevole]);

    useEffect(() => {
        if (!openStatesPopper[componentKey]) setOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalIsOpen, componentKey]);

    const handleOpen = () => {
        let tmp = {};
        tmp[componentKey] = true;
        setOpenStatesPopper({ ...tmp });
        setOpen(true);
        handleStopTimer();
    };

    const handleClose = () => {
        setOpen(false);
        let tmp = { ...openStatesPopper };
        tmp[componentKey] = false;
        setOpenStatesPopper({ ...tmp });
        handleStopTimer();
    };

    const handleDelayedClose = () => {
        timerRef.current = setTimeout(() => {
            setOpen(false);
        }, 500);
    };

    const handleStopTimer = () => {
        clearTimeout(timerRef.current);
    };

    const handleClick = () => {
        handleClose();
        setOpenFicheUser(true);
    };

    const formatName = (name) => {
        name = name.trim();
        const string = name.replace(/([A-Z])/g, ' $1').toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const closeFiche = () => {
        setOpenFicheUser(false);
    }
    const ficheBenevole = () => {
        return (
            <InfosBenevole readOnly={currentUser?.User?.User_Administrator ? false : true}
                idBenevole={idBenevole}
                benevSelectedName={benevol?.Person_Name}
                benevSelectedFirstName={benevol?.Person_Firstname}
                handleClose={closeFiche} />
        )
    }

    return (
        <div>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onMouseEnter={handleOpen}
                onMouseLeave={handleDelayedClose}
            >
                {text}
            </Button>
            <Popper
                open={open}
                className={classes.popperTip}
                anchorEl={anchorRef.current}
                role={undefined}
                placement={position}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper onMouseEnter={handleStopTimer} onMouseLeave={handleClose} className={classes.paperPopper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box>
                                    <Box>
                                        <Box className={classes.title}><img alt="Profil user" src={avatarPix} className={classes.popperTipImage} /></Box>
                                        <Typography color="inherit" className={classes.title}>
                                            {`${benevol?.Person_Firstname} ${benevol?.Person_Name}`}
                                            {currentUser?.User?.User_Administrator && showEdit && <IconButton onClick={handleClick} color="primary">
                                                <AssignmentIndIcon />
                                            </IconButton>}
                                        </Typography>
                                        <Box><PhoneIcon /> : {`${benevol?.Person_Phone_Mobile?.trim() || benevol?.Person_Phone_Pers?.trim() || benevol?.Person_Phone_Pro?.trim() || ''}`}</Box>
                                        <Box><HomeIcon /> : {`${benevol?.Person_Address?.trim() || benevol?.Person_Address_2?.trim() || benevol?.Person_Address_3?.trim() || ''}`}</Box>
                                        <Box><CakeIcon /> : {`${new Date(benevol?.Person_Birth).toLocaleDateString('fr-CH')} - ${Math.floor((new Date() - new Date(benevol?.Person_Birth)) / (365.25 * 24 * 60 * 60 * 1000))} ans`}</Box>
                                        {benevol?.Person_Tshirt && <Box><PersonOutlineIcon /> : {benevol?.Person_Tshirt}</Box>}
                                        <Box><MailOutlineIcon /> : {benevol?.Person_Email}</Box>
                                    </Box>
                                    {benevol?.Person_InfosComples &&
                                        <Box>
                                            <Divider style={{ margin: '10px' }} />
                                            <Typography color="inherit" className={classes.title}> {'Info complemantaire'} </Typography>

                                            <Typography color="inherit" variant="body2"> {JSON.parse(benevol.Person_InfosComples)?.InfosComplementaires?.map(info => (
                                                <Box key={GetRandomKey(9)}>
                                                    <strong>{formatName(info.nom)}:</strong> {info.valeur}
                                                </Box>
                                            ))}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {openFicheUser && ficheBenevole()}
        </div>
    );
}

export default PopperTipsBenevol;

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleConnexion = makeStyles((theme) => createStyles({
    paperClass: {
        padding: theme.spacing(4),
        // height: "70vh"
        background:"#E5E9EC",
    },
    linkStyle: {
        cursor: "pointer",
        color:"#000"
    },
    cardTitle: {
        fontWeight: "bold",
        color: "#888",
    }
}));
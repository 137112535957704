import React, { useContext, useEffect } from 'react';
import AppContext from '../appContext';
import clsx from 'clsx';
import { AppBar, Button, Divider, IconButton, Hidden, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getManifParams } from '../../actions/manif';
import rest from '../../rest';
import { useLocation, useHistory } from "react-router-dom";
import { useStyleMenu } from './menu.css';
import { CustomTheme1 } from "../css/CustomTheme1.css";
import { CustomTheme2 } from "../css/CustomTheme2.css";
import { CustomTheme3 } from "../css/CustomTheme3.css";
import { CustomTheme4 } from "../css/CustomTheme4.css";
import { CustomTheme5 } from "../css/CustomTheme5.css";


const AppbarMenu = (props) => {
    const history = useHistory();
    let classesSelected = null
    const location = useLocation()

    const theme1 = CustomTheme1();
    const theme2 = CustomTheme2();
    const theme3 = CustomTheme3();
    const theme4 = CustomTheme4();
    const theme5 = CustomTheme5();
    switch (props.manif.manifParams.Theme) {
        case "theme1":
            classesSelected = theme1
            break;
        case "theme2":
            classesSelected = theme2
            break;
        case "theme3":
            classesSelected = theme3
            break;
        case "theme4":
            classesSelected = theme4
            break;
        case "theme5":
            classesSelected = theme5
            break;
        default:
            break;
    }

    const classesMenu = useStyleMenu();
    const myContext = useContext(AppContext);
    const openSidebar = myContext.openSidebar;
    const handleDrawer = myContext.handleDrawer;
    const manifInfos = props.manif.manifInfos;
    const manifParams = props.manif.manifParams;
    const userConnected = props.user.User;
    const logoManif = `data:image/png;base64,${manifInfos.Logo}`;
    const [anchorEl, setAnchorEl] = React.useState(null);
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(["manifCourante"]);
    const [open, setOpen] = React.useState(false);
    const currentUser = useSelector(state => state.user);
  
  
    useEffect(() => {
        setCookie('manifCourante', manifInfos.Id, { path: '/' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manifInfos.Id, manifParams.Theme])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = () => {
        setOpen(!open);
    };
    const handleChangeManif = (manifSelected) => {
        setCookie('manifCourante', manifSelected, { path: '/' });
        var paramsData = {
            "vIdManif": manifSelected,
        }
        rest.post('/manifestation/redefinir.json', encodeURIComponent(JSON.stringify(paramsData))).then(function (response) {
            handleToggle();
            window.location.reload(false);
        })
    }

    const handleIndex = () => {
        if (currentUser.pageIndex!=="/planning.html") {
            history.push(currentUser.pageIndex ? currentUser.pageIndex : "/index.html")
        } else {
            window.location.href = currentUser.pageIndex
        } 
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classesMenu.appBar, classesSelected.bandeauMenu, {
                [classesMenu.appBarShift]: openSidebar,
            })}
        >
            {manifInfos.ModeTest && <Toolbar className={classesSelected.modeTestAppBar}>
                <Typography>Le mode test est activé, les emails seront envoyés uniquement à l'adresse email suivante: <strong>{manifInfos.ModeTestEmail}</strong>.</Typography>
            </Toolbar>}
            <Toolbar>
                {location.pathname!=="/connexion.html" && <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawer}
                    edge="start"
                    className={clsx(classesSelected.toggleMenu, openSidebar)}
                >
                    <MenuIcon fontSize="medium" />
                </IconButton>}
                <Hidden xsDown smDown>
                    <Divider orientation="vertical" flexItem className={classesSelected.dividerMenu} />
                    <Typography variant="h6" className={classesSelected.titleMenu} onClick={handleIndex}>BENEVOLES</Typography>
                </Hidden>
                <Divider orientation="vertical" flexItem className={classesSelected.dividerMenu} />
                <div style={{ flexGrow: 1 }} />
                {(manifInfos.AffichageLogoTitre === "logo" || manifInfos.AffichageLogoTitre === "logoTitre") &&
                    <div className={classesSelected.divLogoMenu}>
                        <img alt={`Logo ${manifInfos.Nom}`} src={logoManif} className={classesSelected.logoMenu} />
                    </div>
                }
                {(manifInfos.AffichageLogoTitre === "titre" || manifInfos.AffichageLogoTitre === "logoTitre") &&
                    <Typography variant="subtitle1" className={classesSelected.manifMenu}><b>{manifInfos.Nom}</b></Typography>
                }
                {manifInfos.NbManif > 1 &&
                    <>
                        <Button aria-controls="chooseManif" aria-haspopup="true" onClick={handleClick}>
                            <ExpandMore />
                        </Button>
                        <Menu
                            id="chooseManif"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            classes={{
                                paper: classesSelected.choixManif
                            }}
                        >
                            {userConnected.User_Administrator &&
                                <span>
                                    {manifInfos.tManifId.map((manifId, key) => (
                                        <MenuItem key={manifId} className={classesSelected.itemChoixManif} onClick={_ => handleChangeManif(manifId)}>
                                            <img alt={`Logo ${manifInfos.tManifNom[key]}`} src={`/parametres/images/logoManif?vManif=${manifId}`} className={classesSelected.logoChoixManif} />
                                            <Typography variant="h6" align="right" className={classesSelected.manifChoixManif}><b>{manifInfos.tManifNom[key]}</b></Typography>
                                        </MenuItem>
                                    ))}
                                </span>
                            }
                            {!userConnected.User_Administrator &&
                                <span>
                                    {manifInfos.tManifId.map((manifId, key) => (
                                        manifInfos.tManifStatut[key] !== 0 &&
                                        <MenuItem key={manifId} className={classesSelected.itemChoixManif} onClick={_ => handleChangeManif(manifId)}>
                                            <img alt={`Logo ${manifInfos.tManifNom[key]}`} src={`/parametres/images/logoManif?vManif=${manifId}`} className={classesSelected.logoChoixManif} />
                                            <Typography variant="h6" align="right" className={classesSelected.manifChoixManif}><b>{manifInfos.tManifNom[key]}</b></Typography>
                                        </MenuItem>
                                    ))}
                                </span>
                            }
                        </Menu>
                    </>
                }
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state) => {
    return {
        manif: state.manif,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getManifParams }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AppbarMenu)

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleSelect = makeStyles((theme) => createStyles({
      // Select Groupe   
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
      //Select Activities
      root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(3),
        },
      },
}));
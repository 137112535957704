import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    alignTextLeft: {
        textAlign: "left",
    },
    alignTextCenter: {
        textAlign: "center",
    },
    alignTextRight: {
        textAlign: "right",
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        zIndex:10000
    },
    toolBarClass: {
        textAlign: "center",
        minHeight: "0px",
        padding: "6px",
    }
});

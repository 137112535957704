import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Button, Select, FormControl, InputLabel, ButtonGroup, ListItemText} from '@material-ui/core';
import { Tooltip, Fab } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from '@material-ui/icons/FilterList';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import { useStyleLesBenevoles } from "./Lesbenevoles.css";
import FormulaireInscription from '../../components/formulaire/FormulaireInscription';
import DataTable from '../../components/dataTable/DataTable';
import rest from '../../rest';
import DialogAttribut from '../../components/DialogAttribut/DialogAttribut';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import { useSelector } from 'react-redux';

const currentYear = new Date().getFullYear();

// Debut fonction 
export default function LesBenevoles() {
  const classesLesBenevoles = useStyleLesBenevoles();  
  const [showForm, setShowForm] = useState(false);
  const [filterShow, setFilterShow] = useState(true);
  const [valeurFiltre, setValeurFiltre] = useState("");
  const [attribut, setAttribut] = useState([]);
  const [attributId, setAttributId] = useState([]);
  const [listAttribut, setListAttribut] = useState(null);
  const [open, setOpen] = useState(false);
  const [refListAttribut, setRefListAttribut] = useState([]);
  const [refresh , setRefresh]= useState(false);
  const [snack, setSnack] = useState({});

  const manifParams = useSelector(state => state.manif);
  // console.log("manifParams", manifParams)
  const handleClickOpen = () => {
    setOpen(true);
  };

  

  // Accreditation et fiche bénévole ( les deux boutons du haut)
  const generateAccreditations = () => {
    window.open("/export/benevoles/allAccreditations/pdf")
  }
  const generateFicheBenevoles = () => {
    window.open("/export/benevoles/fiches/pdf")
  }

  useEffect(() => {
    rest.get('/attribut/liste.json')
      .then((response) => {
        let tbl = []
        for( let index = 0; index < response.data.length ; index++){
          tbl.push(React.createRef())
        }
        setRefListAttribut(tbl)
        setListAttribut(response.data)
    
      })
  }, [refresh]);


  // Le champs optionnelle pour le tableau ( le + dans le menu)
  const customToolbar = () => {
    const newRecord = () => {
      setShowForm(true)
    }
    const newFilter = () => {
      setFilterShow(true)
    }
    const add = <Tooltip title={"Nouveau bénévole"}>
      <Fab className={classesLesBenevoles.iconButton} size="small" color="secondary" aria-label="add" onClick={newRecord}>
        <AddIcon />
      </Fab>
    </Tooltip>
    const filter = <Tooltip title={"Filter"}>
      <Fab className={classesLesBenevoles.iconButton} size="small" color="secondary" aria-label="add" onClick={newFilter}>
        <FilterListIcon />
      </Fab>
    </Tooltip>
    const selectFilter = <>
      <FormControl variant="outlined" style={{ marginBlock: "0.5vw" }}>
        <InputLabel >Filter Bénévoles</InputLabel>
        <Select
          value={valeurFiltre}
          onChange={handleChange}
          style={{ width: "15vw" }}
        >
          <MenuItem value={"Tous"} >Tous les bénévoles</MenuItem>
          <MenuItem value={"Edition"}>Bénévoles {currentYear}</MenuItem>
          <MenuItem value={"Personne"}>Bénévoles {currentYear} individuels</MenuItem>
          <MenuItem value={"Groupe"}>Bénévoles {currentYear} groupes</MenuItem>
          <MenuItem value={"Edition-1"}>Bénévoles {currentYear-1}</MenuItem>
          <MenuItem value={"Diff_N-1"}>Pas encore inscrit à cette édition</MenuItem>
          <MenuItem value={"Responsable"}>Responsable</MenuItem>
          <MenuItem value={"SeanceInfo"}>Participation Seance Info</MenuItem>
          <MenuItem value={"admin"}>Administrateurs</MenuItem>
          <MenuItem value={"benAvecCptUser"}>Bénévoles avec compte utilisateur </MenuItem>
          <MenuItem value={"benSansCptUser"}>Bénévoles sans compte utilisateur</MenuItem>
          <MenuItem value={"benAcceptNewsletter"}>Accepte Newsletter</MenuItem>
        </Select>
      </FormControl>
      {/* Select attribut */}
      <ButtonGroup caria-label="outlined primary button group" style={{ marginInline: "1vw", marginBlock: "0.5vw" }}>
        <Button onClick={handleClickOpen}>
          <SettingsRoundedIcon />
        </Button>
        <FormControl variant='outlined'>
          <InputLabel >Attributs</InputLabel>
          <Select
            id="attributs"
            name="attributs"
            multiple
            label="Attribut"
            value={attribut}
            style={{ width: "15vw" }}
            onChange={handleAttrChange}
            renderValue={selected => {
              return selected.map(item => item).join(",");
            }}
          >
            {listAttribut && listAttribut.map((attr, i) => (
              <MenuItem value={attr.name} key={attr.id}>
                <ListItemText primary={attr.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ButtonGroup>
    </>

    return (
      <>
        <MuiDatatableToolBar extraBtn={filterShow ? [selectFilter, add] : [filter, add]} hideFilter={true} />
      </>
    );
  }

  const handleChange = (event) => {
    if (event.target.value === "Edition") {
      setValeurFiltre("Edition")
    }
    else if (event.target.value === "Personne") {
      setValeurFiltre("Personne")
    }
    else if (event.target.value === "Groupe") {
      setValeurFiltre("Groupe")
    }
    else if (event.target.value === "Edition-1") {
      setValeurFiltre("Edition-1")
    }
    else if (event.target.value === "Diff_N-1") {
      setValeurFiltre("Diff_N-1")
    }
    else if (event.target.value === "Responsable") {
      setValeurFiltre("Responsable")
    }
    else if (event.target.value === "SeanceInfo") {
      setValeurFiltre("SeanceInfo")
    }
    else if (event.target.value === "admin") {
      setValeurFiltre("admin")
    }
    else if (event.target.value === "benAvecCptUser") {
      setValeurFiltre("benAvecCptUser")
    }
    else if (event.target.value === "benSansCptUser") {
      setValeurFiltre("benSansCptUser")
    }
    else if (event.target.value === "benAcceptNewsletter") {
      setValeurFiltre("benAcceptNewsletter")
    }
    else {
      setValeurFiltre("Tous")
    }
  }
  const handleAttrChange = (event) => {
    let name = event.target.value
    setAttribut(name)
    let tableau = []
    listAttribut.forEach((ele) => {
      name.forEach((eve) => {
        if (eve === ele.name) {
          tableau.push(ele.id)
        }
      })
      setAttributId(tableau)
    })
  }
  
  return (
    <>
      {/*  Ajout de bénévoles  */}
      {!showForm ?
        <Grid container>
          <Grid item sm={12}>
            <Grid container spacing={1}>
              {manifParams.manifInfos.Ref === "MeyRun" && <Grid item>
                <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} size="medium" className={classesLesBenevoles.exportBtn} onClick={generateAccreditations}>
                  Générer toutes les accréditations
                </Button>
              </Grid>}
              <Grid item>
                <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} size="medium" className={classesLesBenevoles.exportBtn} onClick={generateFicheBenevoles}>
                  Générer toutes les fiches bénévoles
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <br />
            <DataTable customToolbar={customToolbar} valeurFiltre={valeurFiltre} attribut={attributId} nom={"Les bénévoles"} />
          </Grid>
        </Grid>
        :
        <FormulaireInscription idBenevole={0} setShowForm={setShowForm} setSnack={setSnack} />
      }
      <DialogAttribut setOpen={setOpen} open={open} listAttribut={listAttribut} refListAttribut={refListAttribut} setRefresh={setRefresh} />
      <MySnackbar params={snack} />
    </>
  );
}
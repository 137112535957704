
const getUserAgentInfo = () => {
    const userAgentData = navigator.userAgentData;
    let browserName = 'Autre';
    let browserVersion = 'N/A';
    let operatingSystem = 'Autre';
    const userAgent = navigator.userAgent;

    if (userAgentData) {
        const { brands, platform, uaFullVersion } = userAgentData;
        const brand1 = brands[0];
        const { brand, version } = brand1; // , variant

        browserName = brand;
        browserVersion = !uaFullVersion ? version : uaFullVersion;
        operatingSystem = platform;
    } else {

        if (userAgent.includes('Chrome')) {
            browserName = 'Chrome';
            browserVersion = userAgent.match(/Chrome\/([\d.]+)/)[1];
        } else if (userAgent.includes('Firefox')) {
            browserName = 'Firefox';
            browserVersion = userAgent.match(/Firefox\/([\d.]+)/)[1];
        } else if (userAgent.includes('Safari')) {
            browserName = 'Safari';
            browserVersion = userAgent.match(/Version\/([\d.]+)/)[1];
        } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
            browserName = 'Internet Explorer';
            browserVersion = userAgent.match(/(?:MSIE |Trident\/.*; rv:)([\d.]+)/)[1];
        } 

        // Extraction du système d'exploitation
        if (userAgent.includes('Windows')) operatingSystem = 'Windows';
        else if (userAgent.includes('Macintosh')) operatingSystem = 'Mac OS';
        else if (userAgent.includes('Linux')) operatingSystem = 'Linux';
        else if (userAgent.includes('Android')) operatingSystem = 'Android';
        else if (userAgent.includes('iOS')) operatingSystem = 'iOS';
    }

    // Obtenir la taille de l'écran
    const largeurEcran = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const hauteurEcran = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    return {
        browserName: browserName,
        userAgent: userAgent,
        browserVersion: browserVersion,
        operatingSystem: operatingSystem,
        largeurEcran: largeurEcran,
        hauteurEcran: hauteurEcran
    };
};

export default  getUserAgentInfo;
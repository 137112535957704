import React, { useEffect } from 'react'
import { useStyles } from "./FinalStep.css";
import { Alert } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';


const FinalStep = (props) => {
  const classes = useStyles();
  // const disponibilites = props.displayedDates;
  const displayDateTxt = props.displayDateTxt
  const selectedActNom = props.selectedActNom;
  const manifInfo = props.manifInfo;

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "index.html";
    }, 11000);
  }, [])

  return (
    <React.Fragment>
      <main className={classes.content}>
        <Grid container >
          <Grid item sm={5} >
            <br />
            <Alert severity="info">
              <div dangerouslySetInnerHTML={{ __html: manifInfo.TexteEtapeFinale }} />
            </Alert>
          </Grid>
          <Grid item sm={1} ></Grid>
          <Grid item sm={3} >
            <h2 className={classes.titreChoix} >Dates sélectionnées</h2>
            {displayDateTxt.map((key, value) => {
              return <div key={key.date}>
                <h2 className={classes.marginDate}>{key.date}</h2>
                <p className={classes.marginPeriode}>{key.periode}</p>
              </div>
            }
            )}
          </Grid>
          <Grid item sm={3} >
            <h2 className={classes.titreChoix}>Listes des activités choisies</h2>
            <ul>
              {selectedActNom.map((key, value) =>
                <li className={classes.actChoisies}>{key}</li>
              )}
            </ul>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    manif: state.manif,
  }
}


export default connect(mapStateToProps)(FinalStep)


import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useStylesOnglet } from '../onglets/Onglets.css';
import { useStylesGlobal } from "../../pages/GlobalStyle.css";
import rest from "../../rest";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import AppContext from '../appContext';


const PhotoProfil = ({ open, setOpen, idBenevole }) => {
    const [selectImg, setSelectImg] = useState();
    const classesGlobal = useStylesGlobal();
    const classes = useStylesOnglet();
    const [croppedImage, setCroppedImage] = useState(null);
    const cropperRef = useRef(null);
    const [refresh, setRefresh] = useState(false);
    const myContext = useContext(AppContext);
    // console.log("myContext",myContext)
    const onCrop = () => {    
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    };

    useEffect(() => {
        rest.post(`/benevols/getPicture.json`, { vIdPers: idBenevole }).then((response) => {
            if (response.data.picture !== "") {
                setSelectImg("data:image/jpeg;base64," + response.data.picture)
                myContext.setReload((prev)=> {return !prev})
            }
        })
    }, [idBenevole, refresh, myContext])

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }



    const handleChange = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setSelectImg(base64)
    }

    const handleSave = () => {
        if (croppedImage) {
            rest.post(`/benevols/savePicture.json`, { vIdPers: idBenevole, picture: encodeURIComponent(croppedImage.split("base64,")[1]) }).then((response) => {
                if (response.data.error === true) {
                    
                }
                setOpen(false)
                setRefresh(!refresh)
                myContext.setReload((prev) => !prev)
            })
        } else {
            setOpen(false)
        }      
    }

    return (
        <Dialog
            open={open}
            onClose={_ => setOpen(false)}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Choisir une nouvelle photo de profil</DialogTitle>
            <DialogContent className={classes.root}>
                <DialogContentText>
                    Nous te recommandons de choisir une photo portrait de toi afin de te reconnaitre plus facilement sur le terrain.
                    Nous te remercie de choisir une photo au format JPG.
                </DialogContentText>
            </DialogContent>
            <DialogContent className={classes.root}>
                <input
                    inputProps={{ accept: 'image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt' }}
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={e => handleChange(e)}
                />
                <DialogContentText >
                    <Cropper
                        src={selectImg}
                        crop={onCrop}
                        ref={cropperRef}
                        autoCrop={false}
                        background={false}
                        minContainerWidth={0}
                        minContainerHeight={0}

                    />
                </DialogContentText>
                <DialogActions>
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="secondary" component="span">
                            <PhotoCamera /> Choisir une photo
                        </Button>
                    </label>
                    <Button variant="contained" color="primary" onClick={handleSave} >
                        Enregistrer
                    </Button>
                    <Button variant="contained" className={classesGlobal.buttonFermer} onClick={_ => setOpen(false)} >
                        Fermer
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default PhotoProfil;
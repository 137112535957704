import { Paper, Grid, Link } from '@material-ui/core';
import { useStyleForbidenAccess } from "./ForbidenAccess.css";
import BlockIcon from '@material-ui/icons/Block';

export const NotFound = () => {
    const classesForbidenAccess = useStyleForbidenAccess();

    return (
        <main>
            <div />
            <Paper elevation={3} className={classesForbidenAccess.paperClass}>
                <Grid container align="center">
                    <Grid item xs={12}>
                        <BlockIcon className={classesForbidenAccess.customIcon} />
                        <h1>Accès refusé</h1>
                        <h2>Vous n'avez pas les accès nécessaires.</h2>
                        <Link href="/index.html" color="inherit" style={{fontSize:"1rem"}}>
                            Revenir à la page d'accueil
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </main>
    )
}

export default NotFound;
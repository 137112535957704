import React, { useState, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import LoadingIndicator from "../loadingIndicator/LoadingIndicator";
// import { retrieveSession } from '../../actions/session'
import rest from '../../rest'

const ProtectedRoute = ({ component: Component, user }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sessionChecked, setSessionChecked] = useState(false);
    const [authOk, setAuthOk] = useState(false);

    useEffect(() => {

        rest.post('/user/informations.json', { "path": history.location.pathname }).then((response) => {
            if (response.data.connected) {
                if (response.data.access) {
                    setAuthOk(true);
                } else {
                    history.push("/403")
                }
            } else {
                setAuthOk(false);
                history.push("/connexion.html")
            }
            setSessionChecked(true);
        }) 

    }, [history, dispatch, history.location.pathname]);

    return (
        sessionChecked ?
            <Route {...rest} render={
                props => {
                    if (authOk) {
                        return <Component {...rest} {...props} />
                    } else {
                        return <Redirect to={
                            {
                                pathname: '/index.html',
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }
                }
            } />
            :
            <LoadingIndicator open={!sessionChecked} />
    )



}

export default ProtectedRoute;
import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import GetRandomKey from '../general/genereRandomKey';
import axios from 'axios';
import { useModal } from '../general/modalContext';
import ModalConfirm from '../general/modals/confirmModal';
import ModalAlert from '../general/modals/alertModal';
import MultiModal from '../general/modals/multiOptModal';

const UserLine = ({ row, distinctHoursByDate, filteredData, rowKey, fetchData, data, options, selectedOption, params }) => {
  const { openModal, closeModal } = useModal();

  const toggleCroix = async (dataRow, isActive) => {
    const urlAdd = '/tableauDesAttributions/addHour.json';
    const urlRemove = '/tableauDesAttributions/removeHour.json';
    const url = isActive ? urlRemove : urlAdd;
    const modalOption = [
      { handle: () => valideTranche(dataRow, urlAdd), color: "secondary", text: "Confirmer", variant: 'outlined' },
      { handle: () => suppTranche(dataRow, urlAdd), color: "default", text: "Supprimer", variant: 'outlined' },
      { handle: closeModal, color: "primary", text: "Fermer" },
    ]


    if (selectedOption === dataRow.ID_Activite) {
      if (dataRow.temp) {
        openModal("Attention ! modification du statut", <MultiModal
          text={`Souhaitez-vous supprimer les tranches horaires non validées par le bénévole de "${options?.find((e) => e.ID === selectedOption)?.Nom}" ou les confirmer ?
              Nous vous rendons attentif au fait que cette action est irréversible\n \u200B 
              CONFIRMER = valide les tranches temporaires du bénévole et ajoute en supplément celle entrée par le responsable. 
              Cette dernière est automatiquement en statut "validé"
              SUPPRIMER = supprime l'ensemble des tranches temporaires du bénévole et ajoute uniquement celle entrée par le responsable en statut "validé."`}
          options={modalOption} />)
      } else {
        changeHour (dataRow, url);
      }
    }
    else {
      openModal("Attention !", <ModalAlert 
      text={`La ligne selectionnée n'est pas une ligne ${options?.find((e) => e.ID === selectedOption)?.Nom}`} 
      handleConfirm={closeModal} />)
    }
    return true;
  }

  const valideTranche = (dataRow, url) => {
    console.log('valide', url);
    dataRow.action = 'valide';
    changeHour(dataRow, url);
    closeModal();
  }
  const suppTranche = (dataRow, url) => {
    console.log('supprime', url);
    dataRow.action = 'supprime';
    changeHour(dataRow, url);
    closeModal();
  }
  const changeHour = async (dataRow, url) => {
    try {
      const response = await axios.post(url, dataRow);
      if (response.data.status === 'success') {
        fetchData();
      } else {
        openModal("Attention !", <ModalAlert text={response.data.message} handleConfirm={closeModal} />)
      }
    } catch (error) {
      // Gérer les erreurs de requête
      console.error(error);
    }
  }

  const toggleCross = async (e, row, day, [alias, heureDebut, heureFin], isActive) => {
    // const [heureDebutString, heureFinString] = hour.split(" - ");
    // const heureDebut = new Date(`1970-01-01T${heureDebutString}:00`).getTime() / 1000;
    // const heureFin = new Date(`1970-01-01T${heureFinString}:00`).getTime() / 1000;
    if (selectedOption !== '' && selectedOption !== 0) {

      e.stopPropagation();
      const dataRow = {
        vID: row.ID,
        date: day,
        heureDebut: heureDebut,
        heureFin: heureFin,
        Alias: alias,
        temp: row.temp,
        // ID_Activite: selectedOption === 0 ? row.ID_Activite : selectedOption,
        ID_Activite: row.ID_Activite,
      };
      let isNotlastLine = true;
      if (isActive) { // if remove hour we test if is last hour  
        const allUserRow = Object.values(data).filter((e) => e[0].ID === row.ID && e[0].ID_Activite === row.ID_Activite);
        if (allUserRow[0].length < 2) {
          isNotlastLine = false;
        }

        if (!isNotlastLine) { // suppretion de la dernière heure 
          openModal("Confirmation", <ModalConfirm
            text={`C'est la seule heure de ce bénévole si vous la supprimez le bénévole disparaîtra de "${options?.find((e) => e.ID === selectedOption)?.Nom}" ?`}
            handleConfirm={() => toggleCroix(dataRow, isActive)} handleAnnul={closeModal} />
          )
        }
      }
      if (isNotlastLine) toggleCroix(dataRow, isActive)
    } else {
      openModal("Attention !", <ModalAlert text={`Pour tout changement, vous devez, en premier lieu, faire une sélection dans la liste déroulante "${params?.Infos?.DenominationSousAct}"`} handleConfirm={closeModal} />)
    }

  };

  const UserInrtern = ({ day, i, row }) => {
    return (
      <React.Fragment key={GetRandomKey(7)}>
        {distinctHoursByDate[day].map(([alias, heureDebut, heureFin], j) => {
          const hasMatchingHour = filteredData[rowKey].some((item) => (
            item.Date === day && item.Alias === alias
          ));
          const content = hasMatchingHour ? '✗' : '';
          const color = row.temp ? ' colorTemp' : '';
          const altern = i % 2 === 0 ? '' : ' impaire';
          return (
            <TableCell key={alias+heureDebut} className={`tdHours${altern}${color}`} onDoubleClick={(e) => toggleCross(e, row, day, [alias, heureDebut, heureFin], hasMatchingHour)}>
              {content}
            </TableCell>
          );
        })}
      </React.Fragment>)
  }

  return (
    <TableRow>
      <TableCell>{row.Name}</TableCell>
      <TableCell>{row.Firstname}</TableCell>
      <TableCell>{row.Name_Activite}</TableCell>
      {
        Object.keys(distinctHoursByDate).map((day, i) => (
          <UserInrtern day={day} i={i} key={GetRandomKey(10)} row={row} />
        ))
      }
    </TableRow>
  );
}
export default UserLine;
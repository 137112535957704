import React, { useContext, useState, useEffect } from 'react'
import InscriptionsLibres from '../../components/inscriptionWish/InscriptionsLibres/InscriptionsLibres';
import InscriptionsLibres2 from '../../components/inscriptionWish/InscriptionsLibres/InscriptionsLibres2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActsDatesDispo } from '../../actions/activites';
import { CircularProgress, Grid } from '@material-ui/core';
import rest from '../../rest';
import { useStyleInscriptionWish } from "./InscriptionWish.css";
import AppContext from '../../components/appContext';
const InscriptionWish = (props) => {
    const myContext = useContext(AppContext);
    const classesInscriptionWish = useStyleInscriptionWish();
    const [isLoaded, setIsLoaded] = useState(false);
    const [manifInfo, setManifInfo] = useState({});
    const [paramsGeneral, setParamsGeneral] = useState({});
    // const [lienLogo, setLienlLogo] = useState("");
    useEffect(() => {
        rest.get('/parametresV2/getGeneral.json').then(function (response) {
            setParamsGeneral(response.data.Parametres);
            console.log(response.data.Parametres);
        })
    }, [])
    useEffect(() => {
        function load() {
            try {
                // props.getActsDatesDispo();
                // setTimeout(() => {
                //     setIsLoaded(true)
                // }, 1000);
                setIsLoaded(true)
            } catch (e) {
                setIsLoaded(false)
            }
        }
        load();
    }, [isLoaded])

    useEffect(() => {
        myContext.setOpenSidebar(false)
        rest.get('/parametres/infoWishlit.json').then((response) => {
            setManifInfo(response.data);
            // setLienlLogo(`/parametres/images/logoManif?vManif==${response.data.Id}`)
        })
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className={['App', classesInscriptionWish.bodyContainer]} >
            <Grid container>
                {isLoaded ?
                    <Grid item xs={12}>
                        {paramsGeneral.InscripionWishlistType === 'wishlistByActivity' ? (
                            <InscriptionsLibres2 manifInfo={manifInfo} />
                        ) : (
                            <InscriptionsLibres manifInfo={manifInfo} />
                        )}
                    </Grid>
                    :
                    <React.Fragment>
                        <CircularProgress color="secondary" />
                    </React.Fragment>
                }
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        activites: state.activites,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getActsDatesDispo }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(InscriptionWish)




import React from 'react';

const OngletGestionRepas = () => {
    return (
        <div>
            onglet 4
        </div>
    );
};

export default OngletGestionRepas;
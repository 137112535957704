import React, { useState, useEffect } from 'react'
import { useStyles } from "./ChoixActivites.css";
import { Alert } from '@material-ui/lab';
import { Button, Checkbox, FormControlLabel, Grid, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { postActiviteWhislist } from '../../../actions/activites'
import rest from '../../../rest';

const Notes = (props) => {
  const classes = useStyles();
  const [showWarning, setShowWarning] = useState(false);
  const manifInfo = props.manifInfo;
  const disponibilites = props.displayedDates;
  const displayDateTxt = props.displayDateTxt
  // const vActDispo = props.activites.activitesAvailables;
  const [actDispo, setActDispo] = useState([]);
  const selectedActId = props.selectedActId;
  const setSelectedActId = props.setSelectedActId;
  const selectedActNom = props.selectedActNom;
  const setSelectedActNom = props.setSelectedActNom;
  const notesTH = props.notesTH;
  const notesGeneral = props.notesGeneral;
  const [nbGrid, setNbGrid] = useState(5);

  useEffect(() => {
    var paramsData = {
      'vDispo': JSON.stringify(props.displayedDates),
    }
    rest.post('/activites/activitesDisponibles.json', paramsData)
      .then((response) => {
        setActDispo(response.data)
      })
  }, [props.displayedDates])

  useEffect(() => {
    setNbGrid(12 / (actDispo.length))
  }, [actDispo])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  const showPrevious = () => {
    props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const showNext = () => {
    let acts = [];
    selectedActId.forEach((item, index) => {
      var obj = {}
      obj.id = item
      obj.nom = selectedActNom[index]
      obj.treated = false
      acts.push(obj)
    });

    let error = false;

    if (manifInfo.NbActMinWishlist > 0) {
      if (selectedActId.length < manifInfo.NbActMinWishlist) {
        error = true
      }
    }

    if (manifInfo.NbActMaxWishlist > 0) {
      if (selectedActId.length > manifInfo.NbActMaxWishlist) {
        error = true
      }
    }





    if (error) {
      scrollToTop();
      setShowWarning(true)
    } else {
      var paramsData = {
        'vDispo': JSON.stringify(disponibilites),
        'vRemarquesTH': notesTH,
        'vRemarqueGenerale': notesGeneral,
        'vActivitesChoisies': JSON.stringify(acts),
      }
      props.postActiviteWhislist(paramsData);
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  }

  const handleCheck = (event) => {
    var valueText = event.target.value;
    var valueId = event.target.id;
    var valueChecked = event.target.checked;

    var arraySelectedId = [...selectedActId];
    var arraySelectedNom = [...selectedActNom];

    if (valueChecked) {
      arraySelectedId.push(valueId)
      arraySelectedNom.push(valueText)
    } else {
      var indexId = arraySelectedId.indexOf(valueId)
      var indexText = arraySelectedNom.indexOf(valueText)
      arraySelectedId.splice(indexId, 1);
      arraySelectedNom.splice(indexText, 1);
    }
    setSelectedActId(arraySelectedId)
    setSelectedActNom(arraySelectedNom)
  }

  return (
    <React.Fragment>
      <main className={classes.content}>
        {manifInfo.TexteChoixActivites !== "" &&
          <Grid container className={classes.marginGrid}>
            <Grid item xs={12} >
              <Alert severity="warning">
                <div dangerouslySetInnerHTML={{ __html: manifInfo.TexteChoixActivites }} />
              </Alert>
            </Grid>
          </Grid>
        }
        {showWarning &&
          <Grid container className={classes.marginGrid}>
            <Grid item xs={12} >
              {
                manifInfo.NbActMinWishlist === manifInfo.NbActMaxWishlist ?
                  <Alert severity="error">
                    {
                      manifInfo.Ref === "chi" ?
                      "Vous devez selectionner " + manifInfo.NbActMaxWishlist +" missions pour enregistrer votre sélection."
                    :
                      "Tu dois selectionner " + manifInfo.NbActMaxWishlist + " missions pour enregistrer ta sélection."
                    }
                  </Alert>
                  :
                  <Alert severity="error">
                    {
                      manifInfo.Ref === "chi" ?
                        "Vous devez selectionner entre " + manifInfo.NbActMinWishlist + "et " + manifInfo.NbActMaxWishlist +" missions pour enregistrer votre sélection."
                        :
                        "Tu dois selectionner entre " + manifInfo.NbActMinWishlist + "et " + manifInfo.NbActMaxWishlist +" missions pour enregistrer ta sélection."
                    }
                  
                  </Alert>
              }
            </Grid>
          </Grid>
        }
        <Grid container >
          <Grid item sm={8}>
            <Grid container>
              {actDispo.map((key, value) => (
                < >
                  {key.SousActivieDispo.length > 0 &&
                    <Grid item sm={nbGrid} >
                      <h2 className={classes.titleActivite}>{key.Activite}</h2>
                      <Table aria-label="simple table" style={{ width: "95%" }} className={classes.tableActivite}>
                        <TableBody>
                          {key.SousActivieDispo.map((ke, va) => (
                            <TableRow key={`act_${ke.ID.toString()}`}>
                              <TableCell align="left" width="10%">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="default"
                                      id={ke.ID.toString()}
                                      onChange={handleCheck}
                                      checked={selectedActId.indexOf((ke.ID).toString()) > -1}
                                      value={ke.Nom}
                                    />
                                  }
                                  label={ke.Nom}
                                /><br />
                                <small className={classes.desc}>
                                  <div dangerouslySetInnerHTML={{ __html: ke.Description.replaceAll("\n", "<br/>") }} />
                                </small>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  }
                </>
              ))}
            </Grid>
            <br /><br />

          </Grid>
          <Grid item sm={4} >
            <Grid container>
              <Grid item sm={6} >
                <h2 className={classes.titreChoix} >Dates sélectionnées</h2>
                {displayDateTxt.map((key, value) => {
                  return <div key={key.date}>
                    <h2 className={classes.marginDate}>{key.date}</h2>
                    <p className={classes.marginPeriode}>{key.periode}</p>
                  </div>
                }
                )}
              </Grid>
              <Grid item sm={6}>
                <h2 className={classes.titreChoix}>Activités choisies</h2>
                <ul>
                  {selectedActNom.map((key, value) =>
                    <li className={classes.actChoisies}>{key}</li>
                  )}
                </ul>
              </Grid>
            </Grid>
            <br /><br />
            <Grid container>
              <Grid item sm={2}>
                <Button size="small" fullWidth variant="contained" color="default" onClick={showPrevious}>
                  Retour
                </Button>
              </Grid>
              <Grid item sm={1}></Grid>
              <Grid item sm={9}>
                <Button size="small" fullWidth variant="contained" color="secondary" onClick={showNext}>
                  Enregistrer mes choix
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    activites: state.activites,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ postActiviteWhislist }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(Notes)


import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleMesBenevoles = makeStyles((theme) => createStyles({
    formControl: {
        marginBottom: theme.spacing(3),
        width: "32.5%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


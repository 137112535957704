import { Popover, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Grid, Button, Accordion, AccordionSummary, Typography, AccordionDetails, ListItemText, AccordionActions, Input } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStylesGlobal } from "../../pages/GlobalStyle.css";
import rest from '../../rest';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'

const DialogAttribut = ({ setOpen, open, listAttribut, refListAttribut, setRefresh }) => {
    const classesGlobal = useStylesGlobal();
    const [showModif, setShowModif] = useState(false);
    const [ajoutAttri, setAjoutAttri] = useState();
    const [ajoutAttriFonction, setAjoutAttriFonction] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const openPopover = Boolean(anchorEl);
    const [expanded, setExpanded] = React.useState(false);
    const [listFonction, setListFonction] = useState([]);
    const [fonction, setFonction] = useState(-1);
    const [idAttribut, setIdAttribut] = useState();
    const [snack, setSnack] = useState({});
    const handleChange = (panel, functionAttribut) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setFonction(functionAttribut)
    };
    const handleClose = () => {
        setExpanded(false)
        setOpen(false);
        setAnchorEl(null);
    };
    const handleRedirection = () => {
        setShowModif(false)
    };

    // fonction en lien avec les attribut 
    useEffect(() => {

        rest.get("/attribut/listeFonction.json")
            .then((response) => {
                setListFonction(response.data)
                // setRefresh(prev => !prev)
            })

    }, [])

    const handleFonctionChange = (event) => {
        setFonction(event.target.value)
    }

    const handleAttriFonctionChange = (event) => {
        setAjoutAttriFonction(event.target.value)
    }

    // Lors d'un ajout d'attribut
    const pageAjout = (event) => {
        setAjoutAttri("")
        setAjoutAttriFonction("")
        setShowModif(true)
    }

    const handleChangeAjout = (event) => {
        setAjoutAttri(event.target.value)
    }

    const ajoutAttribut = (event) => {
        var params = {
            vAction: "Ajouter",
            vNomAttribut: ajoutAttri,
            vFonctionAttribut: ajoutAttriFonction
        }
        rest.post("/4DACTION/F_Controller_Attribut", params)
            .then((response) => {
                if (response.data.Error.Error !== "") {
                    switch (response.data.Error.Error) {
                        case "vNomAttributDejaExistant":
                            setSnack({ message: "Ce nom d'attribut est déjà utilisé.", show: true, severity: "error" })
                            break;
                        case "vNomAttribut":
                            setSnack({ message: "Le nom de l'attribut ne peut être vide", show: true, severity: "error" })
                            break;
                        default:
                            break;
                    }
                } else {
                    setRefresh((prev) => !prev)
                    setSnack({ message: "Attribut ajouté avec succès.", show: true, severity: "success" })
                    setShowModif(false)
                }             
            })

    }

    // Modification de la valeur de l'attribut
    const handleChangeName = (IdAttribut, nomAttribut) => {
        var params = {
            vAction: "Modifier",
            vIdAttribut: IdAttribut,
            vNomAttribut: nomAttribut.current.value,
            vFonctionAttribut: fonction
        }
        rest.post("/4DACTION/F_Controller_Attribut", params)
            .then((response) => {
                if (response.data.Error.Error !== "") {
                    switch (response.data.Error.Error) {
                        case "vNomAttributDejaExistant":
                            setSnack({ message: "Ce nom d'attribut est déjà utilisé.", show: true, severity: "error" })
                            break;
                        case "vNomAttribut":
                            setSnack({ message: "Le nom de l'attribut ne peut être vide", show: true, severity: "error" })
                            break;
                        default:
                            break;
                    } 
                } else {
                    setSnack({ message: "Modification effectuée avec succès.", show: true, severity: "success" })
                    setRefresh((prev) => { return !prev })  
                }
               
            })

    }

    // Supprimer un attribut qui n'est jamais utilisé 
    const supprimerAttribut = () => {
        var params = {
            vAction: "Supprimer",
            vIdAttribut: idAttribut,
        }
        rest.post("/4DACTION/F_Controller_Attribut", params)
            .then((response) => {
                    setSnack({ message: "Attribut supprimé avec succès.", show: true, severity: "success" })
                    setRefresh((prev) => !prev) 
            })
    }
    function handleDelete(event, id) {
        setIdAttribut(id)
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            {/* Dialog pour ajout / modification d'attribut */}
            <MySnackbar params={snack} />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Attribut des personnes</DialogTitle>
                {!showModif ?
                    <>
                        <DialogContent>
                            <DialogContentText>
                                Liste des attributs déjà existants
                            </DialogContentText>
                            Pour supprimer un attribut, il ne doit être relié a aucune personne.<br /><br />
                            {listAttribut && listAttribut.map((attr, i) => (
                         

                                        
                                        <Accordion expanded={expanded === attr.id} onChange={handleChange(attr.id, attr.functionAttribut)}>
                                            <AccordionSummary
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                    >
                                                <Grid container spacing={1}>
                                                        <Grid xs={6} >
                                                            <Typography >{attr.name}</Typography>
                                                        </Grid>
                                                        <Grid xs={5}>
                                                            <Typography >{attr.functionAttributName}</Typography>
                                                        </Grid>
                                                        <Grid xs={1} style={{ textAlign: "right" }}>
                                                            <Typography ><CreateIcon /></Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                        <Grid container spacing={1}>
                                                    {/* Attribut modifiable */}

                                                    <Grid xs={12} md={5} >
                                                        <TextField
                                                            key={attr.id}
                                                            defaultValue={attr.name}
                                                            inputRef={refListAttribut[i]}
                                                            fullWidth
                                                    style={{ paddingTop: fonction !== -1 && "12px" }}
                                                            label="Attribut"
                                                        />
                                                    </Grid>
                                            {/* Fonction modifiable */}
                                            <Grid md={1}> </Grid>
                                                    <Grid xs={12} md={5}>
                                                            <TextField
                                                                id={"fonction"+i}
                                                                value={fonction}
                                                                onChange={handleFonctionChange}
                                                                input={<Input />}
                                                                select
                                                                label="Fonction"
                                                                fullWidth
                                                            >
                                                    <MenuItem value={-1} style={{ paddingBottom: "1.5vw" }}>
                                                                    <ListItemText />
                                                                </MenuItem>
                                                                {listFonction && listFonction.map((fonction, i) => (
                                                                    <MenuItem value={fonction.id} key={fonction.id} >
                                                                        <ListItemText primary={fonction.text} />
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                    </Grid>
                                            </Grid>
                                    </AccordionDetails>
                                    <AccordionActions >
                                        {attr.nbPers !== "Plus de 0" &&
                                            <>
                                                <span>
                                                    <Button onClick={(e) => { handleDelete(e, attr.id) }}><DeleteIcon color="error" /></Button>
                                                    <Popover
                                                        open={openPopover}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        className="popover_class"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <Button onClick={_ => supprimerAttribut()} className={classesGlobal.buttonFermer} >Confirmer désinscription</Button>
                                                    </Popover>
                                                </span>
                                            </>}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={_ => handleChangeName(attr.id, refListAttribut[i])}>
                                            Modifier
                                        </Button>
                                    </AccordionActions>
                                        </Accordion>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={pageAjout}>
                                Ajouter un attribut
                            </Button>
                            <Button variant="contained" className={classesGlobal.buttonFermer} onClick={handleClose}>
                                Fermer
                            </Button>
                        </DialogActions>
                    </>
                    :
                    <>
                        {/* Lors d'un ajout d'attribut */}
                        <TextField
                            value={ajoutAttri}
                            style={{ width: "20vw", marginInline: "5vw", marginBlock: "2vw" }}
                            label={"Nom attribut"}
                            onChange={handleChangeAjout} />
                        <br />
                        <TextField
                            id={"fonctioncreateAttr"}
                            value={ajoutAttriFonction}
                            onChange={handleAttriFonctionChange}
                            input={<Input />}
                            select
                            label="Fonction attribut"
                            style={{ width: "20vw", marginInline: "5vw", marginBlock: "2vw" }}
                        >
                            <MenuItem value={-1} style={{ paddingBottom: "1.5vw" }}>
                                <ListItemText />
                            </MenuItem>
                            {listFonction && listFonction.map((fonction, i) => (
                                <MenuItem value={fonction.id} key={fonction.id} >
                                    <ListItemText primary={fonction.text} />
                                </MenuItem>
                            ))}
                        </TextField>
                        <DialogActions>
                            <Button variant="contained" color="primary" onClick={ajoutAttribut}>
                                Enregistrer
                            </Button>
                            <Button onClick={handleRedirection} className={classesGlobal.buttonFermer}>
                                Annuler
                            </Button>
                        </DialogActions>
                    </>}
            </Dialog>
        </>
    );
};

export default DialogAttribut;
import rest from '../../rest';

const resendMail = async (forgotEmail) => {
    const paramsData = new FormData();
        paramsData.append("vRegisterEmail", forgotEmail);
        paramsData.append("reSendMail", true);
        try {
            const data = await rest.post('/connection/registration.json', paramsData);
            return data;
        } catch(e) {
            console.log(e);
            return false;
        }
}

export default resendMail;

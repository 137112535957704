import React, { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();

export const useGlobalContext = () => {
    return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
    const [openStatesPopper, setOpenStatesPopper] = useState({});

    const closeAllElements = () => {
        setOpenStatesPopper({});
    };

    return (
        <GlobalContext.Provider value={{openStatesPopper, closeAllElements, setOpenStatesPopper }}>
            {children}
        </GlobalContext.Provider>
    );
};

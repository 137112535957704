import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import rest from '../../rest';
import moment from 'moment';
import TextTableau from './texteTableau.json'
import { Button } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import { MySnackbar } from "../mySnackbar/MySnackbar";

const ExpandableRowTable = ({ from, to }) => {
    const [dataEmail, setDataEmail] = useState([])
    const [reload, setReload] = useState(false)
    const [snack, setSnack] = useState({});

    useEffect(() => {
        var paramsData = {
            'vStartDate': moment(from).format("DD.MM.YYYY"),
            'vEndDate': moment(to).format("DD.MM.YYYY")
        }
        rest.post('/email/listeComplete', encodeURIComponent(JSON.stringify(paramsData))).then(function (response) {
            setDataEmail(response.data)
        })
    }, [from, to,reload])

    function sendAgain(emailID) {
        rest.post('/email/sendAgain', { "emailID": emailID }).then(function (response) {
            if (response.data.error) {
                setSnack({ message: response.data.message, show: true, severity: "error" })
            } else {
                setSnack({ message: response.data.message, show: true, severity: "success" })
                setReload(!reload)
            }
        })
    }


    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Date Envoie",
            name: "DateEnvoie",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    var dateFormatee = "";
                    dateFormatee = moment.unix(value).subtract(2, 'hour').format("DD.MM.YYYY HH:mm") //parse string moment(new Date(value)).format('DD.MM.YYYY');
                    return dateFormatee
                },
            }
        },
        {
            label: "Expediteur",
            name: "Expediteur",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Sujet",
            name: "Subject",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Destinataire",
            name: "To",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Statut",
            name: "Error",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Action",
            name: "ID",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Button size="small" variant="contained" title="renvoyer" color="primary" onClick={_=>sendAgain(value)}><SendIcon /></Button>
                },
            }
        },
        {
            label: "Body",
            name: "Body",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
    ];

    const options = {
        filter: false,
        search: true,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
        //responsive: 'scrollMaxHeight',
        expandableRows: true,
        textLabels: TextTableau,
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <React.Fragment>
                    <tr style={{ backgroundColor: '#F0F0F0' }}>
                        <td></td>
                        <td colSpan={6} style={{ textAlign: "left" }}>
                            <p dangerouslySetInnerHTML={{
                                __html: rowData[7]
                            }}></p>
                        </td>
                    </tr>
                </React.Fragment>
            );
        },

        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
            allExpanded.splice(0, 1);
        }
    };

    return (
        <>
            <MySnackbar params={snack} />
            <MUIDataTable
                data={dataEmail}
                columns={columns}
                options={options}
            />
        </>
    );
};

export default ExpandableRowTable;

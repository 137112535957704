import React, { useState, } from 'react';
import { CircularProgress, Grid, } from '@material-ui/core';
import SelectGroupe from '../select/SelectGroupe'
import { MySnackbar } from '../mySnackbar/MySnackbar';


const OngletGroupe = ({readOnly,  idBenevole, setRefreshInfosTab }) => {
    const [isLoaded] = useState(false)
    const [snack] = useState({ message: "" ,show: false, severity: "error"});

    return (
        isLoaded ?
            <CircularProgress color="secondary" />
            :
            <div>
                <Grid container >
                    <Grid item xs={4} align="left">
                    </Grid>
                    <Grid item xs={8} align="left">
                        <MySnackbar params={snack} />
                    </Grid>
                    <Grid item xs={8} align="left">
                        <SelectGroupe readOnly={readOnly} idBenevole={idBenevole} setRefreshInfosTab={setRefreshInfosTab}></SelectGroupe>
                    </Grid>
                </Grid>
                <br />
            </div>
    )
}


export default OngletGroupe


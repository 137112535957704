import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import GetRandomKey from '../general/genereRandomKey';

const TotalLine = ({distinctHoursByDate, totalVisibleParHeure, text}) => {

   return ( 
   <TableRow className='tdTotal tdTotalJour'>
        <TableCell colSpan={3}>{text}</TableCell>
        {distinctHoursByDate && Object.keys(distinctHoursByDate).map((day, i) => (
            <React.Fragment key={GetRandomKey(8)}>
                {distinctHoursByDate[day].map(([alias], j) => {
                    const altern = i % 2 === 0 ? '' : ' impaire';
                    const total = totalVisibleParHeure[day] && totalVisibleParHeure[day][alias] ? totalVisibleParHeure[day][alias] : '';

                    return (
                        <TableCell key={GetRandomKey(8)} className={`tdHours${altern}`}>
                            {total}
                        </TableCell>
                    );
                })}
            </React.Fragment>
        ))}

    </TableRow>
    )
}

export default TotalLine;
/* eslint-disable no-use-before-define */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const AutoComplete = withStyles((theme) => ({
  root: {
    maxHeight: '1.5rem'
  }
}))(Autocomplete);

export default function DatesAutocomplete(props) {
  const etOuSelect = props.etOuSelect;
  const setEtOuSelect = props.setEtOuSelect;
  const optionsArray = [
    { valeur: 'et', texte: 'Et' },
    { valeur: 'ou', texte: 'Ou' },
  ]

  const handleChange = (event, value) => {
    setEtOuSelect(value)
  }

  return (
    <>
      <label>Et / Ou</label>
      <AutoComplete
        size="small"
        id="etOuAutocomplete"
        disableClearable
        options={optionsArray}
        getOptionLabel={(option) => option.texte}
        defaultValue={etOuSelect[0]}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            variant="outlined"
          />
        )}
      />
    </>
  );
}

/* eslint-disable no-use-before-define */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const AutoComplete = withStyles((theme) => ({
  root: {
    maxHeight: '1.5rem',
  }
}))(Autocomplete);

export default function DatesAutocomplete(props) {
  const datesDispoWishlist = props.datesDispoWishlist;
  const setFiltreDates = props.setFiltreDates;

  const handleChangeDates = (event, value) => {
    setFiltreDates(value)
  }

  return (
    <>
      <label>Recheche générale sur les dates (souhaits et activités définitives) </label>
      <AutoComplete
        multiple
        size="small"
        id="datesAutocomplete"
        options={datesDispoWishlist}
        disableCloseOnSelect
        getOptionLabel={(option) => option.dateToString}
        onChange={handleChangeDates}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            variant="outlined"
          />
        )}
      />
    </>
    /*  <NoSsr>
       <div>
         <div {...getRootProps()}>
           <Label {...getInputLabelProps()}>Recheche générale sur les dates (souhaits et activités définitives) </Label>
           <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
             {value.map((option, index) => (
               <Tag label={option.dateToString} {...getTagProps({ index })} />
             ))}
             <input {...getInputProps()} />
           </InputWrapper>
         </div>
         {groupedOptions.length > 0 ? (
           <Listbox {...getListboxProps()}>
             {groupedOptions.map((option, index) => (
               <li {...getOptionProps({ option, index })} value={option.date} data-date={option.date}>
                 <span value={option.date} data-date={option.date}>{option.dateToString}</span>
                 <CheckIcon fontSize="small" />
               </li>
             ))}
           </Listbox>
         ) : null}
       </div>
     </NoSsr> */
  );
}

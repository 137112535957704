
export default function manifReducer(state = {}, action) {
    switch (action.type) {
      case "GET_MANIF_INFOS":
        return {...state, manifInfos: action.payload}
      case "GET_MANIF_PARAMS":
          return {...state, manifParams: action.payload}
      case "GET_MANIF_INFOPROFIL":
          return {...state, manifInfosProfil: action.payload}
      default:
        return state;
    }
  }
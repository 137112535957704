import { makeStyles, createStyles } from '@material-ui/core/styles';


export const useStyleGererGroupe = makeStyles((theme) => createStyles({
        root: {
          margin: 'auto',
          width: "100%",
        },
        paper: {
          width: 300,
          height: 350,
          overflow: 'auto',
        },
        button: {
          margin: theme.spacing(0.5, 0),
        },
        select:{
            width: "50vw" 
        }
  }));
import React, { useState, useEffect } from 'react';
import GetRandomKey from '../../../../components/general/genereRandomKey';
import { Checkbox } from '@material-ui/core';

const FilterComponent = ({ data, onChange, name }) => {
  const [selectAll, setSelectAll] = useState(true);
  const [chechSelectAll, setcheckSelectAll] = useState(true);
  const [selectedItems, setSelectedItems] = useState(data);

  const handleSelectAll = () => {
    if (!selectAll) {
      onChange(data);
    }
    setSelectAll(!selectAll);
    setcheckSelectAll(!selectAll);
  };

  const handleItemChange = (item) => {
    setSelectedItems((prev) => {
      const updatedSelection = prev.includes(item)
        ? prev.filter((selected) => selected !== item)
        : [...prev, item];

      onChange(updatedSelection);
      if (selectedItems.length === data.length) setcheckSelectAll(false);
      return updatedSelection;
    });
  };

  useEffect(() => {
    if (selectAll) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [selectAll, data]);

  return (
    <div style={{ maxHeight: 200, overflow: 'auto' }}>
      <h3 style={{ textAlign: 'center' }}>{name}</h3>
      <label>
        <Checkbox type="checkbox" checked={chechSelectAll} onChange={handleSelectAll} />
        Tous
      </label>
      {data.map((item) => (
        <div key={GetRandomKey(12)}>
          <label>
            <Checkbox
              type="checkbox"
              checked={selectedItems.includes(item)}
              onChange={() => handleItemChange(item)}
              label="Start"
            />
            {item}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FilterComponent;



import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import rest from "../../rest";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { TableCell, TableRow } from '@material-ui/core';

const OngletEchanges = ({ idBenevole }) => {
    const [infosMessage, setInfosMessage] = useState([]);

    useEffect(() => {
        var paramsData = {
            'vId': idBenevole,
        }
        rest.post('/benevols/echangesMessage.json', paramsData).then(function (response) {
            setInfosMessage(response.data)

        })
    }, [idBenevole])

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Date",
            name: "dateEnvoie",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "From",
            name: "expediteur",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "To",
            name: "nomDestinataire",
            options: {
                filter: true,
                sortThirdClickReset: true,
            },
        },
        {
            label: "Groupe",
            name: "groupeDestinataire",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Sujet",
            name: "subject",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Statut",
            name: "idDestinataire",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>{value === idBenevole ? "Reçu" : "Envoyé"}</span>
                    );
                },
            }
        },
        {
            label: "Envoyé",
            name: "send",
            options: {
                filter: false,
                sortThirdClickReset: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (

                        <span>{value ? <CheckIcon /> : <ClearIcon />}</span>
                    );
                },
            }
        }
    ];

    const options = {
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            var contenuMessage = infosMessage[rowMeta.dataIndex].body;
            return (
                <TableRow>
                    <TableCell colSpan={7}>
                        <h3 > De : {infosMessage[rowMeta.dataIndex].expediteur}</h3>
                        <h3 >A : {infosMessage[rowMeta.dataIndex].to}</h3>
                        <div dangerouslySetInnerHTML={{ __html: contenuMessage }} />
                    </TableCell>
                </TableRow>
            )
        },
    };

    return (
        <>
            {infosMessage.length > 0 ?
                <MUIDataTable
                    data={infosMessage}
                    columns={columns}
                    options={options}
                />
                : <p style={{ fontSize: 22, textAlign: 'center' }}>Ce bénévole n'a eu aucun échange</p>}
        </>
    )
};

export default OngletEchanges;



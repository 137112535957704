import React, { useEffect, useState } from 'react';
import rest from "../../rest";
import MUIDataTable from "mui-datatables";


const OngletHistorique = ({ idBenevole }) => {
    const [donneHisto, setDonneHisto] = useState([])

    useEffect(() => {
        var paramsData = {
            'vId': idBenevole
        }
        rest.post('/benevols/historique.json', paramsData).then((response) => {
            setDonneHisto(response.data.Historique)
        })
    }, [idBenevole])


    const columns = [
        {
            label: "ID",
            name: "idManif",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Manifestation(s)",
            name: "nomManif",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Edition",
            name: "edition",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Activité(s)",
            name: "Activite",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.map(item => item.nom).join(",");
                }
            }
        },
        {
            label: "Date",
            name: "Activite",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.map(item => item.Tranches.map(itemTran => itemTran.Date)).join(",");
                }
            },
        },
        {
            label: "Durée",
            name: "Activite",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value.map(item => item.duree).join(",");
                }
            },
        },
    ];
    const options = {
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",

    };
    return (
        <>
            <MUIDataTable
                data={donneHisto}
                columns={columns}
                options={options} />
        </>
    );
};

export default OngletHistorique;
import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function MySnackbar(props) {
    const [open, setOpen] = useState(false);


    useEffect(() => {
        setOpen(props.params.show);
    }, [props.params]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    return (
        <Snackbar open={open} autoHideDuration={props.params.autoHideDuration ? props.params.autoHideDuration : 5000} onClose={handleClose} anchorOrigin={{ "vertical": 'top', "horizontal": 'center' }}>
            <Alert onClose={handleClose} severity={props.params.severity}>
                <div dangerouslySetInnerHTML={{
                    __html: props.params.message
                }}></div>
            </Alert>
        </Snackbar>
    );



}
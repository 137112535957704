import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleRecapAttribution = makeStyles((theme) => createStyles({
    iconButton: {
        marginLeft: 15,
    },
    otherState: {
        
    },
    waitingState: {
        backgroundColor: "lightgrey",
        '&:hover': {
            backgroundColor: "lightgrey !important",
        },
    },
    validState: {
        backgroundColor: "#4bff0030",
        '&:hover': {
            backgroundColor: "#4bff0030 !important",
        },
    },
    refusedState: {
        backgroundColor: "#ff00002e",
        '&:hover': {
            backgroundColor: "#ff00002e !important",
        },
    },
    nextYearState: {
        backgroundColor: "#a8000087",
        '&:hover': {
            backgroundColor: "#a8000087 !important",
        },
    },
    modifState: {
        backgroundColor: "#ff98003d",
        '&:hover': {
            backgroundColor: "#ff98003d !important",
        },
    },
    updatedState: {
        backgroundColor: "#de8b4b",
        '&:hover': {
            backgroundColor: "#de8b4b !important",
        },
    },
    alignRow: {
        verticalAlign: "top",
    },
    buttonFermer: {
        backgroundColor: "#d32f2f",
        color: "#FFFFFF",
        '&$hover:hover': {
            backgroundColor: "#ab2626 !important",
        },
    },
    selectableRow: {
        cursor:"pointer",
    }
}));


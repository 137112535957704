
let statutOptions = [
    { text: 'Tous', value: -5 , correspondance:''},
    { text: 'à traiter par responsable', value: -4 , correspondance:''},
    { text: 'à valider par responsable', value: -1 , correspondance:'En Attente de validation'},
    { text: 'à valider par bénévole', value: 0 , correspondance:'En attente'},
    { text: 'Validé', value: 1, correspondance:'Valide' },
    { text: 'Refusé', value: 2 , correspondance:''},
    { text: 'Désistement', value: 3 , correspondance:'Refuse total'},
    { text: "Modifié par l'admin", value: 4 , correspondance:''},
  ];

  export default statutOptions;

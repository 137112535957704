import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    profilPix: {
        width: 80,
        height: 80,
    },
    middleGrid: {
        marginLeft: 30,
    },
    grid: {
        textAlign: "right"
    },
    fab: {
        marginLeft: "10px"
    },
    cardAction: {
        maxWidth: '100%',
        padding: "0vw"
    },
    badge: {
        marginInline: "3vw"
    },
    buttonFermer: {
        backgroundColor: "#d32f2f",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#ab2626",
        },
    },
    title: {
        width: '100%',
        textAlign: 'center',
    },
    popperTip: {
        zIndex: '1000',
        position: 'absolute',
        backgroundColor: 'rgba(200,200,200, 0.67)',
        padding: '10px',
        width:'270px',
        borderRadius: '5px'
    },
    paperPopper: {
        padding: '10px'
    },
    popperTipImage: {
        width: '68px',
        height: '68px',
        borderRadius: '50%',
    },
    InfoBenevoleBox: {
        position:'fixed',
        top:'0px',
        left:'0px',
        backgroundColor: 'rgba(200,200,200, 0.97)',
    },
}));


import rest from '../rest';

export const postActiviteWhislist = (paramsData) => {
    return async (dispatch) => {
        rest.post( '/activites/inscriptionWhislist.json',encodeURIComponent(JSON.stringify(paramsData)))
            .then((response) => {
                dispatch({ type: 'GET_ACTIVITIES_WISHLIST', payload: response.data })
            })

    }
}

export const getAvailablesActivites = (paramsData) => {
    return async (dispatch) => {
        rest.post( '/activites/activitesDisponibles.json', encodeURIComponent(JSON.stringify(paramsData)))
            .then((response) => {
                dispatch({ type: 'GET_AVAILABLE_ACTIVITES', payload: response.data })
            })

    }
}
// wishlist
export const getActsDatesDispo = () => {
    return async (dispatch) => {
        await rest.get('/activites/recupDatesDispoWishlist.json')
            .then((response) => {
                dispatch({ type: 'GET_DATES_DISPO_WISHLIST', payload: response.data })
                /* dispatch({type: 'GET_DATES_DISPO_AVT', payload: response.data.AvantPeriod.DateDispo})
                dispatch({type: 'GET_DATES_DISPOSTRING_AVT', payload: response.data.AvantPeriod.DateString})
                dispatch({type: 'GET_ALIAS_DISPO_AVT', payload: response.data.AvantPeriod.AliasDispo}) 
                dispatch({type: 'GET_DATES_DISPO_PDT', payload: response.data.PendantPeriod.DateDispo})
                dispatch({type: 'GET_DATES_DISPOSTRING_PDT', payload: response.data.PendantPeriod.DateString})
                dispatch({type: 'GET_ALIAS_DISPO_PDT', payload: response.data.PendantPeriod.AliasDispo})*/
            })
    }
}



import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({setUploadPorcesss}) => {
  
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file);
      // Utiliser le Fetch pour envoyer le fichier au serveur
      fetch('/pageImageUpload/uploadImage.json', {
        method: 'POST',
        body: formData,
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('Réponse du serveur :', data);
      })
      .catch((error) => {
        console.error('Erreur lors de l\'envoi du fichier :', error);
      });
    });
    setUploadPorcesss((p) => p +1 )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div {...getRootProps()} className='dropZoneArea'>
        <input {...getInputProps()} />
        <p>Glissez-déposez des fichiers ici ou cliquez pour sélectionner des fichiers</p>
      </div>
    </div>
  );
};

export default FileUpload;

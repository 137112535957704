import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Divider, Grid, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import rest from "../../rest";
// import { useEffect } from 'react';



const TypeTailleMateriel = ({ typeMateriel, tailleMateriel }) => {
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [showElement, setShowElement] = useState(false);
    
    const changementTypeTaille = (params) => {
        rest.post('/materiel/saveTypeMateriel.json', params).then(function (response) {
            // console.log(response.data.Message)

        })
    }
    const openChange = (index) => {
        setShowElement(prev => !prev)
    }
    const handleClickType = (e) => {

        var params = {
            vId: 9,
            vTypeBase: null,
            vNewTypeTaille: "", // texte nouveau
            vAction: "ajouter",
            vTypeTaille: "type",
        }
        changementTypeTaille(params)
    }

    const handleChangeType = (e, type) => {
        var params = {
            vId: 9,
            vTypeBase: type,
            vNewTypeTaille: e.target.value, // texte nouveau
            vAction: "modifier",
            vTypeTaille: "type",
        }
        changementTypeTaille(params)

    }
    const handleChangeTaille = (e, taille) => {
        var params = {
            vId: 9,
            vTypeBase: taille,
            vNewTypeTaille: e.target.value, // texte nouveau
            vAction: "modifier",
            vTypeTaille: "taille",
        }
        changementTypeTaille(params)
    }
    return (<>
        <Accordion expanded={expanded === typeMateriel} onChange={handleChange(typeMateriel)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ backgroundColor: "#eceff1" }}
            // onClick={_ => benevoleInscrit(tranche.ID, tranche.DateActivite, tranche.Horaire)}
            >
                <Grid container spacing={1}>
                    <Grid xs={5} >
                        <Typography >Type</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails >

                <List component="nav" aria-label="main mailbox folders" style={{ width: "100%" }}>
                    {typeMateriel.map((type, index) => (
                        <>
                            <ListItem button id={index}>
                                <ListItemText >
                                    {showElement ?
                                        <>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                defaultValue={type}
                                                onChange={handleChangeType}
                                                label="Nom materiel"
                                                fullWidth
                                            />
                                            <br />
                                            <br />
                                            <Button variant='contained' color='primary' > Modifier</Button>

                                            <br />
                                            <br /> </>
                                        :
                                        <p>{type}</p>} </ListItemText>
                                <ListItemText style={{ textAlign: "right" }} onClick={_ => openChange(type)} ><BorderColorIcon /></ListItemText>
                            </ListItem>
                            <Divider />
                        </>))}
                </List>
            </AccordionDetails>
            <AccordionActions >
                <>
                    <Button variant="contained" color="secondary" onClick={_ => handleClickType()}>
                        Nouveau Type
                    </Button>
                </>
            </AccordionActions>
        </Accordion>
        <Accordion expanded={expanded === tailleMateriel} onChange={handleChange(tailleMateriel)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ backgroundColor: "#eceff1" }}
            // onClick={_ => benevoleInscrit(tranche.ID, tranche.DateActivite, tranche.Horaire)}
            >
                <Grid container spacing={1}>
                    <Grid xs={5} >
                        <Typography >Taille</Typography>
                    </Grid>
                </Grid>

            </AccordionSummary>
            <AccordionDetails >

                <List component="nav" aria-label="main mailbox folders" style={{ width: "100%" }}>
                    {tailleMateriel.map((taille, index) => (
                        <>
                            <ListItem button onClick={_ => openChange(taille)} id={index}>
                                <ListItemText primary={taille} />
                                <ListItemText style={{ textAlign: "right" }} ><BorderColorIcon /></ListItemText>
                            </ListItem>
                            <Divider />
                            {showElement &&
                                <>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        value={taille}
                                        onChange={handleChangeTaille}
                                        label="Nom materiel"
                                        fullWidth
                                    />
                                    <br />
                                    <br />
                                    <Button variant='contained' color='primary' > Modifier</Button>

                                    <br />
                                    <br /> </>}
                        </>))}
                </List>


            </AccordionDetails>
            <AccordionActions >
                <>
                    <Button variant="contained" color="secondary" /* onClick={_ => setAjoutBen(true)} */>
                        Nouveau Taille
                    </Button>
                </>
            </AccordionActions>
        </Accordion>
    </>
    );
};

export default TypeTailleMateriel;
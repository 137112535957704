import rest from "../rest"

export const getManifParams = () =>{
    return async (dispatch) => {
        await rest.get('/parametres/recupConfigManif2.json')
        .then((response) => {
            dispatch({type: 'GET_MANIF_INFOS', payload: response.data.Infos})
            dispatch({type: 'GET_MANIF_PARAMS', payload: response.data.Params})
            dispatch({type: 'GET_MANIF_INFOPROFIL', payload: response.data.InfosProfil})
        })
        
    }
}
import React, { useContext, useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button,
  Typography, InputAdornment, InputLabel, Select, MenuItem, FormControl, Box, Grid, CircularProgress
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Search as SearchIcon } from '@material-ui/icons';
import axios from 'axios';
import DateFormatted from '../../components/general/formateDate';
import sortCollection from '../../components/tableauDesAtributions/calculeMethodes';
import TruncatedText from '../../components/tableauDesAtributions/truncateText';
import UserLine from '../../components/tableauDesAtributions/rowUser';
import TotalLine from '../../components/tableauDesAtributions/totalLine';
import './tableauDesAttributions.css';
import GetRandomKey from '../../components/general/genereRandomKey';
import AddNewUser from '../../components/tableauDesAtributions/addNewUser';
import AppContext from '../../components/appContext';
import rest from '../../rest';
import { useSelector } from "react-redux";
import AssessmentIcon from '@material-ui/icons/Assessment';
import Fab from '@material-ui/core/Fab';

const ExcelTable = () => {
  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [textecours, setTextecours] = useState('Off');
  const [distinctDates, setDistinctDates] = useState([]);
  const [distinctHoursByDate, setDistinctHoursByDate] = useState({});
  const [totalJourneeParHeure, setTotalJourneeParHeure] = useState({});
  const [totalVisibleParHeure, setTotalVisibleParHeure] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedOptionName, setSelectedOptionName] = useState('');
  const [options, setOptions] = useState([]);
  const [params, setParams] = useState([]);
  const [manifInfo, setManifInfo] = useState({});
  const myContext = useContext(AppContext);
  const openSidebar = myContext.openSidebar;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [indexOfLastItem, setIndexOfLastItem] = useState(1);
  const [indexOfFirstItem, setIndexOfFirstItem] = useState(1);
  const [changeOption, setChangeOption] = useState('off');
  const [itemsPerPage, setitemsPerPage] = useState(30);
  const currentUser = useSelector(state => state.user);

  useEffect(() => {
    if (!data) return;
    setTotalVisibleParHeure({});
    const timer = setTimeout(() => {
      const res = Object.keys(data).reduce((result, key) => {
        const itemList = data[key];
        const filteredItems = itemList.filter(item => filterItems(item));

        if (filteredItems.length > 0) {
          result[key] = filteredItems;
        }

        return result;
      }, {});
      setFilteredData(res);
      setTotalPages(Math.ceil(Object.keys(res).length / itemsPerPage));
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, data, itemsPerPage, changeOption, selectedOptionName]);

  function filterItems(item) {
    const { Name, Firstname, Name_Activite } = item;
    const compositeNames = [`${Name.trim()} ${Firstname.trim()}`, `${Firstname.trim()} ${Name.trim()}`];
    const searchTermLowerCase = searchTerm.replace(/\s+/g, ' ').toLowerCase();
    if (changeOption === 'on') {
      if (searchTermLowerCase !== '') {
        return (
          Name_Activite === selectedOptionName &&
          (compositeNames.some(name =>
            name.toLowerCase().includes(searchTermLowerCase)
          ))
        );
      } else {
        return Name_Activite === selectedOptionName;
      }
    } else {
      return (
        compositeNames.some(name =>
          name.toLowerCase().includes(searchTermLowerCase)
        )
      );
    }
  }

  useEffect(() => {
    if (!filteredData || !distinctHoursByDate) return

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setIndexOfLastItem(indexOfLastItem);
    setIndexOfFirstItem(indexOfFirstItem);

    setTotalVisibleParHeure(calcValue(filteredData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, distinctHoursByDate, itemsPerPage])

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setIndexOfLastItem(indexOfLastItem);
    setIndexOfFirstItem(indexOfFirstItem);

  }, [currentPage, itemsPerPage])

  useEffect(() => {
    if (!data || !distinctHoursByDate) return
    setTotalJourneeParHeure(calcValue(data))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, distinctHoursByDate])

  useEffect(() => {// Appel AJAX en fonction de l'option Action choisie    
    const fetchSubOptions = async () => {
      const data = { selectedOption }
      setIsLoaded(false);
      try {
        const response = await axios.post(`/tableauDesAttributions/listeJours.json`, data);
        const sortData = sortCollection(response.data);
        extractDistinctDatesAndHours(sortData);
        setIsLoaded(true);
      } catch (error) {
        console.error('Erreur lors de la récupération des sous-options :', error);
        setIsLoaded(true);
      }
    };

    if (selectedOption !== '' && selectedOption !== 0) {
      fetchSubOptions();
    } else {
      fetchDays();
      setSearchTerm('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    setIsLoaded(false);
    const getData = async () => {
      fetchData();
      const parmsTemp = await fetchParams();
      if (parmsTemp) { }
      rest.get('/parametres/infoWishlit.json').then((response) => {
        setManifInfo(response.data);
      })
    }
    getData();

    const fetchOptions = async () => {
      try {
        const response = await axios.get('/tableauDesAttributions/distinctActivité.json');
        setOptions(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des options :', error);
      }
    };

    fetchOptions();

    const manifOptions = async () => {
      try {
        const response = await axios.get('/parametres/recupConfigManif2.json');
        setTextecours(response.data?.Params?.ficheOccupationLongueHeure ? 'Off' : 'On');
      } catch (error) {
        console.error('Erreur lors de la récupération des Manif - options :', error);
      }
    };

    manifOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const fetchData = async () => {
    setIsLoaded(false);
    try {
      const response = await axios.get('/tableauDesAttributions/getBenevoles.json');
      const transformedData = {};

      response.data.forEach((item) => {
        const key = `${item.Name}_${item.Firstname}_${item.Name_Activite}`;
        if (!transformedData[key]) {
          transformedData[key] = [];
        }
        const alias = creetAlias(item.HeureDebut, item.HeureFin);//item.Alias !== '' ? item.Alias :
        transformedData[key].push({
          ID: item.ID || '',
          Name: item.Name || '',
          Firstname: item.Firstname || '',
          Name_Activite: item.Name_Activite || '',
          HeureDebut: item.HeureDebut || 0,
          HeureFin: item.HeureFin || 0,
          Alias: alias,
          Date: item.Date,
          temp: item.temp,
          ID_Activite: item.ID_Activite,
        });
      });
      setData(transformedData);

      setIsLoaded(true);
      return transformedData
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoaded(true);
    }
  };

  const fetchDays = async () => {
    try {
      const response = await axios.get('/tableauDesAttributions/listeJours.json');
      const sortData = sortCollection(response.data);
      extractDistinctDatesAndHours(sortData);

      return sortData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchParams = async () => {
    try {
      const response = await axios.get('/parametres/recupConfigManif2.json');
      setParams(response.data);

      return response.data;
    } catch (error) {
      console.error('Error fetching Params:', error);
    }
  };

  const creetAlias = (heureDebut, heureFin) => {
    heureDebut = new Date(heureDebut * 1000);
    heureFin = new Date(heureFin * 1000);
    const heureDebutString = `${heureDebut.getUTCHours().toString().padStart(2, '0')}:${heureDebut.getUTCMinutes().toString().padStart(2, '0')}`;
    const heureFinString = `${heureFin.getUTCHours().toString().padStart(2, '0')}:${heureFin.getUTCMinutes().toString().padStart(2, '0')}`;
    return `${heureDebutString} - ${heureFinString}`;
  }

  const extractDistinctDatesAndHours = (data) => {
    const distinctDatesExtract = [...new Set(data.map((item) => item.linkActTH_TH.DateActivite))];
    setDistinctDates(distinctDatesExtract);
    const distinctHoursByDateExtract = distinctDatesExtract.reduce((acc, date) => {
      const hours = data
        .filter((item) => item.linkActTH_TH.DateActivite === date)
        .map((item) => {
          // let alias = item.linkActTH_TH.Alias !== '' ? item.linkActTH_TH.Alias : creetAlias(item.linkActTH_TH.HeureDebut, item.linkActTH_TH.HeureFin);
          let alias = creetAlias(item.linkActTH_TH.HeureDebut, item.linkActTH_TH.HeureFin);
          return [
            alias,
            item.linkActTH_TH.HeureDebut,
            item.linkActTH_TH.HeureFin,
          ]
        });

      const uniqueHours = {};

      hours.forEach(([alias, heureDebut, heureFin]) => {
        const key = `${alias}-${heureDebut}-${heureFin}`;
        uniqueHours[key] = [alias, heureDebut, heureFin];
      });

      acc[date] = Object.keys(uniqueHours).map((key) => uniqueHours[key]);

      return acc;
    }, {});
    setDistinctHoursByDate(distinctHoursByDateExtract);
  };

  const calcValue = (filteredData) => {
    const res = {};
    Object.keys(filteredData).map((rowKey) => {
      Object.keys(distinctHoursByDate).map((day) => (
        distinctHoursByDate[day].map(([alias]) => {
          const hasMatchingObject = filteredData[rowKey].some((item) => (
            item.Date === day && item.Alias === alias
          ));
          res[day] = res[day] || {};
          res[day][alias] = res[day][alias] | 0;
          res[day][alias] = hasMatchingObject ? res[day][alias] + 1 : res[day][alias];
          return null;
        })
      ));
      return null;
    });

    return res;
  };

  const handleSearch = (event) => {
    setCurrentPage(1);
    setSearchTerm(event.target.value);
  };

  const handleToggleTextecours = () => {
    setTextecours((prevTextecours) => (prevTextecours === 'On' ? 'Off' : 'On'));
  };

  const handlePagination = (event, value) => {
    setCurrentPage(value);
  };

  const handleOptionChange = (event) => {
    setChangeOption(Number(event.target.value) === 0 ? 'off' : 'on');
    setCurrentPage(1);
    setSelectedOption(Number(event.target.value));
    const item = options.find((item) => item.ID === Number(event.target.value));
    const nom = item ? item.Nom : '';
    setSelectedOptionName(nom);
    setSearchTerm('');
  };

  const handleItemsPerPageChange = (e) => {
    setitemsPerPage(e.target.value);
    setCurrentPage(1);
  }

  const handleFicheOccupation = () => {
    let resp = currentUser.User.User_Person_ID;
    window.open("/export/fichesOccupation?vResp=true&idResp=" + resp);
  }

  return (
    <Grid container spacing={2}>
      {isLoaded ?
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {manifInfo?.vInscripionWishlist &&
                <Typography>Legende :  - Bénévole a validé ses heures ✗
                  <span className='colorTemp'>  - le bénévole n’a pas validé ses heures ✗</span></Typography>}
              <Typography>Info : Pour tout changement, vous devez, en premier lieu, faire une sélection dans la liste déroulante "{params?.Infos?.DenominationSousAct}". <br />
                Pour ajouter ou supprimer des heures au bénévole, double-cliquez sur la case correspondante</Typography>
            </Grid>
            <Grid item xs={12} md={6} container justifyContent="flex-end">
              <div>
                <Fab color="primary" onClick={handleFicheOccupation} size="small" title="Fiche Occupations" component="span" id='btFiccheOccupation'>
                  <AssessmentIcon />
                </Fab>
                <Button variant="contained" onClick={handleToggleTextecours} className='onOffButton' color={textecours === 'Off' ? 'primary' : 'secondary'}>
                  {textecours === 'Off' ? 'Heure de début de tranche horaire' : 'Intervalle de tranche horaire'}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" id='boxInputeTableau'>
              <TextField
                label="Recherche"
                value={searchTerm}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <FormControl>
                <InputLabel id="dropdown-label">{params?.Infos?.DenominationSousAct}</InputLabel>
                <Select
                  labelId="dropdown-label"
                  id="dropdown-menu"
                  value={selectedOption}
                  onChange={handleOptionChange}
                  style={{ minWidth: '220px', }}
                >
                  <MenuItem value="0">Toutes</MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option.ID} value={option.ID}>{option.Nom}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className={openSidebar ? "sticky-container close" : "sticky-container"}>
              <TableContainer component={Paper}>
                <Table className='stikyTable' aria-label="Excel Table" id="tableauDesAttributionsTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell>Prénom</TableCell>
                      <TableCell>{params?.Infos?.DenominationSousAct}</TableCell>
                      {
                        distinctDates.map((date) => <TableCell key={date} colSpan={distinctHoursByDate[date]?.length || 0} className='tdDays'>
                          <DateFormatted dateString={date} /></TableCell>)
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3}></TableCell>
                      {Object.keys(distinctHoursByDate).map((day, i) => (
                        <React.Fragment key={GetRandomKey(18)}>
                          {distinctHoursByDate[day].map(([alias, heureDebut, heureFin], j) => {//day + '' + i
                            const altern = i % 2 === 0 ? '' : ' impaire';
                            return (
                              <TableCell key={GetRandomKey(17)} className={`tdHours${altern}`}>
                                <TruncatedText text={alias} active={textecours === 'On'} />
                              </TableCell>
                            )
                          })}
                        </React.Fragment>
                      ))}
                    </TableRow>
                    {Object.keys(filteredData).slice(indexOfFirstItem, indexOfLastItem).map((rowKey, i) => {
                      const row = filteredData[rowKey][0]; // Accéder à l'objet correspondant à la clé
                      return <UserLine
                        row={row}
                        indexOfFirstItem={indexOfFirstItem}
                        indexOfLastItem={indexOfLastItem}
                        distinctHoursByDate={distinctHoursByDate}
                        filteredData={filteredData}
                        rowKey={rowKey}
                        i={i}
                        key={GetRandomKey(8)}
                        fetchData={fetchData}
                        data={data}
                        options={options}
                        selectedOption={selectedOption}
                        params={params} />
                    })
                    }
                    <TotalLine distinctHoursByDate={distinctHoursByDate} totalVisibleParHeure={totalVisibleParHeure} text={'Total des bénévoles selon recherche'} />
                    <TotalLine distinctHoursByDate={distinctHoursByDate} totalVisibleParHeure={totalJourneeParHeure} text={'Total des bénévoles inscrits par tranche horaire'} />
                  </TableBody>
                </Table>
              </TableContainer>
              <AddNewUser data={data} setData={setData} idActivite={selectedOption} activites={options}
                setSearchTerm={setSearchTerm}
                setChangeOption={setChangeOption} />
              <div className="paginationSticky">
                <div className='inline'>
                  <Pagination count={totalPages} page={currentPage} onChange={handlePagination} />
                  <span> Éléments par page: </span>
                  <select
                    id="rowsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
                <div className='compteParPage'>
                  {Object.keys(filteredData).length > 0 ? currentPage * itemsPerPage - (itemsPerPage - 1) : 0}
                  &nbsp;- {currentPage * itemsPerPage > Object.keys(filteredData).length ? Object.keys(filteredData).length : currentPage * itemsPerPage}
                  &nbsp;/ {Object.keys(filteredData).length}</div>
              </div>
            </div>
          </Grid>
        </React.Fragment>
        : <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Grid>
      }
    </Grid>
  );
};

export default ExcelTable;
import { makeStyles, createStyles } from '@material-ui/core/styles';

const drawerWidth = 220;

export const useStylesGlobal = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        width: '100%',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    contentModeTest: {
        marginTop: "20px",
    },
    content: {
        flexGrow: 1,
        maxWidth: '100%',
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        // height: '100vh',
        fontSize: '13px !important',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    // Pour tout les boutons fermer 
    buttonFermer: {
        backgroundColor: "#d32f2f",
        color:"#FFFFFF",
         "&:hover": {
             backgroundColor: "#ab2626",
        },
    },
    // pas encore vu 
    //PAGE Aprecu
    formControl: {
        marginBottom: theme.spacing(3),
        width: "32.5%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    // PAGE InscriptionWish / EmailList
    appBarBg: {
        backgroundColor: "#FFFFFF",
    }, 
    successBtn: {
        background: "#388e3c",
        color: "#FFFFFF",
         "&:hover": {
             backgroundColor: "#2b6e2e",
        },
    },
    btnTableau: {
        width:"100%"
    }
}));

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleListe = makeStyles((theme) => createStyles({
  // listesActivitesBenevolesTabsAllInOne
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  // listesActivitesBenevolesAccordion
  column: {
    flexBasis: '33.33%',
  },
  //listesActivitesBenevoles
  rootLstBenevoles: {
    flexGrow: 1,
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

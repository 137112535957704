import { makeStyles, createStyles } from '@material-ui/core/styles';


export const useStyleFormInscript = makeStyles((theme) => createStyles({
    root: {
      width: 'auto',
      backgroundColor: theme.palette.background.paper,
      height: 'auto',
      marginTop: theme.spacing(1),
    },
    inputsForm : {
        margin: 8, 
    }
  }));
  
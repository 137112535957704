
import { Box, Button, Grid } from '@material-ui/core';

const ModalConfirm = ({ text, handleConfirm, handleAnnul }) => {

  const onConfirm = async () => {
    const shouldClose = await handleConfirm?.();

    if (!shouldClose)
      handleAnnul?.();
  }

  return (
    <Box>
      <p>{text}</p>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button onClick={onConfirm} color="primary">
            Confirmer
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleAnnul} color="secondary" autoFocus>
            Annuler
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ModalConfirm;
import { makeStyles, createStyles } from '@material-ui/core/styles';


export const useStyleCard = makeStyles((theme) => createStyles({
    //Activities Card
    rootCard: {
        maxWidth: '100%',
        border: '1px solid grey'
      },
      highlightCard: {
        boxShadow: '5px 5px 10px 5px #3937af ',
        border: '1px solid #25a7af '
      },
      changePadding: {
        padding:'0',
        paddingLeft: '0.5%',
        paddingTop: '0.5%',
      },
      removePadding :{
        paddingBottom:'0',
        marginTop:'1px'
      },
      tranchesStyle:{
        paddingLeft:'0.5%',
        paddingRight:'0.5%',
        border: '1px solid blue'
      },
      personListUl:{
        columns: '2',
        paddingBottom:'0',
        marginTop:'0.5px', 
        width:'100%'
      },
      personList:{
        marginBottom:'2px',
        listStyleType: "none", 
      },
      buttonRemove:{
        width:'90%'
      }, 
      cardTh:{
        paddingTop:'10px',
        paddingBottom:'10px',
      }, 
      buttonValidee:{
        width:'90%',
        cursor:'context-menu', 
        background:'#c7f1c7',
        '&:hover': {
          background:'#c7f1c7',
       },
      },
      buttonSupprimer:{
        marginTop:'5px',
        width:'100%',
        cursor:'context-menu', 
        background:'#f1c7c7',
        '&:hover': {
          background:'#efafaf',
       },
      },

    //Benevoles Card
      rootCardBenevole: {
        maxWidth: '100%',
        border: '1px solid #'
      },
      highlightCardBenevole: {
        boxShadow: '5px 5px 10px 5px #37A8AF ',
        border: '1px solid #25a7af ',
        top: '115px',
        right: '10px',
        bottom: "0",
        position:'fixed',
        padding: '5px', 
        background:'#fff',
        zIndex:'2',
        height: 'calc(100vh - 150px)',
        maxWidth:'40vw',
        [theme.breakpoints.down('sm')]: {
          // styles spécifiques pour la breakpoint sm et plus petit
          right: '0',
        }
      },
    
      listClasse:{
        paddingTop:'3px'
      },
      paddingRemarque:{
        paddingBottom:'0',
        marginTop:'1px',
        listStyleType: "none", 
        marginBottom:'0px'
      },
      avatar:{
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
      imgAvatar: {
        width:"100%",
      },
      imgAvatarList :{
          width: "200px"
      },
      paper: {
        padding: '20px',
        width: '20vw',
      },
      popover: {
        pointerEvents: 'none',
      },
      actionBtn:{
        fontSize:'30px',
        cursor:"pointer"
      },
      actionBtnValide:{
        fontSize:'30px',
      },
      /* typography: {
        padding: theme.spacing(2),
      }, */
      // Card Materiel
      chip: {
        position: "absolute",
        color: "#FFFFFF",
        left: "-1vw",
        top: "-1vw",
      },
  
}));
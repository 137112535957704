import React, { useEffect, useState } from 'react';
import rest from '../../rest';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useStyleMesBenevoles } from './MesBenevoles.css';
import FiltreBenevole from '../../components/filtreMesBene/FiltreBenevole';
import { useSelector } from "react-redux";

// Debut fonction 
export default function MesBenevoles() {
    const classes = useStyleMesBenevoles();
    const [seeResponsable, setSeeResponsable] = useState([]);
    const [selectedResponsable, setSelectedResponsable] = useState(0);
    const [displayRespSelect, setDisplayRespSelect] = useState(false);
    const currentUser = useSelector(state => state.user);

    // requete pour obtenir les responsables
    useEffect(() => {
        rest.get('/benevols/listeResponsables.json')
            .then((response) => {
                setSeeResponsable(response.data)
            })
    }, [])
    const handleChange = (event) => {
        setSelectedResponsable(event.target.value);
    };
    const handleClickOpen = () => {
        setDisplayRespSelect(true);
    };

    return (
        <>
            {currentUser.User.User_Administrator && <>
                {!displayRespSelect ?
                    <Button variant="contained" size="small" style={{ width: "20%" }} color="primary" onClick={handleClickOpen}>Voir en tant que...</Button>
                    :
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-helper-label">Choix responsables</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedResponsable}
                            onChange={handleChange}
                        >
                            {seeResponsable.map((item) => (
                                <MenuItem key={item.ID} value={item.ID} >
                                    {item.Firstname} {item.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
            </>}
            <br />
            <FiltreBenevole selectedResponsable={selectedResponsable} />
        </>
    )
}
import React, { useEffect, useState } from 'react';
import rest from '../../rest';
import { useStylesGlobal } from "../../pages/GlobalStyle.css";
import { Button, CircularProgress, Popover } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import clsx from 'clsx';
import { MySnackbar } from '../mySnackbar/MySnackbar';

const TableActiviteDetails = (
  { numActChoosen,
    idPerson,
    handleClick,
    activitesBen,
    handleDelete,
    restrict, showGrp,
    setShowGererGrp,
    setIdTrancheHoraire,
    setDateTranche,
    setHoraireTranche }) => {

  const classesGlobal = useStylesGlobal();
  const [isLoaded, setIsLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [listeDetailAct, setListeDetailAct] = useState([]);
  const [inscription, setInscription] = useState([]);
  const [snack, setSnack] = useState({});
  const open = Boolean(anchorEl);

  useEffect(() => {
    var paramsData = {
      'vId': numActChoosen,
      'vIdPer': idPerson,
      'vRestrict': restrict ? restrict : false,
    }
    rest.post(`/activites/detailsSousActivite.json`, paramsData).then((response) => {
      setListeDetailAct(response.data.TranchesHoraires)
    }, (error) => {
      console.log(error);
    });
    setIsLoaded(true)
  }, [numActChoosen, inscription, idPerson, restrict])

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Requete inscription Activite 
  const InscripActiviteBenevole = (idTh) => {
    setIsLoaded(false)
    var paramsData = {
      'vId': idTh,
      'vIdPersonnes': idPerson
    }
    rest.post(`/benevols/activiteInscription.json`, paramsData).then((response) => {
      if (response.data.Msg === "nok") {
        setSnack({ message: response.data.textMsg, show: true, severity: "error" })
      } else {
        setSnack({ message: response.data.textMsg, show: true, severity: "success" })
        setInscription(response.data)
      }
    }, (error) => {
      console.log(error);
    });
    setIsLoaded(true)
  };
  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        viewColumns: false,
        filter: false,
        display: 'excluded',

      }
    },
    {
      label: "Date",
      name: "date",
      options: {
        viewColumns: false,
        filter: false,
        // display: 'excluded',

      }
    },

    {
      label: "Heure",
      name: "horaire",
      options: {
        viewColumns: false,
        filter: false,
      }
    },
    {
      label: "Durée",
      name: "nbHeure",
      options: {
        filter: true,
        sortThirdClickReset: true,
      }
    },
    {
      label: "Places disponibles",
      name: "nbDispo",
      options: {
        filter: true,
        sortThirdClickReset: true,
      }
    },

    {
      label: " ",
      name: "statut",
      options: {
        filter: true,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData)
            return (
              idPerson === 0 ?
                <>
                  <Link to={"/connexion.html"} style={{ textDecoration: 'none' }}>
                    <Button className={classesGlobal.btnTableau} variant="contained" size="small">Connectez-vous</Button>
                  </Link>
                </> :
                <>
                  {(value === "1") &&
                    <Button variant="contained" size="small" className={ classesGlobal.btnTableau} onClick={() => InscripActiviteBenevole(tableMeta.rowData[0])} color="primary">Inscrire</Button>
                  }
                  {(value === "11") &&
                    <Button variant="contained" size="small" className={classesGlobal.btnTableau} onClick={() => InscripActiviteBenevole(tableMeta.rowData[0])} color="primary">Inscrire (Conflit)</Button>
                  }
                  {value === "2" &&
                    <span>
                      <Button onClick={() => InscripActiviteBenevole(tableMeta.rowData[0])} className={clsx(classesGlobal.buttonFermer, classesGlobal.btnTableau)} variant="contained" size="small">Désinscrire</Button>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className="popover_class"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Button variant="contained" className={clsx(classesGlobal.buttonFermer, classesGlobal.btnTableau)} size="small" value={activitesBen} onClick={handleDelete}>Confirmer désinscription</Button>
                      </Popover>
                    </span>
                  }
                  {value === "3" &&
                    <Button variant="contained" size="small" className={classesGlobal.btnTableau} onClick={handleClick} disabled>Conflit</Button>
                  }
                  {value === "4" &&
                    <Button variant="contained" size="small" className={classesGlobal.btnTableau} disabled>Complet</Button>
                  }
                  {/* Statut utilisé lors que la date de l'activité est deja passé  */}
                  {value === "5" &&
                    <Button variant="contained" size="small" className={classesGlobal.btnTableau} onClick={() => InscripActiviteBenevole(tableMeta.rowData[0])} color='primary'>Inscrire</Button>
                  }</>
            )
          
        }
      }
    },
    {
      label: " ",
      name: "groupe",
      options: {
        filter: true,
        sortThirdClickReset: true,
        display: showGrp && true
      }
    },
    {
      label: " ",
      name: "id",
      options: {
        filter: true,
        sortThirdClickReset: true,
        display: showGrp ? true : 'excluded',
        customBodyRender: (value, tableMeta, updateValue) => {
          const handleGroupe = (value) => {
            setIdTrancheHoraire(value)
            setDateTranche(tableMeta.rowData[1])
            setHoraireTranche(tableMeta.rowData[2])
            setShowGererGrp(prev => !prev)

          }
          return (
            <>
              <Button onClick={_ => handleGroupe(value)} size="small" className={classesGlobal.btnTableau} variant="contained" color="primary">Gérer groupe</Button>
            </>
          )

        }
      }
    },

  ]

  const options = {
    responsive: 'simple',
    filterType: 'checkbox',
    selectableRowsHeader: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    textLabels: {
      "body": {
        "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
        "toolTip": "Trier"
      },
      "pagination": {
        "next": "Page suivante",
        "previous": "Page précédente",
        "rowsPerPage": "Ligne par page:",
        "displayRows": "de",
        "jumpToPage": "Aller à la page:"
      },
      "toolbar": {
        "search": "Rechercher",
        "downloadCsv": "Télécharger CSV",
        "viewColumns": "Afficher les colonnes",
        "filterTable": "Tableau des filtres"
      },
      "viewColumns": {
        "title": "Afficher les colonnes",
        "titleAria": "Afficher/Masquer les colonnes du tableau"
      },
      "selectedRows": {
        "text": "ligne(s) sélectionnée(s)",
        "delete": "Effacer",
        "deleteAria": "Supprimer les lignes sélectionnées"
      }
    },
  };

  return (
    isLoaded ?
      <>
        <MySnackbar params={snack} />
        <MUIDataTable
          // style={{ width: "100%" }}
          data={listeDetailAct}
          columns={columns}
          options={options}
        />
      </>
      :
      <CircularProgress />

  )
}

export default TableActiviteDetails

import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import rest from '../../rest';
import { useSelector } from "react-redux";
import MUIDataTable from 'mui-datatables';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import MuiDatatableToolBar from '../../components/muiDatatableToolBar/MuiDatatableToolBar';
import { useStyleCard } from '../../components/cards/Cards.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import clsx from 'clsx';
import { useStyleRecapAttribution } from './RecapAttributions.css';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar';
import TrancheCheckbox from './TrancheCheckbox.';
import moment from 'moment/moment';
import CustomAutocomp from '../../components/general/customAutocomp/CustomAutocomp';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStylesGlobal } from '../GlobalStyle.css';
import { useModal } from '../../components/general/modalContext';
import ModalConfirmText from '../../components/general/modals/confirmTextarea';
import statutOptions from '../../components/general/statutBenevoleTemp';

const RecapAttributions = (props) => {
    const classes = useStyleCard();
    const classesGlobal = useStylesGlobal();
    const classesRecap = useStyleRecapAttribution();
    const manifParams = useSelector(state => state.manif);
    const currentUser = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [showConfirmPlanning, setShowConfirmPlanning] = useState(false);
    const [onlyMyBenev, setOnlyMyBenev] = useState(true);
    const [reload, setReload] = useState(false);
    const [snack, setSnack] = useState({});
    const [showUpdateDispo, setShowUpdateDispo] = useState(false);
    const [currentUpdatePerson, setCurrentUpdatePerson] = useState("");
    const [listTrancheUpdate, setListTrancheUpdate] = useState([]);
    const [listTrancheToAdd, setListTrancheToAdd] = useState([]);
    const [rowUpdate, setRowUpdate] = useState(0);
    const [addTrancheActive, setAddTrancheActive] = useState(false);

    const { openModal, closeModal } = useModal();

    //persist table state
    const [sortOrder, setSortOrder] = useState({ name: "Name", direction: 'asc' })
    const [rowsPerPage, setRowsPerPage] = useState(100)
    //filtre
    const [activityFilter, setActivityFilter] = useState({ id: "", text: "" });

    const [openedPopoverId, setOpenedPopoverId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event, itemId) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopoverId(itemId);
        event.stopPropagation();
    };

    const handlePopoverClose = (event) => {
        setAnchorEl(null);
        setOpenedPopoverId(null);
        event.stopPropagation();
    };

    useEffect(() => {
        setLoading(true)
        rest.post('/benevols/listeBenevolesRecapitulatifV2.json', { "onlyMyBenev": manifParams.manifParams.InscriptionWishlistAttributionParResp ? onlyMyBenev : false, "activityFilter": JSON.stringify(activityFilter) })
            .then(function (response) {
                setLoading(false)
                setRows(response.data)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });

    }, [onlyMyBenev, manifParams.manifParams.InscriptionWishlistAttributionParResp, reload, activityFilter])


    const handleChangeMyBenevFilter = (event) => {
        setOnlyMyBenev(event.target.checked);
    };

    const handleValidPlanningConfirm = (event) => {
        setLoading(true)
        rest.post('/benevols/validerPlanning.json', { onlyMyBenev: manifParams.manifParams.InscriptionWishlistAttributionParResp ? onlyMyBenev : false, activityFilter: JSON.stringify(activityFilter) })
            .then(function (response) {
                setLoading(false)
                if (response.data.Message === "Ok") {
                    setShowConfirmPlanning(false)
                    setReload(!reload)
                } else {
                    setSnack({ message: "Une erreur s'esr produite. Veuillez contacter l'administrateur", show: true, severity: "error" })
                    setShowConfirmPlanning(false)
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });
    }

    const handleChangeDispoConfirm = () => {
        let newTranchesTempo = listTrancheUpdate.filter(item => item.isChecked).map(item => item.id);

        setLoading(true)
        let actNum = rowUpdate.Activites.map(function (key) { return key.ID; }).join(",")
        rest.post('/benevols/updateTrancheTempo.json', { actID: actNum, persID: rowUpdate.ID, selectedTranche: JSON.stringify(newTranchesTempo) },)
            .then(function (response) {
                if (response.data.error) {
                    setSnack({ message: "Une erreur s'esr produite. Veuillez contacter l'administrateur", show: true, severity: "error" })
                    setLoading(false)
                } else {
                    setSnack({ message: "Tranches mise à jour avec succès.", show: true, severity: "success" })
                    setShowUpdateDispo(false)
                    setReload(!reload)
                    setLoading(false)
                }

            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });

    }

    function refreshListConcordanceDispoTranche(actNum, row) {
        rest.post('/benevols/concordanceDispoTranche.json', { actID: actNum, persID: row.ID })
            .then(function (response) {
                let selectecTrancheId = row.Activites[0].Tranches.map(function (key) { return key.ID; })

                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    element.isChecked = false
                    if (selectecTrancheId.indexOf(element.id) >= 0) {
                        element.isChecked = true
                    }
                }

                setListTrancheUpdate(response.data)
                row.actNum = actNum
                setRowUpdate(row)

                setCurrentUpdatePerson(row.Firstname + " " + row.Name)
                setShowUpdateDispo(true)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });
    }

    const handleLineSelection = (rowData, rowMeta) => {
        let row = rows[rowMeta.dataIndex]
        if (row.dispoUpdatable) {
            setLoading(true)
            let actNum = row.Activites.map(function (key) { return key.ID; }).join(",")
            refreshListConcordanceDispoTranche(actNum, row)
        }
    };

    const handleAutocompActivity = autocompName => (event, value) => {
        setActivityFilter(value)
    };

    const handleAddTranche = () => {
        setAddTrancheActive(true)

        rest.post('/benevols/dispoTrancheRestante.json', { persID: rowUpdate.ID, actID: parseInt(rowUpdate.actNum) })
            .then(function (response) {
                for (let index = 0; index < response.data.length; index++) {
                    response.data[index].isChecked = false
                }
                setListTrancheToAdd(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });

    }

    const handleAddTrancheValid = () => {
        let addTranchesTempo = listTrancheToAdd.filter(item => item.isChecked).map(item => item.id);

        setLoading(true)
        rest.post('/benevols/addTrancheTempo.json', { actID: rowUpdate.actNum, persID: rowUpdate.ID, selectedTranche: JSON.stringify(addTranchesTempo) },)
            .then(function (response) {
                if (response.data.error) {
                    setSnack({ message: "Une erreur s'est produite. Veuillez contacter l'administrateur", show: true, severity: "error" })
                    setLoading(false)
                } else {
                    setSnack({ message: "Tranches mise à jour avec succès.", show: true, severity: "success" })
                    setAddTrancheActive(false)
                    setLoading(false)
                    setReload(!reload)

                    for (let index = 0; index < listTrancheToAdd.length; index++) {
                        const element = listTrancheToAdd[index];
                        rowUpdate.Activites[0].Tranches.push({ ID: element.id, })
                    }

                    refreshListConcordanceDispoTranche(rowUpdate.actNum, rowUpdate)
                }

            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });

        setAddTrancheActive(false)
    }

    const handleClick = (event, value) => {
        event.preventDefault()
        event.stopPropagation()
        openModal("Confirmation", <ModalConfirmText
            text={"Vous pouvez, ajouter un commentaire "}
            handleConfirm={(commentaire) => handleRefusedBenevole(commentaire, value)} handleAnnul={closeModal} />)
    };

    const handleRefusedBenevole = (commentaire, id) => {
        setLoading(true);
        rest.post('/benevols/refusedFromRecap.json', { personId: id, comment: commentaire },)
            .then(function (response) {
                if (response.data.error) {
                    setSnack({ message: response.data.message, show: true, severity: "error" })
                } else {
                    setSnack({ message: response.data.message, show: true, severity: "success" })
                    setReload((prev) => !prev)
                }

            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });

    }


    const autoCompActivity = {};
    autoCompActivity.url = "/activites/listeAutocomp.json";
    autoCompActivity.autoCompState = activityFilter;
    autoCompActivity.autoCompLabel = manifParams.manifInfos.DenominationAct;
    autoCompActivity.autoCompName = "activityFilter";
    autoCompActivity.multiple = false;
    autoCompActivity.handleAutocompChange = handleAutocompActivity;
    autoCompActivity.delayAndRequest = false;
    autoCompActivity.variant = "filled";

    const customToolbar = () => {

        const onlyMyBenevFilterCheckbox = <FormControlLabel
            key={1}
            control={<Checkbox checked={onlyMyBenev} color="primary" onChange={handleChangeMyBenevFilter} />}
            label="Mes bénévoles uniquement"
            style={{ marginLeft: "10px" }}
        />

        const validatePlanning = () => {
            setShowConfirmPlanning(true)
        }
        const validateBtn = <Tooltip key={2} title={<h3>Valider planning</h3>}>
            <Fab className={classesRecap.iconButton} size="small" color="primary" aria-label="add" onClick={validatePlanning}>
                <AssignmentTurnedInIcon />
            </Fab>
        </Tooltip>

        const exportBilleterie = () => {
            window.open("/export/billeterie/csv")
        }
        const exportBilleterieBtn = <Tooltip key={3} title={<h3>Export billeterie</h3>}>
            <Fab className={classesRecap.iconButton} size="small" color="primary" aria-label="add" onClick={exportBilleterie}>
                <CardMembershipIcon />
            </Fab>
        </Tooltip>

        const filterActivity = <CustomAutocomp params={autoCompActivity} />
        //<ActivitesAutocomplete allActsWishlist={listeActFiltre} filtreActivites={filtreActivites} setFiltreActivites={setFiltreActivites} />


        return (
            <>
                <MuiDatatableToolBar extraBtn={[exportBilleterieBtn, validateBtn, (currentUser.User.User_Administrator && manifParams.manifParams.InscriptionWishlistAttributionParResp) && onlyMyBenevFilterCheckbox, manifParams.manifParams.InscriptionWishlistAttributionParResp && filterActivity]} hideFilter={true} />
            </>
        );
    }

    function trierStatutNumerique(a, b) {
        if (a.StatutNumerique < b.StatutNumerique) {
            return -1;
        }
        if (a.StatutNumerique > b.StatutNumerique) {
            return 1;
        }
        return 0;
    }

    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                download: false,
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Photo",
            name: "ID",
            options: {
                setCellProps: () => ({ style: { minWidth: "100px" } }),
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta) => {
                    const itemId = tableMeta.rowData[0];
                    return (
                        <div>
                            <LazyLoadImage src={`/4DACTION/IMAGE_PROFILE/?person==${value}`}
                                // width={600} height={400}
                                className={classes.imgAvatarList}
                                alt={"pictureMini"}
                            />
                            <InfoIcon
                                color="primary"
                                className={classes.actionBtn}
                                onClick={(e) => { handlePopoverOpen(e, itemId) }}
                            />
                            <Popover
                                open={openedPopoverId === itemId}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                classes={{
                                    paper: classes.paper,
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography><i>Ses infos</i></Typography><br />
                                <Typography>Age {tableMeta.rowData[13]}</Typography>
                                <Typography>Tel {tableMeta.rowData[4]}</Typography>
                                <Typography>Email {tableMeta.rowData[5]}</Typography>
                                <hr style={{ borderTop: '2px dotted grey ' }} />
                                <Typography>Adresse {tableMeta.rowData[15]}</Typography>
                                <Typography>Localité {tableMeta.rowData[16]} {tableMeta.rowData[17]}</Typography>
                            </Popover>
                        </div>
                    );
                },
            }
        },
        {
            label: "Nom",
            name: "Name",
            options: {
                filter: false,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Prénom",
            name: "Firstname",
            options: {
                filter: false,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Tel",
            name: "Phone_Mobile",
            options: {
                filter: false,
                sort: false,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Email",
            name: "Email",
            options: {
                display: 'excluded',
                filter: false,
                sort: false,
            }
        },
        {
            label: "Attribution",
            name: "Activites",
            options: {
                setCellProps: () => ({ style: { minWidth: "200px" } }),
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const groupedPeriods = value.reduce((acc, item) => {
                        item.Tranches.forEach((tranche) => {
                            const dateKey = moment(tranche.DateActivite).format("ddd DD MMM YY");
                            const period = moment.utc(tranche.HeureDebut * 1000).format('HH:mm') + "-" + moment.utc(tranche.HeureFin * 1000).format('HH:mm');

                            if (acc[dateKey]) {
                                acc[dateKey].push(period);
                            } else {
                                acc[dateKey] = [period];
                            }
                        });
                        return acc;
                    }, {});

                    const formattedPeriods = Object.keys(groupedPeriods).map((date) => (
                        `<b>${date}</b><br/>${groupedPeriods[date].map(period => `${period}`).join(', ')}`
                    )).join('<br/>');

                    return <div dangerouslySetInnerHTML={{ __html: formattedPeriods }} />;
                },
            },
        },
        {
            label: "Remarques",
            name: "remarques",
            options: {
                filter: false,
                sort: false,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Commentaire",
            name: "link_PersonTranchesTempo_Person",
            options: {
                filter: false,
                sort: false,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value[0].Commentaire
                },
            }
        },
        {
            label: "Statut",
            name: "link_PersonTranchesTempo_Person",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {

                    const arrayStatutNum = value.sort(trierStatutNumerique);
                    const firstStatut = arrayStatutNum[0];
                    const statutPerson = firstStatut.StatutNumerique
                    ? statutOptions.find(option => option.value === firstStatut.StatutNumerique)?.text
                    : firstStatut.Statut
                    ? statutOptions.find(option => option.correspondance === firstStatut.Statut)?.text
                    : null;
                    return statutPerson;
                    // var statut = "En Attente de validation";
                    // if (arrayStatutNum[0].StatutNumerique === 4) {/*"Modifié par l'admin"*/
                    //     statut = "Modifié par l'admin"
                    // } else if (arrayStatutNum[0].StatutNumerique === 3) {/*"Refuse total"*/
                    //     statut = "Pas cette année"
                    // } else if (arrayStatutNum[0].StatutNumerique === 2) {/*"Refuse"*/
                    //     statut = "Refusé"
                    // } else if (arrayStatutNum[0].StatutNumerique === 1) {/*"Valide"*/
                    //     statut = "Validé"
                    // } else if (arrayStatutNum[0].StatutNumerique === 0) {/*"En attente"*/
                    //     statut = "En attente"
                    // }

                    // return statut
                },
            }
        },
        {
            label: "StatutNum",
            name: "link_PersonTranchesTempo_Person",
            options: {
                display: 'excluded',
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value[0].StatutNumerique
                },
            }
        },
        {
            label: "updated",
            name: "updated",
            options: {
                display: 'excluded',
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                },
            }
        },
        {
            label: "dispoUpdatable",
            name: "dispoUpdatable",
            options: {
                display: 'excluded',
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value
                },
            }
        },
        {
            label: "Age",
            name: "Age",
            options: {
                display: 'excluded',
                filter: false,
                download: true,
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     return value
                // },
            }
        },
        {
            label: "Action",
            name: "ID",
            options: {
                download: false,
                viewColumns: false,
                filter: false,
                display: ((manifParams.manifInfos.Ref === "chi") && (currentUser.User.User_Administrator)),
                customBodyRender: (value) => {
                    return <>
                        <span>
                            <Tooltip key={2} title={<h3>Refuser le bénévole</h3>}>
                                <IconButton aria-label="cancel" size="small" color="secondary" className={classesGlobal.buttonFermer} onClick={(e) => handleClick(e, value)}>
                                    <CancelIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </>
                },
            }
        },
        {
            label: "Address",
            name: "Address",
            options: {
                display: 'excluded',
                filter: false,
                download: false,
            }
        },
        {
            label: "Locality",
            name: "Locality",
            options: {
                display: 'excluded',
                filter: false,
                download: false,
            }
        },
        {
            label: "Zip",
            name: "Zip",
            options: {
                display: 'excluded',
                filter: false,
                download: false,
            }
        },
    ];

    const options = {
        scrollX: true,
        filter: true,
        print: false,
        searchOpen: false,
        download: true,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
        rowsPerPage: rowsPerPage,
        tableBodyMaxHeight: "700px",
        rowsPerPageOptions: [10, 20, 50, 100],
        // pagination:false,
        sortOrder: sortOrder,
        downloadOptions: { filename: "recapAttributions.csv", separator: ';' },
        onDownload: function (buildHead, buildBody, columns, data) {
            let copyData = JSON.parse(JSON.stringify(data));//copy object
            for (let index = 0; index < copyData.length; index++) {

                //attribution
                let formatAttr = ""
                copyData[index].data[6].forEach(function (item) {
                    formatAttr += item.Nom + "\n";
                    item.Tranches.forEach(function (tranche) {
                        formatAttr += "-" + moment(tranche.DateActivite).format("ddd DD MMM YY") + "   " + moment.utc(tranche.HeureDebut * 1000).format('HH:mm') + "-" + moment.utc(tranche.HeureFin * 1000).format('HH:mm') + "\n";
                    })
                })
                copyData[index].data[6] = formatAttr;

                //commentaire
                copyData[index].data[8] = copyData[index].data[8][0].Commentaire
                const arrayStatutNum = copyData[index].data[9].sort(trierStatutNumerique);
                const firstStatut = arrayStatutNum[0];
                const statutPerson = firstStatut.StatutNumerique
                ? statutOptions.find(option => option.value === firstStatut.StatutNumerique)?.text
                : firstStatut.Statut
                ? statutOptions.find(option => option.correspondance === firstStatut.Statut)?.text
                : null;
                
                // var statut = "En Attente de validation";
                // var arrayStatutNum = copyData[index].data[9].sort(trierStatutNumerique);
                // if (arrayStatutNum[0].StatutNumerique === 4) {/*"Modifié par l'admin"*/
                //     statut = "Modifié par l'admin"
                // } else if (arrayStatutNum[0].StatutNumerique === 3) {/*"Refuse total"*/
                //     statut = "Pas cette année"
                // } else if (arrayStatutNum[0].StatutNumerique === 2) {/*"Refuse"*/
                //     statut = "Refusé"
                // } else if (arrayStatutNum[0].StatutNumerique === 1) {/*"Valide"*/
                //     statut = "Validé"
                // } else if (arrayStatutNum[0].StatutNumerique === 0) {/*"En attente"*/
                //     statut = "En attente"
                // }
                copyData[index].data[9] = statutPerson;


            }
            return "\uFEFF" + buildHead(columns) + buildBody(copyData);
        },
        onRowClick: handleLineSelection,
        customToolbar: customToolbar ? customToolbar : null,
        textLabels: {
            "body": {
                "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
                "toolTip": "Trier"
            },
            "pagination": {
                "next": "Page suivante",
                "previous": "Page précédente",
                "rowsPerPage": "Ligne par page:",
                "displayRows": "de",
                "jumpToPage": "Aller à la page:"
            },
            "toolbar": {
                "search": "Rechercher",
                "downloadCsv": "Télécharger CSV",
                "viewColumns": "Afficher les colonnes",
                "filterTable": "Tableau des filtres"
            },
            "filter": {
              "all": "Tous",
              "title": "FILTRES",
              "reset": "RÉINITIALISER"
            },
            "viewColumns": {
                "title": "Afficher les colonnes",
                "titleAria": "Afficher/Masquer les colonnes du tableau"
            },
            "selectedRows": {
                "text": "ligne(s) sélectionnée(s)",
                "delete": "Effacer",
                "deleteAria": "Supprimer les lignes sélectionnées"
            }
        },
        onTableChange: (action, tableState) => {
            //persist table state
            switch (action) {
                case "sort":
                    setSortOrder(tableState.sortOrder)
                    break;
                case "changeRowsPerPage":
                    setRowsPerPage(tableState.rowsPerPage)
                    break;
                case "propsUpdate":
                    tableState.sortOrder = sortOrder
                    tableState.rowsPerPage = rowsPerPage
                    break;
                default:
                    break;
            }
        },
        setRowProps: (row, dataIndex, rowIndex) => {
            return {
                className: clsx({
                    [classesRecap.updatedState]: ((row[9] === -1) && (row[10])),
                    [classesRecap.waitingState]: row[9] === 0,
                    [classesRecap.validState]: row[9] === 1,
                    [classesRecap.refusedState]: row[9] === 2,
                    [classesRecap.nextYearState]: row[9] === 3,
                    [classesRecap.modifState]: row[9] === 4,
                    [classesRecap.alignRow]: true,
                    [classesRecap.selectableRow]: row[11],
                }),
            };
        },
    };


    return (
        <>
            <CssBaseline />
            <LoadingIndicator open={loading} />
            <MySnackbar params={snack} />
            <MUIDataTable
                title={<h1>Récapitulatif des attributions</h1>}
                data={rows}
                columns={columns}
                options={options}
            />
            {/* dialog validation planning */}
            <Dialog
                open={showConfirmPlanning}
                onClose={_ => setShowConfirmPlanning(false)}
            >
                <DialogTitle>{"Validation du planning"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir valider le planning ?
                        Si tel est le cas, un mail partira à chaque bénévole concerné.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleValidPlanningConfirm} color="secondary" className={classesRecap.buttonFermer}>
                        Valider le(s) planning
                    </Button>
                    <Button variant="contained" onClick={_ => setShowConfirmPlanning(false)} color="default" autoFocus>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
            {/* dialog change dispo */}
            <Dialog
                open={showUpdateDispo}
                onClose={_ => setShowUpdateDispo(false)}
            >
                {addTrancheActive ?
                    <>
                        <DialogTitle>{"Ajout tranches horaires d'attribution pour "}{currentUpdatePerson}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {listTrancheToAdd && listTrancheToAdd.map((row, index) => (
                                    <TrancheCheckbox key={row.id} row={row} />
                                ))}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleAddTrancheValid} color="primary">
                                Valider
                            </Button>
                            <Button variant="contained" onClick={_ => setAddTrancheActive(false)} color="default" autoFocus>
                                Annuler
                            </Button>
                        </DialogActions>
                    </>
                    :
                    <>
                        <DialogTitle>{"Modification tranches horaires d'attribution pour "}{currentUpdatePerson}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {listTrancheUpdate && listTrancheUpdate.map((row, index) => (
                                    <TrancheCheckbox key={row.id} row={row} />
                                ))}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleAddTranche} color="secondary">
                                Ajouter tranche
                            </Button>
                            <Button variant="contained" onClick={handleChangeDispoConfirm} color="primary">
                                Modifier
                            </Button>
                            <Button variant="contained" onClick={_ => setShowUpdateDispo(false)} color="default" autoFocus>
                                Annuler
                            </Button>
                        </DialogActions>
                    </>}
            </Dialog>

            {/* <div dangerouslySetInnerHTML={{ __html: "<style>.MuiTableRow-root:hover{ background: initial !important }" }}></div>  */}
        </>
    );
}


export default RecapAttributions

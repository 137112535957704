import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormLabel, Grid, Popover, Radio, RadioGroup, TextField, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import rest from "../../rest";
import React, { useEffect, useState } from 'react';
import MuiDatatableToolBar from '../muiDatatableToolBar/MuiDatatableToolBar';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStylesGlobal } from '../../pages/GlobalStyle.css';
import TypeTailleMateriel from './TypeTailleMateriel';

const OngletGestionMateriel = () => {
    const classesGlobal = useStylesGlobal();
    const [listMateriel, setListMateriel] = useState([]);
    const [open, setOpen] = useState(false);
    const [materiel, setMateriel] = useState("");
    const [idMateriel, setIdMateriel] = useState(0);
    const [showAjout, setShowAjout] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [radioARendre, setRadioARendre] = useState(null);
    const [radioStock, setRadioStock] = useState(null);
    const [radioChoixMultiple, setRadioChoixMultiple] = useState(null);
    const [typeMateriel, setTypeMateriel] = useState([]);
    const [tailleMateriel, setTailleMateriel] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);


    // La liste des caracteristiques
    useEffect(() => {
        rest.get('/materiel/liste.json').then(function (response) {
            setListMateriel(response.data)
        })
    }, [open])

    useEffect(() => {
        if (open) {
            modificationMateriel()
        }
          // eslint-disable-next-line
    }, [idMateriel])
    //  Confirmation de la modification
    const modificationMateriel = () => {
        let params = {
            vId: idMateriel,
        }
        rest.post('/materiel/typeMateriel.json', params).then(function (response) {
            setRadioARendre(response.data.aRendre)
            setRadioStock(response.data.gestionStock)
            setRadioChoixMultiple(response.data.materielMultiple)
            setTypeMateriel(response.data.Couleur)
            setTailleMateriel(response.data.Taille)

        })
        setOpen(false)
    }
    //  Confirmation de l'ajout
    const ajouterMateriel = () => {
        let params = {
            vId: null,
            vMateriel: materiel,
            vAction: "ajouter",
            vMatARendre: radioARendre,
            vMatGestStock: radioStock,
            vMatMultiple: radioChoixMultiple,
        }
        rest.post('/materiel/saveMateriel.json', params).then(function (response) {
            setOpen(false)
        })


    }
    // Au  moment de la selection de la ligne
    const handleLineSelection = (rowData, rowMeta) => {
        let materiel = listMateriel[rowMeta.dataIndex]
        setIdMateriel(materiel.id)
        setMateriel(materiel.name)
        setOpen(true)
        setShowAjout(false)
    };
    //  changement de donnée
    const handleCaract = (e) => {
        setMateriel(e.target.value)
    }
    const handleChangeMateriel = (e) => {
        setRadioARendre(prev => !prev)
    }
    const handleChangeStock = (e) => {
        setRadioStock(prev => !prev)
    }
    const handleChangeChoixMultiple = (e) => {
        setRadioChoixMultiple(prev => !prev)
    }
    //  Autre 
    const handleClose = () => {
        setMateriel("");
        setTypeMateriel("")
        setTailleMateriel("")
        setRadioChoixMultiple(null)
        setRadioARendre(null)
        setRadioStock(null)
        setOpen(false);
    };
    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };
    function handleClick(event, id) {
        setOpen(false)
        setShowDelete(true)
        setIdMateriel(id)
        setAnchorEl(event.currentTarget);

    };
    const handleDelete = () => {
        var params = {
            'vIdMat': idMateriel,
        }
        rest.post('/materiel/deleteMateriel.json', params).then(function (response) {

        })
    };


    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Action",
            name: "nbPersonLiee",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        (value === 0) &&
                        <span>
                            <Button onClick={(e) => { handleClick(e, tableMeta.rowData[0]) }}><DeleteIcon color="error" /></Button>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleCloseAnchor}
                                className="popover_class"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Button variant="contained" className={classesGlobal.buttonFermer} onClick={_ => { handleDelete() }}>Confirmer désinscription</Button>
                            </Popover>
                        </span>
                    );
                },
            }
        },
    ]

    const options = {
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
        onRowClick: handleLineSelection,
        customToolbar: () => {
            const handleOpenModalAddAct = () => {
                setOpen(true)
                setShowAjout(true)
            };

            const element = <Tooltip title={"Ajouter un matériel"}>
                <Fab size="small" color="secondary" aria-label="add" onClick={handleOpenModalAddAct}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            return (
                <MuiDatatableToolBar extraBtn={element} hideFilter={true} />
            );
        },
        textLabels: {
            "body": {
                "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
                "toolTip": "Trier"
            },
            "pagination": {
                "next": "Page suivante",
                "previous": "Page précédente",
                "rowsPerPage": "Ligne par page:",
                "displayRows": "de",
                "jumpToPage": "Aller à la page:"
            },
            "toolbar": {
                "search": "Rechercher",
                "downloadCsv": "Télécharger CSV",
                "viewColumns": "Afficher les colonnes",
                "filterTable": "Tableau des filtres"
            },
            "viewColumns": {
                "title": "Afficher les colonnes",
                "titleAria": "Afficher/Masquer les colonnes du tableau"
            },
            "selectedRows": {
                "text": "ligne(s) sélectionnée(s)",
                "delete": "Effacer",
                "deleteAria": "Supprimer les lignes sélectionnées"
            }
        },
    };

    return (
        <>
            <MUIDataTable
                data={listMateriel}
                columns={columns}
                options={options} />

            {!showDelete && 
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'} fullWidth>
                {!showAjout ? <DialogTitle id="form-dialog-title">Modification Materiel</DialogTitle>
                    :
                    <DialogTitle id="form-dialog-title">Nouveau Materiel</DialogTitle>}
                <DialogContent >
                    <TextField
                        autoFocus
                        margin="dense"
                        value={materiel}
                        onChange={handleCaract}
                        label="Nom materiel"
                        fullWidth
                    />
                    <br/>
                    <br/>
                    <Grid container xs={12}>
                        <Grid item xs={4}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Materiel à rendre ?</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={radioARendre} onChange={handleChangeMateriel}>
                                    <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                    <FormControlLabel value={false} control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Gérer dans le stock ?</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={radioStock} onChange={handleChangeStock}>
                                    <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                    <FormControlLabel value={false} control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Choix multiple ?</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={radioChoixMultiple} onChange={handleChangeChoixMultiple}>
                                    <Grid item xs={6}>
                                        <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControlLabel value={false} control={<Radio />} label="Non" />
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br/>
                    { typeMateriel && tailleMateriel && <TypeTailleMateriel typeMateriel={typeMateriel} tailleMateriel={tailleMateriel} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classesGlobal.buttonFermer}>
                        Femer
                    </Button>
                    {!showAjout ?
                        <Button onClick={_ => { modificationMateriel() }} color="primary" variant='contained'>
                            Modifer
                        </Button>
                        :
                        <Button onClick={_ => { ajouterMateriel() }} color="secondary" variant='contained'>
                            Ajouter
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            }
        </>
    );
};

export default OngletGestionMateriel;
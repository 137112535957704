import { createContext, useContext, useState } from 'react'
import {Box, IconButton, Modal, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const ModalContext = createContext({})

export const useModal = () => useContext(ModalContext)

export const ModalProvider = ({ children }) => {
    const [modalContent, setModalContent] = useState(null)
    const [title, setTitle] = useState("")

    const isOpen = Boolean(modalContent)

    const openModal = (title = "", content) => {setTitle(title);setModalContent(content)}

    const closeModal = () => {setModalContent(null); setTitle("")}

    return (
        <ModalContext.Provider value={{ openModal, closeModal,  isOpen}}>
            {children}
            <Modal
                onClose={closeModal}
                open={isOpen}
            >
                <Box sx={{position: "absolute", top:"50%", left: "50%", maxWidth: "80%", transform: 'translate(-50%,-50%)'}}>
                    <Box sx={{bgcolor: "white", width: "100%"}}>
                        <Box sx={{bgcolor: "primary.main", paddingX: "10px", paddingY: "4px", justifyContent:"space-between", display: "flex", alignItems: "center"}}>
                            <Typography component="h4" style={{color:"white"}}>{title}</Typography>
                            <IconButton onClick={closeModal}>
                                <CloseIcon style={{color: "white"}} />
                            </IconButton>
                        </Box>
                        <Box sx={{ padding: 10}}>{modalContent}</Box>
                    </Box>
                </Box>
            </Modal>
        </ModalContext.Provider>
    )
}
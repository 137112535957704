import React from 'react'
import { useStyles } from "./Notes.css";
import { Alert } from '@material-ui/lab';
import { Button, Grid, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

const Notes = (props) => {
  const classes = useStyles();
  // const displayedDates = props.displayedDates;
  const displayDateTxt = props.displayDateTxt;
  const notesTH = props.notesTH;
  const setNotesTH = props.setNotesTH;
  const notesGeneral = props.notesGeneral;
  const setNotesGenerale = props.setNotesGenerale;
  const manifInfo = props.manifInfo;

  const showPrevious = () =>{
    props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
  const showNext = () =>{
    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleChange = (event) => {
    var textfieldID = event.target.id;
    if(textfieldID === "vNoteTH"){
      setNotesTH(event.target.value)
    } else if(textfieldID === "vNoteGlobal"){
      setNotesGenerale(event.target.value)
    }
  };

  return (
    <React.Fragment>
        <main className={classes.content}>
          {manifInfo.TexteRemarque !== "" &&
            <Grid container className={classes.marginGrid}>
                <Grid item xs={12} >
                    <Alert severity="warning">
                      <div dangerouslySetInnerHTML={{ __html: manifInfo.TexteRemarque }} />
                    </Alert>
                </Grid>
            </Grid>
          }
          <Grid container>
            <Grid item xs={12} sm={7}>
              <br/>
                <TextField
                    id="vNoteTH"
                    label="Remarques pour les tranches horaires sélectionnées"
                    multiline
                    maxRows={10}
                    variant="outlined"
                    value={notesTH}
                    onChange={handleChange}
                    fullWidth
                />
                <br/><br/>
                <TextField
                    id="vNoteGlobal"
                    label="Remarques générales"
                    multiline
                    maxRows={10}
                    variant="outlined"
                    value={notesGeneral}
                    onChange={handleChange}
                    fullWidth
                />                
            </Grid>
            <Grid item xs={false} sm={1}></Grid>
            <Grid item xs={12} sm={3} >
              <h2 className={classes.titreDatesSelect} >Dates sélectionnées</h2> 
            {displayDateTxt.map((key,value)=>{
                return <div key={key.date}>
                          <h2 className={classes.marginDate}>{key.date}</h2>
                          <p className={classes.marginPeriode}>{key.periode}</p>
                      </div>
              })}
              <br/><br/>
              <Grid container>
                  <Grid item sm={2}>
                    <Button size="small" fullWidth variant="contained" color="default"  onClick={showPrevious}>
                      Retour
                    </Button>
                  </Grid>
                  <Grid item sm={1}></Grid>
                  <Grid item sm={9}>
                    <Button size="small" fullWidth variant="contained" color="secondary"  onClick={showNext}>
                      Choisir les activités
                    </Button>
                  </Grid>
                </Grid>
            </Grid> 
        </Grid> 
        </main>
     </React.Fragment>
  )
}


const mapStateToProps = (state) => {
    return {
      manif: state.manif,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({ }, dispatch)
  };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(Notes)


import React, { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
    calculateTotalDataPerManifestation, convertToManifChart, calculateActivityStats,
    sortActivityStatsByValues, convertToChartValue, getDataForTableActivity,
    filterDataByNomManif, filterDataByNomActivite, filterDataByGenre, getManifName, getActivityName,
    getGender, getIntervalDaysInscription, transformDataForBarChart, //getTShirtSize
} from './components/Fonctions/fonctions.js';
import rest from '../../rest';
// import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SearchIcon from '@material-ui/icons/Search';
import './assets/css/generalStyle.css'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GroupIcon from '@material-ui/icons/Group';
import CachedIcon from '@material-ui/icons/Cached';


//  import { Doughnut } from 'react-chartjs-2';

// creativeTime components
import Card from "./components/Card/Card.js";
import CardHeader from "./components/Card/CardHeader.js";
import CardIcon from "./components/Card/CardIcon.js";
import CardBody from "./components/Card/CardBody.js";
import CardFooter from "./components/Card/CardFooter.js";
import styles from "./assets/jss/material-dashboard-react/views/dashboardStyle.js";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LineChart from './components/Chart/line.js';
import BarChart from './components/Chart/bar.js';
import PieChart from './components/Chart/pie.js';
import AppContext from '../../components/appContext.js';
import CustomTable from './components/Table/Table.js';
import FilterComponent from './components/filter/filter.js';

// import "./assets/css/material-dashboard-react.css?v=1.10.0";
import { Accessibility } from '@material-ui/icons';

const useStyles2 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#dcdcde",
        display: 'flex',
        minHeight: window.innerHeight - 300,
    },
    paperStepper: {
        padding: theme.spacing(2),
    },
}));

// Debut fonction 
export default function Statistiques() {
    const [showFilters, setShowFilters] = useState(false);

    const myContext = useContext(AppContext);

    const sortTypeActivity = [
        { label: 'Bénévoles', column: 'totalVolunteers', sortOrder: 'desc' },
        { label: 'Heures', column: 'totalHours', sortOrder: 'desc' },
        { label: 'Réinscriptions', column: 'reinscription', sortOrder: 'desc' },
        { label: 'Activité', column: 'activityName', sortOrder: 'asc' }
    ];
    const [sortActivity, setSortActivity] = useState(sortTypeActivity[0]);

    const classes = useStyles();
    const classes2 = useStyles2();
    const [loading, setLoading] = useState(false);
    const [manifChartData, setManifChartData] = useState([]);
    const [manifStatConvert, setManifStatConvert] = useState({ datasets: [], labels: [] });
    const [activityChartData, setActivityChartData] = useState({ datasets: [], labels: [] });
    const [activityData, setActivityData] = useState({});
    const [dataFetched, setDataFetched] = useState(false);
    const [data, setData] = useState([]);
    const [manifName, setManifName] = useState([]);
    const [activityName, setActivityName] = useState([]);
    const [dateInscriptionData, setDateInscriptionData] = useState({});
    const [resEdition, setResEdition] = useState([]);

    const [activityTableData, setActivityfTabletData] = useState({ tableHead: [], tableData: [] });

    const [selectedManifestations, setSelectedManifestations] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [selectedGender, setSelectedGender] = useState([]);




    const [gender, setGender] = useState([]);
    const [genderOriginal, setGenderOriginal] = useState([]);

    const handleFilterManif = (item) => {
        setSelectedManifestations(item);
    };
    const handleFilterActivity = (item) => {
        setSelectedActivities(item);
    };
    const handleFilterGender = (item) => {
        setSelectedGender(item);
    };


    const handleSortTable = (columnName, sortDirection) => {
        const sortColumn = sortTypeActivity.find((sortType) => sortType.label === columnName);
        setSortActivity(sortColumn);
    };

    const getAllInscriptions = async () => {
        setLoading(true);
        let lastTime = localStorage.getItem('timeLastFetch');
        let CurrentTime = Math.floor(new Date().getTime() / 1000);
        if (lastTime && (Number(lastTime) + (6 * 60 * 60) > CurrentTime)) {
            setManifName(JSON.parse(localStorage.getItem('allManiName')));
            setSelectedManifestations(JSON.parse(localStorage.getItem('allManiName')));
            setActivityName(JSON.parse(localStorage.getItem('allActivityName')));
            setSelectedActivities(JSON.parse(localStorage.getItem('allActivityName')));
            setGenderOriginal(JSON.parse(localStorage.getItem('allGender')));
            setSelectedGender(JSON.parse(localStorage.getItem('allGender')));
            setData(JSON.parse(localStorage.getItem('statData')));
            setDataFetched(true);
            setLoading(false);
            setResEdition(JSON.parse(localStorage.getItem('edition')));
            return;
        }
        try {
            const resEdition = await rest.get('/statsReact/allEdition.json');
            const response = await rest.get('/statsReact/allInscriptions.json');
            setResEdition(resEdition.data);
            // creer checkbox
            const allManiName = getManifName(response.data);
            setManifName(allManiName);
            setSelectedManifestations(allManiName);
            const allActivityName = getActivityName(response.data);
            setActivityName(allActivityName);
            setSelectedActivities(allActivityName);
            const allGender = Object.keys(getGender(response.data)).sort();
            setGenderOriginal(allGender);
            setSelectedGender(allGender);
            let time = Math.floor(new Date().getTime() / 1000);
            localStorage.setItem('timeLastFetch', time.toString());
            localStorage.setItem('allManiName', JSON.stringify(allManiName));
            localStorage.setItem('allActivityName', JSON.stringify(allActivityName));
            localStorage.setItem('allGender', JSON.stringify(allGender));
            localStorage.setItem('statData', JSON.stringify(response.data));
            localStorage.setItem('edition', JSON.stringify(resEdition.data));
            // ===

            // let sizeTShirt = getTShirtSize(response.data);

            setData(response.data);
            setDataFetched(true);

            setLoading(false);
        } catch (error) {
            if (error.response) {
                console.error('Erreur lors de la récupération des données Status:', error.response.status);
                console.error('Data:', error.response.data);
            } else console.error('Erreur lors de la récupération des données:', error);
            setLoading(false);
        }
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    useEffect(() => {
        myContext.setOpenSidebar(false);
        if (!dataFetched) getAllInscriptions();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data.length === 0) return;
        let dataFiltered = filterDataByNomManif(data, selectedManifestations);
        dataFiltered = filterDataByNomActivite(dataFiltered, selectedActivities);
        dataFiltered = filterDataByGenre(dataFiltered, selectedGender);

        const extractedChartDataParManif = calculateTotalDataPerManifestation(dataFiltered);
        setManifChartData(extractedChartDataParManif);
        let activityStats = calculateActivityStats(dataFiltered);
        setActivityData(activityStats);
        let gender = getGender(dataFiltered)
        setGender(gender);

        setManifStatConvert(convertToManifChart(extractedChartDataParManif));

        let activityStatsSort = sortActivityStatsByValues(activityStats, sortActivity.column, sortActivity.sortOrder);
        let activityStatsConvert = convertToChartValue(activityStatsSort);
        setActivityChartData(activityStatsConvert);
        setActivityfTabletData(getDataForTableActivity(sortActivityStatsByValues(activityStats, sortActivity.column, sortActivity.sortOrder)));

        setDateInscriptionData(transformDataForBarChart(getIntervalDaysInscription(dataFiltered, resEdition)));

    }, [data, selectedManifestations, selectedActivities, sortActivity, selectedGender, resEdition]);


    // {/* <MySnackbar params={snack} /> */}
    return (
        <div>
            <LoadingIndicator open={loading} />
            <Button
                className={`search-button ${showFilters ? 'open' : 'closed'}`}
                onClick={toggleFilters}
                variant="contained"
                color="primary"
            >
                <SearchIcon className="search-icon" />
                {!showFilters && <ExpandMoreIcon className="search-icon" />}
                {showFilters && <ExpandLessIcon className="search-icon" />}

            </Button>
            <div className={`filters-container ${showFilters ? 'open' : 'closed'}`}>
                <Grid container className='boxGride'>
                    <Grid item xs={12} sm={6} md={4}>
                        {manifName?.length > 0 && <FilterComponent data={manifName} selectedItems={selectedManifestations} onChange={handleFilterManif} name='Manifestations' />}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {activityName?.length && <FilterComponent data={activityName} selectedItems={selectedActivities} onChange={handleFilterActivity} name='Activités' />}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {genderOriginal?.length && <FilterComponent data={genderOriginal} selectedItems={selectedGender} onChange={handleFilterGender} name='Genre(s)' />}
                    </Grid>
                </Grid>
            </div>
            <Grid container>
                <Grid item sm={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="warning" stats icon>
                                    <CardIcon color="warning">
                                        <GroupIcon />
                                    </CardIcon>
                                    <p className={classes2.cardCategory}>Manifestations</p>
                                    <h3 className={classes2.cardTitle}>
                                        {manifChartData.length} <small style={{ color: "#51AB55" }}>Total</small><br />
                                        <small></small><span style={{ color: "#E94340" }}>{manifStatConvert.labels.length}</span> <small>première(s) manifestation(s) affichée(s)</small>
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.cardCategory}>
                                        <BarChart
                                            labels={manifStatConvert.labels}
                                            data={[
                                                { label: "Nombre d'heure", values: manifStatConvert.totalHoursValues },
                                                { label: "Nombre de bénévole", values: manifStatConvert.totalVolunteersValues },
                                                { label: "Nombre de réinscription", values: manifStatConvert.totalReinscriptionsValues },
                                            ]}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="info" stats icon>
                                    <CardIcon color="info">
                                        <AccountTreeIcon />
                                    </CardIcon>
                                    <p className={classes2.cardCategory}>Activités ordonnées par {sortActivity.label}</p>
                                    <h3 className={classes2.cardTitle}>
                                        {Object.keys(activityData).length} <small style={{ color: "#51AB55" }}>Total</small><br />
                                        <small></small> <span style={{ color: "#E94340" }}>{activityChartData.labels.length}</span> <small>première(s) activité(s) affichée(s)</small>
                                    </h3>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.cardCategory}>
                                        <LineChart
                                            labels={activityChartData.labels}
                                            data={[
                                                { label: "Nombre d'heure", values: activityChartData.totalActivityHoursValues },
                                                { label: "Nombre de bénévole", values: activityChartData.totalActivityVolunteersValues },
                                                { label: "Nombre de réinscriptione", values: activityChartData.totalActivityReinscriptionValues },
                                            ]}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card>
                                <CardHeader color="success" stats icon>
                                    <CardIcon color="success">
                                        {sortActivity.label === sortTypeActivity[0].label && <Accessibility />}
                                        {sortActivity.label === sortTypeActivity[1].label && <AccessTimeIcon />}
                                        {sortActivity.label === sortTypeActivity[2].label && <CachedIcon />}
                                        {sortActivity.label === sortTypeActivity[3].label && <AccountTreeIcon />}
                                    </CardIcon>
                                    <p className={classes2.cardCategory}>Activités ordonnées par {sortActivity.label}</p>
                                    <h3 className={classes2.cardTitle}>{Object.keys(activityData).length} <small style={{ color: "#51AB55" }}>Total</small></h3>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.cardCategory}>
                                        <CustomTable tableHead={activityTableData.tableHead} tableData={activityTableData.tableData || []} onSortColumn={handleSortTable} exept={['N°']} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card chart>
                                <CardHeader color="success">
                                    Bénévole genre(s)
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.cardCategory}>
                                        <span className={classes.successText}>
                                            {gender && <PieChart
                                                labels={Object.keys(gender)}
                                                datasets={Object.values(gender)} />}
                                        </span>{" "}
                                    </div>
                                </CardBody>
                                <CardFooter chart>
                                    <div className={classes.stats}>
                                        {/* <AccessTime /> updated 4 minutes ago */}
                                    </div>
                                </CardFooter>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="warning" stats icon>
                                    <CardIcon color="warning">
                                        <AccessTimeIcon />
                                    </CardIcon>
                                    <p className={classes2.cardCategory}>Inscription</p>
                                    <p className={classes2.cardCategory}>Sélection des 30 jours avec la plus forte affluence</p>
                                </CardHeader>
                                <CardBody>
                                    <div className={classes.cardCategory}>
                                        {Object.keys(dateInscriptionData).length > 0 && <BarChart
                                            labels={dateInscriptionData.labels}
                                            data={dateInscriptionData.data}
                                        />}
                                    </div>
                                </CardBody>
                                <CardFooter chart>
                                    <div className='footerCard'>
                                        Nombre d'inscription par jour entre l'inscription et l'événement
                                    </div>
                                </CardFooter>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
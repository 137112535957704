import React, { useState, useEffect } from 'react';
import { useStyleFiltre } from './FiltreBenevole.css';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import rest from '../../rest';
import DataTable from '../dataTable/DataTable';
import { useSelector } from "react-redux";
import AssessmentIcon from '@material-ui/icons/Assessment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: "#f5f5f5",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      marginTop: 40,
      width: 350,
    },
  },
};


// Debut fonction 
export default function FiltreBenevole({ selectedResponsable }) {
  const classesFiltre = useStyleFiltre();
  const currentUser = useSelector(state => state.user);
  const [filter, setFilter] = useState({
    vFiltreDate: [],
    vFiltreAct: [],
    vFiltreSousAct: [],
    vIdResp: selectedResponsable > 0 ? selectedResponsable : currentUser.User.User_Person_ID
  });
  // Hooks pour le filtrage date manifestation
  const [dateManif, setDateManif] = useState([]);
  // Hooks pour le filtrage activite
  const [activite, setActivite] = useState([]);
  //  Hook pour le filtrage sous-activite
  const [sousActivite, setSousActivite] = useState([]);


  const handleChange = (event) => {
    setFilter({ ...filter, "vFiltreDate": event.target.value });
  };
  const handleChangeActivite = (event) => {
    setFilter({ ...filter, "vFiltreAct": event.target.value });
  };
  const handleChangeSousActivite = (event) => {
    setFilter({ ...filter, "vFiltreSousAct": event.target.value });
  };

  
  useEffect(() => {
    if (selectedResponsable !== 0) {
      if (filter.vIdResp !== selectedResponsable) {
        setFilter({ ...filter, "vIdResp": selectedResponsable });
      }
      
    }
  }, [selectedResponsable, filter])

   // UseEffect
   useEffect(() => {
    dataManifestation();
    dataActivite();
    dataSousActivite();
  }, [])

  
  // Valeur Requete Date Manifestation
  const dataManifestation = () => {
    rest.get('/activites/recuperationDatesDispo.json')
      .then((response) => {
        setDateManif(response.data.DateDispo)
      })
  }

  // Valeur Requete Activite 
  const dataActivite = () => {
    rest.get('/activites/listeActivitesResponsable.json?vType=activites',//?vType=activites 
    )
      .then((response) => {
        setActivite(response.data)
      })
  }
  
  // Valeur Requete  Sous-Activite
  const dataSousActivite = () => {
    rest.get('/activites/listeActivitesResponsable.json',
    )
      .then((response) => {
        setSousActivite(response.data)

      })
  }

  const handleFicheOccupation = () => {
    let resp = (selectedResponsable > 0 ? selectedResponsable : currentUser.User.User_Person_ID);
    window.open("/export/fichesOccupation?vResp=true&idResp=" + resp);
  }

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
      >

        {/* FORM POUR LE FILTRAGE DES DATE DE MANIFESTATION */}
        <Grid item xs={12} md={3}>
          <FormControl className={classesFiltre.formControl} >

            <InputLabel id="demo-mutiple-chip-label">Date manif.</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={filter.vFiltreDate}
              onChange={handleChange}
              input={<Input id="select-multiple-chip" />}
              MenuProps={MenuProps}
            >
              {dateManif.map((item) => (
                <MenuItem key={item} value={item} MenuProps={MenuProps}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* FORM POUR LE FILTRAGE DES ACTIVITES*/}
          <FormControl className={classesFiltre.formControl}>
            <InputLabel id="demo-mutiple-chip-label" style={{ color: "" }}>Activité</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={filter.vFiltreAct}
              onChange={handleChangeActivite}
              input={<Input id="select-multiple-chip" />}
              MenuProps={MenuProps}
            >
              {activite.map((item) => (
                <MenuItem key={item.ID} value={item.ID} >
                  {item.Nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* FORM POUR LE FILTRAGE DES SOUS-ACTIVITES */}
        <Grid item xs={12} md={4}>
          <FormControl className={classesFiltre.formControl}>
            <InputLabel id="demo-mutiple-chip-label">Sous-Activité</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={filter.vFiltreSousAct}
              onChange={handleChangeSousActivite}
              input={<Input id="select-multiple-chip" />}
              MenuProps={MenuProps}
            >
              {sousActivite.map((item) => (
                <MenuItem key={item.ID} value={item.ID} >
                  {item.Nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <Fab color="primary"onClick={handleFicheOccupation} size="small" title="Fiche Occupations" component="span">
            <AssessmentIcon />
          </Fab>
        </Grid>
      </Grid>

      <DataTable filter={filter} readOnly={true} nom={"Mes bénévoles"} />
    </>
  );
}

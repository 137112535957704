import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyleListe } from './liste.css';
import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import PanelTranche from './panelTranches'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}



export default function ListeActivitesBenevoles(props) {
  const classesListe = useStyleListe();
  const [value, setValue] = useState(0);
  const listesActJour = props.listesActJour;
  const datesSelected = props.datesSelected;

  useEffect(() => {
    setValue(0);
  }, [listesActJour])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let cpt = -1;
  let cpt2 = -1;
  return (
    <div className={classesListe.rootLstBenevoles}>
      {(listesActJour && listesActJour.length > 0) ?
        <>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
              {listesActJour.map((item, index) => {
                cpt++
                return [
                  <Tab key={index} label={item.Nom} {...a11yProps(cpt)} />
                ]
              })}
            </Tabs>
          </AppBar>
          {listesActJour.map((item, index) => {
            cpt2++
            return [
              <TabPanel key={cpt2} value={value} index={cpt2}>
                <PanelTranche idAct={item.ID} datesSelected={datesSelected} />
              </TabPanel>
            ]
          })}
        </>
        :
        <Grid container style={{ height: '80vh' }}>
          <Grid item xs={12} md={12}>
            <h4>Vous n'êtes pas responsable d'une activité pour ce jour</h4>
          </Grid>
        </Grid>
      }
    </div>
  );
}
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleNotFound = makeStyles((theme) => createStyles({
    paperClass: {
        padding: theme.spacing(4),
        height: "70vh"
    },
    customIcon: {
        fontSize: "15rem",
    },
}));
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Step, StepLabel, Stepper, TextField } from '@material-ui/core';
import rest from "../../rest";
import { MySnackbar } from '../mySnackbar/MySnackbar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SimpleUploader from '../fileUploader/SimpleUploader'
import { HighlightOff } from '@material-ui/icons';
import { ColorPicker } from 'material-ui-color';
import { Alert } from '@material-ui/lab';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';

//DATEPICKER AND UTILS
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: window.innerHeight-300,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: "200px",
    },
    customTab: {
        fontWeight: "normal",
        '&:focus': {
            fontWeight: "bold",
        },
        '&:hover': {
            fontWeight: "bold",
        },
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    rootStepper: {
        width: '100%',
    },
    paperStepper: {
        padding: theme.spacing(2),
    }
}));

function getSteps() {
    return ['Nouvelle édition', 'Informations complémentaires', 'Baasculement des données','Finalité'];
}

function getStepContent(stepIndex, classes, newEdition, setNewEdition, newEditionError) {
    switch (stepIndex) {
        case 0:
            return (
                <Paper elevation={3} className={classes.paperStepper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                id="editionTitle"
                                name="title"
                                label="Titre de la nouvelle édition"
                                type="text"
                                required
                                fullWidth
                                value={newEdition.title}
                                onChange={(e) => {    
                                    setNewEdition(prevState => ({
                                        ...prevState,
                                        [e.target.name]: e.target.value
                                    }));
                                }}
                                error={newEditionError.title}
                                helperText={newEditionError.title ? "Champ obligatoire" : ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="editionName"
                                name="name"
                                label="Edition"
                                type="text"
                                required
                                fullWidth
                                value={newEdition.name}
                                onChange={(e) => {
                                    setNewEdition(prevState => ({
                                        ...prevState,
                                        [e.target.name]: e.target.value
                                    }));
                                }}
                                error={newEditionError.name}
                                helperText={ newEditionError.name ? "Champ obligatoire" : "" }
                            />
                        </Grid>
                    </Grid>
                </Paper>);
        case 1:
            return (
                <Paper elevation={3} className={classes.paperStepper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                <DatePicker
                                    // inputFormat="dd.MM.yyyy"
                                    okLabel="OK"
                                    clearLabel="Effacer"
                                    cancelLabel="Annuler"
                                    autoOk={true}
                                    openTo="date"
                                    format="dd.MM.yyyy"
                                    label="Date de début de la nouvelle édition*"
                                    views={["year", "month", "date"]}
                                    value={newEdition.date}
                                    fullWidth
                                    onChange={(newValue) => {
                                        setNewEdition(prevState => ({
                                            ...prevState,
                                            "date": newValue
                                        }));
                                    }}
                                    error={newEditionError.date}
                                    helperText={newEditionError.date ? "Champ obligatoire" : ""}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <div dangerouslySetInnerHTML={{
                                __html: "<strong>Remarque</strong>: il ne s'agit pas de la date de la première acticité, mais du premier jour de la manifestation.<br>Si la manifestation se déroule que sur un jour, veuillez renseigner cette date.<br>Si la manifestation dure plusieurs jours, veuillez renseigner le premier jour de la manifestation"}}/>
                        </Grid>
                    </Grid>
                </Paper>);
        case 2:
            return (
                <Paper elevation={3} className={classes.paperStepper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Alert severity="error" style={{ fontWeight: "bold" }}>En cliquant sur 'Je comprends' vous allez démarrer le processus de basculement.
                                Cette action est définitive et irréversible!</Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl required error={newEditionError.agree} component="fieldset">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={newEdition.agree}
                                            onChange={(e) => {
                                                setNewEdition(prevState => ({
                                                    ...prevState,
                                                    "agree": e.target.checked
                                                }));
                                            }}
                                            color="primary" 
                                        />
                                        }
                                    label="Je comprends que je ne pourrais pas revenir en arrière !"
                                    labelPlacement="end"
                                />
                                {newEditionError.agree && <FormHelperText>Merci de lire le message et d'accepter</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            );
        case 3:
            return (
                <Paper elevation={3} className={classes.paperStepper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Alert severity="success" style={{ fontWeight: "bold" }}>
                                <div dangerouslySetInnerHTML={{ __html: "Nouvelle édition activée<br><br><strong>" + newEdition.title +"</strong> - "+newEdition.name+"<br>Date de l'évènement : "+newEdition.date}}></div>
                            </Alert>
                        </Grid>
                    </Grid>
                </Paper>
            );
        default:
            return 'Unknown stepIndex';
    }
}

const OngletParamGeneraux = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [paramsGeneral, setParamsGeneral] = useState({});
    const [snack, setSnack] = useState({});
    const [loading, setLoading] = useState(false);
    const [fileResource, setFileResource] = useState(null);
    const [fileResourceDoc, setFileResourceDoc] = useState(null);
    const [fileResourceEnteteDoc, setFileResourceEnteteDoc] = useState(null);
    const [fileResourceFooterDoc, setFileResourceFooterDoc] = useState(null);
    const [openPreviewMail, setOpenPreviewMail] = useState(false);
    const [contentPreviewMail, setContentPreviewMail] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [newEdition, setNewEdition] = useState({ title: "", name: "", date: null, agree:false });
    const [newEditionError, setNewEditionError] = useState({ title: false, name: false, date: false, agree:false });
    const steps = getSteps();


    useEffect(() => {
        setLoading(true)
        rest.get('/parametresV2/getGeneral.json').then(function (response) {
            setParamsGeneral(response.data)
            if (response.data.logo !== "") {
                setFileResource({ name: "", base64File: response.data.logo })
            }
            if (response.data.logoDoc !== "") {
                setFileResourceDoc({ name: "", base64File: response.data.logoDoc })
            }
            if (response.data.CustomHeader !== "") {
                setFileResourceEnteteDoc({ name: "", base64File: response.data.CustomHeader })
            }
            if (response.data.CustomFooter !== "") {
                setFileResourceFooterDoc({ name: "", base64File: response.data.CustomFooter })
            }
            setLoading(false)
        })
    }, [])

    const handleChange = (event, newValue) => {
        if (newValue === 2) {
            setNewEdition({ title: "", name: "", date: null, agree: false })
            setActiveStep(0)
        }
        setValue(newValue);
    };

    const handleCopyLink = () => {
        var textArea = document.createElement("textarea");
        textArea.value = paramsGeneral.manifLink
        document.body.appendChild(textArea);
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            if (successful) {
                setSnack({ message: "Lien copié avec succès.", show: true, severity: "success" }) 
            } else {
                setSnack({ message: "Une erreur est survenue.", show: true, severity: "error" })
            }
        } catch (err) {
            setSnack({ message: "Une erreur est survenue.", show: true, severity: "error" })
        }

        document.body.removeChild(textArea);
    };
    
    const handleChangeParams = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
       
        setParamsGeneral(prevState => ({
            ...prevState,
            [event.target.name]: value
        }));
    }

    const saveStatut = () => {
        let params = {};
        params.statut = paramsGeneral.inscriptionState
        params.txtClose = paramsGeneral.txtInscrClose
        params.txtAncien = paramsGeneral.txtInscrOpenAncien
        rest.post('/parametresV2/saveState.json',params).then(function (response) {
            if (response.data.error) {
                setSnack({ message: response.data.message, show: true, severity: "error" })
            } else {
                setSnack({ message: response.data.message, show: true, severity: "success" }) 
            }
        })
    }

    const saveInterface = () => {
        setLoading(true)
        var params = new FormData();
        params.append("displayFrontOption", paramsGeneral.displayFrontOption);
        params.append("emailTemplateActive", paramsGeneral.emailTemplateActive);
        params.append("papierTemplateActive", paramsGeneral.papierTemplateActive);
        params.append("CouleurDeFond", paramsGeneral.CouleurDeFond);
        params.append("logo", fileResource ? fileResource.base64File : "");
        params.append("logoDoc", fileResourceDoc ? fileResourceDoc.base64File : "");
        params.append("CustomHeader", fileResourceEnteteDoc ? fileResourceEnteteDoc.base64File : "");
        params.append("CustomFooter", fileResourceFooterDoc ? fileResourceFooterDoc.base64File : "");


        rest.post('/parametresV2/saveInterface.json', params).then(function (response) {
            if (response.data.error) {
                setSnack({ message: response.data.message, show: true, severity: "error" })
            } else {
                setSnack({ message: response.data.message, show: true, severity: "success" })
            }
            setLoading(false)
        })
    }

    const emailPreview = () => {
        
        let content = '<div style="background-color: ' + paramsGeneral.CouleurDeFond + '; padding-top: 40px; padding-bottom: 40px; padding-left: 80px; padding-rigth: 80px">';
        content += '<div style="background-color:white;padding:10px;width:80%">'
        if (fileResourceEnteteDoc) {
            content += '<div style="text-align:center">'
            content += '<img id="headerimg" src="/parametres/images/imageHeader?vManif=1" style="max-width:100%;margin-left:auto;margin-right:auto">'
            content += '</div>';
        }
        

        content += '<p style="color:black">'
        content += '<p>Lorem ipsum dolor sit amet,</p>'
        content += '<p style="text-align:justify">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue neque gravida in fermentum. Lorem ipsum dolor sit amet. Sed id semper risus in hendrerit gravida rutrum quisque. Viverra nam libero justo laoreet sit amet cursus sit. Porttitor massa id neque aliquam. A iaculis at erat pellentesque adipiscing commodo.</p>'  
        content += '<p style="text-align:justify">Accumsan in nisl nisi scelerisque. Ultrices sagittis orci a scelerisque purus semper eget duis at. Amet nulla facilisi morbi tempus iaculis urna. Commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. In mollis nunc sed id. Ut ornare lectus sit amet est placerat in. Egestas maecenas pharetra convallis posuere. Risus viverra adipiscing at in tellus integer feugiat scelerisque varius. In mollis nunc sed id semper. Arcu non odio euismod lacinia at quis.</p>'
        content += '<br>'
        content += '</p>'

        if (fileResourceFooterDoc) {
            content += '<div style="text-align:center">'
            content += '<img id="footerimg" src="/parametres/images/imageFooter?vManif=1" style="height:38px">'
            content += '</div>';
        }
       
        content += '</div>';
        content += '</div>';
        
        setContentPreviewMail(content)
        setOpenPreviewMail(true)
    }
    
    const handleNext = () => {
        let error = false;
        switch (activeStep) {
            case 0:
                setNewEditionError(prevState => ({
                    ...prevState,
                    "title": (newEdition.title === "")
                }));
                if (newEdition.title === "") {
                    error = true;
                }
                setNewEditionError(prevState => ({
                    ...prevState,
                    "name": (newEdition.name === "")
                }));
                if (newEdition.name === "") {
                    error = true;
                } 
                break;
            case 1:
                setNewEditionError(prevState => ({
                    ...prevState,
                    "date": !newEdition.date
                }));
                if (!newEdition.date) {
                    error = true;
                }
                break;
            case 2:
                setNewEditionError(prevState => ({
                    ...prevState,
                    "agree": !newEdition.agree
                }));
                if (!newEdition.agree) {
                    error = true;
                }
                break;
            case 3:
               
                break;
            default:
                break;
        }

        if (error) {
            return false;
        }
        if ((activeStep === 2) && (newEdition.agree)) {
            setLoading(true)
            let params = {};
            params.vNomNewEdition = newEdition.title;
            params.vNewEdition = newEdition.name;
            params.vNewDate = newEdition.date;
            rest.post('/parametres/majEdition.json', params).then(function (response) {
                if (response.data.Message === "ok") {
                    // setSnack({ message: "Nouvelle édition créée avec succès.", show: true, severity: "success" })
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setTimeout(() => {
                        setValue(0);
                    }, 3000);
                } else {
                    setSnack({ message: "Une erreur est survenue.", show: true, severity: "error" })
                }
                setLoading(false)
            })
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1); 
        }
        
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className={classes.root}>
            <LoadingIndicator open={loading} />
            <MySnackbar params={snack} />
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="General params tabs"
                className={classes.tabs}
            >
                <Tab className={classes.customTab}  label="Statut Plateforme" {...a11yProps(0)} />
                <Tab className={classes.customTab}  label="Gestion Interface" {...a11yProps(1)} />
                <Tab className={classes.customTab}  label={<div><SupervisorAccountIcon style={{ verticalAlign: 'middle' }} /> Nouvelle édition </div>} {...a11yProps(2)} />
                <Tab className={classes.customTab}  label="Lien manifestation" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {paramsGeneral.inscriptionState>=0 &&
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>Statut de la Plateforme</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="inscriptionStateLabel">Statut inscriptions</InputLabel>
                                <Select
                                    labelId="inscriptionStateLabel"
                                    id="inscriptionState"
                                    value={paramsGeneral.inscriptionState}
                                    name="inscriptionState"
                                    onChange={handleChangeParams}
                                >
                                    <MenuItem value={0}>Fermées</MenuItem>
                                    <MenuItem value={1}>Ouvertes aux anciens</MenuItem>
                                    <MenuItem value={2}>Ouvertes à tous</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Texte inscriptions fermées</FormLabel>
                            <CKEditor
                                editor={ClassicEditor}
                                data={paramsGeneral.txtInscrClose}
                                id="txtInscrClose"
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setParamsGeneral(prevState => ({
                                        ...prevState,
                                        "txtInscrClose": data
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Texte inscriptions ouvertes aux anciens</FormLabel>
                            <CKEditor
                                editor={ClassicEditor}
                                data={paramsGeneral.txtInscrOpenAncien}
                                id="txtInscrOpenAncien"
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setParamsGeneral(prevState => ({
                                        ...prevState,
                                        "txtInscrOpenAncien": data
                                    }));
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"right"}}>
                            <Button color="primary" variant="contained" onClick={saveStatut}>Enregistrer</Button>
                        </Grid>
                    </Grid>
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Configuration de l'interface</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <SimpleUploader fileResource={fileResource} setFileResource={setFileResource} light={false} title={["Insérer le logo","Remplacer le logo"]} allowedType={["image/jpg", "image/png", "image/jpeg"]}/>
                                <FormHelperText>Format PNG ou JPG uniquement !</FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                {fileResource ? <><img src={fileResource.base64File} alt="logoConfig" style={{maxHeight:"200px"}} />{fileResource.name}</> : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup aria-label="displayFrontOption" name="displayFrontOption" value={paramsGeneral.displayFrontOption} onChange={handleChangeParams}>
                            <FormControlLabel value="logo" control={<Radio />} label="Afficher uniquement le logo" />
                            <FormControlLabel value="titre" control={<Radio />} label="Afficher uniquement le titre" />
                            <FormControlLabel value="logoTitre" control={<Radio />} label="Afficher le logo et le titre" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <SimpleUploader fileResource={fileResourceDoc} setFileResource={setFileResourceDoc} light={false} title={["Insérer le logo pour les documents", "Remplacer le logo pour les documents"]} allowedType={["image/jpg", "image/png", "image/jpeg"]} />
                                <FormHelperText>Format PNG ou JPG uniquement !</FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                {fileResourceDoc ? <><img src={fileResourceDoc.base64File} alt="logoDocConfig" style={{ maxHeight: "200px" }} />{fileResourceDoc.name}</> : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Configuration template emails</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={paramsGeneral.emailTemplateActive}
                                    name="emailTemplateActive"
                                    onChange={handleChangeParams} 
                                />
                            }
                            label="Activer le template des emails"
                            labelPlacement="end"
                        />
                    </Grid>
                    {paramsGeneral.emailTemplateActive &&
                        <>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={paramsGeneral.papierTemplateActive}
                                            name="papierTemplateActive"
                                            onChange={handleChangeParams}
                                        />
                                    }
                                    label=" Activer le template également pour la version papier"
                                    labelPlacement="end"
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <ColorPicker style={{ marginLeft: "10px" }} value={paramsGeneral.CouleurDeFond} hideTextfield onChange={(e) => {
                                        console.log(e)
                                        setParamsGeneral(prevState => ({
                                            ...prevState,
                                            "CouleurDeFond": "#"+e.hex
                                        }));
}} />
                                }
                                label="Couleur de fond"
                                labelPlacement="start"
                            />
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <SimpleUploader fileResource={fileResourceEnteteDoc} setFileResource={setFileResourceEnteteDoc} light={false} title={["Image de l'en-tête", "Remplacer l'image de l'en-tête"]} allowedType={["image/jpg", "image/png", "image/jpeg"]} />
                                    <FormHelperText>Format PNG ou JPG uniquement !</FormHelperText>
                                </Grid>
                                <Grid item xs={6}>
                                    {fileResourceEnteteDoc ? <>
                                        <img src={fileResourceEnteteDoc.base64File} alt="logoDocConfig" style={{ maxHeight: "200px" }} />
                                        {fileResourceEnteteDoc.name}
                                        <IconButton style={{ color: "red" }} aria-label="delete header doc" component="span" onClick={_=>setFileResourceEnteteDoc(null)}>
                                            <HighlightOff />
                                        </IconButton>
                                    </> : ""}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <SimpleUploader fileResource={fileResourceFooterDoc} setFileResource={setFileResourceFooterDoc} light={false} title={["Image du pied de page", "Remplacer l'image du pied de page"]} allowedType={["image/jpg", "image/png", "image/jpeg"]} />
                                    <FormHelperText>Format PNG ou JPG uniquement !</FormHelperText>
                                </Grid>
                                <Grid item xs={6}>
                                    {fileResourceFooterDoc ? <>
                                        <img src={fileResourceFooterDoc.base64File} alt="logoDocConfig" style={{ maxHeight: "200px" }} />
                                        {fileResourceFooterDoc.name}
                                        <IconButton style={{ color: "red" }} aria-label="delete footer doc" component="span" onClick={_=>setFileResourceFooterDoc(null)}>
                                            <HighlightOff />
                                        </IconButton>
                                    </> : ""}
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" variant="contained" onClick={emailPreview}>Aperçu email</Button>
                        </Grid>
                        </>
                    }
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                        <Button color="primary" variant="contained" onClick={saveInterface}>Enregistrer</Button>
                    </Grid>
                </Grid>
                <Dialog open={openPreviewMail} onClose={_=>setOpenPreviewMail(false)} aria-labelledby="preview-mail-dialog">
                    <DialogTitle id="preview-mail-dialog">Aperçu email</DialogTitle>
                    <DialogContent>
                       <div dangerouslySetInnerHTML={{__html: contentPreviewMail }}></div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_ => setOpenPreviewMail(false)} color="primary" variant="contained">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Configuration de la nouvelle édition</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="warning" style={{fontWeight:"bold"}}>Vous êtes sur le point de créer une nouvelle édition.
                            Attention ! Cette action est irréversible !</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.rootStepper}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                <div>
                                    <Typography className={classes.instructions}>{getStepContent(activeStep, classes, newEdition, setNewEdition, newEditionError)}</Typography>
                                    {
                                        !(activeStep === steps.length - 1) &&
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.backButton}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Précédent
                                                </Button>
                                                <Button variant="contained" color="primary" onClick={handleNext}>
                                                    Suivant
                                                </Button>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              { paramsGeneral.manifLink && <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Lien de la manifestation</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="manifLink"
                            label=""
                            disabled
                            fullWidth
                            defaultValue={paramsGeneral.manifLink}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="copy"
                                        onClick={handleCopyLink}
                                    >
                                        <FileCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Grid>}
            </TabPanel>
        </div>
    );
};

export default OngletParamGeneraux;
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { useStyleCard } from './Cards.css';
import { Box, Button, Card, CardActions, CardActionArea, CardHeader, CardContent, Grid, Popover, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import ClearIcon from '@material-ui/icons/Clear';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#f56954",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#37A8AF',
  },
}))(LinearProgress);

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 30 }}>
        <Typography variant="body2" color="text.secondary">
          {props.textvalue}
        </Typography>
      </Box>
    </Box>
  );
}

const ActivitesCards = (props) => {
  const classes = useStyleCard();
  const activitesDispo = props.actsDefDispo;
  const thSelected = props.thSelected
  const setTHSelect = props.setTHSelected
  const setBenToRemove = props.setBenToRemove;
  const setThToRemove = props.setThToRemove;
  const setThToRemoveStatut = props.setThToRemoveStatut;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null)
  const handlePopoverOpen = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId)
  }
  const handlePopoverClose = (e) => {
    setAnchorEl(null);
    setOpenedPopoverId(null)
  }

  const handleTHClick = (e) => {
    setTHSelect(e.target.offsetParent.id);
  }

  const removeBenTH = (th, ben, statut) => {
    setThToRemove(th);
    setBenToRemove(ben);
    setThToRemoveStatut(statut)
    handlePopoverClose();
  }

  return (
      (activitesDispo.length > 0) ?
        activitesDispo.map((item, index) => (
            item.Tranches.length > 0 &&
              <>
              <Card key={item.ID} className={classes.rootCard}>
                <CardHeader
                  className={classes.changePadding}
                  titleTypographyProps={{ variant: 'h4' }}
                  title={item.Nom}
                >
                </CardHeader>
                <CardContent className={classes.changePadding}>
                  
                    <Grid container>
                      <Grid item md={12}>
                        {item.Tranches.map((itm, idx) => (
                          <>
                            <Card key={itm.ID} className={(thSelected === itm.ID) ? classes.highlightCard : classes.tranchesStyle}>
                              <CardActionArea id={itm.ID} onClick={handleTHClick} className={classes.cardTh}>
                                <Grid container>
                                  <Grid item md={6}>
                                    <p><b>{itm.DateActivite}</b>  {itm.HeureDebut} - {itm.HeureFin}
                                      {itm.Alias !== "" && <small>{itm.Alias}</small>}
                                    </p>
                                  </Grid>
                                  <Grid item md={6}>
                                    <br />
                                    <LinearProgressWithLabel variant="determinate" value={((itm.NbInscritsTempo) * 100) / (itm.NombreIdeal)} valueBuffer={itm.NbInscritsTempo / itm.NombreIdeal} textvalue={itm.NbInscritsTempo + "/" + itm.NombreIdeal} />
                                  </Grid>
                                </Grid>
                              </CardActionArea>
                              <CardActions>
                                <Grid container>
                                  <Grid item md={12}>
                                    {(itm.Person.length > 0) &&
                                      <ul className={classes.personListUl} >
                                        {itm.Person.map((pers, index) => (
                                          <>
                                            {pers.link_PersonTranchesTempo_Person[0].StatutNumerique !== 1 ?
                                              <li key={pers.ID} className={classes.personList}>
                                                <Button
                                                  variant="contained"
                                                  size="small"
                                                  color="default"
                                                  className={classes.buttonRemove}
                                                  endIcon={<ClearIcon></ClearIcon>}
                                                  onClick={() => removeBenTH(itm.ID, pers.ID, "")}
                                                >
                                                  {pers.Firstname} {pers.Name}
                                                </Button>
                                              </li>
                                              :
                                              <li key={pers.ID} className={classes.personList}>
                                                <Button
                                                  variant="contained"
                                                  size="small"
                                                  color="default"
                                                  className={classes.buttonValidee}
                                                  endIcon={<Button value={pers.ID}><ClearIcon></ClearIcon></Button>}
                                                  onClick={(e) => handlePopoverOpen(e, pers.ID)}
                                                >
                                                  {pers.Firstname} {pers.Name}
                                                </Button>
                                                <Popover
                                                  open={openedPopoverId === pers.ID}
                                                  anchorEl={anchorEl}
                                                  onClose={handlePopoverClose}
                                                  anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }}
                                                  transformOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'left',
                                                  }}
                                                  value={pers.ID}
                                                >
                                                  <Card>
                                                    <CardContent >
                                                      <Typography variant="body1" gutterBottom>{`Êtes-vous sûre de vouloir supprimer ${pers.Firstname} ${pers.Name} de ${item.Nom}? `}</Typography>
                                                      <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="default"
                                                        className={classes.buttonSupprimer}
                                                        onClick={() => removeBenTH(itm.ID, pers.ID, "dejaValide")}
                                                      >
                                                        Supprimer {pers.Firstname} {pers.Name}
                                                      </Button>
                                                    </CardContent>
                                                  </Card>
                                                </Popover>
                                              </li>
                                            }
                                          </>
                                        ))}
                                      </ul>
                                    }
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                            <br />
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  
                </CardContent>
              </Card>
              <br />
              </>
        ))
        :
        <p>Aucune activité ne correspond a cette recherche</p>
  )

}

export default ActivitesCards;

/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import rest from '../../rest';
import { useStyleSelect } from "./Select.css";

//Requete pour les sous Activités
const ActivitiesSelector = (props) => {
  const classesSelectActivities = useStyleSelect();
  const [listeActivite, setListeActivite] = useState([])

  function allActivites() {
    rest.get(`/activites/listeSousAct.json`).then((response) => {
      setListeActivite(response.data)
    }, (error) => {
      console.log(error);
    });
  }
  useEffect(() => {
    allActivites()
  }, [])


  const onChangeActivitie = (val, reason) => {
    if (reason !== "clear") {
      if (val !== null) {
        props.setShowActDetail(true)
        props.setNumActChoosen(val.id)
      } else {
        props.setShowActDetail(false);
      }
    } else {
      props.setShowActDetail(false)
    }
  }
  return (
    <div className={classesSelectActivities.root}>
      <Autocomplete
        options={listeActivite}
        getOptionLabel={option => option.name}
        onChange={(event, value, reason) => onChangeActivitie(value, reason)}
        renderOption={(option, { selected, inputValue, value }) => {
          return (
            <React.Fragment>
              {option.name}
            </React.Fragment>
          );
        }}
        getOptionSelected={(option, value) => {
          return option.name === value.name;
        }}
        style={{ width: '100%' }}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Choix d'une activité"
          />
        )}
      />
    </div>
  );
}
export default ActivitiesSelector
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStylePortail = makeStyles((theme) => createStyles({
    paperClass: {
        padding: theme.spacing(4),
        // height: "70vh"
        background:"none"
    },
    root: {
        display: 'flex',
        // width: "100%",
        cursor: "pointer",
        padding: theme.spacing(2)
    },
    rootDisable: {
        cursor: "not-allowed",
        display: 'flex',
        // width: "100%",
        background: "rgba(191, 191, 191,0.5)",
        padding: theme.spacing(2)
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width:"70%"
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: "150px!important",
        padding: "10px",
        maxWidth: "200px",
        height: "80px",
        textAlign: "right",
        verticalAlign: "baseline",
        backgroundSize: "contain",
    },

}));
import React, { useEffect } from 'react';
import { useStyles } from "./InscriptionsLibres.css";
import { Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import ChoixDates from '../ChoixDates/ChoixDates'
import Notes from '../Notes/Notes'
import ChoixActivites from '../ChoixActivites/ChoixActivites'
import FinalStep from '../FinalStep/FinalStep'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { getActsDatesDispo, getAvailablesActivites } from '../../../actions/activites'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const InscriptionsLibres = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Sélection des disponibilités', 'Remarques', 'Choix des activités', 'Finalisation'];
  const [displayedDates, setDisplayedDates] = React.useState([])
  const [displayDateTxt, setDisplayDateTxt] = React.useState([]);
  const [notesTH, setNotesTH] = React.useState("")
  const [notesGeneral, setNotesGenerale] = React.useState("")
  const [selectedActId, setSelectedActId] = React.useState([])
  const [selectedActNom, setSelectedActNom] = React.useState([])
  // const [disponibilites, setDisponibilites] = React.useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
  const manifInfo = props.manifInfo;
  const matches = useMediaQuery('(max-width:570px)');


  useEffect(() => {
    if (activeStep === 0) {
      // let dispo = [];
      // setDisponibilites(dispo)
    } else if (activeStep === 1) {
      let result = Object.entries(displayedDates);
      let dispo = [];
      result.forEach((item, index) => {
        var obj = {}
        obj.date = item[0]
        obj.periode = item[1]
        dispo.push(obj)
      });
      // setDisponibilites(dispo)
      var paramsData = {
        'vDispo': JSON.stringify(displayedDates),
      }
      props.getAvailablesActivites(paramsData);
    }
  }, [activeStep, displayedDates, props])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ChoixDates manifInfo={manifInfo} setActiveStep={setActiveStep} displayedDates={displayedDates} 
        setDisplayedDates={setDisplayedDates} selectedCheckboxes={selectedCheckboxes} 
        setSelectedCheckboxes={setSelectedCheckboxes} displayDateTxt={displayDateTxt} setDisplayDateTxt={setDisplayDateTxt} />;
      case 1:
        return <Notes manifInfo={manifInfo} setActiveStep={setActiveStep} displayedDates={displayedDates} displayDateTxt={displayDateTxt} notesTH={notesTH} setNotesTH={setNotesTH} notesGeneral={notesGeneral} setNotesGenerale={setNotesGenerale} />;
      case 2:
        return <ChoixActivites manifInfo={manifInfo} setActiveStep={setActiveStep} displayedDates={displayedDates} displayDateTxt={displayDateTxt} selectedActId={selectedActId} setSelectedActId={setSelectedActId} notesTH={notesTH} notesGeneral={notesGeneral} selectedActNom={selectedActNom} setSelectedActNom={setSelectedActNom} />;
      default:
        return <FinalStep manifInfo={manifInfo} selectedActNom={selectedActNom} displayedDates={displayedDates} displayDateTxt={displayDateTxt} />;
    }
  }

  return (
    <React.Fragment>
      <span className={classes.content} >
        <Grid container>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} orientation={!matches ? "horizontal" : "vertical"}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={classes.stepsContent} >
              {activeStep === steps.length ? (
                <div>
                  <Typography component={'span'} variant={'body2'} className={classes.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography component={'span'} variant={'body2'} className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </span>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => {
  return {
    activites: state.activites,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getActsDatesDispo, getAvailablesActivites }, dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(InscriptionsLibres)




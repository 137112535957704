
const initialState = {};

export default function userReducer(user = initialState, action) {
  // eslint-disable-next-line
    const { type, payload } = action;

    switch (action.type) {
      case "GET_USER_LOGGED":
        return payload
      default:
        return user;
    }
  }
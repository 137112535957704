import React, { useState, useEffect } from 'react'
import { Paper, Grid, Card, CardContent, Typography, CardMedia, Button } from '@material-ui/core';
import { useStylePortail } from "./Portail.css";
import rest from '../../rest';
import { useCookies } from 'react-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const Portail = () => {
    const classesPortail = useStylePortail();
    const [listManif, setListManif] = useState([]) 
    const [orgaInfo, setOrgaInfo] = useState({}) 
          // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(["manifCourante"]);
    const matches = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        rest.get("/orgaInfo/")
            .then((response) => {
                let manifArray = []
                for (let index = 0; index < response.data.nom.length; index++) {
                    manifArray.push(
                        {
                            nom: response.data.nom[index],
                            actif: response.data.active[index],
                            id: response.data.id[index],
                            statut: response.data.statut[index],
                            texteBandeau: response.data.texteBandeau[index]
                        }
                    )
                }

                setListManif(manifArray)
                setOrgaInfo(response.data)
            })
    }, [])

    const handleManifSelection = (id) => {

        rest.post("/manifestation/redefinir.json", { vIdManif :id})
            .then((response) => {
            setCookie('manifCourante', id, { path: '/' });
            window.location.href ="/index.html"
            })
    }

    return (
            <Paper elevation={0} className={classesPortail.paperClass}>
                <h3>
                    <div dangerouslySetInnerHTML={{
                        __html: orgaInfo.textIntro
                    }} />
                </h3>
                <Grid container align="flex-start" spacing={4} style={{paddingRight:"2vw"}}>
                   {listManif.map((manifInfos, index) => (
                       manifInfos.actif && <Grid item xs={12}>
                           <Card className={manifInfos.statut !== 0 ? classesPortail.root : classesPortail.rootDisable} onClick={_ => manifInfos.statut !== 0 && handleManifSelection(manifInfos.id)}>
                               <div className={classesPortail.details} style={{ width: (matches ? "100%" : "70%") }}>
                                   <CardContent className={classesPortail.content} >
                                       <Typography component="h5" variant="h5">
                                           {manifInfos.nom}
                                       </Typography>
                                       {(manifInfos.statut === 2 || manifInfos.statut === 1) && <Typography variant="subtitle1" color="textSecondary">
                                           <div dangerouslySetInnerHTML={{
                                               __html: manifInfos.texteBandeau
                                           }} />
                                       </Typography>}
                                       {manifInfos.statut === 0 && <Typography variant="subtitle1" color="textSecondary">
                                           Cette manifestation n'est pas active.<br/>
                                           Vous ne pouvez pas la sélectionner.
                                       </Typography>}
                                       {(manifInfos.statut === 2 || manifInfos.statut === 1) && <Button color="primary" size="large" variant="contained" onClick={_ => manifInfos.statut !== 0 && handleManifSelection(manifInfos.id)}>{manifInfos.Ref === "chi" ? "Inscrivez-vous" : "Inscris-toi"}</Button>}
                                   </CardContent>
                               </div>
                              { !matches && <CardMedia
                                   className={classesPortail.cover}
                                   image={"/parametres/images/logoManif?vManif="+manifInfos.id}
                               />}
                           </Card>
                       </Grid>
                    ))}
                </Grid>
            </Paper>
    )
}

export default Portail;
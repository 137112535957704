import React, { useEffect, useState } from 'react';
import rest from "../../rest";
import MUIDataTable from "mui-datatables";
import CardMateriel from '../cards/CardMateriel';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import { Button, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const OngletMateriel = ({ readOnly, idBenevole }) => {
    const [histoMateriel, setHistoMateriel] = useState([])
    const [allMateriels, setAllMateriels] = useState([])
    const [materielsNecessaire, setMaterielsNecessaire] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [myInterval, setMyInterval] = useState(null)

    useEffect(() => {
        return () => {
            if (myInterval) {
                clearInterval(myInterval.refInterval);
                setMyInterval(null)
            }
        }
    }, [myInterval])

    //Requete pour avoir l'historique des actions, tout les materiels et le materiel necessaire
    useEffect(() => {
        var paramsData = {
            'vIdPers': idBenevole
        }
        rest.post(`/benevols/GetMateriels.json`, paramsData).then((response) => {
            setHistoMateriel(response.data.materiels)
            setAllMateriels(response.data.materielsAll)
            setMaterielsNecessaire(response.data.materielsNecessaire)
        }, (error) => {
            console.log(error);
        });
    }, [refresh, idBenevole])

    const scanRead = (action) => {

        if (action === "Stop") {
            clearInterval(myInterval.refInterval);
            setMyInterval(null)
        } else {
            let params = {};
            params.vNom = "";
            params.vCoul = "";
            params.vTai = "";
            params.vType = action;

            function scanRequest() {
                rest.post('/stock/scan/lire', params).then((response) => {
                    // console.log("responseScan", response.data)
                }, (error) => {
                    console.log(error);
                });
            }
            if (!myInterval) {
                let tmp = setInterval(scanRequest, 1000);
                setMyInterval({ "refInterval": tmp, action: action })
            }
        }

    }

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Matériel",
            name: "nom",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Date/heure",
            name: "dateHeure",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Etat",
            name: "action",
            options: {
                filter: true,
                sortThirdClickReset: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value === "Donné" || value === "Confié" ? <span>{value} <RedoIcon /></span> : <span> {value} <UndoIcon /></span>}</span>

                }
            }
        },

    ];
    const options = {
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
        textLabels: {
            "body": {
                "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
                "toolTip": "Trier"
            },
            "pagination": {
                "next": "Page suivante",
                "previous": "Page précédente",
                "rowsPerPage": "Ligne par page:",
                "displayRows": "de",
                "jumpToPage": "Aller à la page:"
            },
            "toolbar": {
                "search": "Rechercher",
                "downloadCsv": "Télécharger CSV",
                "viewColumns": "Afficher les colonnes",
                "filterTable": "Tableau des filtres"
            },
            "viewColumns": {
                "title": "Afficher les colonnes",
                "titleAria": "Afficher/Masquer les colonnes du tableau"
            },
            "selectedRows": {
                "text": "ligne(s) sélectionnée(s)",
                "delete": "Effacer",
                "deleteAria": "Supprimer les lignes sélectionnées"
            }
        },
    };

    return (
        <>
            <Grid item xs={12} md={6}>
                {/* Materiel que le bénévole a besoin pour ses activités */}
                <Typography variant="h6" /* className={classes.title} */>
                    Materiels nécessaire :
                </Typography>
                <div /* className={classes.demo} */>
                    <List >
                        {materielsNecessaire.length > 0 ? materielsNecessaire.map((value) =>
                            <div key={value.ID}>
                                <Divider />
                                <ListItem>
                                    <ListItemText>{value.Nom}</ListItemText>
                                </ListItem>

                            </div>
                        ) : <ListItem><ListItemText>Aucun matériel nécessaire</ListItemText></ListItem>}
                    </List>
                </div>

            </Grid>
            <Divider />
            {!readOnly &&
                <Grid style={{ marginBlock: "2vw" }}>
                    <Typography color="textSecondary" style={{ marginBottom: 10 }}>
                        En stock <span style={{ color: "#00897b" }}>&#11044;</span>
                        <span style={{ color: "#1565c0" }}>&#11044;</span>
                    </Typography>
                    <Typography color="textSecondary" style={{ marginBottom: 50 }}>
                        Distribué <span style={{ color: "#d32f2f" }}>&#11044;</span>
                    </Typography>
                    {/* tout le materiel existan*/}
                    <Grid container spacing={1} >
                        {allMateriels && allMateriels.map((mat) =>
                            <CardMateriel key={mat.ID} mat={mat} idBenevole={idBenevole} setRefresh={setRefresh} />
                        )}
                    </Grid>
                </Grid>}

            {!readOnly &&
                <Grid item xs={12}  >
                    <ListItem >
                        {(!myInterval || myInterval.action === "Reprendre") ? <Button disabled={myInterval && (myInterval.action === "Reprendre")} variant="contained" style={{ width: "120vw", color: "white", backgroundColor: "#00897b" }} onClick={_ => scanRead("Confier")}>
                            Confier <RedoIcon />
                        </Button>
                            :
                            <Button variant="contained" style={{ width: "120vw", color: "white", backgroundColor: "#00897b" }} onClick={_ => scanRead("Stop")}>
                                Arrêter <CircularProgress style={{ color: "#e53935", marginLeft: "5px" }} />
                            </Button>
                        }
                    </ListItem>
                    <ListItem >
                        {(!myInterval || myInterval.action === "Confier") ? <Button disabled={myInterval && (myInterval.action === "Confier")} variant="contained" style={{ width: "120vw", color: "white", backgroundColor: "#e53935" }} onClick={_ => scanRead("Reprendre")}>
                            Reprendre  <UndoIcon />
                        </Button>
                            :
                            <Button variant="contained" style={{ width: "120vw", color: "white", backgroundColor: "#e53935" }} onClick={_ => scanRead("Stop")}>
                                Arrêter <CircularProgress style={{ color: "#00897b", marginLeft: "5px" }} />
                            </Button>}
                    </ListItem>
                </Grid>}


            {/* L'historique afin de savoir si le materiel a bien été donné ou rendu */}
            <MUIDataTable
                title={"Historique "}
                data={histoMateriel}
                columns={columns}
                options={options}
            />
        </>
    );
};

export default OngletMateriel;
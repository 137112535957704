import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Badge, Box, Tab, Tabs } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { connect } from 'react-redux';
import OngletProfil from './OngletProfil'
import OngletActivites from './OngletActivites'
import OngletCompteUser from './OngletCompteUser'
import OngletGroupe from './OngletGroupe'
import OngletAccreditation from './OngletAccreditation';
import OngletHistorique from './OngletHistorique';
import OngletEchanges from './OngletEchanges';
import rest from "../../rest";
import OngletResponsable from './OngletResponsable';
import OngletMateriel from './OngletMateriel';
import OngletWish from './OngletWish';
import { useStylesOnglet } from "./Onglets.css";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}



const BenevoleTabs = ({ readOnly, idBenevole, benevSelectedName, benevSelectedFirstName, handleCloseFiche }) => {
  const classesOnglet = useStylesOnglet();
  const [value, setValue] = useState(0);
  const [infoTab, setInfoTab] = useState(
    {
      nbActivity: 0,
      nbMateriel: 0,
      nbActResponsable: 0,
      nbGroupe: 0,
      gestionMateriel: false,
      gestionGroupe: false,
      gestionAccreditations: false,
      historique: false,
      haveAccount: false,
    }
  )
  const [refreshInfosTab, setRefreshInfosTab] = useState(false);


  useEffect(() => {
    if (idBenevole > 0) {
      var dataParams = {
        vIdPers: idBenevole
      }
      rest.post('/benevols/getTabConfig.json', dataParams).then((response) => {
        setInfoTab(response.data)
      })
    }
  }, [idBenevole, refreshInfosTab])

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <span className={classesOnglet.rootInfoBenevole}>
      {/* Onglet La barre  */}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          /* onClick={handleClickTab(value)} */
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Profil" {...a11yProps(0)} />
          <Tab label={<Badge badgeContent={infoTab.nbActivity} color="primary">Activité(s)</Badge>}  {...a11yProps(1)} />
          <Tab style={{ "display": (infoTab.wishlist && infoTab.haveWishList && !infoTab.wishlistTreated) ? "" : "none" }} label={<Badge color="primary">Souhaits </Badge>}  {...a11yProps(2)} />
          <Tab style={{ "display": infoTab.gestionMateriel ? "" : "none" }} label={<Badge badgeContent={infoTab.nbMateriel} classes={{ badge: classesOnglet.purpleBadge }}>Matériel </Badge>}  {...a11yProps(3)} />
          <Tab style={{ "display": infoTab.nbActResponsable ? "" : "none" }} label={<Badge badgeContent={infoTab.nbActResponsable} classes={{ badge: classesOnglet.greenBadge }}>Responsable </Badge>}  {...a11yProps(4)} />
          <Tab style={{ "display": infoTab.haveAccount ? "" : "none" }} label={<Badge badgeContent={<DoneIcon className={classesOnglet.smallIcon} />} classes={{ badge: classesOnglet.orangeBadge }}>Compte utilisateur </Badge>}  {...a11yProps(5)} />
          <Tab style={{ "display": infoTab.gestionGroupe ? "" : "none" }} label={<Badge badgeContent={infoTab.nbGroupe} classes={{ badge: classesOnglet.orangeBadge }}>Groupe </Badge>}  {...a11yProps(6)} />
          <Tab style={{ "display": infoTab.historique ? "" : "none" }} label="Historique" {...a11yProps(7)} />
          <Tab style={{ "display": infoTab.gestionAccreditations ? "" : "none" }} label="Accréditation" {...a11yProps(8)} />
          <Tab label="Echanges" {...a11yProps(9)} />
        </Tabs>
      </AppBar>

      {/* Choix Page  */}
      <TabPanel value={value} index={0} >{/*Profil*/}
        <OngletProfil idBenevole={idBenevole} handleCloseFiche={handleCloseFiche} />
      </TabPanel>
      <TabPanel value={value} index={1}>{/*Activités*/}
        <OngletActivites readOnly={readOnly} idBenevole={idBenevole} benevSelectedName={benevSelectedName} benevSelectedFirstName={benevSelectedFirstName} setRefreshInfosTab={setRefreshInfosTab} />
      </TabPanel>
      {infoTab.wishlist &&
        <TabPanel value={value} index={2}>{/*Souhaits*/}
          <OngletWish readOnly={readOnly} idBenevole={idBenevole} />
        </TabPanel>
      }
      {infoTab.gestionMateriel &&
        <TabPanel value={value} index={3}>{/*Materiel*/}
          <OngletMateriel   readOnly={readOnly} idBenevole={idBenevole} />
        </TabPanel>
      }
      {infoTab.nbActResponsable > 0 &&
        <TabPanel value={value} index={4}>{/*Responsable*/}
          <OngletResponsable readOnly={readOnly} idBenevole={idBenevole} />
        </TabPanel>
      }
      {infoTab.haveAccount &&
        <TabPanel value={value} index={5}>{/*CpteUser*/}
          <OngletCompteUser  readOnly={readOnly} idBenevole={idBenevole} />
        </TabPanel>
      }
      <TabPanel value={value} index={6}>{/*Groupe*/}
        {<OngletGroupe  readOnly={readOnly}  idBenevole={idBenevole} setRefreshInfosTab={setRefreshInfosTab} />}
      </TabPanel>
      <TabPanel value={value} index={7}>{/*Historique*/}
        <OngletHistorique idBenevole={idBenevole} />
      </TabPanel>
      <TabPanel value={value} index={8}>{/*Accreditation*/}
        <OngletAccreditation  readOnly={readOnly}  idBenevole={idBenevole} />
      </TabPanel>
      <TabPanel value={value} index={9}>{/* Echanges3 */}
        <OngletEchanges idBenevole={idBenevole} />
      </TabPanel>
    </span>
  );
}


const mapStateToProps = (state) => {
  return {
    manif: state.manif,
  }
}

export default connect(mapStateToProps)(BenevoleTabs)



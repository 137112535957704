import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { getRandomColor } from '../Fonctions/fonctions';

const PieChartExample = ({ labels, datasets }) => {
    const chartRef = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy(); // Détruire l'instance précédente si elle existe
        }

        const ctx = chartRef.current.getContext('2d');
        const data = {
            labels: labels,
            datasets: [{
                data: datasets,
                backgroundColor: getRandomColor(datasets.length),
            }],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
        };

        const newChartInstance = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: options,
        });

        setChartInstance(newChartInstance);

        return () => {
            if (chartInstance) {
                chartInstance.destroy(); // Détruire l'instance du graphique lors du démontage
            }
        };
    }, [labels, datasets]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <canvas ref={chartRef} />
        </div>
    );
};

export default PieChartExample;

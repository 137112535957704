

import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import rest from '../../../rest';
import debounce from "lodash/debounce";

export default function CustomAutocomp(props) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const [currentValue, setCurrentValue] = useState("");



    const handleSearch = (pCurrentValue) => {
        let params = {};
        params.value = pCurrentValue
        params.url = props.params.url;
        if (props.params.extraParams) {
            for (const key in props.params.extraParams) {
                params[key] = props.params.extraParams[key]
            }
        }
        rest.get(props.params.url, params).then((response) => {
            const rows = response.data;
            setOptions(rows);
        }, (error) => {
            console.log(error);
        });
    }

    // eslint-disable-next-line
    const handleSearchDelayed = useCallback(
        debounce(handleSearch, 1000)
    , []);
       

    useEffect(() => {
        if (!props.params.delayAndRequest) {
            if (!loading) {
                return undefined;
            }
            handleSearch(currentValue)
        } else {
            if (currentValue.length >= 3) {
                handleSearchDelayed(currentValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, props.params.url, currentValue, props.params.delayAndRequest]);



    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleInputChange = (event,value) => {
        setCurrentValue(value)
    }

    return (
        <Autocomplete
            style={{ marginBottom: "10px", marginTop:"10px" }}
            id={props.params.autoCompName}
            multiple={props.params.multiple}
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={props.params.handleAutocompChange(props.params.autoCompName)}
            getOptionSelected={(option, value) => option.text === value.text}
            getOptionLabel={(option) => option.text}
            options={options}
            loading={loading}
            onInputChange={handleInputChange}
            value={props.params.autoCompState}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={props.params.autoCompLabel}
                    name={props.params.autoCompName}
                    error={props.params.error ? props.params.error : false}
                    required={props.params.error ? true : false}
                    helperText={props.params.error ? (props.params.error ? props.params.helperText : "") : ""}
                    variant={props.params.variant ? props.params.variant:"outlined"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={option.id}
                        color="primary"
                        label={option.text}
                        {...getTagProps({ index })}
                    />
                ))}
        />
    );
}

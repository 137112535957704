import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Card, CardHeader, CardContent, FormControlLabel, Grid, IconButton, Menu, MenuItem, Typography, Switch } from '@material-ui/core';
import rest from '../../rest';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useCookies } from 'react-cookie';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`activite-tabpanel-${index}`}
      aria-labelledby={`activite-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function PanelTranches(props) {
  const [idAct, setIdAct] = useState(props.idAct);
  const [listesActBen, setListesActBen] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const datesSelected = props.datesSelected;
  const [tabPresent, setTabPresent] = useState([]);
  const [nbTotal, setNbTotal] = useState(0);
  const [cookies, setCookie] = useCookies(['actSelected', 'thSelected'])
  // const [thSelected, setThSelected] = useState(cookies.thSelected)

  useEffect(() => {
    setIdAct(props.idAct)
  }, [props.idAct])

  useEffect(() => {
    var arrayPresent = []
    setTabPresent(arrayPresent);
    var arrayDateSelected = []
    arrayDateSelected.push(JSON.parse(localStorage.getItem('dateSelected')))
    var paramsData = {
      'vDates': JSON.stringify(arrayDateSelected),
      'vIDAct': props.idAct,
      'vIDTH': cookies.thSelected
    }
    rest.post('/presences/listeTHAct.json', encodeURIComponent(JSON.stringify(paramsData)))
      .then((response) => {
        if ((cookies.thSelected < 1) || (typeof cookies.thSelected === 'undefined')) {
          if (response.data.length > 0) {
            setCookie('thSelected', JSON.stringify(response.data[0].THDuJour[0].ID), { path: '/' });
          }
        }
        setListesActBen(response.data);
        if (response.data.length > 0) {
          let testPresent = response.data[0].Tranches.filter(item => item.ID === parseInt(cookies.thSelected));
          if (testPresent.length===0) {
            setCookie('thSelected', JSON.stringify(response.data[0].THDuJour[0].ID), { path: '/' });
          }
        }
      })
  }, [idAct, datesSelected, cookies.thSelected, props.idAct, setCookie])

  useEffect(() => {
    let arrayPresent = [];
    let tot = 0;
    listesActBen.map((item, index) =>
      item.Tranches.map((itm, ind) =>
        itm.Person.forEach((pers, idx) => {
          tot++;
          let obj = {}
          obj.index = index + "_" + ind + "_" + idx
          obj.value = pers.Present
          arrayPresent.push(obj)
        })
      )
    )
    setNbTotal(tot);
    setTabPresent(arrayPresent)
  }, [listesActBen])

  const handleSeeTH = (event, thChoisies) => {
    setCookie("thSelected", JSON.stringify(thChoisies), { path: "/" });
    // setThSelected(thChoisies)
    setAnchorEl(null);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePresence = (event, newValue, idPers, idTH, indexTab) => {
    var paramsData = {
      'vIdPers': idPers,
      'vIdTH': idTH,
      'vPresent': newValue,
    }
    rest.post('/presences/changementStatutPresence.json', encodeURIComponent(JSON.stringify(paramsData)))
      .then((response) => {
        let arrayPresent = [...tabPresent];
        arrayPresent.filter(e => e.index === indexTab)[0].value = newValue
        setTabPresent(arrayPresent)
      })
  };

  return (
    <div>
      {listesActBen.map((item, index) =>
        <>
          {item.Tranches.length > 0 ?
            <>
              {item.Tranches.map((itm, ind) =>
                <Grid item xs={12} md={4}>
                  <Card>
                    {(item.NbTHDuJour < 2 && item.Tranches.length > 0) | (item.NbTHDuJour < 1 && item.Tranches.length < 1) ?
                      <CardHeader
                        title={itm.DateActivite}
                        subheader={itm.HeureDebut + ' - ' + itm.HeureFin}
                      />
                      :
                      <CardHeader
                        action={
                          <IconButton aria-label="settings" aria-haspopup="true" onClick={handleClick}>
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title={itm.DateActivite}
                        subheader={itm.HeureDebut + ' - ' + itm.HeureFin}
                      />
                    }
                    {item.NbTHDuJour > 1 &&
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {item.THDuJour.map((thJour, indThJour) =>
                          <MenuItem onClick={(event) => { handleSeeTH(event, thJour.ID) }}>{thJour.heureDebut} - {thJour.heureFin}</MenuItem>
                        )}
                      </Menu>
                    }
                    <CardContent>
                      <>
                        {item.NbPers > 0 ?
                          itm.Person.map((pers, idx) =>
                            <Grid container style={{ marginLeft: '3%' }}>
                              <Grid item xs={8} md={6}>
                                <p>
                                  <a style={{ marginRight: '3%' }} href={`tel=${pers.Phone_Mobile}`}><PhoneIphoneIcon fontSize="small" color="primary" /></a>
                                  {pers.Firstname} {pers.Name}
                                </p>
                              </Grid>
                              <Grid item xs={4} md={6}>
                                {tabPresent.length > 0 && tabPresent.length === nbTotal &&
                                  <FormControlLabel
                                    control={<IOSSwitch checked={(tabPresent.filter(e => e.index === index + "_" + ind + "_" + idx))[0].value} onChange={(event, newValue) => { handleChangePresence(event, newValue, pers.ID, itm.ID, index + "_" + ind + "_" + idx) }} name="present" />}
                                  />
                                }
                              </Grid>
                            </Grid>
                          )
                          :
                          <Grid container style={{ marginLeft: '3%' }}>
                            <Grid item xs={12} md={12}>
                              <p>Aucune personne n'est inscrite à cette tranche horaire</p>
                            </Grid>
                          </Grid>
                        }
                      </>
                    </CardContent>
                  </Card>
                  <br />
                </Grid>
              )}
            </>
            :
            <>
              {item.THDuJour.length > 0 &&
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings" aria-haspopup="true" onClick={handleClick}>
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={item.THDuJour[0].dateToString}
                      subheader={""}
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {item.THDuJour.map((thJour, indThJour) =>
                        <MenuItem onClick={(event) => { handleSeeTH(event, thJour.ID) }}>{thJour.heureDebut} - {thJour.heureFin}</MenuItem>
                      )}
                    </Menu>
                    <CardContent>
                      <Grid container style={{ marginLeft: '3%' }}>
                        <Grid item xs={12} md={12}>
                          <p>Il n'y a pas de tranches horaires pour l'heure actuelle</p>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              }
            </>
          }
        </>
      )}
    </div>
  );
}
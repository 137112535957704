import { Card, CardActionArea, CardContent, Typography, Chip, Grid } from '@material-ui/core';
import React from 'react';
import rest from "../../rest";
import { useStyleCard } from './Cards.css';


const CardMateriel = ({ mat, idBenevole, setRefresh }) => {
  const classes = useStyleCard();

  const changement = (e) => {
    var paramsData = {
      'vIdPers': idBenevole,
      "vIdMateriel": mat.ID,
      "vAction": mat.action
    }
    rest.post(`/benevols/SwitchStateMateriels.json`, paramsData).then((response) => {
      setRefresh((refresh) => { return !refresh })
    }, (error) => {
      console.log(error);
    });
  }

  const type = mat.type === 'Confier' && mat.action === "Rendu" ? 'Confié' :
    mat.type === 'Donner' && mat.action === "Rendu" ? 'Donné' : mat.type;

  return (
    <Grid item style={{ position: "relative" }}>
      <Chip label={type} style={{ backgroundColor: mat.action === "Rendu" ? "#d32f2f" : mat.action === "Donné" ? "#00897b" : "#1565c0" }} className={classes.chip} />
      <Card style={{ width: "140px" }} variant="outlined">
        <CardActionArea style={{ height: "100px" }} onClick={(e) => changement(e)}>
          <CardContent >
            <Typography color="textSecondary" gutterBottom>
              {mat.Nom}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>

  );
};

export default CardMateriel;
import React, { useEffect, useState, useContext } from 'react';
import { MenuItem, Button, Card, CardActionArea, CardActions, CardContent, Chip, FormControl, Grid, Input, InputLabel, Select, Slider, Typography, withStyles, ButtonGroup, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Paper, Tooltip } from '@material-ui/core';
import rest from "../../rest";
import AppContext from '../../components/appContext';
import { Link, useHistory } from 'react-router-dom';
import { useStyleActivity } from './Activity.css';
import { useSelector } from "react-redux";
import { useStylesGlobal } from '../GlobalStyle.css';
// import debounce from 'lodash.debounce'
import TableActiviteDetails from '../../components/table/TableActiviteDetails';
import MUIDataTable from "mui-datatables";
// ICON
import DateRangeIcon from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AccordionActivity from '../../components/AccordionActivity/AccordionActivity';
import GererGroupe from '../../components/GererGroupe/GererGroupe';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ClearIcon from '@material-ui/icons/Clear';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GetRandomKey from '../../components/general/genereRandomKey';


const PrettoSlider = withStyles({
    track: {
        height: 8,
        borderRadius: 4,
        color: "#37A8AF"


    },
    rail: {
        height: 8,
        borderRadius: 4,
        color: "#F29B12",
        opacity: "0.9",

    },
    thumb: {
        display: "none",

    },
})(Slider);

export const Activity = () => {
    const matches = useMediaQuery('(max-width:600px)');
    const classesAccueil = useStyleActivity();
    const classesGlobal = useStylesGlobal();
    const history = useHistory();
    const manifParams = useSelector(state => state.manif);
    const currentUser = useSelector(state => state.user);
    const myContext = useContext(AppContext);
    // Activites
    const [activites, setActivites] = useState([]);
    const [idSousActivites, setIdSousActivites] = useState(0);
    const [idActivites, setIdActivites] = useState(0);
    const [dateActivites, setDateActivites] = useState([]);
    const [idTrancheHoraire, setIdTrancheHoraire] = useState(0);
    const [dateTranche, setDateTranche] = useState("");
    const [horaireTranche, setHoraireTranche] = useState("");
    // Critere
    const [critere, setCritere] = useState([]);
    const [selectcritere, setSelectcritere] = useState([]);
    // Date
    const [selectDate, setSelectDate] = useState([]);
    
    // Recherche
    const [recherche, setRecherche] = useState("");
    // Dialog
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState("");
    const [descriptionLongue, setDescriptionLongue] = useState("");
    const [nom, setNom] = useState("");
    // Dialog Plus Infos 
    const [infos, setInfos] = useState([]);
    // Dialog Choisir
    const [restrict, setRestrict] = useState(false);
    const [showChoisir, setShowChoisir] = useState(false);
    const [showInfosPlus, setShowInfosPlus] = useState(false);
    const [showGererGrp, setShowGererGrp] = useState(false);

    useEffect(() => {
        listActivites();
        // eslint-disable-next-line
    }, [selectDate, recherche, idActivites, selectcritere])

    useEffect(() => {
        myContext.setOpenSidebar(!matches)
        // eslint-disable-next-line
    }, [matches])

    useEffect(() => {
        listDate();
        listCritere();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        listPlusInfos();
        // eslint-disable-next-line
    }, [idSousActivites])


    //  Requete pour le filtre Date
    const listDate = () => {
        rest.get('/activites/recuperationDatesDispo.json')
            .then((response) => {
                setDateActivites(response.data.DateDispo)

            })
    }

    //  Requete pour le filtre Critere
    const listCritere = () => {
        rest.get('/parametres/getCaracteristique.json')
            .then((response) => {
                setCritere(response.data)
            })
    };

    // Requete pour avoir toutes les activites
    const listActivites = () => {
        let params = {
            vId: idActivites,
            vRechGenerale: recherche,
            vCaract: selectcritere.join(","),
            // vActiviteLibre: actLibre,
            vDateTest: selectDate.length > 0 ? JSON.stringify(selectDate) : "",
            vSpecification: "",
        }
        rest.post('/4DACTION/ACTIVITES_Liste', params)
            .then((response) => {
                setActivites(response.data.activites);
            })
    }

    
    useEffect(()=>{
        if (!open) {
            listActivites();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])

    // Requete pour les donnees concernant Plus Infos (document)
    const listPlusInfos = () => {
        let params = {
            vID: idSousActivites,
            vListe: "Lie"
        }
        rest.post('/documents/liste.json', params)
            .then((response) => {
                setInfos(response.data)
            })
    }

    const columns = [
        {
            label: "ID",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: " ",
            name: "ID",
            options: {
                viewColumns: false,
                filter: false,
                // display: 'excluded',
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Link to={{
                            pathname: `/documents/download?vid=${value}`,
                        }} target="_blank" rel='noopener noreferrer' >
                            <DescriptionIcon color="primary" />
                        </Link>
                    )
                }
            }
        },
        {
            label: "Nom",
            name: "Nom",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Date version",
            name: "DateHeureVersion",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },

    ]

    const options = {
        responsive: 'simple',
        filterType: 'checkbox',
        selectableRowsHeader: false,
        filter: false,
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: "none",
        textLabels: {
            "body": {
                "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
                "toolTip": "Trier"
            },
            "pagination": {
                "next": "Page suivante",
                "previous": "Page précédente",
                "rowsPerPage": "Ligne par page:",
                "displayRows": "de",
                "jumpToPage": "Aller à la page:"
            },
            "toolbar": {
                "search": "Rechercher",
                "downloadCsv": "Télécharger CSV",
                "viewColumns": "Afficher les colonnes",
                "filterTable": "Tableau des filtres"
            },
            "viewColumns": {
                "title": "Afficher les colonnes",
                "titleAria": "Afficher/Masquer les colonnes du tableau"
            },
            "selectedRows": {
                "text": "ligne(s) sélectionnée(s)",
                "delete": "Effacer",
                "deleteAria": "Supprimer les lignes sélectionnées"
            }
        },
    };

    // Dialog (close)
    const handleClose = () => {
        setOpen(false)
    }

    // Les changements
    const handleChange = (event) => {
        setIdActivites(event.target.id);
    }
    const handleChangeDate = (event) => {
        setSelectDate(event.target.value);
    }
    const handleChangeRechercher = (event) => {
        setRecherche(event.target.value);
    }
    const handleChangeCritere = (event) => {
        setSelectcritere(event.target.value);
    }
    const handleChangeCheck = () => {
        setRestrict((prev) => !prev)
    }

    //retour en arrière
    const handleBack = () => {
        setIdActivites(0)
    }
    //  Suppression des recherches 
    const remiseAZero = (event) => {
        setRecherche("");
        setSelectDate([]);
        setSelectcritere([])
        setIdActivites(0)
    }
    // Choisir Activité
    const ChoisirAct = (id, description, nom) => {
        setOpen(true)
        setShowChoisir(true)
        setShowInfosPlus(false);
        setShowGererGrp(false);
        setIdSousActivites(id)
        setDescription(description)
        setNom(nom)
    }
    // Gerer Groupe
    const GererGRP = (id, description, nom) => {
        setOpen(true)
        setShowChoisir(true)
        setShowInfosPlus(false);
        setShowGererGrp(false);
        setIdSousActivites(id)
        setDescription(description)
        setNom(nom)
    }
    // Bouton Plus d'infos
    const PlusInfos = (id, description, nom, descriptionLongue) => {
        setOpen(true);
        setShowChoisir(false);
        setShowInfosPlus(true);
        setIdSousActivites(id);
        setDescription(description);
        setDescriptionLongue(descriptionLongue);
        setNom(nom)
    }
    // Les Inscrits
    const Inscrit = (id, description, nom) => {
        setOpen(true);
        setShowChoisir(false);
        setShowInfosPlus(false);
        setIdSousActivites(id);
        setDescription(description);
        setNom(nom);
    }
    return (
        //    sessionChecked && null
        (manifParams.manifParams.AffichagePageAccueil === "showActivities") &&
            (
                (manifParams.manifInfos.StatutInscription === 1 && currentUser.connected) ||
                (manifParams.manifInfos.StatutInscription === 2) ||
                (currentUser.User.User_Administrator)
            ) ?
            <>

                {/* Filtre Recherche */}
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <FormControl className={classesAccueil.filtre} >
                            <TextField id="filled-basic" label="Recherche" value={recherche} onChange={handleChangeRechercher} />
                            <InputLabel style={{ marginInlineStart: "95%" }}> <SearchIcon /> </InputLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {/* Filtre Date  */}
                        <FormControl className={classesAccueil.filtre} >
                            <InputLabel id="select-multiple" >Dates</InputLabel>
                            <Select
                                labelId="select-multiple"
                                id="select-multiple"
                                multiple
                                value={selectDate}
                                IconComponent={DateRangeIcon}
                                onChange={handleChangeDate}
                                input={<Input id="select-multiple" />}
                                renderValue={(select) => (
                                    <div className={classesAccueil.chips}>
                                        {select.map((value, id) => (
                                            <Chip key={GetRandomKey(11)} label={value} color="primary" />
                                        ))}
                                    </div>
                                )}
                            >
                                {dateActivites.map((date, id) => (
                                    <MenuItem key={GetRandomKey(10)} value={date} >
                                        {date}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Filtre Autres critères */}
                    <Grid item xs={6} md={3}>
                        <FormControl className={classesAccueil.filtre}  >
                            <InputLabel>Autres critères</InputLabel>
                            <Select
                                value={selectcritere}
                                multiple
                                IconComponent={FilterListIcon}
                                onChange={handleChangeCritere}
                                input={<Input />}
                                // variant="outlined"
                                renderValue={(select) => (
                                    <div className={classesAccueil.chips}>
                                        {critere.map((value, id) => (
                                            select.map((sel) => (
                                                (sel === value.ID) &&
                                                <Chip key={GetRandomKey(12)} label={value.Nom} color="primary" />
                                            ))
                                        ))}
                                    </div>
                                )}>
                                {critere.map((value, id) => (
                                    <MenuItem key={GetRandomKey(8)} value={value.ID} >
                                        {value.Nom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                    </Grid>
                    <Grid item xs={6} md={3}>
                        <ButtonGroup style={{ marginBlockStart: "0.5vw" }} fullWidth>
                            <Button onClick={_ => remiseAZero()} className={classesGlobal.buttonFermer}>Effacer recherche</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                {/* Card des activites ou sous-activites */}
                <Grid container spacing={2} >
                    {(idActivites > 0) && <Grid item xs={12} style={{ "paddingBottom": "0px" }}>
                        <Button onClick={handleBack} color="primary" variant="contained" fullWidth>Retour</Button>
                    </Grid>}
                    {activites && activites.map((act) => (
                        <>
                            {((currentUser.connected && currentUser.User.User_Administrator) || (manifParams.manifParams.AffichagePageAccueil === "showActivities")) &&
                                (act.active) && <Grid item xs={12} md={6} lg={4} key={GetRandomKey(14)} >
                                    <Card className={(!act.nom_pere) ? classesAccueil.root : classesAccueil.rootSousActivite} id={act.id} variant="outlined" color="primary">
                                        <CardActionArea className={classesAccueil.presentationText} id={act.id} onClick={e => { (!act.nom_pere) && handleChange(e) }}>
                                            <CardContent id={act.id}>
                                                {/* Icon avec animation */}
                                                <Typography className={classesAccueil.iconCustom}>
                                                    <i id={act.id} className={act.icone !== "" ? ("fa " + act.icone) : "fas fa-user"} aria-hidden="true"></i>
                                                </Typography>
                                                <Typography gutterBottom id={act.id} variant="h5" className={classesAccueil.titreCard}>
                                                    {act.interne &&
                                                        <Tooltip
                                                            title={<h3>Activité interne</h3>}
                                                            aria-label="Activité interne"
                                                            placement="bottom"
                                                        >
                                                            <VisibilityOffIcon />
                                                        </Tooltip>}
                                                    {!act.active &&
                                                        <Tooltip
                                                            title={<h3>Inactive</h3>}
                                                            aria-label="Inactive"
                                                            placement="bottom"
                                                        >
                                                            <ClearIcon />
                                                        </Tooltip>}
                                                    {act.nom}
                                                </Typography>
                                                <Typography gutterBottom id={act.id} variant="p" component="h5" className={classesAccueil.titreCard}>
                                                    {act.description}
                                                </Typography>
                                                {/* Bouton pour les sous-activité */}
                                                {act.nom_pere &&
                                                    <CardActions style={{ justifyContent: "flex-end" }}>
                                                        {act.plusinfo &&
                                                            <Button onClick={_ => { PlusInfos(act.id, act.description, act.nom, act.descriptionLongue) }}
                                                                className={classesAccueil.bouton}
                                                                startIcon={<InfoOutlinedIcon />}
                                                                size="small"  >
                                                                plus d'infos
                                                            </Button>
                                                        }
                                                        {(myContext.User_Administrator || (myContext.User_Chef && myContext.user?.actionsIds?.includes(String(act.id))))  &&
                                                            <Button
                                                                onClick={_ => Inscrit(act.id, act.description, act.nom)}
                                                                className={classesAccueil.bouton}
                                                                size="small" >
                                                                Les inscrits
                                                            </Button>
                                                        }
                                                        {act.choisissable &&
                                                            <Button onClick={_ => ChoisirAct(act.id, act.description, act.nom)}
                                                                className={classesAccueil.bouton}
                                                                size="small" >
                                                                Choisir
                                                            </Button>
                                                        }
                                                    </CardActions>
                                                }
                                                <Typography id={act.id} style={{ marginBlockStart: "20%", textAlign: "right" }} >Occupation {act.nbinscrit}/{act.nbideal} </Typography>
                                                <PrettoSlider id={act.id} max={act.nbideal} value={act.nbinscrit} />
                                            </CardContent>
                                        </CardActionArea>

                                    </Card>
                                </Grid>
                            }
                        </>
                    ))}
                </Grid>
                {/*  Details sous activite */}
                <Dialog open={open} onClose={handleClose} fullWidth={true}
                    maxWidth={'lg'}>

                    <DialogTitle className={classesAccueil.cardHead}>{nom}
                        <br /><Typography>{description}</Typography>
                    </DialogTitle>

                    <DialogContent  >
                        {myContext.User_Administrator && !showInfosPlus && !showGererGrp &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={restrict}
                                        onChange={handleChangeCheck}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label="Afficher les dates passées "
                            />}
                        {showChoisir ?
                            <>
                                {showGererGrp ?
                                    <GererGroupe
                                        idTrancheHoraire={idTrancheHoraire}
                                        dateTranche={dateTranche}
                                        horaireTranche={horaireTranche} />
                                    :
                                    <Grid xs={12} >
                                        <TableActiviteDetails
                                            numActChoosen={idSousActivites}
                                            idPerson={currentUser.User.User_Person_ID}
                                            restrict={!restrict}
                                            showGrp={((currentUser.User.User_Administrator || currentUser.User.User_GestGroupe) && currentUser.connected && manifParams.manifParams.Modules[0].GestionGroupe)}
                                            setShowGererGrp={setShowGererGrp}
                                            setIdTrancheHoraire={setIdTrancheHoraire}
                                            setDateTranche={setDateTranche}
                                            setHoraireTranche={setHoraireTranche} />
                                    </Grid>
                                }
                            </>
                            :
                            <>
                                {showInfosPlus ?
                                    // Plus d'infos 
                                    <>
                                        {descriptionLongue !== "" && <div dangerouslySetInnerHTML={{
                                            __html: descriptionLongue
                                        }}>

                                        </div>}
                                        {infos.length > 0 && <MUIDataTable
                                            // style={{ width: "100%" }}
                                            title={<h3>Documents</h3>}
                                            data={infos}
                                            columns={columns}
                                            options={options}
                                        />}
                                    </>
                                    :
                                    <>
                                        <AccordionActivity restrict={!restrict} idSousActivites={idSousActivites} />
                                    </>}
                            </>}
                    </DialogContent>
                    <DialogActions>
                        {!showChoisir && showInfosPlus &&
                            <Button variant="contained" color="primary" onClick={_ => ChoisirAct(idSousActivites, description, nom)}>
                                Choisir
                            </Button>}
                        {showGererGrp && !showInfosPlus &&
                            <Button variant="contained" color="primary" onClick={_ => GererGRP(idSousActivites, description, nom)}>
                                Retour
                            </Button>}
                        <Button variant="contained" className={classesGlobal.buttonFermer} onClick={handleClose}>
                            Fermer
                        </Button>

                    </DialogActions>
                </Dialog>
            </> :
            <Paper elevation={3} className={classesAccueil.paperClass}>
                <div dangerouslySetInnerHTML={{
                    __html: manifParams.manifInfos.StatutInscription === 0 ? manifParams.manifInfos.TextInscrFermees : manifParams.manifInfos.StatutInscription === 1 ? manifParams.manifInfos.TextInscrAnciens : manifParams.manifParams.pageAccueilText
                }} />
                <br />
                {manifParams.manifInfos.StatutInscription === 2 && <Button color="primary" variant="contained" onClick={_ => history.push("/connexion.html")}>{manifParams.manifInfos.Ref === "chi" ? "Inscrivez-vous" : "Inscris-toi"}</Button>}
            </Paper>
    )
}

export default Activity;
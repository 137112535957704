import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardActions, CardContent, CardHeader, Grid, Radio } from '@material-ui/core';
// import rest from "../../../rest";


const useStyles = makeStyles((theme) => ({
    cardRoot: {
        backgroundColor: "#d9d8d7",
    },
    radioBtnContainer: {
        textAlign:"center",
    }
}));




const ThemeConfig = () => {
     const classes = useStyles();
     const themeList = [
        {
            title: "Blanc-Acqua",
            ref: "theme1",
            src:"/img/Themes/Theme1.PNG",
        },
        {
            title: "Noir-Acqua",
            ref: "theme2",
            src: "/img/Themes/Theme2.PNG",
        },
        {
            title: "Bleu Marine",
            ref: "theme3",
            src: "/img/Themes/Theme3.PNG",
        },
        {
            title: "Acqua",
            ref: "theme4",
            src: "/img/Themes/Theme4.PNG",
        },
        {
            title: "Orangé",
            ref: "theme5",
            src: "/img/Themes/Theme5.PNG",
        },
    ]
    const [selectedTheme, setSelectedTheme] = useState("")

    useEffect(() => {
       
    }, [])

    
    const handleChange = (event) => {
        setSelectedTheme(event.target.value);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>Configuration du thème</Typography>
            </Grid>
            {themeList.map((theme) => (
                <Grid item>
                    <Card className={classes.cardRoot}>
                        <CardHeader
                            title={theme.title}
                        />
                        <CardContent>
                            <img src={theme.src} alt={theme.ref} />
                        </CardContent>
                        <CardActions disableSpacing className={classes.radioBtnContainer}>
                            <Radio
                                checked={selectedTheme === theme.ref }
                                onChange={handleChange}
                                value={theme.ref}
                                name="themeSelection"
                                color="primary"
                            />
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default ThemeConfig;
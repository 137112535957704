import { Paper, Grid, Link } from '@material-ui/core';
import { useStyleNotFound } from "./NotFound.css";
import MoodBadIcon from '@material-ui/icons/MoodBad';

export const NotFound = () => {
    const classesNotFound = useStyleNotFound();

    return (
        <main>
            <div />
            <Paper elevation={3} className={classesNotFound.paperClass}>
                <Grid container align="center">
                    <Grid item xs={12}>
                        <MoodBadIcon className={classesNotFound.customIcon} />
                        <h1>Page introuvable</h1>
                        <h2>Nous n'avons malheureusement pas pus trouver la page que vous recherchiez.</h2>
                        <Link href="/index.html" color="inherit" style={{ fontSize: "1rem" }}>
                            Revenir à la page d'accueil
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </main>
    )
}

export default NotFound;
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      "& p": {
        fontSize: '1rem'
      }, 
      "& li": {
        fontSize: '1rem'
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    accordion: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    }, 
    accordionSummary:{
      backgroundColor: '#b7b7b7',
      borderBottom: '1px solid rgba(0, 0, 0, .125)'
    },
    accordionDetail:{
      backgroundColor: '#f2f2f2',
      display:'flex', 
      flex: 1, 
      flexDirection:'column'
    }, 
    marginGrid:{
      marginBottom: '2%'
    },
    alertCustom: {
      display:"block",
    },
    marginDate:{
      marginBottom:'-10px'
    },
    marginPeriode:{
      marginLeft:'15px', 
      paddingTop:'0px',
      fontSize: '1rem'
    }, 
    titreDatesSelect:{
      color: "#f50057",
      fontSize: '1.6em'
    }
  }));
  
import React, { useEffect, useState } from 'react';
import rest from "../../rest";
import MUIDataTable from "mui-datatables";
import { Checkbox } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';


const OngletResponsable = ({ readOnly, idBenevole }) => {
    const [actRespon, setActRespon] = useState([]);
    const [state, setState] = useState(false)
    const [modifActiviite, setModifActiviite] = useState(false)

    //Requete pour afficher les activites dont le benevoles est responsable
    useEffect(() => {
        var paramsData = {
            'vIdPers': idBenevole
        }
        rest.post(`/benevols/GetActResponsables.json`, paramsData).then((response) => {
            setActRespon(response.data.activity)
            setState(response.data.autoriserComm)
            setModifActiviite(response.data.autoriserModifActiv);
        }, (error) => {
            console.log(error);
        });
    }, [idBenevole])



    //L'autorisation pour communiquer aux benevoles
    const handleChange = (event) => {
        setState(event.target.checked)
        var paramsData = {
            'vID': idBenevole,
            'vAutor': event.target.checked
        }
        rest.post(`/benevols/autorisationCommAvecBene.json`, paramsData).then((response) => {
        }, (error) => {
            console.log(error);
        });

    };
    const handleChangeActivity = (event) => {
        setModifActiviite(event.target.checked)
        var paramsData = {
            'vID': idBenevole,
            'vAutor': event.target.checked
        }
        rest.post(`/benevols/authoriserChangerActivite.json`, paramsData).then((response) => {
        }, (error) => {
            console.log(error);
        });

    };

    const columns = [
        {
            label: "ID",
            name: "ActTrancheId",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Activité(s)",
            name: "NomActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Date",
            name: "DateActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Horaire",
            name: "HeureActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Occupation",
            name: "OccupationActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            },
        },
    ];
    const options = {
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
    };


    return (
        <>
            {!readOnly &&
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state}
                            onChange={handleChange}
                            name="checked"
                            color="primary"
                        />
                    }
                    label="Autoriser à communiquer avec ses bénévoles"
                />}
            {!readOnly &&
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={modifActiviite}
                            onChange={handleChangeActivity}
                            name="checked"
                            color="primary"
                        />
                    }
                    label="Autoriser à modifier ses activités"
                />}
            <MUIDataTable
                data={actRespon}
                columns={columns}
                options={options}
            />

        </>
    );
};

export default OngletResponsable;
import React, { useState, useEffect } from 'react'
import AppContext from '../../components/appContext';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ProtectedRoute from '../../components/protectedRoute/ProtectedRoute';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { getManifParams } from '../../actions/manif'
import { getUserInformations } from '../../actions/user'
/* Importer composant */
import Activity from '../activity/Activity';
import Connexion from '../connexion/Connexion';
import LesBenevoles from '../LesBenevoles/LesBenevoles';
import MesBenevoles from '../mesBenevoles/MesBenevoles';
import Attributions from '../attributions/Attributions';
import Presences from '../presences/Presences';
import EmailList from '../emailList/EmailList';
import InscriptionWish from '../inscriptionWish/InscriptionWish';
import MesActivites from "../mesActivites/MesActivites"
import Profil from "../profil/Profil"
import Parametre from '../Parametre/Parametre';
import Portail from '../portail/Portail';
import RecapAttributions from '../recapAttributions/RecapAttributions';
import tableauDesAttributions from '../tableauDesAttributions/tableauDesAttributions';
import pageImageUpload from '../pageImageUpload/pageImageUpload';
import GestionTextes from '../texte/texte';
//404
import NotFound from '../notFound/NotFound';
//403
import ForbidenAccess from '../forbidenAccess/ForbidenAccess'

import Menu from '../../components/menu/menu'
import Footer from '../../components/footer/Footer'
import clsx from 'clsx';
import { useStylesGlobal } from "../GlobalStyle.css";
import Statistiques from '../statistiques/Statistiques';

const LoadDatas = (props) => {
    const classesGlobal = useStylesGlobal();
    const [openSidebar, setOpenSidebar] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleDrawer = () => {
        if (openSidebar) {
            setOpenSidebar(false);
        } else {
            setOpenSidebar(true);
        }
    }
    const sidebarSettings = {
        openSidebar: openSidebar,
        setOpenSidebar: setOpenSidebar,
        handleDrawer: handleDrawer,
        user: props.user?.User
    };


    useEffect(() => {
        props.getManifParams();
        props.getUserInformations();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        var propsManif = Object.keys(props.manif).length;
        var propsUser = Object.keys(props.user).length;
        if (propsManif > 0 & propsUser > 0) {
            setIsLoaded(true)
        }
    }, [props.manif, props.user])

    return (
        <>

            {isLoaded ?
                <AppContext.Provider value={sidebarSettings}>
                    <Router>
                        <div className={classesGlobal.root}>
                            <Menu />
                            <main
                                className={clsx(classesGlobal.content, {
                                    [classesGlobal.contentShift]: openSidebar,
                                    [classesGlobal.contentModeTest]: props.manif.manifInfos.ModeTest,
                                })}
                            >
                                <div className={classesGlobal.drawerHeader} />
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect to="/index.html" />
                                    </Route>
                                    <Route exact path="/portail.html" component={Portail} />
                                    <Route exact path="/index.html" component={Activity} />
                                    <Route exact path="/connexion.html" component={Connexion} />
                                    <ProtectedRoute exact path="/les_benevoles.html" component={LesBenevoles} />
                                    <ProtectedRoute exact path="/mes_benevoles.html" component={MesBenevoles} />
                                    <ProtectedRoute exact path="/attributions.html" component={Attributions} />
                                    <ProtectedRoute exact path="/recap_attributions.html" component={RecapAttributions} />
                                    <ProtectedRoute exact path="/tableauDesAttributions.html" component={tableauDesAttributions} />
                                    <ProtectedRoute exact path="/pageImageUpload.html" component={pageImageUpload} />
                                    <ProtectedRoute exact path="/presences.html" component={Presences} />
                                    <ProtectedRoute exact path="/listeEmails.html" component={EmailList} />
                                    <ProtectedRoute exact path="/inscriptionWishlit.html" component={InscriptionWish} />
                                    <ProtectedRoute exact path="/mes_activites.html" component={MesActivites} />
                                    <ProtectedRoute exact path="/parametres.html" component={Parametre} />
                                    <ProtectedRoute exact path="/profile_informations.html" component={Profil} />
                                    <ProtectedRoute exact path="/stats.html" component={Statistiques} />
                                    <Route exact path="/403" component={ForbidenAccess} />
                                    <Route exact path="/gtexte*" component={GestionTextes} />
                                    <Route exact path="*" component={NotFound} />
                                </Switch>
                                <Footer />
                            </main>
                        </div>
                    </Router>
                </AppContext.Provider>
                :
                <LoadingIndicator open={!isLoaded} />
            }

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        manif: state.manif,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getManifParams, getUserInformations }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadDatas)




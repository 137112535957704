import React, { useEffect, useState, useRef } from 'react';
import styles from '../../App.css'
import SelectActivities from '../select/SelectActivities'
import TableActiviteDetails from '../table/TableActiviteDetails'
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, Popover, Tooltip } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import TextTableau from '../texts/texteTableau.json'
import JoditEditor from "jodit-react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import configJoditEditor from '../editor/JoditEditorConf';
import { useStylesGlobal } from "../../pages/GlobalStyle.css";
import rest from '../../rest';
import MuiDatatableToolBar from "../../components/muiDatatableToolBar/MuiDatatableToolBar";
import { MySnackbar } from '../mySnackbar/MySnackbar';

//TODO Voir si on peut l'enlever 
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


const OngletActivites = ({ readOnly, idBenevole, benevSelectedName, benevSelectedFirstName, setRefreshInfosTab }) => {
    const classesGlobal = useStylesGlobal();
    const [isLoaded, setIsLoaded] = useState(false);
    const [activity, setActivity] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [snack, setSnack] = useState({});
    const [openModalAddAct, setOpenModalAddAct] = useState(false);
    const [openModalAttestation, setOpenModalAttestation] = useState(false);
    const [showActDetail, setShowActDetail] = useState(false);
    const [numActChoosen, setNumActChoosen] = useState(0);
    const [currentActTrancheID, setCurrentActTrancheID] = useState(0);
    const editor = useRef(null)
    const [textAttestation, setTextAttestation] = useState("");
    const attestationBen = `/export/benevoles/attestation/pdf/?id=${idBenevole}`
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [refresh, setRefresh] = useState(false);



    useEffect(() => {
        let arrayElementValidated = [];
        for (let index = 0; index < activity.length; index++) {
            const elementValidated = activity[index].ActiviteValidee;
            if (elementValidated) {
                arrayElementValidated.push(index)
            }
        }
        setSelectedRows(arrayElementValidated);


    }, [idBenevole, activity])

    // REQUETE Activité
    useEffect(() => {
        var paramsData = {
            'vIdPers': idBenevole,
            'allAct' : true
        }
        rest.post(`/benevols/GetActivity.json`, paramsData).then((response) => {
            setActivity(response.data)
            setRefreshInfosTab((refreshInfos) => !refreshInfos)
        }, (error) => {
            console.log(error);
        });
    }, [refresh, idBenevole, setRefreshInfosTab])

    // ATTESTATION 
    const handleOpenModalAttestation = () => {
        let id = idBenevole;
        var paramsData = {
            'vId': id
        }
        rest.post(`/email/recupererInfoAttestation`, paramsData)
            .then((response) => {
                setTextAttestation(response.data.Message)
                setOpenModalAttestation(true);
            })
    };

    const handleCloseModalAttestation = () => {
        setIsLoaded(false)
        setOpenModalAttestation(false);
    };

    const sendAttestation = () => {
        var paramsData = {
            'vID': idBenevole,
            'vMessage': textAttestation.replaceAll('&nbsp;', ' ')
        }
        rest.post('/email/sendAttestation', paramsData).then(function (response) {
            if (response.data.Message === "Ok") {
                setSnack({ message: "Attestation envoyée", show: true, severity: "success" })
                setOpenModalAttestation(false);
            }
        })
    }

    // REQUETE DELETE 
    function deleteActivite(paramsData) {
        rest.post(`/benevols/activiteInscription.json`, paramsData).then((response) => {
            console.log('response Onglet ==================>>>>> ', response);
            if (response.data.Msg === "nok") {
                setSnack({ message: response.data.textMsg, show: true, severity: "error" })
            } else {
                setCurrentActTrancheID(response.data)
                setRefresh((refresh) => { return !refresh })
            }
            setIsLoaded(false)
        }, (error) => {
            console.log(error);
        });
    }

    const handleDelete = () => {
        setIsLoaded(true);
        handleClose();
        var paramsData = {
            'vId': currentActTrancheID,
            'vIdPersonnes': idBenevole
        }
        deleteActivite(paramsData);
        setRefresh((refresh) => { return !refresh })
    };

    // REQUETE VALIDATION ACTIVITE 
    const handleCheckRow = (targetChecked, targetIndex) => {
        const paramsData = {
            vID: idBenevole,
            vIdAct: activity[targetIndex].ActTrancheId,
            vActivate: targetChecked
        }
        rest.post(`/benevols/validationActivites.json`, paramsData).then((response) => {
            if (response.data.reponse === 'ok') {
                setRefresh((refresh) => { return !refresh })
                if (targetChecked === true) {
                    setSnack({ message: "Activité ajoutée à l'attestation", show: true, severity: "success" })
                } else {
                    setSnack({ message: "Activité enlevée de l'attestation", show: true, severity: "warning" })

                }
            }
            else {
                console.log("NOT OK")
                setSnack({ message: "Une erreur s'est produite", show: true, severity: "error" })

            }
            setRefresh((refresh) => { return !refresh })
        }, (error) => {
            console.log(error);
        });
    };

    // Autre 
    const handleCloseModalAddAct = () => {
        setIsLoaded(false)
        setRefresh((refresh) => { return !refresh })
        setOpenModalAddAct(false);
    };


    function handleClick(event, id) {
        setCurrentActTrancheID(id)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // DONEE TABLEAU 
    const columns = [
        {
            label: "ID",
            name: "IdActivite",
            options: {
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Activité(s)",
            name: "NomActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Date",
            name: "DateActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Horaire",
            name: "HeureActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            },
        },
        {
            label: "Durée",
            name: "DureeActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },

        {

            label: "Action",
            name: "ActTrancheId",
            options: {
                display: readOnly ? 'excluded' : true,
                filter: false,
                sortThirdClickReset: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (!readOnly) {
                        return (
                            <span>
                                <Button onClick={(e) => { handleClick(e, value) }}><DeleteIcon color="error" /></Button>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    className="popover_class"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Button variant="contained" className={classesGlobal.buttonFermer} onClick={handleDelete}>Confirmer désinscription</Button>
                                </Popover>
                            </span>
                        );
                    }
                },
            }
        }
    ];

    const options = {
        selectableRows: readOnly ? "none" : 'multiple',
        responsive: 'simple',
        filterType: 'checkbox',
        selectableRowsHeader: false,
        filter: false,
        // search: false,
        download: false,
        print: false,
        viewColumns: false,
        selectToolbarPlacement: "none",
        textLabels: TextTableau,
        rowsSelected: selectedRows,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            var checkedRow = false;
            if (allRowsSelected.some(allRowsSelected => allRowsSelected.index === currentRowsSelected[0].index)) {
                checkedRow = true
            } else {
                checkedRow = false
            }
            handleCheckRow(checkedRow, currentRowsSelected[0].index)
        },
        customToolbar: () => {
            if (!readOnly) {
                const handleOpenModalAddAct = () => {
                    setShowActDetail(false);
                    setRefresh((refresh) => { return !refresh })
                    setOpenModalAddAct(true);
                };

                const element = <Tooltip title={"Nouvelle activité"}>
                    <Fab size="small" color="secondary" aria-label="add" onClick={handleOpenModalAddAct}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                return (
                    <MuiDatatableToolBar extraBtn={element} hideFilter={true} />
                );
            }
        },
    };

    ///JSX  
    return (
        isLoaded ?
            <CircularProgress />
            :
            <div>
                <MySnackbar params={snack} />
                <Grid container spacing={1}>
                    {!readOnly && <Grid item sm={12} align="left">
                        <HtmlTooltip title="Il faut cocher les activités ci-dessous afin qu'elles s'affichent sur l'attestation." placement="right-start">
                            <Button variant="contained" onClick={handleOpenModalAttestation} color="primary">
                                Attestation
                            </Button>
                        </HtmlTooltip>
                    </Grid>}
                    <Grid item sm={12}>

                        <MUIDataTable
                            className={styles.MUINumberRowsSelected}
                            fullWidth
                            style={{ width: '100%' }}
                            data={activity}
                            columns={columns}
                            options={options}
                        />

                    </Grid>
                </Grid>

                <Dialog
                    open={openModalAddAct}
                    onClose={handleCloseModalAddAct}
                    fullWidth={true}
                    maxWidth={'md'}
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="alert-dialog-title">Ajouter une/des activités à {benevSelectedName} {benevSelectedFirstName}</DialogTitle>
                    <DialogContent>
                        <SelectActivities setShowActDetail={setShowActDetail} setNumActChoosen={setNumActChoosen} />
                        {showActDetail &&
                            <span>
                                <br /><TableActiviteDetails numActChoosen={numActChoosen} idPerson={idBenevole} showGrp={false} />
                            </span>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <Grid item xs={10} align="right"></Grid>
                            <Grid item xs={1} align="left">
                                <Button variant="contained" onClick={handleCloseModalAddAct} className={classesGlobal.buttonFermer}>
                                    Fermer
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <br />

                <Dialog
                    open={openModalAttestation}
                    onClose={handleCloseModalAttestation}
                    fullWidth={true}
                    maxWidth={'lg'}
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="alert-dialog-title">Attestation pour {benevSelectedName} {benevSelectedFirstName}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <iframe title="attestation" src={attestationBen} style={{ width: '100%', minHeight: '75vh' }}></iframe>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ paddingLeft: '2%' }}>
                                    <JoditEditor
                                        ref={editor}
                                        value={textAttestation}
                                        config={configJoditEditor}
                                        tabIndex={1}
                                        onBlur={newContent => setTextAttestation(newContent)} // preferred to use only this option to update the content for performance reasons
                                        onChange={newContent => { }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseModalAttestation} className={classesGlobal.buttonFermer}>
                            Fermer
                        </Button>
                        <Button variant="contained" onClick={sendAttestation} color="primary">
                            Envoyer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
    )
}

export default OngletActivites

import React, { useEffect, useState } from 'react';
import { MenuItem, FormControl, Input, InputLabel, Select, Grid, List, ListItem, ListItemText, Divider, Chip } from '@material-ui/core';
import rest from '../../rest';
import { useStyleSelect } from "./Select.css";


// TODO voir comment faire
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 45,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectGroupe = ({readOnly, idBenevole, setRefreshInfosTab }) => {
  const classesSelectGroupe = useStyleSelect();
  const [dense] = useState(false);
  const [selectedGrp, setSelectedGrp] = useState([]);
  const [listGrp, setListGrp] = useState([]);


  // Requete liste des groupes
  useEffect(() => {
    rest.get(`/groupe/liste.json`).then((response) => {
      setListGrp(response.data)
    }, (error) => {
      console.log(error);
    });
  }, [])

  // Requete Groupe deja Membre
  useEffect(() => {
    if (listGrp.length > 0) {
      var dataParams = {
        vIdPers: idBenevole
      }
      rest.post(`/benevols/GetGroup.json`, dataParams).then((response) => {

        let filteredArray = [];
        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index];
          for (let index2 = 0; index2 < listGrp.length; index2++) {
            const element2 = listGrp[index2];
            if (element2.id === element.id) {
              filteredArray.push(element2)
              index2 = listGrp.length
            }
          }
        }
        setSelectedGrp(filteredArray)

      }, (error) => {
        console.log(error);
      });
    }

  }, [idBenevole, listGrp])

  //OnChange  Garder les modifications ( ajoute / supp d'un groupe)
  const handleChange = (event) => {
    setSelectedGrp(event.target.value);
    let idMembre = "";
    for (let index = 0; index < event.target.value.length; index++) {
      const element = event.target.value[index];
      idMembre = idMembre + element.id + ","
    }
    let paramsData = {
      vIdPerson: idBenevole,
      vIdMembreDe: idMembre
    }
    rest.post(`/groupe/save.json`, paramsData).then((response) => {

      if (response.data.retour === 'ok') {
        setRefreshInfosTab((refreshInfos) => !refreshInfos)
      } else {
        console.log("Not ok")
      }
    }, (error) => {
      console.log(error);
    });
  };



  return (
    <>
    {!readOnly &&
      <FormControl fullWidth className={classesSelectGroupe.formControl} >
        <InputLabel id="select-multiple" style={{ fontSize: 19 }}>Séléctionne un/des groupes</InputLabel>
        <Select
          labelId="select-multiple"
          id="select-multiple"
          multiple
          value={selectedGrp}
          fullWidth
          onChange={handleChange}
          input={<Input id="select-multiple" />}
          MenuProps={MenuProps}
          renderValue={(selectedGrp) => (
            <div className={classesSelectGroupe.chips}>
              {selectedGrp.map((value) => (
                <Chip key={value.id} label={value.name} className={classesSelectGroupe.chip} color="primary" />
              ))}
            </div>
          )}
        >
          {listGrp.map((grp) => (
            <MenuItem key={grp.id} value={grp} >
              {grp.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> }
      <Grid item xs={12} md={6}>
        <div className={classesSelectGroupe.demo}>
          <List dense={dense}>
            {selectedGrp.length > 0  ? selectedGrp.map((value) =>
              <div key={value.id}>
                <ListItem>
                  <ListItemText>{value.name}</ListItemText>
                </ListItem> 
                <Divider />
              </div>
            ): <p>Ce bénévole n'appartient à aucun groupe</p>}
          </List>
        </div>
      </Grid>
    </>
  );
};

export default SelectGroupe;
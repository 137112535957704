import { Box, Button, Grid } from '@material-ui/core';

const ModalAlert = ({ text, handleConfirm }) => {

    const onConfirm = async () => {
        await handleConfirm?.();
    }

    return (
        <Box>
            <p>{text}</p>

            <Grid container justifyContent="space-between">
                <Grid item>
                </Grid>
                <Grid item>
                    <Button onClick={onConfirm} color="primary" variant='outlined'>
                        OK
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ModalAlert;
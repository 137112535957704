import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import rest from "../../rest"
import { Popover, Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useStylesGlobal } from '../GlobalStyle.css';
import { useSelector } from "react-redux";
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import DescriptionIcon from '@material-ui/icons/Description';
import SendIcon from '@material-ui/icons/Send';
import getUserAgentInfo from '../../components/general/userAgent';
// Requetes lors de la suppression est un requete qui envoie du HTML 
const MesActivites = () => {
    const classesGlobal = useStylesGlobal();
    const [activites, setActivites] = useState([]);
    const [activitesId, setActivitesId] = useState(0);
    const [state, setState] = useState(false);
    const [snack, setSnack] = useState({});
    const [emptyData, setEmptyData] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [refresh, setRefresh] = useState(false)
    const currentUser = useSelector(state => state.user);
    const manifParams = useSelector(state => state.manif);
    

    // Requete concernant les activites dont l'utilisateur est connecté
    useEffect(() => {
        var paramsData = {
            vIdPers: currentUser.User.User_Person_ID,
            allAct: state
        }
        rest.post(`/benevols/GetActivity.json`, paramsData).then((response) => {
            const data = response.data;
            setActivites(data);
            if(data === undefined || data.length === 0){
                setEmptyData(true);
            }
            // setState((prev)=>!prev)
        });
    }, [refresh, state, currentUser.User.User_Person_ID])

    const handleChange = (event) => {
        setState(event.target.checked)
    }

    const handleMyFiche = () => {
        window.open("/export/benevoles/tranche/pdf")
    }
    const handleMyFicheByEmail = () => {
        rest.post("/email/sendFicheBenevole", {}).then((response) => {
            if (response.data.Message === "Ok") {
                setSnack({ message: "Votre fiche bénévole vous a bien été envoyée.", show: true, severity: "success" })
            } else {
                setSnack({ message: "Une erreur est survenue.", show: true, severity: "error" })
            }
        });
    }



    const columns = [
        {
            label: "ID",
            name: "IdActivite",
            options: {
                download: false,
                viewColumns: false,
                filter: false,
                display: 'excluded',
            }
        },
        {
            label: "Date",
            name: "DateActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Heure",
            name: "HeureActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        {
            label: "Duree",
            name: "DureeActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            },
        },
        {
            label: manifParams.manifInfos.DenominationSousAct,
            name: "NomActivite",
            options: {
                filter: true,
                sortThirdClickReset: true,
            }
        },
        // Donnee Changante 
        // {
        //     label: "Manifestation",
        //     name: "Manifestation",
        //     options: {
        //         filter: true,
        //         sortThirdClickReset: true,
        //         display: state ? true : false,
        //         download: state ? true : false,
        //     }
        // },

        // {
        //     label: "Type",
        //     name: "Type",
        //     options: {
        //         filter: true,
        //         sortThirdClickReset: true,
        //         display: state ? false : true,
        //         download: state ? false : true,
        //     }
        // },
        {
            label: "Responsable",
            name: "Responsable",
            options: {
                filter: true,
                sortThirdClickReset: true,
                display: true,
                download: true,
            }
        },

        {
            label: "Statut",
            name: "Statut",
            options: {
                filter: true,
                sortThirdClickReset: true,
                display: false,
                download: false,

            }
        },
        {
            label: "Désinscription",
            name: "ActTrancheId",
            options: {
                display: manifParams.manifParams.InscripionWishlist ? 'excluded' : true,
                filter: true,
                sortThirdClickReset: true,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const handleClose = () => {
                        setAnchorEl(null);
                    };
                    const handleClick = (event, value) => {
                        setActivitesId(value)
                        setAnchorEl(event.currentTarget);
                    };
                    // Se desincrire d'une activité pas encore passé 
                    const desinscription = () => {
                        let params = {
                            vId: activitesId,
                            info : JSON.stringify(getUserAgentInfo())
                        }
                        rest.post('/benevols/activiteInscription.json', params)
                            .then(function (response) {
                                console.log('response mes activite ==================>>>>> ', response);
                                if (response.data.Msg === "nok") {
                                    setSnack({ message: response.data.textMsg, show: true, severity: "error" })
                                } else {
                                    setSnack({ message: response.data.textMsg, show: true, severity: "success" })
                                    setRefresh((prev) => !prev);
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                    if (tableMeta.rowData[8] === 2) { return <Button variant="contained" size="small" disabled>Activité terminé </Button> }
                    else {
                        return (
                            <span>
                                <Button onClick={(e) => { handleClick(e, value) }} className={classesGlobal.buttonFermer} size="small">Se désincrire</Button>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    className="popover_class"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Button onClick={_ => { desinscription() }} size="small" className={classesGlobal.buttonFermer} >Confirmer désinscription</Button>
                                </Popover>
                            </span>);
                    }
                }
            },
        },

    ];

    const options = {
        filter: false,
        print: false,
        viewColumns: false,
        selectableRows: "none",
        responsive: "vertical",
        sortOrder: { name: "Name", direction: 'asc' },
        downloadOptions: { filename: "donneeBenevole.csv", separator: ';' },
        onDownload: function (buildHead, buildBody, columns, data) { return "\uFEFF" + buildHead(columns) + buildBody(data); },
        textLabels: {
            "body": {
                "noMatch": "Désolé, aucun enregistrement correspondant n'a été trouvé",
                "toolTip": "Trier"
            },
            "pagination": {
                "next": "Page suivante",
                "previous": "Page précédente",
                "rowsPerPage": "Ligne par page:",
                "displayRows": "de",
                "jumpToPage": "Aller à la page:"
            },
            "toolbar": {
                "search": "Rechercher",
                "downloadCsv": "Télécharger CSV",
                "viewColumns": "Afficher les colonnes",
                "filterTable": "Tableau des filtres"
            },
            "viewColumns": {
                "title": "Afficher les colonnes",
                "titleAria": "Afficher/Masquer les colonnes du tableau"
            },
            "selectedRows": {
                "text": "ligne(s) sélectionnée(s)",
                "delete": "Effacer",
                "deleteAria": "Supprimer les lignes sélectionnées"
            }
        },
    }

    return (
        <Grid container>
            <MySnackbar params={snack} />
            <Grid item sm={12}>
                <Grid container spacing={1}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state}
                                    onChange={handleChange}
                                    name="checked"
                                    color="primary"
                                />
                            }
                            label="Afficher toutes mes activités (présentes - passées)"
                        />
                    </Grid>
                    {!emptyData && <Grid item>
                        <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} size="medium" style={{ marginBottom: "5px", fontSize: "11px" }} onClick={handleMyFiche}>
                            Ma fiche bénévole
                        </Button>
                    </Grid>}
                    {!emptyData && <Grid item>
                        <Button variant="contained" color="primary" startIcon={<SendIcon />} size="medium" style={{ marginBottom: "5px", fontSize: "11px" }} onClick={handleMyFicheByEmail}>
                            Recevoir ma fiche par email
                        </Button>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <MUIDataTable
                    title={<h1>Mes activites</h1>}
                    data={activites}
                    columns={columns}
                    options={options}
                />
            </Grid>
        </Grid>

    );
};

export default MesActivites;
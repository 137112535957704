import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

export default function StatutAutocomplete(props) {
  const [selectedStatut, setSelectedStatut] = useState(null);
  let statutOptions = props.statutOptions;

    if(!props.currentUser.User.User_Administrator){// si pas admin
      statutOptions = statutOptions.slice(0, 2);
    }
    
  useEffect(() => {
    

    const initialStatutOption = statutOptions.find((item) => item.value === props.statut);
    setSelectedStatut(initialStatutOption || null);
  }, [props.statut, statutOptions]);

  const handleChangeStatut = (event, value) => {
    setSelectedStatut(value);
    props.setStatut(value ? value.value : null);
  };

  return (
    <>
      <label>Statut</label>
      <Autocomplete
        label="Statut"
        size="small"
        id="statutAutocomplete"
        disableClearable
        options={statutOptions}
        getOptionLabel={(option) => option.text}
        value={selectedStatut}
        onChange={handleChangeStatut}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            variant="outlined"
          />
        )}
      />
    </>
  );
}

import React, { useEffect, useState, useContext } from 'react'
import { Paper, Grid, Card, CardContent, Typography, CardActions, DialogActions, Button, TextField, InputAdornment, Link, FormControlLabel, Checkbox, IconButton } from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';
import { useStyleConnexion } from "./Connexion.css";
import rest from '../../rest';
import resendMail from '../../components/general/modalResendMail';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'
import AppContext from '../../components/appContext';
import ForgotPassword from '../../components/forgotPassword/ForgotPassword';
import { getManifParams } from '../../actions/manif';
import { useCookies } from 'react-cookie';
import getUserAgentInfo from '../../components/general/userAgent';
import CancelIcon from '@material-ui/icons/Cancel';

export const Connexion = (props) => {
    const classesConnexion = useStyleConnexion();
    const myContext = useContext(AppContext);
    const [cookiesLogin, setCookiesLogin] = useCookies(['SessionLogin'])
    const [cookiesSaveLogin, setCookiesSaveLogin] = useCookies(['SaveLogin'])
    const [loading, setLoading] = useState(false);
    const [snack, setSnack] = useState({});
    const [sessionChecked, setSessionChecked] = useState(false);
    const [openForgotPassword, setOpenForgotPassword] = useState(false);

    const [linkActiveMail, setLinkActiveMail] = useState(false);
    const [resendLinkMail, setresendLinkMail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    //connexion
    const [remember, setRemember] = useState(cookiesSaveLogin.SaveLogin ? (cookiesSaveLogin.SaveLogin === "true") : false);
    const [connexionEmail, setConnexionEmail] = useState(cookiesLogin.SessionLogin ? cookiesLogin.SessionLogin : "");
    const [connexionEmailError, setConnexionEmailError] = useState(false);
    const [connexionPassword, setConnexionPassword] = useState("");
    const [connexionPasswordError, setConnexionPasswordError] = useState(false);
    const [connexionEmailErrorMsg, setConnexionEmailErrorMsg] = useState("");
    //inscription
    const [inscriptionEmail, setInscriptionEmail] = useState("");
    const [inscriptionEmailError, setInscriptionEmailError] = useState(false);
    const [inscriptionPassword, setInscriptionPassword] = useState("");
    const [inscriptionPasswordError, setInscriptionPasswordError] = useState(false);
    const [inscriptionPasswordRepeat, setInscriptionPasswordRepeat] = useState("");
    const [inscriptionPasswordRepeatError, setinscriptionPasswordRepeatError] = useState(false);
    const [inscriptionPasswordErrorMsg, setInscriptionPasswordErrorMsg] = useState("");
    const [inscriptionPasswordRepeatErrorMsg, setInscriptionPasswordRepeatErrorMsg] = useState("");
    const [inscriptionEmailErrorMsg, setInscriptionEmailErrorMsg] = useState("");



    useEffect(() => {
        myContext.setOpenSidebar(false)

        rest.get('/user/informations.json').then((response) => {
            if (response.data.connected) {
                window.location.href = response.data.redirect
            }
            setSessionChecked(true);
        })
        // eslint-disable-next-line
    }, [])

    const handleRemember = () => {
        setRemember((prev) => !prev)
    }

    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (re.test(email) && email !== "");
    }

    const handleConnect = () => {
        let error = false;

        if (connexionEmail === "") {
            error = true
            setConnexionEmailError(true)
            setConnexionEmailErrorMsg("Champ obligatoire")
        } else {
            if (!validateEmail(connexionEmail)) {
                setConnexionEmailError(true)
                setConnexionEmailErrorMsg("Format email invalide")
            } else {
                setConnexionEmailError(false)
                setConnexionEmailErrorMsg("")
            }
        }
        if (connexionPassword === "") {
            error = true
            setConnexionPasswordError(true)
        } else {
            setConnexionPasswordError(false)
        }

        if (!error) {
            setLoading(true)
            var paramsData = new FormData();
            paramsData.append("vConnexionEmail", connexionEmail);
            paramsData.append("vConnexionPassword", connexionPassword);
            rest.post('/user/connexion.json', paramsData).then(function (response) {
                if (response.data.error) {
                    if (!response.data.ActiveLink) setSnack({ message: response.data.Message, show: true, severity: "error" });
                    setErrorMessage(response.data.Message);
                    if (response.data.ActiveLink) setLinkActiveMail(true);
                } else {
                    setCookiesSaveLogin('SaveLogin', remember, { path: '/' });
                    if (remember) {
                        setCookiesLogin('SessionLogin', connexionEmail, { path: '/' });
                    } else {
                        setCookiesLogin('SessionLogin', "", { path: '/' });
                    }
                    window.location.href = response.data.Redirect
                }
                setLoading(false)
            });
        }

    }

    const submitOnEnter = (event) => {
        if (event.keyCode === 13) {
            handleConnect();
        }
    }
    const submitSubscriptionOnEnter = (event) => {
        if (event.keyCode === 13) {
            handleSubscribe();
        }
    }

    const handleSubscribe = () => {
        let error = false;
        let errorPsw = false;

        if (inscriptionEmail === "") {
            error = true
            setInscriptionEmailError(true)
            setInscriptionEmailErrorMsg("Champ obligatoire")
        } else {
            if (!validateEmail(inscriptionEmail)) {
                setInscriptionEmailError(true)
                setInscriptionEmailErrorMsg("Format email invalide.")
            } else {
                setInscriptionEmailError(false)
                setInscriptionEmailErrorMsg("")
            }
        }
        if (inscriptionPassword === "") {
            error = true
            setInscriptionPasswordError(true)
            setInscriptionPasswordErrorMsg("Champ obligatoire")
        } else {
            if (inscriptionPassword.length < 6) {
                error = true
                errorPsw = true
                setInscriptionPasswordError(true)
                setInscriptionPasswordErrorMsg("Le mot de passe doit contenir minimum 6 caractères.")
            } else {
                setInscriptionPasswordError(false)
                setInscriptionPasswordErrorMsg("")
            }
        }
        if (inscriptionPasswordRepeat === "") {
            error = true
            setinscriptionPasswordRepeatError(true)
            setInscriptionPasswordRepeatErrorMsg("Champ obligatoire")
        } else {
            if (inscriptionPasswordRepeat.length < 6) {
                error = true
                errorPsw = true
                setinscriptionPasswordRepeatError(true)
                setInscriptionPasswordRepeatErrorMsg("Le mot de passe doit contenir minimum 6 caractères.")
            } else {
                setinscriptionPasswordRepeatError(false)
                setInscriptionPasswordRepeatErrorMsg("")
            }
        }
        if (((inscriptionPassword !== "") && (inscriptionPasswordRepeat !== "")) && (!errorPsw)) {
            if (inscriptionPassword !== inscriptionPasswordRepeat) {
                error = true
                setInscriptionPasswordError(true)
                setinscriptionPasswordRepeatError(true)
                setInscriptionPasswordErrorMsg("Les mots de passe doivent concorder.")
                setInscriptionPasswordRepeatErrorMsg("Les mots de passe doivent concorder.")
            } else {
                setInscriptionPasswordError(false)
                setinscriptionPasswordRepeatError(false)
                setInscriptionPasswordErrorMsg("")
                setInscriptionPasswordRepeatErrorMsg("")
            }
        }

        if (!error) {
            setLoading(true)
            var paramsData = new FormData();
            paramsData.append("vRegisterEmail", inscriptionEmail);
            paramsData.append("vRegisterPassword", inscriptionPassword);
            paramsData.append("vRegisterPasswordConfirm", inscriptionPasswordRepeat);
            paramsData.append("vFromReact", true);
            paramsData.append("infos", JSON.stringify(getUserAgentInfo()));

            rest.post('/connection/registration.json', paramsData).then(function (response) {
                if (response.data.error) {
                    if (!response.data.ActiveLink) setSnack({ message: response.data.message, show: true, severity: "error" });
                    setErrorMessage(response.data.message);
                    if (response.data.ActiveLink) setLinkActiveMail(true);
                } else {
                    setSnack({ message: response.data.message, show: true, severity: "success", autoHideDuration: 20000 })
                    setInscriptionEmail("");
                    setInscriptionPassword("");
                    setInscriptionPasswordRepeat("");
                }
                setLoading(false)
            })
        }
    }

    const handleForgetPassword = () => {
        setOpenForgotPassword(true)
    }

    const handleCloseDialogSendMail = () => {
        setLinkActiveMail(false);
    }

    const resendMailFromForgot = async () => {
        setLoading(true);
        const response = await resendMail(resendLinkMail);
        if (response && response.data.success === true) {
            setSnack({ message: response.data.Message, show: true, severity: "success" });
            setLinkActiveMail(false);
        } else {
            const message = response?.data?.Message || 'Erreur de connection !!!';
            setSnack({ message: message, show: true, severity: "error" });
        }
        setLoading(false);
    }

    return (
        sessionChecked && <main>
            {linkActiveMail ? <DialogActions style={{
                flexWrap: 'wrap', background: '#f44336', padding: '0.2em', zIndex: 100, width: '800px', borderRadius: '5px',
                position: 'absolute', left: '50%', top: '110px', marginLeft: '-400px', marginTop: '0', color: '#fff', fontWeight: 'normal',
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
            }}>
                <IconButton onClick={handleCloseDialogSendMail}>
                    <CancelIcon />
                </IconButton >
                <h2 style={{ width: '100%', marginTop: '-10px'}}>{errorMessage}</h2>
                <p style={{ width: '100%', marginTop: '-10px'}}>Souhaitez vous que nous vous renvoyons un lien d'activation sur votre email ?
                    <Button onClick={resendMailFromForgot} variant="contained" style={{ flexBasis: '20%', marginBottom: '25px', marginLeft: '20px' }}>
                        M'envoyer le lien d'activation
                    </Button>
                </p>
            </DialogActions> : ''}
            <div />
            <LoadingIndicator open={loading} />
            <MySnackbar params={snack} />
            <ForgotPassword openDialog={openForgotPassword} setOpenDialog={setOpenForgotPassword} defaultEmail={connexionEmail} />
            <Paper elevation={0} className={classesConnexion.paperClass}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={props.manif.manifInfos.StatutInscription > 1 ? 6 : 12}>
                        <Card className={classesConnexion.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2" className={classesConnexion.cardTitle}>
                                    Connexion
                                </Typography>
                                <br />
                                <Typography variant="body2" component="p">
                                    J'ai déjà un compte bénévoles je souhaite m'authentifier
                                </Typography>
                            </CardContent>
                            <CardContent>
                                <TextField
                                    id="connexionEmail"
                                    name="connexionEmail"
                                    label="Votre adresse email"
                                    required
                                    fullWidth
                                    type={'email'}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={connexionEmail}
                                    onChange={(newValue) => {
                                        setConnexionEmail(newValue.target.value?.trim());
                                        setresendLinkMail(newValue.target.value?.trim());
                                    }}
                                    onKeyDown={(e) => submitOnEnter(e)}
                                    error={connexionEmailError}
                                    helperText={connexionEmailError && connexionEmailErrorMsg}
                                /><br /><br />
                                <TextField
                                    id="connexionPassword"
                                    name="connexionPassword"
                                    label="Votre mot de passe"
                                    required
                                    fullWidth
                                    type={'password'}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={connexionPassword}
                                    onChange={(newValue) => {
                                        setConnexionPassword(newValue.target.value?.trim())
                                    }}
                                    onKeyDown={(e) => submitOnEnter(e)}
                                    error={connexionPasswordError}
                                    helperText={connexionPasswordError && "Champ obligatoire"}
                                />
                                <br /><br />
                                <Link className={classesConnexion.linkStyle} onClick={handleForgetPassword}>Mot de passe oublié</Link>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth color="primary" variant="contained" onClick={handleConnect}>Connexion</Button>
                            </CardActions>
                            <CardActions>
                                <FormControlLabel
                                    control={<Checkbox checked={remember} onChange={handleRemember} color="primary" name="rememberMe" />}
                                    label="Se souvenir de moi"
                                />
                            </CardActions>
                        </Card>
                    </Grid>
                    {props.manif.manifInfos.StatutInscription > 1 && <Grid item xs={12} md={6}>
                        <Card className={classesConnexion.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2" className={classesConnexion.cardTitle}>
                                    S'inscrire
                                </Typography>
                                <br />
                                <Typography variant="body2" component="p">
                                    Je souhaite créer un compte pour pouvoir devenir bénévoles
                                </Typography>
                            </CardContent>
                            <CardContent>
                                <TextField
                                    id="inscriptionEmail"
                                    name="inscriptionEmail"
                                    label="Votre email"
                                    required
                                    fullWidth
                                    type={'email'}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={inscriptionEmail}
                                    onChange={(newValue) => {
                                        setInscriptionEmail(newValue.target.value?.trim())
                                        setresendLinkMail(newValue.target.value?.trim());// pour resend email
                                    }}
                                    error={inscriptionEmailError}
                                    helperText={inscriptionEmailError && inscriptionEmailErrorMsg}
                                    onKeyDown={(e) => submitSubscriptionOnEnter(e)}
                                /><br /><br />
                                <TextField
                                    id="inscriptionPassword"
                                    name="inscriptionPassword"
                                    label="Votre mot de passe"
                                    required
                                    fullWidth
                                    type={'password'}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={inscriptionPassword}
                                    onChange={(newValue) => {
                                        setInscriptionPassword(newValue.target.value?.trim())
                                    }}
                                    error={inscriptionPasswordError}
                                    helperText={inscriptionPasswordError && inscriptionPasswordErrorMsg}
                                    onKeyDown={(e) => submitSubscriptionOnEnter(e)}
                                />
                                <br /><br />
                                <TextField
                                    id="inscriptionPasswordRepeat"
                                    name="inscriptionPasswordRepeat"
                                    label="Confirmez Votre mot de passe"
                                    required
                                    fullWidth
                                    type={'password'}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={inscriptionPasswordRepeat}
                                    onChange={(newValue) => {
                                        setInscriptionPasswordRepeat(newValue.target.value?.trim())
                                    }}
                                    error={inscriptionPasswordRepeatError}
                                    helperText={inscriptionPasswordRepeatError && inscriptionPasswordRepeatErrorMsg}
                                    onKeyDown={(e) => submitSubscriptionOnEnter(e)}
                                />
                                <br /><br />
                                <Typography variant="body2" component="p">
                                    En vous inscrivant, vous acceptez que les responsables des activités aient accès à vos informations et que ces derniers, ainsi que le comité d’organisation des bénévoles, puissent vous contacter par email ou téléphone, pour les besoins de l’organisation de l'évènement.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth color="secondary" variant="contained" onClick={handleSubscribe}>S'inscrire</Button>
                            </CardActions>
                        </Card>
                    </Grid>}
                </Grid>
            </Paper>
        </main>
    )
}

const mapStateToProps = (state) => {
    return {
        manif: state.manif,
        user: state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getManifParams }, dispatch)
};
// export default Connexion;
export default connect(mapStateToProps, mapDispatchToProps)(Connexion)
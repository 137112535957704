import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { getRandomColor } from '../Fonctions/fonctions';

const BarChart = ({ labels, data }) => {
    const chartRef = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy(); // Détruire l'instance précédente si elle existe
        }

        const ctx = chartRef.current.getContext('2d');
        const colors = getRandomColor(data.length);
        const chartData = {
            labels: labels,
            datasets: data.map((dataset, index) => ({
                label: dataset.label,
                data: dataset.values,
                borderColor: colors[index],
                backgroundColor: `${colors[index]}80`,
                fill: false,
            })),
        };
        const newChartInstance = new Chart(ctx, {
            type: 'bar',
            data: chartData,
            options: {
                events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                maintainAspectRatio: false,
            },
        });

        setChartInstance(newChartInstance);

        return () => {
            newChartInstance.destroy(); // Détruire l'instance du graphique lors du démontage
        };
        // eslint-disable-next-line
    }, [data]);
    
    return <canvas ref={chartRef} />;
};


export default BarChart;

const configJoditEditor = {
    language: 'fr',
    toolbarButtonSize: 'medium',
    enter: 'DIV',
    readonly: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    autofocus: true,
    spellcheck: true,
    buttons: [
        'Cut', 'Copy', 'Paste', '|', 'Undo', 'Redo', '|', 'Link', 'Unlink', '|', 'Table', 'hr', '|', 'Bold', 'Italic', 'Underline', '|', 'ol', 'ul', '|', 'Indent', 'Outdent', '|', 'left', 'center', 'right', 'justify', '|', 'paragraph', 'font', 'fontsize', '|', 'source', '|',
        {
            name: 'Champs de fusion',
            list: {
                Nom: 'Nom',
                Prenom: 'Prénom',
                Email: 'Email',
                Telephone: 'Téléphone',
                LesActivites: 'Les Activités',
                TitreEvenement: 'Titre Evènement',
                FicheBenevole: 'Fiche Bénévole',
                TexteSoireeInfo: 'Texte Soirée Info"'
            },
            exec: function (e, control, $btn) {
                if ($btn.control.name !== "Champs de fusion") {
                    e.selection.insertHTML("[[" + $btn.control.name + "]]");
                }
            },
        }
    ],
    toolbarAdaptive: false,
}

export default configJoditEditor;

import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleLesBenevoles = makeStyles((theme) => createStyles({
  SelectedRow : {
      '& td': { backgroundColor: theme.palette.grey[200] },
  },
  iconButton: {
    marginLeft: 15,
  },
  exportBtn: {
    marginBottom: "5px",
    // marginLeft: "5px",
    fontSize:"11px"
  }
}));
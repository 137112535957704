import React from 'react';
import { AppBar, Box, Tabs, Typography, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import OngletParamGeneraux from "./OngletParamGeneraux";
import OngletCaracteristique from "./OngletCaracteristique";
import OngletGestionMateriel from "./OngletGestionMateriel";
import OngletGestionRepas from "./OngletGestionRepas";
import OngletListeTextes from "./OngletListeTextes";
import OngletFormulaireInscri from "./OngletFormulaireInscri";
import OngletInfoSupplAct from "./OngletInfoSupplAct";
import OngletConfigurationBase from "./OngletConfigurationBase";
import EmailList from '../../pages/emailList/EmailList';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  customTab: {
    fontWeight: "normal",
    '&:focus': {
      fontWeight: "bold",
    },
    '&:hover': {
      fontWeight: "bold",
    },
  }
}));


const ParametreTabs = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollableTabs"
        >
          <Tab className={classes.customTab} label="Gestion Paramètre généraux" {...a11yProps(0)} />
          <Tab className={classes.customTab} label="Gestion caractéristiques" {...a11yProps(1)} />
          <Tab className={classes.customTab} label="Gestion materiel" {...a11yProps(2)} />
          <Tab className={classes.customTab} label="Gestion repas" {...a11yProps(3)} />
          <Tab className={classes.customTab} label="Liste des textes" {...a11yProps(4)} />
          <Tab className={classes.customTab} label="Formulaire inscription" {...a11yProps(5)} />
          <Tab className={classes.customTab} label={<div><SupervisorAccountIcon style={{ verticalAlign: 'middle' }} /> Gestion infos supplémentaires Activités </div>} {...a11yProps(6)} />
          <Tab className={classes.customTab} label={<div><SupervisorAccountIcon style={{ verticalAlign: 'middle' }} /> Configuration de base </div>}  {...a11yProps(7)} />
          <Tab className={classes.customTab} label="Liste Emails" {...a11yProps(8)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OngletParamGeneraux />
      </TabPanel>
      <TabPanel value={value} index={1} >
        <OngletCaracteristique />
      </TabPanel>
      <TabPanel value={value} index={2} >
        <OngletGestionMateriel />
      </TabPanel>
      <TabPanel value={value} index={3} >
        <OngletGestionRepas />
      </TabPanel>
      <TabPanel value={value} index={4} >
        <OngletListeTextes />
      </TabPanel>
      <TabPanel value={value} index={5}  >
        <OngletFormulaireInscri />
      </TabPanel>
      <TabPanel value={value} index={6}  >
        <OngletInfoSupplAct />
      </TabPanel>
      <TabPanel value={value} index={7}  >
        <OngletConfigurationBase />
      </TabPanel>
      <TabPanel value={value} index={8}  >
        <EmailList />
      </TabPanel>
        </div>
  );
};

export default ParametreTabs;
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleFiltre = makeStyles((theme) => createStyles({
    formControl: {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      chips: {
        display: 'fixe',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 10,
        backgroundColor: "red"
      },
   
}));
import React, { useState } from "react";
import ExpandableRowTable from "../../components/emailsTable/EmailsTable";
import moment from 'moment';
import { Button, Grid, TextField } from "@material-ui/core";
import { useStyleEmailList } from "./EmailList.css";
export default function EmailList() {
    const classesEmailList = useStyleEmailList();
    const [localFrom, setLocalFrom] = useState(moment(new Date()).subtract(3, 'days').format("YYYY-MM-DD"));
    const [localTo, setLocalTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [from, setFrom] = useState(moment(new Date()).subtract(3, 'days').format("YYYY-MM-DD"));
    const [to, setTo] = useState(moment(new Date()).format("YYYY-MM-DD"));

    const handleFromChange = (eve) => {
        setLocalFrom(eve.currentTarget.value);
    };
    const handleToChange = (eve) => {
        setLocalTo(eve.currentTarget.value);
    };

    const searchData = () => {
        setFrom(localFrom);
        setTo(localTo);
    }

    return (
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Grid container>
                        <Grid item xs={2}>
                            <TextField
                                id="date"
                                label="Depuis"
                                type="date"
                                defaultValue={localFrom}
                                onChange={handleFromChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="date"
                                label="Jusqu'à"
                                type="date"
                                defaultValue={localTo}
                                onChange={handleToChange}
                            />
                        </Grid>
                        <Grid item xs={2} className={classesEmailList.container}>
                            <Button variant="contained" color="primary" style={{ justifyContent: 'flex-end' }} onClick={searchData}>Chercher</Button>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <ExpandableRowTable {...{ from, to }} />
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
    );
}

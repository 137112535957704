import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, InputAdornment } from '@material-ui/core';
import { MySnackbar } from '../mySnackbar/MySnackbar'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import rest from "../../rest";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { useStyleForgotPassword } from "./ForgotPassword.css";
import resendMail from '../general/modalResendMail';


const ForgotPassword = ({ openDialog, setOpenDialog, defaultEmail }) => {
    const classesForgotPassword = useStyleForgotPassword();
    const [forgotEmail, setForgotEmail] = useState(defaultEmail ? defaultEmail : "");
    const [forgotEmailError, setForgotEmailError] = useState(false);
    const [forgotEmailErrorMsg, setForgotEmailErrorMsg] = useState("");
    const [snack, setSnack] = useState({});
    const [loading, setLoading] = useState(false);
    const [sendMail, setSendMail] = useState(false);

    useEffect(() => {
        setForgotEmail(defaultEmail)
    }, [defaultEmail])

    const handleClose = () => {
        setOpenDialog(false);
    };

    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (re.test(email) && email !== "");
    }

    const handleAskPassword = () => {
        let error = false;

        if (forgotEmail === "") {
            error = true
            setForgotEmailError(true)
            setForgotEmailErrorMsg("Champ obligatoire")
        } else {
            if (!validateEmail(forgotEmail)) {
                error = true
                setForgotEmailError(true)
                setForgotEmailErrorMsg("Format email invalide")
            } else {
                setForgotEmailError(false)
                setForgotEmailErrorMsg("")
            }
        }

        if (!error) {
            setLoading(true)
            let paramsData = {}
            paramsData.vAction = "MdpOublie"
            paramsData.vEmail = forgotEmail
            rest.post('/4DACTION/ACCOUNT_GestionCompte2', paramsData).then(function (response) {
                if (response.data.Erreur !== "0") {
                    setSnack({ message: response.data.Message, show: true, severity: "error" })
                    if(response.data.sendMail){
                        setSendMail(true);
                    }
                } else {
                    // setOpenDialog(false);
                    setSnack({ message: response.data.Message, show: true, severity: "success" })
                }
                setLoading(false)
            })
        }
    }

    const submitOnEnter = (event) => {
        if (event.keyCode === 13) {
            handleAskPassword();
        }
    }

    const resendMailFromForgot = async () => {
            const response = await resendMail(forgotEmail);
            if (response && response.data.success === true) {
                setSnack({ message: response.data.Message, show: true, severity: "success" });
                handleClose();
            } else {
                setSnack({ message: response?.data?.Message, show: true, severity: "error" });
            }
            setLoading(false);
            setSendMail(true);
    }


    return (
        <>
            <LoadingIndicator open={loading} />
            <MySnackbar params={snack} />
            <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle className={classesForgotPassword.cardTitle} id="form-dialog-title">Mot de passe oublié</DialogTitle>
                <DialogContent>
                    <TextField
                        id="forgotEmail"
                        name="forgotEmail"
                        label="Votre adresse email"
                        required
                        fullWidth
                        type={'email'}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AlternateEmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={forgotEmail}
                        onChange={(newValue) => {
                            setForgotEmail(newValue.target.value)
                        }}
                        onKeyDown={(e) => submitOnEnter(e)}
                        error={forgotEmailError}
                        helperText={forgotEmailError && forgotEmailErrorMsg}
                    />
                </DialogContent>
                <DialogActions style={{flexWrap:'wrap-reverse'}}>
                    <Button onClick={handleClose} variant="contained" color="default">
                        Fermer
                    </Button>
                    {!sendMail ? <Button onClick={handleAskPassword} variant="contained" color="primary">
                        Demander mon mot de passe
                    </Button>
                    :<Button onClick={resendMailFromForgot} variant="contained" color="secondary" style={{ flexGrow: 4, flexBasis: '100%', marginBottom: '25px',}}>
                        Renvoyer le lien d'activation
                    </Button>
                    }                    
                </DialogActions>
            </Dialog>
        </>

    )
}

export default ForgotPassword;



import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyleInscriptionWish = makeStyles((theme) => createStyles({
    bodyContainer: {
        backgroundColor: "#E5E9EC",
        height: '100vh !important'
    },
    title: {
        display: 'flex',
        alignItems: "center",
        '&:hover': {
            color: "#fff",
            background: "#37A8AF",
        },
    },
    titleLink: {
        alignItems: "center",
        textAlign: 'center',
        color: "#37A8AF",
        fontWeight: 'bold',
        paddingLeft: '15px',
        '&:hover': {
            color: "#fff",
            background: "#37A8AF",
        },
    },
}));
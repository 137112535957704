import { makeStyles } from '@material-ui/core/styles';

export const useStylesOnglet = makeStyles((theme) => ({
  //Profil
  ongletProfil_rowAddressInfos: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  ongletProfil_row: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: 40,
    justifyContent: 'flex-start'
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },

  // InfosBenevoles / BenevolesTabs
  rootInfoBenevole: {
    maxWidth: '100%',
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  purpleBadge: {
    backgroundColor: "#932ab6 ",
    color: '#fff'
  },
  greenBadge: {
    backgroundColor: "#37A8AF ",
    color: '#fff'
  },
  orangeBadge: {
    backgroundColor: "#f39c12",
    color: '#fff',
  },
  smallIcon: {
    fontSize: ' 14px'
  },
  // Onglet Accréditaitons 
  margin: {
    margin: theme.spacing(1),
  },



}));


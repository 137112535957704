import { makeStyles, createStyles } from '@material-ui/core/styles';

const drawerWidth = 220;

export const useStyleMenu = makeStyles((theme) => createStyles({
   // sideBar
   drawer: {
    width: drawerWidth,
    flexShrink: 0,    
  },
   centering: {
    display: 'flex',  
    alignSelf:'center'
  },  
  textfieldConnexion:{
    color: 'white',
  },
  menuLink:{
      textDecoration:'none'
  },
  //appBar
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen, 
    }),
    fontSize: '0.5rem' 
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    fontSize: '0.5rem' 
  },
  linkStyle: {
    cursor: "pointer",
    color: "#000",
    fontSize:"12px"
  },
  rememberCheck: {
    color:"#FFF!important"
  }
}));
import React from 'react';

function SplitText({ text }) {
  const textLines = text.split('\n');

  return (
    <div>
      {textLines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
  );
}

export default SplitText;

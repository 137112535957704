import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, Chip, MenuItem, Input, ListItem, List, ListItemText, Divider, TextField, Button, Popover, ListSubheader } from '@material-ui/core';
import rest from "../../rest";
import { makeStyles } from '@material-ui/core/styles';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useStylesGlobal } from "../../pages/GlobalStyle.css";
import { MySnackbar } from '../../components/mySnackbar/MySnackbar'

const SortableItem = SortableElement(({ value, sortIndex }) => <ListItem><ListItemText primary={(sortIndex + 1) +") "+value} /></ListItem>);

const SortableList = SortableContainer(({ items, readOnly }) => {
    return (
        <List dense={true} component="ul" style={{ width: '100%', cursor: "pointer" }} subheader={
            <ListSubheader component="div" id="list-subheader" style={{ paddingLeft: "0px" }}>
                Ordre choix activités
            </ListSubheader>
        }>
            {items.map((value, index) => (
                <>
                    <SortableItem disabled={readOnly}  key={`item-${value}`} index={index} sortIndex={index} value={value} />
                    {index<items.length-1 && <Divider />}
                </>
            ))}
        </List>
    );
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const OngletWish = ({ idBenevole, readOnly }) => {
    const classesGlobal = useStylesGlobal();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [snack, setSnack] = useState({});
    const [dispoList, setDispoList] = useState([]);
    const [selectedDispoList, setSelectedDispoList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState([]);
    const [remarqueTranche, setRemarqueTranche] = useState("");
    const [remarqueGeneral, setRemarqueGeneral] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setLoading(true)
        rest.get(`/activites/recupDatesDispoWishlistModif.json`).then((response) => {
            setDispoList(response.data)
            setLoading(false)
        }, (error) => {
            console.log(error);
        });
        rest.get(`/activites/activitesDisponiblesAll.json`).then((response) => {
            setActivityList(response.data)
            setLoading(false)
        }, (error) => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        
        if (dispoList.length>0) {
            rest.post(`/benevols/getWishlist.json`, { vID: idBenevole }).then((response) => {
                if (response.data.length>0) {
                    let tempArray = []
                    for (let index = 0; index < response.data[0].Contenu.Dispo.length; index++) {
                        const element = response.data[0].Contenu.Dispo[index]
                        const splitPeriod = element.periode.split(",");
                        for (let index2 = 0; index2 < splitPeriod.length; index2++) {
                            let tmp = splitPeriod[index2];
                            if (!tmp.includes("Après-midi")) {
                                tmp = splitPeriod[index2].replace(/ /g, '')
                                tmp = tmp.replace(/-/g, ' - ')
                               
                            } else {
                                tmp = splitPeriod[index2].replace(/ /g, '')
                            }
   
                            tempArray.push(element.date + " : " + tmp)
                        }
                    }
                    setSelectedDispoList(tempArray)

                    // Activités
                    let tempArray2 = []
                    for (let index = 0; index < response.data[0].Contenu.ChoixActivites.length; index++) {
                        const element = response.data[0].Contenu.ChoixActivites[index];
                        tempArray2.push(element.nom)
                    }
                    setSelectedActivity(tempArray2)

                    setRemarqueGeneral(response.data[0].Remarques.RemarqueGlobale)
                    setRemarqueTranche(response.data[0].Remarques.RemarquesTH)

                }
            }, (error) => {
                console.log(error);
            });
        }
       
    }, [idBenevole, dispoList])

    function getSelectedActivityParams(selected) {
        // console.log("selected", selected)
        let newSelectedActivity = []

        for (let index = 0; index < selected.length; index++) {
            const element = selected[index];
            const indexAct = activityList.map(e => e.Nom).indexOf(element);
            if (indexAct >=0) {
                newSelectedActivity.push({ id: activityList[indexAct].ID, nom: activityList[indexAct].Nom, treated: false })
            }
        }

        // let tmp = []
        // tmp = activityList.filter(item => selected.indexOf(item.Nom) >= 0)
        // console.log("tmp", tmp)
        // for (let index = 0; index < tmp.length; index++) {
        //     const element = tmp[index];
        //     newSelectedActivity.push({ id: element.ID, nom: element.Nom, treated: false })
        // }
        // console.log("newSelectedActivity", newSelectedActivity)
        return JSON.stringify(newSelectedActivity)
    }

    function getSelectedDispoParams(selected) {
        let newDispo = [];
        for (let index = 0; index < selected.length; index++) {
            const splitElement = selected[index].split(" : ");
            let currentIndex = newDispo.findIndex(item => item.date === splitElement[0].trim())
            if (currentIndex >=0) {
                newDispo[currentIndex].periode = newDispo[currentIndex].periode + ", " + splitElement[1].trim()
            } else {
                newDispo.push({ date: splitElement[0].trim(), periode: splitElement[1].trim() })
            }
        }
        return JSON.stringify(newDispo)
    }

    const handleChangeDispo = (event) => {
        setSelectedDispoList(event.target.value);
    };

    const handleChangeActivity = (event) => {
        setSelectedActivity(event.target.value);
    };

    const handleChangeRemarqueTranche = (event) => {
        setRemarqueTranche(event.target.value);
    };

    const handleChangeRemarqueGenerale = (event) => {
        setRemarqueGeneral(event.target.value);
    };
   
    const handleUpdateWishlist = () => {
        handleClose();
        setLoading(true)
        let params = {}
        params.vID = idBenevole
        params.vDispo = getSelectedDispoParams(selectedDispoList)
        params.vActivitesChoisies = getSelectedActivityParams(selectedActivity)
        params.vRemarquesTH = remarqueTranche
        params.vRemarqueGenerale = remarqueGeneral

        rest.post(`/activites/modifWhislist.json`, params).then((response) => {
            if (response.data.Message!=="Ok") {
                setSnack({ message: response.data.Message, show: true, severity: "error" })
            } else {
                setSnack({ message: "Souhaits mis à jour avec succès.", show: true, severity: "success" })
            }    
            setLoading(false)
        }, (error) => {
            console.log(error);
            setLoading(false)
        });
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <MySnackbar params={snack} />
            <LoadingIndicator open={loading} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="trancheChoiceLabel">Disponibilités</InputLabel>
                        <Select
                            labelId="trancheChoiceLabel"
                            id="trancheChoice"
                            multiple
                            value={selectedDispoList}
                            onChange={handleChangeDispo}
                            input={<Input id="trancheChoiceChip" />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} color="primary" />
                                    ))}
                                </div>
                            )}
                            inputProps={{ readOnly: readOnly }}
                        >
                            {dispoList.map((item) => (
                                <MenuItem key={item.valeur} value={item.valeur}>
                                    {item.valeur}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="activityChoiceLabel">Choix Activité</InputLabel>
                        <Select
                            labelId="activityChoiceLabel"
                            id="activityChoice"
                            multiple
                            value={selectedActivity}
                            onChange={handleChangeActivity}
                            input={<Input id="activityChoiceChip" />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} color="primary" />
                                    ))}
                                </div>
                            )}
                            inputProps={{ readOnly: readOnly }}
                        >
                            {activityList.map((item) => (
                                <MenuItem key={item.ID} value={item.Nom} disabled={!item.Active}>
                                    {item.Nom}{!item.Active && "(Désactivée)"}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <SortableList
                            items={selectedActivity}
                            readOnly={readOnly}
                            onSortEnd={
                                ({ oldIndex, newIndex }) => {
                                    setSelectedActivity((items) => 
                                        arrayMoveImmutable(items, oldIndex, newIndex)
                                );
                                }
                            } 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="remarques-tranche-horaire"
                        label="Remarques Tranches horaires"
                        multiline
                        rows={4}
                        value={remarqueTranche}
                        onChange={handleChangeRemarqueTranche}
                        fullWidth
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="remarques-general"
                        label="Remarques générales"
                        multiline
                        rows={4}
                        value={remarqueGeneral}
                        onChange={handleChangeRemarqueGenerale}
                        fullWidth
                        disabled={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleClick} disabled={readOnly}>Enregistrer</Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className="popover_class"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Button variant="contained" className={classesGlobal.buttonFermer} onClick={handleUpdateWishlist}>Confirmer modification</Button>
                    </Popover>
                </Grid>
            </Grid> 
        </>
          
    );
};

export default OngletWish;
import React from 'react';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // Ajout de zéros devant le jour et le mois si nécessaire
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
};

const DateFormatted = ({ dateString }) => {
  const formattedDate = formatDate(dateString);

  return <span>{formattedDate}</span>;
};

export default DateFormatted;

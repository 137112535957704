import React, { useState, useEffect } from 'react'
import { useStyles } from "./ChoixDates.css";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Alert } from '@material-ui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import rest from '../../../rest';


const ChoixDates = (props) => {
  const [showWarning, setShowWarning] = useState(false)
  const classes = useStyles();
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const displayedDates = props.displayedDates;
  const setDisplayedDates = props.setDisplayedDates;
  const displayDateTxt = props.displayDateTxt;
  const setDisplayDateTxt = props.setDisplayDateTxt;
  const manifInfo = props.manifInfo;
  const [expanded, setExpanded] = useState('');
  const [expandedSub, setExpandedSub] = useState('');
  const [expandedSubSub, setExpandedSubSub] = useState('');  
  const [firstActivity, setFirstActivity] = useState([])
  const [activity, setActivity] = useState([])
  const [dayActivity, setDayActivity] = useState([])
  const [trancheHoraire, setTrancheHoraire] = useState([])
  const selectedCheckboxes = props.selectedCheckboxes;
  const setSelectedCheckboxes = props.setSelectedCheckboxes;  
  const selectedActNom = props.selectedActNom;
  const setSelectedActNom = props.setSelectedActNom;
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }


  useEffect(() => {//pour reprendre la selection quand on revient
    setSelectedChoices(selectedCheckboxes)
  }, [selectedCheckboxes])

  useEffect(() => {
    rest.get('/activites/actionsv2/firstLevelActivity.json')
      .then((response) => {
        setFirstActivity(response.data);
        console.log('firstLevelActivity ===>> ', response.data);
      })

      rest.get('/activites/actionsv2/activitesDisponibles.json')
      .then((response) => {
        setActivity(response.data)
        console.log('activitesDisponibles ===>> ', response.data);
      })

      rest.get('/activites/actionsv2/getDateOfActivity.json')
      .then((response) => {
        setTrancheHoraire(response.data);
        getDay(response.data);
        console.log('setTrancheHoraire ===>> ', response.data);
      })
  }, [])
  
  const getDay = (date) => {
      let arrayDay = [];
      date.forEach( item => {
        if(arrayDay.find(day => day.parentId === item.parentId && item.longDate === day.Nom) === undefined){
          arrayDay.push({parentId: item.parentId, Nom: item.longDate, ID : item.ID, DateActivite : item.DateActivite, short: item.shortDate})
        }
      });
      setDayActivity(arrayDay);
      
  }

  const showNext = () => {
    let nbTrancheOk = true;
    for (let index = 0; index < displayedDates.length; index++) {
      const element = displayedDates[index];
      let countTrancheByDay = element.periode.split(",")
      if (countTrancheByDay.length < manifInfo.NbTrancheMinWishlist) {
        nbTrancheOk = false;
      }
    }

    var nbChoix = (Object.keys(displayedDates).length);

    if ((nbChoix < manifInfo.NbMinWishlist) || (!nbTrancheOk)) {
      scrollToTop();
      setShowWarning(true);
    } else {
      setShowWarning(false);
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  
  const handleExpand = (panel, niveau) => (event, newExpanded) => {
    switch (niveau) {
      case "1":
        setExpanded(newExpanded ? panel : false);
        setExpandedSub('');
        setExpandedSubSub('');
        break;
      case "2":
        setExpandedSub(newExpanded ? panel : false);
        setExpandedSubSub('');
        break;
      default:
        setExpandedSubSub(newExpanded ? panel : false);
        break;
    }
  };

  const handleChange = (item) => (e) => {
    const newDate = [];
    const newActibities = [];
    const matches = document.querySelectorAll('input[type="checkbox"]:checked');
    matches.forEach(function(chkBox) {
      const words = chkBox.value.split('__');
      let date = newDate.find(ob => ob.date === words[1] );
      if(date === undefined){
          newDate.push({periode:words[0], date: words[1], IDs: words[5], shortDate: words[2]});
      } else {
        let periodes = date.periode.indexOf(words[0]);
        if(periodes === -1){
          date.periode += ', ' + words[0];
          date.IDs += ',' + words[5];
        }
      }
      if(newActibities.find(ob => ob.ID === words[3] ) === undefined) {newActibities.push({ID:words[3], Nom:words[4]});}
    });    
    setSelectedActNom(newActibities)
    setDisplayDateTxt(newDate);  
    setDisplayedDates(newDate);
    
    const targetName = e.target.id;
    const splitDate = targetName.split("_");
    var index = selectedChoices.indexOf(targetName);
    var arrayChoices = [...selectedChoices];
    var arrayDates = [...selectedDates];
    if (index !== -1) {
      arrayChoices.splice(index, 1);
      arrayDates.splice(index, 1);
      var ckAll = splitDate[0] + "_All";
      var indexAll = selectedChoices.indexOf(ckAll)
      if (indexAll >= 0) {
        arrayChoices.splice(indexAll, 1);
        arrayDates.splice(index, 1);
      }
      setSelectedChoices([...arrayChoices])
      setSelectedCheckboxes([...arrayChoices])
      setSelectedDates([...arrayDates])
    } else {
      setSelectedCheckboxes([...selectedChoices, targetName])
      setSelectedChoices([...selectedChoices, targetName])
      setSelectedDates([...selectedDates, splitDate[0]])
      arrayChoices.push(targetName)
    }

  };

  const domAcordeon = (key, index, level, style, style2 = {}) => {
    return (
        <Accordion key={`${level}_${index}`} id={`${level}_${index}`} square className={level === '3' ? '' : classes.accordion} style={style2}
          expanded={level === '1' ? expanded === `panel${key.ID}` : level === '2' ? expandedSub === `panel${key.ID}`: expandedSubSub === `panel${key.ID}`} onChange={handleExpand(`panel${key.ID}`, level)} >
          <AccordionSummary
            style={style}
            className={classes.accordionSummary}
            key={`accordionsum_${index}_${key.ID}`}
            expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="span">
              {key.Nom}                  
            </Typography>
          </AccordionSummary>
          <AccordionDetails key={`details_${index}_${key.ID}`} className={classes.accordionDetail} >
            {key.Description}
            {
              level === '1' && activity.filter(item => item.parentId === key.ID)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {domAcordeon(item, index, '2', { backgroundColor: '#cccccc', display:'flex'})}
                  </React.Fragment>
                ))
            }
            {
              level === '2' && dayActivity.filter(item => item.parentId === key.ID)
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {domAcordeon(item, index, '3', { backgroundColor: '#f2f2f2' }, { backgroundColor: '#f2f2f2', width: '40%' })}
                  </React.Fragment>
                ))
            }
            {
              level === '3' && <React.Fragment key={index}>
                  <FormGroup>
                    {trancheHoraire.filter(item => item.parentId === key.parentId && item.DateActivite === key.DateActivite)
                      .map((item, index) => (
                        
                          <FormControlLabel key={`fcl_${index}_${item.ID}`}
                              control={
                                <Checkbox
                                  key={`ck_${index}_${item.ID}`}
                                  color="primary"
                                  checked={selectedCheckboxes.indexOf(`id_${index}_${item.ID}`) > -1}
                                  onChange={handleChange(item)}
                                  id={`id_${index}_${item.ID}`}
                                  name={item.DateActivite}
                                  inputProps={{ "data-parent": key.Activite }}
                                  className='checkDate'
                                  value={`${item.Alias}__${item.longDate}__${item.shortDate}__${item.parentId}__${item.parentNom}__${item.ID}`}
                                />
                              }
                              label={item.Alias}
                            />
                      ))}
                    </FormGroup>
                </React.Fragment>
            }
          </AccordionDetails>
        </Accordion>
    )
  }
  
  return (
    <React.Fragment >
      <main className={classes.content}>
        {manifInfo.TexteChoixDispo !== "" &&
          <Grid container className={classes.marginGrid}>
            <Grid item xs={12} >
              <Alert severity="warning" className={classes.alertCustom}>
                <div dangerouslySetInnerHTML={{ __html: manifInfo.TexteChoixDispo }} />
              </Alert>
            </Grid>
          </Grid>
        }
        {showWarning &&
          <Grid container className={classes.marginGrid} >
            <Grid item xs={12} className={classes.alertCustom}>
            <Alert severity="error">
              {manifInfo.Ref === "chi"
                  ? `Vous devez sélectionner au minimum ${manifInfo.NbTrancheMinWishlist} tranches horaires par jour sur ${manifInfo.NbMinWishlist} jours différents, en privilégiant les disponibilités pendant la manifestation, pour passer à l'étape suivante.`
                  : `Tu dois sélectionner au minimum ${manifInfo.NbTrancheMinWishlist} tranches horaires par jour sur ${manifInfo.NbMinWishlist} jours différents, en privilégiant les disponibilités pendant la manifestation, pour passer à l'étape suivante.`
              }
              {manifInfo.NbActMinWishlist === manifInfo.NbActMaxWishlist
                  ? (manifInfo.Ref === "chi"
                    ? "Vous devez selectionner " + manifInfo.NbActMaxWishlist + " missions pour enregistrer votre sélection."
                    : "Tu dois selectionner " + manifInfo.NbActMaxWishlist + " missions pour enregistrer ta sélection."
                  )
                  : (manifInfo.Ref === "chi"
                    ? `Vous devez selectionner entre ${manifInfo.NbActMinWishlist} et ${manifInfo.NbActMaxWishlist} missions pour enregistrer votre sélection.`
                    : `Tu dois selectionner entre ${manifInfo.NbActMinWishlist} et ${manifInfo.NbActMaxWishlist} missions pour enregistrer ta sélection.`
                  )
              }
            </Alert>

            </Grid>
          </Grid>
        }
        <Grid container >
          <Grid item xs={12} sm={7}>
            {firstActivity.map( (key, index) => {
              return (
                <React.Fragment key={index}>
                  {domAcordeon(key, index, '1', { backgroundColor: '#b7b7b7' })}
                </React.Fragment>
              )
            })}

          </Grid>
          <Grid item xs={false} sm={1}></Grid>
          <Grid item xs={12} sm={3} >
            <h2 className={classes.titreDatesSelect} >Dates sélectionnées</h2>
            {displayDateTxt.map((key, value) => {
              return <div key={key.date}>
                <h3 className={classes.marginDate}>{key.date}</h3>
                <p className={classes.marginPeriode}>{key.periode}</p>
              </div>
            })}
            <Button size="small" fullWidth variant="contained" color="secondary" onClick={showNext}>
              Valider mes choix
            </Button>
            <h2 className={classes.titreChoix}>Activités choisies</h2>
            <ul>
              {selectedActNom.map((key, i) =>
                <li className={classes.actChoisies} key={i}>{key.Nom} ({i+1})</li>
              )}
            </ul>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    activites: state.activites,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoixDates)
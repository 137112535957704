import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    "& p": {
      fontSize: '1rem'
    }, 
    "& li": {
      fontSize: '1rem'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  marginGrid:{
    marginBottom: '2%'
  },
  marginDate:{
    marginBottom:'-10px'
  },
  marginPeriode:{
    marginLeft:'15px', 
    paddingTop:'0px'
  },
  titreDatesSelect:{
    color: "#f50057",
    fontSize: '1.6em'
  },
}));
  
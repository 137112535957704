import React from 'react';

const OngletFormulaireInscri = () => {
    return (
        <div>
            onglet 6
        </div>
    );
};

export default OngletFormulaireInscri;